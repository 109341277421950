import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMessage } from "../../dashboard/reducer";
import "./notifications.scss";

// type Props = {
//   message?: string;
//   isShow?: boolean;
//   removeDialog?: any;
// };

export default function Error() {
const dispatch = useDispatch();
const { error_message } = useSelector(
    (state: any) => state?.unified
);
  // const [show, setShow] = useState<any>(true);
  useEffect(() => {
    if(error_message) {
      setTimeout(() => {
        dispatch(setErrorMessage(null));
      }, 3000);
    }
  }, [dispatch, error_message]);

  return error_message ? (
    <div
      //   onClick={() => {
      //     setShow(!show);
      //     removeDialog && removeDialog();
      //   }}
      className={"dialog"}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "end",
          justifyContent: "end",
        }}
        // className={"h-full flex items-end justify-end"}
      >
        <div
          style={{
            height: "8.81vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bottom: "12%",
            padding: "0 1.5rem",
            background: "#FFE9D9",
            borderLeft: "4px solid #FA703F",
            cursor: "pointer",
            position: "relative",
          }}
          className={`dialog_container cursor-pointer relative mx-auto `}
        >
          <p
            style={{ color: "#BC4C2E" }}
            // className={`success text-[#667085]`}
          >
            {error_message}
          </p>
        </div>
      </div>
    </div>
  ) : null;
}
