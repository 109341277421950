import React, { useEffect } from 'react';
import user_avatar from "../../assets/icons/user.svg";
import twiterIcon from "../../assets/icons/twitter-icon.svg";
import googleIcon from "../../assets/icons/google-line-1.2.svg";
import facebook from "../../assets/icons/facebook-reviews.svg";
import testimonial from "../../assets/icons/video-chat-line-1.2.svg";
import feedback from "../../assets/icons/feedback-line-1.2.svg";
import { useSelector } from 'react-redux';

const Testimonials = ({data}: any) => {
  const { workspaces } = useSelector((state: any) => state?.headerState);
  const {workspaceSettings} = useSelector((state: any) => state?.workspaceManagementState);

  // useEffect(() => {
  //   console.log({workspaces, workspaceSettings})
  // }, [""]);
  const getImage = (provider: any) => {
    // console.log({ provider });

    switch (provider) {
      case "google":
        return googleIcon;
      case "facebook":
        return facebook;
      case "twitter":
        return twiterIcon;
      case "customerfeedback":
        return feedback;
      case "videotestimonials":
        return testimonial;
      default:
        return googleIcon;
    }
  };
  return (
      <React.Fragment>
          <table>
              <thead></thead>
              <tbody>
              {data?.map((e: any, i: any) => {
                  return (
                    <tr
                      key={i}
                      className=" border-bottom"
                    >
                      <td
                        className="text-start"
                        style={{
                          fontWeight: 600,
                          // fontSize: 12,
                          verticalAlign: "middle",
                        }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{ gap: 2}}
                        >
                          <div
                            className="border rounded-circle user-info"
                          >
                          {
                            e?.imageUrl
                            ? <img
                                className="rounded-circle"
                                src={e?.imageUrl || user_avatar}
                                alt="avatar"
                              />
                            : <span className='special-letter-image' style={{fontSize: "0.5rem", padding: '5px', background: '#14B0BF'}}>
                                {
                                  e?.reviewerDisplayName?.split(" ")?.slice(0, 2)?.length === 2
                                  ? e?.reviewerDisplayName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                                  : e?.reviewerDisplayName?.substr(0, 2).toUpperCase()
                                }
                              </span>
                            }
                          </div>
                          <span className="mx-1 user-name">
                            {e.reviewerDisplayName?.length > 5
                              ? e?.reviewerDisplayName.substring(0, 5) + ""
                              : e.reviewerDisplayName || "User"}
                          </span>
                        </div>
                      </td>
                      <td
                        className="text-start"
                        style={{ width: "55%", verticalAlign: "middle" }}
                      >
                        <small
                          className="service-des"
                        >
                          {e.reviewComment?.length > 20
                            ? e?.reviewComment?.substring(0, 20) + "..."
                            : e?.reviewComment ||
                              "As the brand, so is the service"}
                        </small>
                      </td>
                      <td
                        className="text-start"
                        style={{ verticalAlign: "middle", width: "10%" }}
                      >
                        <span className='rating'>
                          {e?.rating || 0}
                        </span>
                      </td>
                      <td
                        className="text-center"
                        style={{ verticalAlign: "middle", width: "5%" }}
                      >
                        <img
                          className=""
                          src={getImage(e?.Provider?.toLowerCase())}
                          alt="provider"
                        />
                      </td>
                    </tr>
                  );
              })}
              </tbody>
          </table>
      </React.Fragment>
  );
}

export default Testimonials;
