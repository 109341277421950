import React, { useEffect, useState } from 'react';
import { Button, Input, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getTemplateImage } from '../../../../../../../dashboard/image_service';
import { RootState } from '../../../../../../../store';
import { removeQuickReplyTemplate, setQuickReplyButtonsTemplate, toggleInitializedTemplate } from '../../../../../reducer';
import { setErrorMessage } from '../../../../../../../dashboard/reducer';
const QuickReplyButtons: React.FC<any> = () => {
    const dispatch = useDispatch();
    const {
        toggle_template_edition,
        quick_reply_buttons_template, is_initialized_template
    }: any = useSelector((state: RootState) => state?.promotionsState);
    const [selectedType, setSelectedType] = useState<any>("Reply");
    const [replyButtons, setReplyButtons] = useState<any[]>([]);
    const setEditableReplyButtons = (updatable_reply_buttons: any) => { 
        if (updatable_reply_buttons?.length) {
            const replyButtons = updatable_reply_buttons.map((btn: any) => {
                return {
                    id: btn?.id,
                    templateId: btn?.templateId,
                    buttonText: btn?.buttonText,
                    updateType: "update",
                }
            });
            setReplyButtons(replyButtons);
        }
    };

    useEffect(() => {
        // console.log({ quick_reply_buttons_template });
        if (!replyButtons?.length || is_initialized_template) {
            setEditableReplyButtons(quick_reply_buttons_template);
            dispatch(toggleInitializedTemplate(false));
        };
    }, [replyButtons, is_initialized_template]);
    
    const handleAddReplyButton = () => {
        if (replyButtons?.length > 2) return;
        if (toggle_template_edition?.isPromotionOptOut) {
            if (replyButtons?.length > 1) {
                // dispatch(setErrorMessage("You can only add 2 quick reply buttons for opt-out promotion!"));
                return;
            };
        }

        const all_current_buttons = [
            ...replyButtons,
            // id will be 1, 2, 3 sequentially
            {
                // id: Number(new Date()),
                id: replyButtons?.length + 1,
                // type: selectedType,
                templateId: "",
                buttonText: "",
                updateType: "insert"
            }
        ];
        setReplyButtons(all_current_buttons);
        dispatch(setQuickReplyButtonsTemplate(all_current_buttons));
    };

    const handleRemoveReplyButton = (id?: any) => {
        const removable_data = replyButtons?.find(button => button.id === id);
        const filter_buttons = replyButtons?.filter(button => button.id !== id)
            .map((btn: any, index: number) => ({ ...btn, id: btn?.updateType === "insert" ? index + 1 : btn?.id }));
        setReplyButtons(filter_buttons);
        dispatch(setQuickReplyButtonsTemplate(filter_buttons));
        if (removable_data?.updateType !== "insert") {
            dispatch(removeQuickReplyTemplate({
                id: id,
                templateId: removable_data?.templateId,
                buttonText: removable_data?.buttonText,
                updateType: "delete"
            }))
        }
    };

    const handleChange = (value: any, id: any) => {
        if (value?.length > 25) {
            // dispatch(setErrorMessage("Button text should not more than 25 characters!"));
            return;
        }
        const updated_reply_buttons = replyButtons?.map((btn: any, index: number) => (
            btn?.id === id ? {
                ...btn,
                buttonText: value,
                isUpdatable: true
            } : btn
        ));
        setReplyButtons(updated_reply_buttons);
        dispatch(setQuickReplyButtonsTemplate(updated_reply_buttons));
    }

    return (
        <React.Fragment>
            <div className='quick-reply-buttons-form-container'>
                <div className='title-content'>
                    <div className='title'>Quick Reply Buttons</div>
                    <div className='optional'>{"(Optional)"}</div>
                </div>
                <div className="selector-container text-center">
                    <Input
                        readOnly={true}
                        placeholder=""
                        className='input-select'
                        value={selectedType}
                        onChange={(value) => setSelectedType(value)}
                    />
                        {/* Quick Reply */}
                        {/* <Select.Option value="Image">Image</Select.Option>
                        <Select.Option value="Video">Video</Select.Option>
                        <Select.Option value="Document">Document</Select.Option> */}
                    {/* </Select> */}
                    <img
                        onClick={() => {
                            if (selectedType) {
                                handleAddReplyButton();
                            }
                        }}
                        src={getTemplateImage("add-line")}
                        alt="add-line"
                    />
                </div>

                <div className="quick-reply-form">
                    {replyButtons.map((btn: any, index: any) => (
                        <div key={index} className={`form ${(btn?.isPromoted || btn?.buttonText === "Stop Promotions") ? "d-none" : ""}`}>
                            <label className="form-item">
                                Button Text {`(Max 25 Characters)`}
                            </label>
                            <div className="form-item">
                                <Input
                                    placeholder="Enter value"
                                    value={btn?.buttonText}
                                    autoSave="off"
                                    autoComplete="off"
                                    onChange={(e: any) => {
                                        const { value } = e.target;
                                        if(value === "Stop Promotions"){
                                            dispatch(setErrorMessage("Button text should not be 'Stop Promotions'!"));
                                            return;
                                        }
                                        const inputValue = value?.replace(/\s+/g, ' ');
                                        handleChange(inputValue, btn?.id);
                                    }}
                                />
                                <div className='remove-item' onClick={() => handleRemoveReplyButton(btn?.id)}>Remove</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    )
};

export default QuickReplyButtons;
