// import { Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import save_icon from '../../../../assets/icons/settings/save-icon.svg';
import edit_icon from '../../../../assets/icons/settings/edit-icon.svg';
import upload_icon from '../../../../assets/icons/settings/upload-icon.svg';
import { useNavigate } from 'react-router';
import { getBotDetailsSettings, updateBotDetailsSettings } from '../../botReducer';
import { getWorkspaceSettings } from '../../../workspace-management/workSpaceReducer';
import { getProfileInfo } from '../../../profile-management/profileManageReducer';
import { Spinner } from 'react-bootstrap';
import "./ClapUpBotSettings.scss";
import { setErrorMessage, setSuccessMessage } from '../../../../dashboard/reducer';
import { updateBotFileData, uploadBotFileData } from '../../saga';
// import { modifyString } from '../../../../utils/mediaService';
import { Switch } from 'antd';

const ClapUpBotSettings: React.FC = () => {
    // Bot Name
    const [botName, setBotName] = useState<any>(null);
    const [isBotNameActive, setIsBotNameActive] = useState<any>(false);
    const [isBotNameError, setIsBotNameError] = useState<any>(false);
    // Bot Headline
    const [botHeadline, setBotHeadline] = useState<any>(null);
    const [isBotHeadlineActive, setIsBotHeadlineActive] = useState<any>(false);
    const [isBotHeadlineError, setIsBotHeadlineError] = useState<any>(false);

    // Bot Description
    const [botDescription, setBotDescription] = useState<any>(null);
    const [isBotDescriptionActive, setIsBotDescriptionActive] = useState<any>(false);
    const [isBotDescriptionError, setIsBotDescriptionError] = useState<any>(false);

    // Greeting Message
    const [botGreetingMessage, setGreetingMessage] = useState<any>(null);
    const [isBotGreetingMessageActive, setIsBotGreetingMessageActive] = useState<any>(false);
    const [isBotGreetingMessageError, setIsBotGreetingMessageError] = useState<any>(false);

    // Primary Color Code (Hex)
    const [defaultPrimaryColorCodes, setDefaultPrimaryColorCodes] = useState<any>([
        "#3B82F6", "#007736", "#C084FC", "#D88200", "#F59E0B", "#EF4444", "#8B5CF6", "#6B7280", "#1D4ED8", "#047857", "#000000"
    ]);
    const [primaryColor, setPrimaryColor] = useState<any>(null);
    
    const [defaultSecondaryColorCodes, setDefaultSecondaryColorCodes] = useState<any>([
        "#3B82F6", "#007736", "#C084FC", "#D88200", "#F59E0B", "#EF4444", "#8B5CF6", "#6B7280", "#1D4ED8", "#047857", "#000000"
    ]);
    const [secondaryColor, setSecondaryColor] = useState<any>(null);
    // PDF Management
    const [botFile, setBotFile] = useState<any>("");
    const [botFileData, setBotFileData] = useState<any>(null);
    const [botFileURL, setBotFileURL] = useState<any>("");
    // Enable the bio-page
    const [isEnabled, setIsEnabled] = useState<any>(null);

    // const { workspaceSettings } = useSelector((state: any) => state?.workspaceManagementState);
    const { success_message } = useSelector((state: any) => state?.unified);

    const [loading, setLoading]: any = React.useState(false);
    const dispatch = useDispatch();
    // const {personalInfo} = useSelector((state: any) => state?.profileManageSettingState);
    const { all_bot_details } = useSelector((state: any) => state?.botState);
    const [clapUpBotDetails, setClapUpBotDetails] = useState<any>(null);
    
    useEffect(() => {
        if (all_bot_details?.length) {
            console.log({ all_bot_details });
            
            // const clapUpBotDetails = all_bot_details?.find((item: any) => item?.aiChatBotName);
            const clapUpBotDetails = all_bot_details[0];
            setClapUpBotDetails(clapUpBotDetails);
        }
        // else {
        //     dispatch(getBotDetailsSettings());
        // }
    }, [all_bot_details?.length, success_message]);
    
    useEffect(() => {
        console.log({ clapUpBotDetails, botName });
        
        if (clapUpBotDetails !== null) {
            setBotName(clapUpBotDetails?.aiChatBotName);
            setBotHeadline(clapUpBotDetails?.aiChatBotHeadLine);
            setBotDescription(clapUpBotDetails?.aiChatBotDescription);
            setGreetingMessage(clapUpBotDetails?.greetingMsg);
            setPrimaryColor(clapUpBotDetails?.primaryColor);
            setSecondaryColor(clapUpBotDetails?.secondaryColor);
            setBotFileURL(clapUpBotDetails?.knowledgeBase);
            setIsEnabled(Number(clapUpBotDetails?.isEnabledInBioPage) ? true : false);
        }
    }, [
        clapUpBotDetails?.aiChatBotName, clapUpBotDetails?.aiChatBotHeadLine,
        clapUpBotDetails?.aiChatBotDescription, clapUpBotDetails?.greetingMsg,
        clapUpBotDetails?.primaryColor, clapUpBotDetails?.secondaryColor,
        clapUpBotDetails?.knowledgeBase, clapUpBotDetails?.isEnabledInBioPage
    ]);
    

    const handleUpdateBotDetailsSettingField = (fieldName: any, fieldValue: any, setState?: any) => {
        // console.log({columnValue});
        if(!fieldValue && setState){
            return setState && setState("error");
        }
        const dataObject: any = {
            // clapupId: sessionStorage.getItem('cuid'),
            // workSpaceId: sessionStorage.getItem('wuid'),
            // roleId: sessionStorage.getItem('roleId'),
            clapupUserId: sessionStorage.getItem('cuid'),
            aiChatBotId: clapUpBotDetails?.aiChatBotId,
            fieldName: fieldName,
            fieldValue: fieldValue,
            // linktype: "BiopageclapUpBotDetails",
        }
        console.log({dataObject});
        dispatch(updateBotDetailsSettings(dataObject));

        // callBack && callBack();
        dispatch(setSuccessMessage("Settings saved"));
    }

    // Bot File Management
    const handleBotFileSubmit = async () => {
        const {
            aiChatBotId, aiChatBotName,
            aiChatBotHeadLine, aiChatBotDescription,
            greetingMsg, primaryColor, secondaryColor,
            isEnabledInBioPage, contentId, iFrameUrl
        } = clapUpBotDetails || {};
        const insert_object: any = {
            aiChatBotId: aiChatBotId || "",
            aiChatBotName: aiChatBotName || "",
            aiChatBotHeadLine: aiChatBotHeadLine || "",
            aiChatBotDescription: aiChatBotDescription || "",
            greetingMsg: greetingMsg || "",
            primaryColor: primaryColor || "",
            secondaryColor: secondaryColor || "",
            isEnabledInBioPage: isEnabledInBioPage ? "true" : "false",
            contentId: contentId || "",
            iFrameUrl: iFrameUrl || "",
            clapupUserId: sessionStorage.getItem("cuid") || localStorage.getItem('cuid'),
            workspaceId: sessionStorage.getItem("wuid") || localStorage.getItem('wuid'),
        }
        console.log({ insert_object });
        
        const { data, success, message } = clapUpBotDetails?.contentId
            ?   await updateBotFileData({
                    contentFile: botFileData, contentType: "AIChatBotFiles",
                    contentId: clapUpBotDetails?.contentId,
                    uniqueNo: clapUpBotDetails?.uniqueNo,
                    aiChatBotId: clapUpBotDetails?.aiChatBotId
                })
            :   await uploadBotFileData({
                    contentFile: botFileData,
                    contentType: "AIChatBotFiles",
                    aiChatBotId: clapUpBotDetails?.aiChatBotId
                }, insert_object);
        console.log("botFile-response ===> ", {data, success, message});
        if (success) {
            dispatch(setSuccessMessage("File Saved"));
            // setBotFileURL(iconUrl)
            setBotFile(null);
            dispatch(getBotDetailsSettings());
        } else {
            dispatch(setErrorMessage("Server Error"));
            setBotFile(null);
        }
    }

    const handleBotFileChange = (e: any) => {
        // file local path
        // const botFile_path
        const file = e.target.files[0];
        // const path = e.target.value;
        // console.log({file});
        setBotFileData(file);
        
    }

    const {userPermissions} = useSelector((state: any) => state?.login);
    const history = useNavigate();
    
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "generansettings-bioPage") : null;
        if(!permission){
            permission?.value === 0 && history('/not-found');
        }
    }, [userPermissions?.length]);
    
    // console.log({botFileURL});
    const [isCopied, setIsCopied] = useState<any>("");
    const handleCopyUrl = (url: string) => { 
        setIsCopied(url);
        navigator.clipboard.writeText(url);
        setTimeout(() => {
            setIsCopied("");
        }, 2000);
    };

    return (
        <React.Fragment>
            <div className='lg-sub-menu h-100 p-0' style={{alignItems: 'flex-start'}}>
                {
                    loading
                    ? <div className='loading-container w-100 h-100 d-flex justify-content-center align-items-center'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                    : <div className='bot-page-menu-container'>
                        <div className="form-container bot-settings-gap">

                            {/* Bot Name */}
                            <div className="form-group">
                                <label>Bot Name</label>
                                <div className='input-container'>
                                    {
                                        isBotNameActive
                                        ? <input  
                                            value={botName || clapUpBotDetails?.aiChatBotName || ""}
                                            readOnly={!isBotNameActive}
                                            autoFocus={true}
                                            onChange={(e: any) => {
                                                const { value } = e.target;
                                                if (value?.length > 50) {
                                                    dispatch(setErrorMessage("Bot name should contain max 50 characters"));
                                                    return;
                                                }
                                                if (value) {
                                                    setBotName(value);
                                                    setIsBotNameError(false);
                                                } else {
                                                    setBotName("");
                                                    setIsBotNameError(true);
                                                }
                                            }}
                                            type="text" 
                                            placeholder="Bot Name"
                                            // className={`${((isBotNameError ||!clapUpBotDetails?.botName) && isBotNameActive) ? "input-error" : ""}`}
                                            className={`${((isBotNameError) && isBotNameActive) ? "input-error" : ""}`}
                                        />
                                        : <span className='input-value'>{botName || clapUpBotDetails?.aiChatBotName || "Clapup Bot"}</span>
                                    }
                                    <img
                                        src={isBotNameActive ? save_icon : edit_icon}
                                        alt="edit-icon"
                                        className={`add-edit-save-icon ${isBotNameError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isBotNameError) {
                                                if(isBotNameActive && botName){
                                                    setIsBotNameActive(false);
                                                    handleUpdateBotDetailsSettingField("aiChatBotName", botName, setBotName);
                                                } else {
                                                    setIsBotNameActive(true);
                                                }
                                            }
                                        }}
                                    />
                                    
                                </div>
                            </div>
                            
                            {/* Bot Headline */}
                            <div className="form-group">
                                <label>Bot Headline</label>
                                <div className='input-container'>
                                    {
                                        isBotHeadlineActive
                                        ? <input  
                                            value={botHeadline || clapUpBotDetails?.aiChatBotHeadLine || ""}
                                            readOnly={!isBotHeadlineActive}
                                            autoFocus={true}
                                            onChange={(e: any) => {
                                                const { value } = e.target;
                                                if (value?.length > 100) {
                                                    dispatch(setErrorMessage("Bot Headline should contain max 100 characters"));
                                                    return;
                                                }
                                                if (value) {
                                                    setBotHeadline(value);
                                                    setIsBotHeadlineError(false)
                                                } else {
                                                    setBotHeadline("");
                                                    setIsBotHeadlineError(true)
                                                }
                                            }}
                                            type="text" 
                                            placeholder="Bot Headline"
                                            className={`${((isBotHeadlineError ||!clapUpBotDetails?.aiChatBotHeadLine) && isBotHeadlineActive) ? "input-error" : ""}`}
                                        />
                                        : <span className='input-value'>{botHeadline || clapUpBotDetails?.aiChatBotHeadLine || "Chat with our AI bot"}</span>
                                    }
                                    <img
                                        src={isBotHeadlineActive ? save_icon : edit_icon}
                                        alt="edit-icon"
                                        className={`add-edit-save-icon ${isBotHeadlineError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isBotHeadlineError) {
                                                if(isBotHeadlineActive && botHeadline){
                                                    setIsBotHeadlineActive(false);
                                                    handleUpdateBotDetailsSettingField("aIChatBotHeadLine", botHeadline, setBotHeadline);
                                                } else {
                                                    setIsBotHeadlineActive(true);
                                                }
                                            }
                                        }}
                                    />
                                    
                                </div>
                            </div>
                            
                            {/* Bot Description */}
                            <div className='form-group'>
                                <label>Bot Description</label>
                                <div className='input-container'>
                                    {
                                        isBotDescriptionActive
                                            ? <input
                                                value={botDescription || clapUpBotDetails?.aiChatBotDescription || ""}
                                                readOnly={!isBotDescriptionActive}
                                                autoFocus={true}
                                                onChange={(e: any) => {
                                                    const { value } = e.target;
                                                    if (value?.length > 200) {
                                                        dispatch(setErrorMessage("Bot Description should contain max 200 characters"));
                                                        return;
                                                    }
                                                    if (value) {
                                                        setBotDescription(value);
                                                        setIsBotDescriptionError(false);
                                                    } else {
                                                        setBotDescription("");
                                                        setIsBotDescriptionError(true);
                                                    }
                                                }}
                                                type="text"
                                                placeholder="Bot Description"
                                                className={`${((isBotDescriptionError || !clapUpBotDetails?.aiChatBotDescription) && isBotDescriptionActive) ? "input-error" : ""}`}
                                            />
                                            : <span className='input-value'>{botDescription || clapUpBotDetails?.aiChatBotDescription || "Ask any question our AI will answer?"}</span>
                                    }
                                    <img
                                        src={isBotDescriptionActive ? save_icon : edit_icon}
                                        className={`add-edit-save-icon ${isBotDescriptionError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isBotDescriptionError) {
                                                if (isBotDescriptionActive && botDescription) {
                                                    setIsBotDescriptionActive(false);
                                                    handleUpdateBotDetailsSettingField("aIChatBotDescription", botDescription, setBotDescription)
                                                } else {
                                                    setIsBotDescriptionActive(true);
                                                }
                                            }
                                        }}
                                        alt='save-edit'
                                    />
                                </div>
                            </div>
                            
                            {/* Greeting Message */}
                            <div className='form-group'>
                                <label>Greeting Message</label>
                                <div className='input-container'>
                                    {
                                        isBotGreetingMessageActive
                                            ? <textarea
                                                value={botGreetingMessage || clapUpBotDetails?.greetingMsg || ""}
                                                readOnly={!isBotGreetingMessageActive}
                                                autoFocus={true}
                                                onChange={(e: any) => {
                                                    const { value } = e.target;
                                                    if (value?.length > 200) {
                                                        dispatch(setErrorMessage("Greeting Message should contain max 200 characters"));
                                                        return;
                                                    }
                                                    if (value) {
                                                        setGreetingMessage(value);
                                                        setIsBotGreetingMessageError(false);
                                                    } else {
                                                        setGreetingMessage("");
                                                        setIsBotGreetingMessageError(true);
                                                    }
                                                }}
                                                style={{resize: 'none'}}
                                                // type="text"
                                                placeholder="Site Description"
                                                className={`${((isBotGreetingMessageError || !clapUpBotDetails?.greetingMsg) && isBotGreetingMessageActive) ? "input-error" : ""}`}
                                            />
                                            : <span className='input-value'>{botGreetingMessage || clapUpBotDetails?.greetingMsg || "Hi there! How can I help you today?"}</span>
                                    }
                                    <img
                                        src={isBotGreetingMessageActive ? save_icon : edit_icon}
                                        className={`add-edit-save-icon ${isBotGreetingMessageError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isBotGreetingMessageError) {
                                                if (isBotGreetingMessageActive && botGreetingMessage) {
                                                    setIsBotGreetingMessageActive(false);
                                                    handleUpdateBotDetailsSettingField("greetingMsg", botGreetingMessage, setBotDescription)
                                                } else {
                                                    setIsBotGreetingMessageActive(true);
                                                }
                                            }
                                        }}
                                        alt='save-edit'
                                    />
                                </div>
                            </div>
                                
                            {/* Primary Widget Color (Hex) */}
                            <div className="form-group">
                                <label>{"Primary Widget Color (Hex)"}</label>
                                <div className='input-container'>
                                    <div className='color-container'>
                                        {
                                            defaultPrimaryColorCodes?.map((color: any, index: any) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className='color-box'
                                                        style={{ background: color }}
                                                        onClick={() => { 
                                                            // setPrimaryColor(color);
                                                            handleUpdateBotDetailsSettingField("primaryColor", color, setPrimaryColor)
                                                        }}
                                                    ></div>
                                                )
                                            })
                                        }

                                        {/* <div className='color-box' style={{background: "green"}}></div>
                                        <div className='color-box' style={{background: "red"}}></div> */}
                                        
                                    </div>
                                    
                                    <input
                                        type='text'
                                        className="widget-color"
                                        placeholder="Primary Color (Hex)"
                                        style={{
                                            // color: primaryColor || clapUpBotDetails?.primaryColor || "black",
                                            border: `1.5px solid${primaryColor || clapUpBotDetails?.primaryColor || "black"}`,
                                        }}
                                        value={primaryColor || clapUpBotDetails?.primaryColor || ""}
                                        onChange={(e: any) => {
                                            console.log({value: e.target.value, flag: e.target.value?.length});
                                            
                                            if (e?.target?.value[0] !== "#" || e?.target?.value?.length > 7) { 
                                                return;
                                            }
                                            
                                            setPrimaryColor(e?.target?.value);
                                            if (e?.target?.value?.length === 7) {
                                                handleUpdateBotDetailsSettingField("primaryColor", e.target.value, setPrimaryColor)
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            
                            {/* Secondary Widget Color (Hex) */}
                            <div className="form-group">
                                <label>{"Secondary Widget Color (Hex)"}</label>
                                <div className='input-container'>
                                    <div className='color-container'>
                                        {
                                            defaultSecondaryColorCodes?.map((color: any, index: any) => {
                                                return (
                                                    <div
                                                        key={index} className='color-box'
                                                        style={{ background: color }}
                                                        onClick={() => { 
                                                            // setSecondaryColor(color);
                                                            handleUpdateBotDetailsSettingField("secondaryColor", color, setSecondaryColor)
                                                        }}
                                                    ></div>
                                                )
                                            })
                                        }

                                        {/* <div className='color-box' style={{background: "green"}}></div>
                                        <div className='color-box' style={{background: "red"}}></div> */}  
                                    </div>
                                    
                                    <input
                                        type='text'
                                        className="widget-color"
                                        placeholder="Secondary Color (Hex)"
                                        style={{
                                            // color: secondaryColor || clapUpBotDetails?.secondaryColor || "black",
                                            border: `1.5px solid${secondaryColor || clapUpBotDetails?.secondaryColor || "black"}`
                                        }}
                                        value={secondaryColor || clapUpBotDetails?.secondaryColor || ""}
                                        onChange={(e: any) => {
                                            if (e?.target?.value[0] !== "#" || e?.target?.value?.length > 7) { 
                                                return;
                                            }
                                            setSecondaryColor(e.target.value);
                                            if (e?.target?.value?.length === 7) {
                                                handleUpdateBotDetailsSettingField("secondaryColor", e.target.value, setSecondaryColor)
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            
                            {/* Knowledge Base pdf {"( < 25KB)"} */}
                            <div className="form-group">
                                <label>Knowledge Base</label>
                                <div className='input-container'>
                                    <div className='image-label'>
                                        {
                                            botFileURL
                                            // ? <span>{"filename.pdf"}</span>
                                            ? <span>{botFileURL?.replaceAll(" ", "")}</span>
                                            : botFile 
                                            ? <span>Upload a .pdf file</span>
                                            : <span>{"filename.pdf"}</span>
                                            // : <img className='rounded-circle' src={clapUpBotDetails?.logoUrl?.replaceAll("@", "/")} alt='logo' /> 
                                        }                                      
                                    </div>
                                    {
                                        botFile
                                        ? <input 
                                            // disabled={botFile ? false : true}
                                            id="bot-file" type="file"
                                            accept='application/pdf'
                                            onChange={(e: any) => {
                                                // console.log({img: e?.target?.files[0]});
                                                // setBotFileURL(URL.createObjectURL(e?.target?.files[0]));
                                                setBotFileURL(e?.target?.files[0]?.name?.replace(" ", ""));
                                                setBotFile(e?.target?.files[0]?.name?.replace(" ", ""));
                                                handleBotFileChange(e); 

                                            }} 
                                            className="form-input-field d-none w-100 my-1" placeholder="Bot File"
                                        />
                                        : null
                                    }
                                    
                                    {
                                        botFile
                                        ? botFileURL
                                            ?   <img
                                                    src={save_icon}
                                                    className={`add-edit-save-icon`}
                                                    onClick={() => {
                                                        handleBotFileSubmit();
                                                    }}
                                                    alt='save-edit'
                                                />
                                            :   <label className='mx-2' htmlFor="bot-file">
                                                    <img
                                                        src={upload_icon}
                                                        className={`add-edit-save-icon`}
                                                        alt='save-edit'
                                                    />
                                            </label>
                                        : <img
                                            src={edit_icon}
                                            className={`add-edit-save-icon`}
                                            onClick={() => {
                                                setBotFile(true);
                                                setBotFileURL('');
                                                // if (image && botFileURL) {
                                                //     handleBotFileSubmit();
                                                // }
                                            }}
                                            alt='save-edit'
                                        />
                                    }
                                </div>
                            </div>
                            
                            {/* Enable on Biopage */}
                            <div className="form-group">
                                <label style={{color: "transparent"}}>Enable on Biopage</label>
                                <div className='input-container'>
                                    <span className='input-value'>{"Enable on Biopage"}</span>
                                    <div className="switch-icon">
                                        <Switch 
                                            size='small' 
                                            checked={isEnabled}
                                            onChange={(checked: any) => {
                                                console.log({
                                                    checked, botName, botHeadline,
                                                    botDescription, botGreetingMessage,
                                                    primaryColor, secondaryColor,
                                                    knowledgeBase: clapUpBotDetails?.knowledgeBase,
                                                    isEnabled: Number(clapUpBotDetails?.isEnabledInBioPage)
                                                });
                                                
                                                if (checked && (!botName || !botHeadline || !botDescription || !botGreetingMessage || !primaryColor || !secondaryColor || !clapUpBotDetails?.knowledgeBase)) { 
                                                    dispatch(setErrorMessage("Please fill all fields to enable bot on biopage"));
                                                    return;
                                                }
                                                handleUpdateBotDetailsSettingField("enableInBioPage", checked ? 1 : 0, null);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                }
            </div>
            
            <div className='mobile-sub-menu h-100 w-100' style={{overflow: 'auto'}}>
                {
                    loading
                    ? <div className='loading-container w-100 d-flex justify-content-center align-items-center'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                    : <div className='bot-page-menu-container'>
                        <div className="form-container bot-settings-gap">

                            {/* Bot Name */}
                            <div className="form-group">
                                <label>Bot Name</label>
                                <div className='input-container'>
                                    {
                                        isBotNameActive
                                        ? <input  
                                            defaultValue={botName || clapUpBotDetails?.aiChatBotName || ""}
                                            readOnly={!isBotNameActive}
                                            autoFocus={true}
                                            onChange={(e: any) => {
                                                const { value } = e.target;
                                                if (value) {
                                                    setBotName(value);
                                                    setIsBotNameError(false);
                                                } else {
                                                    setBotName("");
                                                    setIsBotNameError(true);
                                                }
                                            }}
                                            type="text" 
                                            placeholder="Bot Name"
                                            // className={`${((isBotNameError ||!clapUpBotDetails?.botName) && isBotNameActive) ? "input-error" : ""}`}
                                            className={`${((isBotNameError) && isBotNameActive) ? "input-error" : ""}`}
                                        />
                                        : <span className='input-value'>{botName || clapUpBotDetails?.aiChatBotName || "Clapup Bot"}</span>
                                    }
                                    <img
                                        src={isBotNameActive ? save_icon : edit_icon}
                                        alt="edit-icon"
                                        className={`add-edit-save-icon ${isBotNameError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isBotNameError) {
                                                if(isBotNameActive && botName){
                                                    setIsBotNameActive(false);
                                                    handleUpdateBotDetailsSettingField("aiChatBotName", botName, setBotName);
                                                } else {
                                                    setIsBotNameActive(true);
                                                }
                                            }
                                        }}
                                    />
                                    
                                </div>
                            </div>

                            {/* Bot Headline */}
                            <div className="form-group">
                                <label>Bot Headline</label>
                                <div className='input-container'>
                                    {
                                        isBotHeadlineActive
                                        ? <input  
                                            defaultValue={botHeadline || clapUpBotDetails?.aiChatBotHeadLine || ""}
                                            readOnly={!isBotHeadlineActive}
                                            autoFocus={true}
                                            onChange={(e: any) => {
                                                const { value } = e.target;
                                                if (value) {
                                                    setBotHeadline(value);
                                                    setIsBotHeadlineError(false)
                                                } else {
                                                    setBotHeadline("");
                                                    setIsBotHeadlineError(true)
                                                }
                                            }}
                                            type="text" 
                                            placeholder="Bot Headline"
                                            className={`${((isBotHeadlineError ||!clapUpBotDetails?.aiChatBotHeadLine) && isBotHeadlineActive) ? "input-error" : ""}`}
                                        />
                                        : <span className='input-value'>{botHeadline || clapUpBotDetails?.aiChatBotHeadLine || "Chat with our AI bot"}</span>
                                    }
                                    <img
                                        src={isBotHeadlineActive ? save_icon : edit_icon}
                                        alt="edit-icon"
                                        className={`add-edit-save-icon ${isBotHeadlineError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isBotHeadlineError) {
                                                if(isBotHeadlineActive && botHeadline){
                                                    setIsBotHeadlineActive(false);
                                                    handleUpdateBotDetailsSettingField("aIChatBotHeadLine", botHeadline, setBotHeadline);
                                                } else {
                                                    setIsBotHeadlineActive(true);
                                                }
                                            }
                                        }}
                                    />
                                    
                                </div>
                            </div>

                            {/* Bot Description */}
                            <div className='form-group'>
                                <label>Bot Description</label>
                                <div className='input-container'>
                                    {
                                        isBotDescriptionActive
                                            ? <input
                                                defaultValue={botDescription || clapUpBotDetails?.aiChatBotDescription || ""}
                                                readOnly={!isBotDescriptionActive}
                                                autoFocus={true}
                                                onChange={(e: any) => {
                                                    const { value } = e.target;
                                                    if (value) {
                                                        setBotDescription(value);
                                                        setIsBotDescriptionError(false);
                                                    } else {
                                                        setBotDescription("");
                                                        setIsBotDescriptionError(true);
                                                    }
                                                }}
                                                type="text"
                                                placeholder="Bot Description"
                                                className={`${((isBotDescriptionError || !clapUpBotDetails?.aiChatBotDescription) && isBotDescriptionActive) ? "input-error" : ""}`}
                                            />
                                            : <span className='input-value'>{botDescription || clapUpBotDetails?.aiChatBotDescription || "Ask any question our AI will answer?"}</span>
                                    }
                                    <img
                                        src={isBotDescriptionActive ? save_icon : edit_icon}
                                        className={`add-edit-save-icon ${isBotDescriptionError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isBotDescriptionError) {
                                                if (isBotDescriptionActive && botDescription) {
                                                    setIsBotDescriptionActive(false);
                                                    handleUpdateBotDetailsSettingField("aIChatBotDescription", botDescription, setBotDescription)
                                                } else {
                                                    setIsBotDescriptionActive(true);
                                                }
                                            }
                                        }}
                                        alt='save-edit'
                                    />
                                </div>
                            </div>

                            {/* Greeting Message */}
                            <div className='form-group'>
                                <label>Greeting Message</label>
                                <div className='input-container'>
                                    {
                                        isBotGreetingMessageActive
                                            ?   <textarea
                                                    defaultValue={botGreetingMessage || clapUpBotDetails?.greetingMsg || ""}
                                                    readOnly={!isBotGreetingMessageActive}
                                                    autoFocus={true}
                                                    onChange={(e: any) => {
                                                        const { value } = e.target;
                                                        if (value) {
                                                            setGreetingMessage(value);
                                                            setIsBotGreetingMessageError(false);
                                                        } else {
                                                            setGreetingMessage("");
                                                            setIsBotGreetingMessageError(true);
                                                        }
                                                    }}
                                                    style={{resize: 'none'}}
                                                    // type="text"
                                                    placeholder="Site Description"
                                                    className={`${((isBotGreetingMessageError || !clapUpBotDetails?.greetingMsg) && isBotGreetingMessageActive) ? "input-error" : ""}`}
                                                />
                                            : <span className='input-value'>{botGreetingMessage || clapUpBotDetails?.greetingMsg || "Hi there! How can I help you today?"}</span>
                                    }
                                    <img
                                        src={isBotGreetingMessageActive ? save_icon : edit_icon}
                                        className={`add-edit-save-icon ${isBotGreetingMessageError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isBotGreetingMessageError) {
                                                if (isBotGreetingMessageActive && botGreetingMessage) {
                                                    setIsBotGreetingMessageActive(false);
                                                    handleUpdateBotDetailsSettingField("greetingMsg", botGreetingMessage, setBotDescription)
                                                } else {
                                                    setIsBotGreetingMessageActive(true);
                                                }
                                            }
                                        }}
                                        alt='save-edit'
                                    />
                                </div>
                            </div>
                                
                            {/* Primary Widget Color (Hex) */}
                            <div className="form-group">
                                <label>{"Primary Widget Color (Hex)"}</label>
                                <div className='input-container'>
                                    <div className='color-container'>
                                        {
                                            defaultPrimaryColorCodes?.map((color: any, index: any) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className='color-box'
                                                        style={{ background: color }}
                                                        onClick={() => { 
                                                            // setPrimaryColor(color);
                                                            handleUpdateBotDetailsSettingField("primaryColor", color, setPrimaryColor)
                                                        }}
                                                    ></div>
                                                )
                                            })
                                        }

                                        {/* <div className='color-box' style={{background: "green"}}></div>
                                        <div className='color-box' style={{background: "red"}}></div> */}
                                        
                                    </div>
                                    
                                    <input
                                        type='text'
                                        className="widget-color"
                                        placeholder="Primary Color (Hex)"
                                        style={{
                                            // color: primaryColor || clapUpBotDetails?.primaryColor || "black",
                                            border: `1.5px solid${primaryColor || clapUpBotDetails?.primaryColor || "black"}`,
                                        }}
                                        value={primaryColor || clapUpBotDetails?.primaryColor || ""}
                                        onChange={(e: any) => {
                                            console.log({value: e.target.value, flag: e.target.value?.length});
                                            
                                            if (e?.target?.value[0] !== "#" || e?.target?.value?.length > 7) { 
                                                return;
                                            }
                                            
                                            setPrimaryColor(e?.target?.value);
                                            if (e?.target?.value?.length === 7) {
                                                handleUpdateBotDetailsSettingField("primaryColor", e.target.value, setPrimaryColor)
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Secondary Widget Color (Hex) */}
                            <div className="form-group">
                                <label>{"Secondary Widget Color (Hex)"}</label>
                                <div className='input-container'>
                                    <div className='color-container'>
                                        {
                                            defaultSecondaryColorCodes?.map((color: any, index: any) => {
                                                return (
                                                    <div
                                                        key={index} className='color-box'
                                                        style={{ background: color }}
                                                        onClick={() => { 
                                                            // setSecondaryColor(color);
                                                            handleUpdateBotDetailsSettingField("secondaryColor", color, setSecondaryColor)
                                                        }}
                                                    ></div>
                                                )
                                            })
                                        }

                                        {/* <div className='color-box' style={{background: "green"}}></div>
                                        <div className='color-box' style={{background: "red"}}></div> */}  
                                    </div>
                                    
                                    <input
                                        type='text'
                                        className="widget-color"
                                        placeholder="Secondary Color (Hex)"
                                        style={{
                                            // color: secondaryColor || clapUpBotDetails?.secondaryColor || "black",
                                            border: `1.5px solid${secondaryColor || clapUpBotDetails?.secondaryColor || "black"}`
                                        }}
                                        value={secondaryColor || clapUpBotDetails?.secondaryColor || ""}
                                        onChange={(e: any) => {
                                            if (e?.target?.value[0] !== "#" || e?.target?.value?.length > 7) { 
                                                return;
                                            }
                                            setSecondaryColor(e.target.value);
                                            if (e?.target?.value?.length === 7) {
                                                handleUpdateBotDetailsSettingField("secondaryColor", e.target.value, setSecondaryColor)
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Knowledge Base pdf {"( < 25KB)"} */}
                            <div className="form-group">
                                <label>Knowledge Base</label>
                                <div className='input-container'>
                                    <div className='image-label'>
                                        {
                                            botFileURL
                                            // ? <span>{"filename.pdf"}</span>
                                            ? <span>{botFileURL?.replaceAll(" ", "")}</span>
                                            : botFile 
                                            ? <span>Upload a .pdf file</span>
                                            : <span>{"filename.pdf"}</span>
                                            // : <img className='rounded-circle' src={clapUpBotDetails?.logoUrl?.replaceAll("@", "/")} alt='logo' /> 
                                        }                                      
                                    </div>
                                    {
                                        botFile
                                        ? <input 
                                            // disabled={botFile ? false : true}
                                            id="bot-file" type="file"
                                            accept='application/pdf'
                                            onChange={(e: any) => {
                                                // console.log({img: e?.target?.files[0]});
                                                // setBotFileURL(URL.createObjectURL(e?.target?.files[0]));
                                                setBotFileURL(e?.target?.files[0]?.name?.replace(" ", ""));
                                                setBotFile(e?.target?.files[0]?.name?.replace(" ", ""));
                                                handleBotFileChange(e); 

                                            }} 
                                            className="form-input-field d-none w-100 my-1" placeholder="Bot File"
                                        />
                                        : null
                                    }
                                    
                                    {
                                        botFile
                                        ? botFileURL
                                            ?   <img
                                                    src={save_icon}
                                                    className={`add-edit-save-icon`}
                                                    onClick={() => {
                                                        handleBotFileSubmit();
                                                    }}
                                                    alt='save-edit'
                                                />
                                            :   <label className='mx-2' htmlFor="bot-file">
                                                    <img
                                                        src={upload_icon}
                                                        className={`add-edit-save-icon`}
                                                        alt='save-edit'
                                                    />
                                            </label>
                                        : <img
                                            src={edit_icon}
                                            className={`add-edit-save-icon`}
                                            onClick={() => {
                                                setBotFile(true);
                                                setBotFileURL('');
                                                // if (image && botFileURL) {
                                                //     handleBotFileSubmit();
                                                // }
                                            }}
                                            alt='save-edit'
                                        />
                                    }
                                </div>
                            </div>

                            {/* Enable on Biopage */}
                            <div className="form-group">
                                <label style={{color: "transparent"}}>Enable on Biopage</label>
                                <div className='input-container'>
                                    <span className='input-value'>{"Enable on Biopage"}</span>
                                    <div className="switch-icon">
                                        <Switch 
                                            size='small' 
                                            checked={isEnabled}
                                            onChange={(checked: any) => {
                                                console.log({
                                                    checked, botName, botHeadline,
                                                    botDescription, botGreetingMessage,
                                                    primaryColor, secondaryColor,
                                                    knowledgeBase: clapUpBotDetails?.knowledgeBase,
                                                    isEnabled: Number(clapUpBotDetails?.isEnabledInBioPage)
                                                });
                                                
                                                if (checked && (!botName || !botHeadline || !botDescription || !botGreetingMessage || !primaryColor || !secondaryColor || !clapUpBotDetails?.knowledgeBase)) { 
                                                    dispatch(setErrorMessage("Please fill all fields to enable bot on biopage"));
                                                    return;
                                                }
                                                handleUpdateBotDetailsSettingField("enableInBioPage", checked ? 1 : 0, null);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            
        </React.Fragment>
    );
};

export default ClapUpBotSettings;