import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setInfoMessage } from "../../dashboard/reducer";
import './notifications.scss';


const InfoMessage: React.FC<unknown> = () => {
    const dispatch = useDispatch();
    const { info_message } = useSelector(
        (state: any) => state?.unified
    );
    // const [show, setShow] = useState<any>(true);
    useEffect(() => {
        if(info_message) {
            setTimeout(() => {
                dispatch(setInfoMessage(null));
            }, 3000);
        } 
    }, [dispatch, info_message]);
    
    // useEffect(() => {
    //     dispatch(setInfoMessage("Added Successfully"));
    // }, [""]);



    return info_message ? (
        <div
          className={"dialog bg-transparent"}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
            }}
            // className={"h-full flex items-end justify-end"}
          >
            <div
              style={{
                height: "8.81vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                bottom: "12%",
                padding: "0 1.5rem",
                background: "#D8E3FF",
                borderLeft: "4px solid #1570EF",
                cursor: "pointer",
                position: "relative",
              }}
              className={`dialog_container cursor-pointer relative mx-auto `}
            >
              <p
                style={{ color: "#667085" }}
              >
                {info_message}
              </p>
            </div>
          </div>
        </div>
      ) : null;
 }

export default InfoMessage;