import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { findWorkspaceList } from '../layout/header/headerReducer';
import { useNavigate } from 'react-router';
interface ModalProps {
    open?: boolean;
    parentCallback?: any;
    children?: React.ReactNode;
    title?: string;
};

const SignUpModal: React.FC<ModalProps> = ({ open, parentCallback, children, title }: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [visible, setVisible] = useState<boolean>(false);
    const {newWorkspaceResult} = useSelector((state: RootState) => state?.workspaceManagementState);
    useEffect(() => {
        setVisible(open);
        if(newWorkspaceResult?.length) {
            console.log('newWorkspaceResult ======> ', newWorkspaceResult);
            const data: any = newWorkspaceResult[0];
            const { CreatedBy, ID, roleId, WorkSpaceName  } = data;
            CreatedBy && sessionStorage.setItem('cuid', CreatedBy);
            ID && sessionStorage.setItem('wuid', ID);
            roleId && sessionStorage.setItem("roleId", roleId);
            // sessionStorage.setItem("roleName", newWorkspaceResult[0]?.roleName);
            WorkSpaceName && sessionStorage.setItem("workspaceName", WorkSpaceName);
            // find workspace list...
            if(CreatedBy) {
                dispatch(findWorkspaceList());
                navigate("/dashboard", { replace: true });
                setVisible(false);
                parentCallback();
            }
        }
    }, [open, newWorkspaceResult?.length]);
    return (
        <React.Fragment>
            <Modal
                title={null}
                closable={false}
                centered
                open={visible}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                // onOk={() => setVisible(false)}
                // onCancel={() => {
                //     setVisible(false);
                //     parentCallback();
                // }}
                
                footer={null}
                width={420}
            >
                {children}
            </Modal>
        </React.Fragment>
    );
}

export default SignUpModal;
