import React from 'react';
import './feedsLeft.scss';
import Testimonials from "./testimonials";
// import Modal from "../modal/modal";
import { triggerUnified } from '../../dashboard/reducer';

interface LeftFeedsProps {
    title?: any;
    link?: any;
    data?: any;
    testimonial?: any;
    twitter?: any;
    children?: React.ReactNode;
    feeds?: any;
    type?: any;
}
const FeedsLeft: React.FC<LeftFeedsProps> = ({ data, type, feeds }) => {
    return (
      <React.Fragment>
        <div className="google-low-rated-review">
          {data && data?.length && type === "low-rated-google-reviews" ? (
            <Testimonials data={data} />
          ) : (
            <div
              style={{ height: "100%" }}
              className="no-data-wrapper d-flex justify-content-center align-items-center text-center"
            >
              <h6>No Data Found</h6>
            </div>
          )}
        </div>
      </React.Fragment>
    );
}

export default FeedsLeft;