import React from 'react';
import './../WallConversations.scss';
import emptyBox from '../../../../assets/img/Empty.svg';
// import prev_icon from '../../../../assets/icons/previous-icon.svg';
// import next_icon from '../../../../assets/icons/next-icon.svg';
// import refresh_icon from '../../../../assets/icons/refresh-icon-unified.svg';
// import user_avatar_2 from '../../../../assets/icons/user-avatar-2.svg';
// import star_half from '../../../../assets/icons/star-half-icon.svg';
// import unlock_icon from '../../../../assets/icons/unlock-icon.svg';
// import google_logo from '../../../../assets/icons/google-logo.svg';
import delete_icon from '../../../../assets/icons/delete-icon.svg';
import user_avatar from '../../../../assets/icons/user-avatar.svg';
// import start from '../../../../assets/icons/star-icon.svg';
// import twitter_logo from '../../../../assets/icons/twitter-icon.svg';
// import facebook_logo from '../../../../assets/icons/facebook-reviews.svg';
// import feedback_logo from '../../../../assets/icons/feedback-line-1.2.svg';
// import video_logo from '../../../../assets/icons/video-chat-line-1.2.svg';
// // import video_link from '../../../../assets/icons/video-link.svg';
// import lock_icon from '../../../../assets/icons/lock-icon.svg';
// import fb_recommendation from '../../../../assets/icons/fb-recommendation-star.svg';
// import fb_dont_recommendation from '../../../../assets/icons/facebook-dont-recommendation-star.svg';
// import like_icon from '../../../../assets/icons/feather-thumbs-up.svg';
// import heart_icon from '../../../../assets/icons/heart-icon.svg';
// import retweet_icon from '../../../../assets/icons/retweet-icon.svg';
// import { dummyConversationData } from './dummy_conversation_data';
// import moment from 'moment';
import { getImage } from '../../../../dashboard/image_service';
import { useState } from 'react';
import moment from 'moment';

interface Props {
    wall_conversation: any;
    handleDelete: any;
}

const FacebookData: React.FC<Props> = ({ wall_conversation, handleDelete }) => {
  const [isMore, setIsMore] = useState<boolean>(false);
  // const timelineRef:any = React.useRef();
  // const [conversation, setConversation] = useState<any>(null);
  // useEffect(() => {   
  //   timelineRef.current?.scrollIntoView({ behavior: "smooth" });
  //   timelineRef.current?.scrollTo(0, timelineRef.current.scrollHeight);
  //   const conversation = dummyConversationData?.find((conversation: any) => conversation?.id === parseInt(wall_conversation?.id));
  //   console.log({conversation});
  //   // textRef.current.value = conversation?.person_2?.message || "";     
    
  //   setConversation(conversation);
  // }, [wall_conversation, dummyConversationData]);


  // const getImage = (type: any) => {
  //     switch (type) {
  //       case 'google':
  //         return google_logo;
  //       case 'lock_icon':
  //         return lock_icon;
  //       case 'facebook':
  //         return facebook_logo;
  //       case 'facebookComments':
  //         return facebook_logo;
  //       case 'twitter':
  //         return twitter_logo;
  //       case 'videoTestimonial':
  //         return video_logo;
  //       case 'customerFeedBack':
  //         return feedback_logo;
  //       case 'heart':
  //         return heart_icon;
  //       case 'retweet':
  //         return retweet_icon;
  //       case 'fb-recommend':
  //         return fb_recommendation;
  //       case 'fb-dont-recommend':
  //         return fb_dont_recommendation;
  //       case 'like':
  //         return like_icon;
  //       default:
  //         return facebook_logo;
  //     }
  // };

  // console.log({wall_conversation_fb: wall_conversation});
  const {reviewerDisplayName, reviewerPhoteUrl, reviewtype, createdOn, reviewComment, provider} = wall_conversation || {};
  
  return (
    <React.Fragment>
      {wall_conversation ? (
        <div
          className={`wall-section-container`}
        >
          <div className="wall">
            <div className="wall-section-header">
              {
                reviewerPhoteUrl
                ? <img
                    className="user-avatar"
                    src={reviewerPhoteUrl || user_avatar}
                    alt="avatar"
                  />
                : <div className='user-avatar'>
                    {
                      reviewerDisplayName?.split(" ")?.slice(0, 2)?.length === 2
                      ? reviewerDisplayName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                      : reviewerDisplayName?.substr(0, 2).toUpperCase()
                    }
                  </div>
              }
              <div className="name-rating d-flex flex-column m-0 align-items-start">
                <h6 className="user-name mx-2 my-0">
                  {reviewerDisplayName || "User"}
                </h6>
                <p className="mt-1 mx-2">
                  {wall_conversation?.tag?.includes("review") ? (
                    reviewtype === "Positive" ? (
                      <span className="d-flex align-items-center mt- 1">
                        <img
                          className="me-1"
                          style={{ marginTop: 3, height: 9, width: 9 }}
                          src={getImage("fb-recommend")}
                          alt="fb-recommend"
                        />
                        <span>recommend</span>
                      </span>
                      ) : (
                      <span className="d-flex align-items-center mt- 1">
                        <img
                          className="me-1"
                          style={{ marginTop: 3, height: 9, width: 9 }}
                          src={getImage("fb-dont-recommend")}
                          alt="fb-don't-recommend"
                        />
                        <span>don't recommend</span>
                      </span>
                    )
                  ) : wall_conversation?.tag?.includes("mention") ? (
                      <span className="d-flex align-items-center mt- 1">
                        <span className="me-1">Facebook Mention</span>
                      </span>
                    ) : wall_conversation?.tag?.includes("comment") ? (
                        <span className="d-flex align-items-center mt- 1">
                          <span className="me-1">Facebook Comment</span>
                        </span>
                  ) : null}
                </p>
              </div>
            </div>
            <div className="wall-section-body">
              <p className="text-start description">
                {
                  reviewComment
                }
                
              </p>
            </div>
            <div className="wall-section-footer">
              <p className="text-start date">
                {/* get second, minutes and hours manually*/}
                {
                  // new Date(createdOn).toDateString() + ' '
                  // + (new Date(createdOn).getHours() < 10 ? '0' + new Date(createdOn).getHours() : new Date(createdOn).getHours())
                  // + ':' + (new Date(createdOn).getMinutes() < 10 ? '0' + new Date(createdOn).getMinutes() : new Date(createdOn).getMinutes())
                  // + ':' + (new Date(createdOn).getSeconds() < 10 ? '0' + new Date(createdOn).getSeconds() : new Date(createdOn).getSeconds())
                  moment(createdOn).format("DD MMM YYYY, hh:mm:ss A")
                }
              </p>
              <div className="verify-delete">
                <p>Verified</p>
                <img
                  onClick={() => handleDelete(wall_conversation?.reviewId)}
                  className="delete-icon"
                  src={delete_icon}
                  alt="delete"
                />
              </div>
            </div>
            <img
              className="social-link"
              style={{ width: 20, height: 20 }}
              src={getImage(provider)}
              alt={provider}
            />
          </div>
        </div>
      ) : (
        <div className="empty-wall-section">
          <img src={emptyBox} alt="" />
          <h4>Your Wall is Empty.</h4>
          <span>Select an item from inbox to display!</span>
        </div>
      )}
    </React.Fragment>
  );
}

export default FacebookData;