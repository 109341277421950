import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store';
import man_avatar from '../../../../assets/icons/media/man-avatar.svg';
import refresh_icon from '../../../../assets/icons/media/refresh.svg';
// import { setInfoMessage } from '../../../../dashboard/reducer';
import Dialog from '../../../dialogs/dialog';
import { requestToRevertVersion, setRevertVersionSuccessfully } from '../../mediaReducer';

export default function Versions() {
  const dispatch = useDispatch();
  const [revertVersion, setRevertVersion] = useState<any>(null);
  const {
    is_reverted_version_successfully, 
    // folder_contents, 
    content_versions: versions 
  }: any = useSelector((state: RootState) => state?.mediaState);
  // const content = folder_contents[default_content_idx];
  // const versions = JSON.parse(content?.versions || "[]");
  // console.log({ content_versions: versions });
  useEffect(() => {
    // console.log({ versions_content: versions });
    if (is_reverted_version_successfully) {
      setRevertVersion(null);
      dispatch(setRevertVersionSuccessfully(null));
    }
  }, [is_reverted_version_successfully]);
  const handleRevertToVersion = async (data: any) => {
    // console.log({ flag: data });
    if (!data) {
      setRevertVersion(null);
      return;
    }
    const data_object = {
      clapupUserId: sessionStorage.getItem("cuid"),
      roleId: sessionStorage.getItem("roleId"),
      workspaceId: sessionStorage.getItem("wuid"),
      contentId: data?.contentId,
      versionNo: data?.version,
      versionPath: ""
    };
    dispatch(requestToRevertVersion(data_object));
  }
  return (
    <React.Fragment>
      <Dialog
        open={revertVersion}
        parentCallback={(flag: any) => handleRevertToVersion(flag)}
        classes='dialog-container-content'
        title='Continue?'
        warning_dialog_content={`If you click Continue, this version will be reverted.` }
        footer={true}
        left_button_text='Cancel'
        right_button_text='Continue'
      />

      <div className='versions-seo-container scroll-content'>
        {
          // versions.map((item: any, index: number) => {
            versions?.currentVersion?.map((item: any, index: number) => {
            return (
              <div className='version-content-container' key={index}>
                <div className='img-container'>
                  <img src={item?.imgUrl || man_avatar} alt='avatar' />
                </div>
                <div className='version-content-info'>
                  <p className='title text-capitalize'>{item?.stage || "N/A"} Version {item?.version || "N/A"} created</p>
                  <p className='user-date'>
                    By {item?.versionCreatedBy || "N/A"} on 
                    {
                      item?.versionCreatedOn
                      ? (" " + new Date(item?.versionCreatedOn).toDateString() + " " + new Date(item?.versionCreatedOn).toLocaleTimeString())
                      : (" N/A")
                    }
                  </p>
                </div>
                {/* <img onClick={() => handleRevertToVersion(item)} src={refresh_icon} alt='refresh' /> */}
                {/* <img onClick={() => {}} src={refresh_icon} alt='refresh' /> */}
              </div>
            )
          })
      }
      
        {
          // versions.map((item: any, index: number) => {
            versions?.previousVersions?.map((item: any, index: number) => {
            return (
              <div className='version-content-container' key={index}>
                <div className='img-container'>
                  <img src={item?.imgUrl || man_avatar} alt='avatar' />
                </div>
                <div className='version-content-info'>
                  <p className='title text-capitalize'>{item?.stage || "N/A"} Version {item?.version || "N/A"} created</p>
                  <p className='user-date'>
                    By {item?.versionCreatedBy || "N/A"} on 
                    {
                      item?.versionCreatedOn
                      ? (" " + new Date(item?.versionCreatedOn).toDateString() + " " + new Date(item?.versionCreatedOn).toLocaleTimeString())
                      : (" N/A")
                    }
                  </p>
                </div>
                <img onClick={() => setRevertVersion(item)} src={refresh_icon} alt='refresh' />
              </div>
            )
          })
        }
      </div>
    </React.Fragment>
  )
}
