import React, { useEffect, useState } from 'react';
import { Button, Input, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getTemplateImage } from '../../../../../../../dashboard/image_service';
import { setQuickReplyButtonsTemplate, toggleTemplateEdition } from '../../../../../reducer';
import { RootState } from '../../../../../../../store';
import { setErrorMessage } from '../../../../../../../dashboard/reducer';

const OptionOutButton: React.FC<any> = () => {
    const dispatch = useDispatch();
    const { toggle_template_edition, quick_reply_buttons_template }: any = useSelector((state: RootState) => state?.promotionsState);
    const [isChecked, setIsChecked] = useState<boolean>(false);
    // console.log({quick_reply_buttons_template});
    useEffect(() => {
        if (!isChecked && toggle_template_edition?.templateFooter) {
            setIsChecked(
                toggle_template_edition?.isPromotionOptOut
                ? true
                : false
            );
        }
    }, [toggle_template_edition?.templateFooter]);

    const handleChange = (value: any, setState: any) => {
        // console.log({ value, quick_reply_buttons_template });
        if (value && quick_reply_buttons_template?.length > 2) {
            dispatch(setErrorMessage("Please remove a quick reply button to include opt out button."));
            return;
        }
        const data = {
            ...toggle_template_edition,
            isPromotionOptOut: value,
            templateFooter: value ? "Not Interested? Tap Stop Promotions" : "",
        };
        // console.log({ data });
        setState(value);
        dispatch(toggleTemplateEdition(data));
        if (value) {
            const updated_reply_buttons = [
                ...(quick_reply_buttons_template?.length ? quick_reply_buttons_template : []),
                {
                    id: quick_reply_buttons_template?.length + 1,
                    templateId: "",
                    buttonText: "Stop Promotions",
                    updateType: "insert",
                    isPromoted: true,
                }
            ];
            dispatch(setQuickReplyButtonsTemplate(updated_reply_buttons));
            
        } else {
            const updated_reply_buttons = quick_reply_buttons_template?.filter((btn: any) => !btn?.isPromoted);
            dispatch(setQuickReplyButtonsTemplate(updated_reply_buttons));
        }
    }

    return (
        <React.Fragment>
            <div className='option-output-buttons-form-container'>
                <div className='title-content'>
                    <div className='title'>Opt Out Button</div>
                    <div className='optional'>{"(Optional)"}</div>
                </div>
                <div className="selector-container text-center">
                    <Input
                        readOnly={true}
                        placeholder="Select"
                        className='promotion-input-select'
                        value={"Promotion Opt Out"}
                        // onChange={(value) => setSelectedType(value)}
                    />
                        {/* Quick Reply */}
                        {/* <Select.Option value="Image">Image</Select.Option>
                        <Select.Option value="Video">Video</Select.Option>
                        <Select.Option value="Document">Document</Select.Option> */}
                    {/* </Select> */}
                    <img
                        onClick={() => {
                            if (isChecked) {
                                handleChange(false, setIsChecked);
                            } else {
                                handleChange(true, setIsChecked);
                            }
                        }}
                        src={isChecked ? getTemplateImage("square-active-check") : getTemplateImage("square-inactive-check")}
                        alt="check-icon"
                    />
                </div>

                <div className="option-output-buttons-form">
                    <div className="parent-form">
                        <div className="form">
                            <label className="form-item">
                                Button Text
                            </label>
                            <div className="form-item">
                                <Input
                                    placeholder="Enter value"
                                    readOnly={true}
                                    value={"Stop Promotions"}
                                    // onChange={(e: any) => handleChange("buttonText", e?.target?.value, btn?.id)}
                                />
                            </div>
                        </div>
                        <div className="form">
                            <label className="form-item">
                                Footer Text
                            </label>
                            <div className="form-item">
                                <Input
                                    readOnly={true}
                                    placeholder={`Enter Value`}
                                    value={"Not Interested? Tap Stop Promotions"}
                                    // onChange={(e: any) => handleChange(btn?.type, e?.target?.value, btn?.id)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
};

export default OptionOutButton;
