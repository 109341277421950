import { takeEvery, put, call, all } from 'redux-saga/effects';
import Constants from '../../constants';
import axios from "axios";
import { generateAccessToken } from '../../profile/reducer';
import { api_url } from '../../utils/server';
import { getProfileInfo, setNotificationSettingList, setProfileInfo } from './profileManageReducer';

// Worker Saga:
function* findNotificationSettings(action: any): Generator<any> {
    
    // console.log("header -> Worker saga => getWorkspacesSaga action===>");
    const wuid = sessionStorage.getItem('wuid');
    const cuid = sessionStorage.getItem('cuid');
    let url = api_url + `/api/v1.2/clapup/getNotificationSettings?WorkspaceId=${wuid}&ClapupId=${cuid}`;
    try {
        const { data, status }: any = yield call(axios.get, url, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });

        if (status === 200) {
            yield put(setNotificationSettingList(data));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* updateNotificationPreference(action: any): Generator<any> {
    // console.log("header -> Worker saga => findUserRoles action===>", action);
    let url = api_url + '/api/v1.2/clapup/setNotificationPreferences';
    try {
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });

        console.log("notification preferences", data);
        if (status === 200) {
            // yield put(getNotificationSettings(data));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* findProfileInfo(action: any = null): Generator<any> {
    
    console.log(action?.payload || "action data is not come" );
    const [cuid, wuid] = [sessionStorage.getItem('cuid'), sessionStorage.getItem('wuid')] ;
    let url = api_url + `/api/v1.2/ClapupIdentity/GetPersonalInfo?clapupUserId=${cuid}&workspaceId=${wuid}`;
    try {
        const { data, status }: any = yield call(axios.get, url, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {
            yield put(setProfileInfo(data[0]));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* updateProfileInfo(action: any = null): Generator<any> {
    console.log("updateProfileInfo -> Watcher Saga");
    let url = api_url + '/api/v1.2/ClapupIdentity/UpdatePersonalInfo';
    try {
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });

        if (status === 200) {
            yield put(getProfileInfo());
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* updateProfileImage(action: any = null): Generator<any> {
    console.log("updateProfileImage -> Watcher Saga");
    let url = api_url + '/api/v1.2/ClapupIdentity/UpdatePersonalPhoto';
    try {
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {
            console.log("updateProfileImage", data);
            
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}


// Watcher Saga:
function* profileManagementSettingSaga() {
    // console.log("profileSetting -> Watcher Saga");
    
    yield all([
        takeEvery(Constants.GET_NOTIFICATION_SETTINGS, findNotificationSettings),
        takeEvery(Constants.UPDATE_NOTIFICATION_PREFERENCE, updateNotificationPreference),
        takeEvery(Constants.GET_PROFILE_INFO, findProfileInfo),
        takeEvery(Constants.UPDATE_PROFILE_INFO_SETTING, updateProfileInfo),
        takeEvery(Constants.UPLOAD_PROFILE_IMAGE, updateProfileImage),
    ]);
}

export default profileManagementSettingSaga;