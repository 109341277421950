import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Tooltip } from 'antd';
import { setErrorMessage, setSuccessMessage } from '../../../../dashboard/reducer';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getCampaignImage } from '../../../../dashboard/image_service';
import { Spinner } from 'react-bootstrap';
import { getFirstTimeCampaignList } from '../../reducer';
const Campaign: React.FC = () => {
    const {campaign_list, searched_campaign_list, searched_campaign_text} = useSelector((state: any) => state?.promotionsState);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [hasMore, setHasMore] = React.useState<boolean>(true);
    const dispatch = useDispatch();
    useEffect(() => {
        console.info("Campaign list ", { campaign_list});
        if (!campaign_list?.length) {
            dispatch(getFirstTimeCampaignList());
            setIsLoading(true);
        } else {
            // console.info("Campaign ", { Campaigns });
            setIsLoading(false);
        }

        setTimeout(() => {
            setIsLoading(false);
        }, 1500);

    }, [dispatch, campaign_list?.length]);

    const fetchMoreData = () => {
        setIsLoading(true);
        // if (!campaignNextDate) {
        //     setHasMore(false);
        //     setIsLoading(false);
        //     return;
        // }
        setTimeout(() => {
          // // setFolders(campaigns.concat(campaigns));
        //   const campaignPayload: any = {
        //       nextDate: campaignNextDate,
        //   };
  
        //   if (searchText) {
        //     campaignPayload.searchText = searchText;
        //     campaignPayload.settingsId = "";
        //   }
        //   dispatch(getFolders(campaignPayload));
          setIsLoading(false);
        }, 1500);
    };

    // console.log({ campaign_list });

    return (
        <React.Fragment>            
            {/* <div className={`content-wall ${query_string?.includes('=contents') ? "mobile-content-wall" : ""}`}> */}
            <div className={`content-wall`}>
                <div className="table-responsive">
                    <div className='infinity-scroll'>
                        <InfiniteScroll
                            dataLength={(searched_campaign_text ? searched_campaign_list : campaign_list)?.length}
                            // className="border"
                            scrollThreshold={0.9}
                            style={{ overflow: "scroll", paddingRight: 0 }}
                            next={() => {
                                // console.log(
                                //     `prev -> ${campaigns?.length}, next -> ${campaigns?.length + 20}`
                                // );
                                // fetchMoreData();
                            }}
                            inverse={false}
                            hasMore={hasMore}
                            loader={
                                null
                                // isLoading && campaign_list?.length ? (
                                //     <div className="d-flex justify-content-center align-items-center mt-1">
                                //         <Spinner
                                //             size="sm"
                                //             animation="border"
                                //             variant="primary"
                                //         />
                                //     </div>
                                // ) : null
                            }
                            scrollableTarget="scrollableDiv"
                            // endMessage={
                            //   <p style={{ textAlign: "center" }}>
                            //     <b>Yay! You have seen it all</b>
                            //   </p>
                            // }
                            height={"70vh"} // 43.5vh
                        >
                            <table className="table table-borderless campaign-table m-0">
                                <thead className='campaign-thead sticky-top' style={{top: -1}}>
                                    <tr>
                                        <th style={{textAlign: 'start', paddingLeft: 40}}>Name</th>
                                        <th>Recipients</th>
                                        <th>Delivered</th>
                                        <th>Read</th>
                                        <th className='replied'>Replied</th>
                                        <th className='failed'>Failed</th>
                                        <th className='status'>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    (searched_campaign_text ? searched_campaign_list : campaign_list)?.map((campaign: any, index: number) => (
                                        <tr key={index}>
                                            <td style={{textAlign: 'start', paddingLeft: 30}}>{campaign?.campaignName}</td>
                                            <td>{campaign?.recipients}</td>
                                            <td>{campaign?.deliveredCount}</td>
                                            <td>{campaign?.readCount}</td>
                                            <td>{campaign?.repliedCount}</td>
                                            <td>{campaign?.failedCount}</td>
                                            <td>
                                                <Tooltip
                                                    placement={"topRight"}
                                                    title={moment(campaign?.scheduledOn).format("hh:mm:ss A, DD-MM-YY") + ", " + campaign?.timeZone}
                                                    color={"#ffffff"}
                                                >
                                                    <img
                                                        // onClick={() => dispatch(setErrorMessage("Error. Not allowed to delete"))}
                                                        src={
                                                            campaign?.uploadStatus?.toLowerCase() === "uploaded"
                                                            ? getCampaignImage("save-tik-campaign")
                                                            : getCampaignImage("schedular-icon")
                                                        }
                                                        alt="delete-icon"
                                                    />
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Campaign;