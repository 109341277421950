import { combineReducers } from "redux";
import loginReducer from '../../signup-login/loginReducer'
import profileReducer from "../../profile/reducer";
// mmhk-region
import headerReducer from "../../components/layout/header/headerReducer";
import unifiedReducer from "../../dashboard/reducer";
import profileManageSettingReducer from "../../pages/profile-management/profileManageReducer";
import workspaceManagementSettingReducer from "../../pages/workspace-management/workSpaceReducer";
import bioPageManagementReducer from "../../pages/biopage-management/biopageReducer";
import userManagementReducer from "../../pages/user-management/userManagementReducer";
import mediaManagementReducer from "../../components/MediaContents/mediaReducer";
import botReducer from "../../pages/bot-management/botReducer";
import promotionsReducer from "../../components/Promotions/reducer";
const rootReducer = combineReducers({
    login: loginReducer,
    unified: unifiedReducer,
    profile: profileReducer,
    // mmhk-region
    headerState: headerReducer,
    profileManageSettingState: profileManageSettingReducer,
    userManagementState: userManagementReducer,
    workspaceManagementState: workspaceManagementSettingReducer,
    bioPageSettingState: bioPageManagementReducer,
    mediaState: mediaManagementReducer,
    botState: botReducer,
    promotionsState: promotionsReducer,
});

export default rootReducer;