import React from 'react';
import moment from 'moment';
import user_avatar from '../../../../assets/icons/user-avatar.svg';
import user_avatar_2 from '../../../../assets/icons/user-avatar-2.svg';
import start from '../../../../assets/icons/star-icon.svg';
import star_half from '../../../../assets/icons/star-half-icon.svg';
import lock_icon from '../../../../assets/icons/lock-icon.svg';
import unlock_icon from '../../../../assets/icons/unlock-icon.svg';
import { getGenerateApiImageByName } from '../../../../dashboard/image_service';

export default function TwitterDetails({ workspaceSettings, getImage, conversation, unifiedDetailsData }: any) {
  const {reviewerDisplayName, reviewedbyUserName, mentionedbyUserName, recieverName, reviewerPhoteUrl, mentionedbyUserImageUrl, reviewtype, createdOn, reviewComment, provider, tag, starRating, likeCount, retweetCount, locationName, recieverCompany, mentionedbyUserEmailId, reviewComment2} = conversation || {};
  const {reviewer, location, page, properties, details} = unifiedDetailsData || {};
  
  const {
      // facebook
      Reviewer, Reviews, reviews, Comments, comments,
      // google
      reviewerName, daysFromFirstReview, reviewerReviewCount, averageRating,
      // video testimonial
      totalReviews,
      // facebook, twitter
      Mentions, mentions,
      // twitter
      Days, days, Today,
  } = reviewer || {};
  const {
    // facebook
    reviewCount, ratingStars, followerCount, followersCount,
    // google, facebook,
    todaysCount,
    // google, video testimonial, customer feedback
    averageResponse, locationAverageReviewRating, locationReviewCount,
    // video testimonial
    averageRating: locationAverageRating, requestCount, responseCount,
    // twitter
    totalMentionCount,
    todaysTotalMentionCount,
    Followers,

      
  } = location || page || {};
  console.log({conversation, unifiedDetailsData});
  const {ResponseTime, RequestedDate, SubmittedOn, repliedBy, repliedOn, reviewedOn, MentionedOn, requestorEmail} = details || {};
  return (
    <React.Fragment>
      {/* Mobile */}
      <div className="w-100 h-100 conversation-details-mobile">
        <div className="inbox-wall w-100">
          {conversation && reviewer && conversation ? (
            <div className="right-feed-card rounded">
              <div className="d-flex align-items-start p-2">
                {
                  mentionedbyUserImageUrl
                  ? <img
                      className="user-avatar"
                      src={mentionedbyUserImageUrl || user_avatar}
                      alt="avatar"
                    />
                  : <img
                      className="user-avatar"
                      src={getGenerateApiImageByName(mentionedbyUserName)}
                      alt="avatar"
                    />
                }
                <div className="user-name-provider-container">
                  <div className="ps-2 d-flex flex-column justify-content-start align-items-start">
                    {provider === "twitter" || averageRating ? (
                      <div>
                        <span className="user_name">
                          {reviewerName ||
                            Reviewer ||
                            mentionedbyUserName ||
                            recieverName || 
                            "Client"}
                        </span>
                        <span className="rating-social-email">
                          <div>
                            {"averageRating" in reviewer ? (
                              <div>
                                {Array.from(
                                  Array(
                                    Math.floor(parseFloat(averageRating))
                                  ).keys()
                                ).map((item: any, index: number) => {
                                  return (
                                    <img
                                      className="start"
                                      key={index}
                                      src={start}
                                      alt="star"
                                    />
                                  );
                                })}
                                {Array.from(
                                  Array(
                                    5 - Math.floor(parseFloat(averageRating))
                                  ).keys()
                                ).map((item: any, index: number) => {
                                  return (
                                    <img
                                      className="star"
                                      key={index}
                                      src={star_half}
                                      alt="star"
                                    />
                                  );
                                })}
                                <span className="ms-2">
                                  {Number(averageRating)}/5
                                </span>
                              </div>
                            ) : provider === "twitter" ? (
                              <span className="">{"@twitter_link"}</span>
                            ) : null}
                          </div>
                        </span>
                      </div>
                    ) : (
                      <span className="user_name mt-2">
                        {reviewerName ||
                          Reviewer ||
                          mentionedbyUserName ||
                          // recieverName ||
                          "User name"}
                      </span>
                    )}
                  </div>
                  <img
                    className="social-logo"
                    src={getImage(provider)}
                    alt={provider}
                  />
                </div>
              </div>
              <div className="footer w-100">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {daysFromFirstReview || Reviews || reviews || Days || 0}
                    </span>
                    <span className="title">
                      {"daysFromFirstReview" in reviewer
                        ? "Days"
                        : "Reviews" in reviewer || "reviews" in reviewer
                        ? "Reviews"
                        : "Days" in reviewer
                        ? "Days"
                        : "Title-1"}
                    </span>
                  </div>
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {totalReviews ||
                        reviewerReviewCount ||
                        Comments || comments ||
                        Mentions || mentions ||
                        0}
                    </span>
                    <span className="title">
                      {"reviewerReviewCount" in reviewer ||
                      "totalReviews" in reviewer
                        ? "Reviews"
                        : "Comments" in reviewer || "comments" in reviewer
                        ? "Comments"
                        : "Mentions" in reviewer || "mentions" in reviewer
                        ? "Mentions"
                        : "Title-2"}
                    </span>
                  </div>
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {averageRating || Today || Mentions || mentions || 0}
                    </span>
                    <span className="title">
                      {"averageRating" in reviewer
                        ? "Avg Rating"
                        : "Today" in reviewer
                        ? "Today"
                        : "Mentions" in reviewer || "mentions" in reviewer
                        ? "Mentions"
                        : "Title-3"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
        <div className="inbox-wall w-100">
          {conversation && location || page ? (
            <div className="right-feed-card rounded">
              <div className="d-flex align-items-start p-2">
                <img
                  className="user-avatar"
                  src={workspaceSettings?.logo || user_avatar_2}
                  alt="user_avatar"
                />
                <div className="user-name-provider-container">
                  <div className="ps-2 d-flex flex-column justify-content-start align-items-start">
                    <span className="user_name">
                      {workspaceSettings?.workspaceName || "Tezla Inc"}
                    </span>
                    <span className="rating-social-email">
                      {(provider === "twitter" ||
                        locationAverageReviewRating ||
                        ratingStars ||
                        locationAverageRating) && (
                        <div>
                          {locationAverageReviewRating ||
                          ratingStars ||
                          locationAverageRating ? (
                            <div>
                              {Array.from(
                                Array(
                                  Math.floor(
                                    parseFloat(
                                      locationAverageReviewRating ||
                                        ratingStars ||
                                        locationAverageRating
                                    )
                                  )
                                ).keys()
                              ).map((item: any, index: number) => {
                                return (
                                  <img
                                    className="start"
                                    key={index}
                                    src={start}
                                    alt="star"
                                  />
                                );
                              })}
                              {Array.from(
                                Array(
                                  5 -
                                    Math.floor(
                                      parseFloat(
                                        locationAverageReviewRating ||
                                          ratingStars ||
                                          locationAverageRating
                                      )
                                    )
                                ).keys()
                              ).map((item: any, index: number) => {
                                return (
                                  <img
                                    className="star"
                                    key={index}
                                    src={star_half}
                                    alt="star"
                                  />
                                );
                              })}
                              <span className="ms-2">
                                {Number(
                                  locationAverageReviewRating ||
                                    ratingStars ||
                                    locationAverageRating
                                )}
                                /5
                              </span>
                            </div>
                          ) : provider === "twitter" ? (
                            <span>{"@twitter_link"}</span>
                          ) : null}
                        </div>
                      )}
                    </span>
                  </div>
                  <img
                    className="social-logo"
                    src={getImage(provider)}
                    alt={provider}
                  />
                </div>
              </div>
              <div className="footer w-100 mt-1">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(
                        locationReviewCount || reviewCount || requestCount
                      ) ||
                        totalMentionCount ||
                        0}
                    </span>
                    <span className="title">
                      {"locationReviewCount" in (location || page) ||
                      "reviewCount" in (location || page)
                        ? "Reviews"
                        : "requestCount" in (location || page)
                        ? "Request"
                        : "totalMentionCount" in (location || page)
                        ? "Mentions"
                        : "Title-1"}
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(todaysCount || responseCount) ||
                        todaysTotalMentionCount ||
                        0}
                    </span>
                    <span className="title">
                      {"todaysCount" in (location || page)
                        ? "Today"
                        : "responseCount" in (location || page)
                        ? "Response"
                        : "averageResponse" in (location || page)
                        ? "Resp. Rate"
                        : "todaysTotalMentionCount" in (location || page)
                        ? "Today"
                        : "Title-2"}
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(
                        locationAverageReviewRating ||
                        followerCount ||
                        followersCount ||
                        Followers ||
                        averageResponse
                      ) || 0}
                    </span>
                    <span className="title">
                      {"locationAverageReviewRating" in (location || page)
                        ? "Avg Rating"
                        : ("followerCount" in (location || page) ||
                          "followersCount" in (location || page) ||
                          "Followers" in (location || page))
                        ? "Followers"
                        : "averageResponse" in (location || page)
                        ? "Resp. Rate"
                        : "Title-3"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded  d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
        <div className="properties-wall w-100">
          {
            // "properties" in unifiedDetailsData || provider === "videoTestimonial" || provider === "twitter" ? (
              conversation && "details" in unifiedDetailsData ? (
              <div className="right-feed-card rounded p-2">
                <h4 className="text-start header-title">Properties</h4>
                <div className="content d-flex align-items-start justify-content-between">
                  <div className="type-to-lock d-flex flex-column">
                    <span>Type:</span>
                    <span>To:</span>
                    <span className="d-block">Lock:</span>
                  </div>
                  <div className="type-to-lock-value">
                    <span>
                      {"Twitter Mention"}
                    </span>
                    <span>
                      {workspaceSettings?.workspaceName || "Workspace Name"}
                    </span>
                    {
                      <span className="lock-unlock d-flex align-items-center justify-content-center pt-1 mt-1">
                        {properties?.replied ? (
                          <span>
                            Locked <img src={lock_icon} alt="locked" />
                          </span>
                        ) : properties?.lock_title ? (
                          <span>{properties?.lock_title}</span>
                        ) : (
                          <span>
                            Unlocked <img src={unlock_icon} alt="unlocked" />
                          </span>
                        )}
                      </span>
                    }
                  </div>
                  <div className="type-to-lock">
                    {properties?.replied ? (
                        <span className="replied">Replied</span>
                      ) : (
                        <span className="not-replied">Not Replied</span>
                      )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="right-feed-card rounded  d-flex align-items-center justify-content-center">
                <p className="nothing-show">Nothing to show </p>
              </div>
            )
          }
        </div>
        <div className="details-wall w-100">
          {conversation && details ? (
            <div className="right-feed-card rounded p-2">
              <h4 className="text-start header-title">Details</h4>
              <div className="content d-flex align-items-start" style={{gap: '5%'}}>
                <div className="req-res">
                  <span>
                    {details.hasOwnProperty("MentionedOn")
                      ? "Mentioned"
                      : details.hasOwnProperty("CreatedOn")
                      ? "Created"
                      : details.hasOwnProperty("commentedOn")
                      ? "Commented"
                      : details.hasOwnProperty("RequestedDate")
                      ? "Requested"
                      : details.hasOwnProperty("reviewedOn")
                      ? "Reviewed"
                      : null}
                    :
                  </span>
                  <span>
                    {details.hasOwnProperty("SubmittedOn")
                      ? "Submitted"
                      : details.hasOwnProperty("repliedOn")
                      ? "Replied"
                      : null}
                    :
                  </span>
                  <span>Res. Time:</span>
                  <span>
                    {details.hasOwnProperty("requestorEmail")
                      ? "Email"
                      : details.hasOwnProperty("repliedBy")
                      ? "Replied By"
                      : null}
                    :
                  </span>
                </div>
                <div className="req-res-value">
                  <span>
                    {RequestedDate
                      ? moment(RequestedDate).format("ll") +
                        " " +
                        moment(RequestedDate).format("LT")
                      : // new Date(details?.requested_time).toDateString() + " " +
                      // new Date(details?.requested_time).toLocaleTimeString()
                      reviewedOn
                      ? moment(reviewedOn).format("ll") +
                        " " +
                        moment(reviewedOn).format("LT")
                      : // new Date(details?.reviewed_time).toDateString() + " " +
                      // new Date(details?.reviewed_time).toLocaleTimeString()
                      MentionedOn
                      ? moment(MentionedOn).format("ll") +
                        " " +
                        moment(MentionedOn).format("LT")
                      : // new Date(MentionedOn).toDateString() + " " +
                        // new Date(MentionedOn).toLocaleTimeString()
                        " --- "}
                  </span>
                  <span>
                    {SubmittedOn
                      ? moment(SubmittedOn).format("ll") +
                        " " +
                        moment(SubmittedOn).format("LT")
                      : // new Date(details?.submitted_time).toDateString() + " " +
                      // new Date(details?.submitted_time).toLocaleTimeString()
                      repliedOn
                      ? moment(repliedOn).format("ll") +
                        " " +
                        moment(repliedOn).format("LT")
                      : // new Date(replied_time).toDateString() + " " +
                        // new Date(replied_time).toLocaleTimeString()
                        " --- "}
                  </span>
                  <span className="d-block">
                    {ResponseTime
                      ? ResponseTime
                      : // moment(ResponseTime).format('ll') + " " + moment(ResponseTime).format('LT')
                        // moment(details?.ResponseTime).calendar()
                        // new Date(details?.response_time).toDateString() + " " +
                        // new Date(details?.response_time).toLocaleTimeString()
                        " --- "}
                  </span>
                  {requestorEmail ? (
                    <span>{requestorEmail}</span>
                  ) : repliedBy ? (
                    <span className="replier">
                      <img src={user_avatar} alt="avatar" />
                      {repliedBy ? repliedBy : "---"}
                    </span>
                  ) : (
                    <span>{"---"}</span>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded  d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
      </div>
      {/* Laptop */}
      <div className="w-100 h-100 conversation-details-lg">
        <div className="inbox-wall w-100">
          {conversation && reviewer ? (
            <div className="right-feed-card rounded">
              <div className="d-flex align-items-start p-2">
                {
                  mentionedbyUserImageUrl
                  ? <img
                      className="user-avatar"
                      src={mentionedbyUserImageUrl || user_avatar}
                      alt="avatar"
                    />
                  : <img
                      className="user-avatar"
                      src={getGenerateApiImageByName(mentionedbyUserName)}
                      alt="avatar"
                    />
                }
                <div className="user-name-provider-container">
                  <div className="ps-2 d-flex flex-column justify-content-start align-items-start">
                    {provider === "twitter" || averageRating ? (
                      <div>
                        <span className="user_name">
                          {mentionedbyUserName ||
                            recieverName ||
                            "Client"}
                        </span>
                        <span className="rating-social-email">
                          <div>
                            {"averageRating" in reviewer ? (
                              <div>
                                {Array.from(
                                  Array(
                                    Math.floor(parseFloat(averageRating))
                                  ).keys()
                                ).map((item: any, index: number) => {
                                  return (
                                    <img
                                      className="start"
                                      key={index}
                                      src={start}
                                      alt="star"
                                    />
                                  );
                                })}
                                {Array.from(
                                  Array(
                                    5 - Math.floor(parseFloat(averageRating))
                                  ).keys()
                                ).map((item: any, index: number) => {
                                  return (
                                    <img
                                      className="star"
                                      key={index}
                                      src={star_half}
                                      alt="star"
                                    />
                                  );
                                })}
                                <span className="ms-2">
                                  {Number(averageRating)}/5
                                </span>
                              </div>
                            ) : provider === "twitter" ? (
                              <span className="">{"@twitter_link"}</span>
                            ) : null}
                          </div>
                        </span>
                      </div>
                    ) : (
                      <span className="user_name mt-2">
                        {mentionedbyUserName ||
                          recieverName ||
                          "User name"}
                      </span>
                    )}
                  </div>
                  <img
                    className="social-logo"
                    src={getImage(provider)}
                    alt={provider}
                  />
                </div>
              </div>
              <div className="footer w-100">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {daysFromFirstReview || Reviews || reviews || Days || days || 0}
                    </span>
                    <span className="title">
                      {"daysFromFirstReview" in reviewer
                        ? "Days"
                        : "Reviews" in reviewer || "reviews" in reviewer
                        ? "Reviews"
                        : "Days" in reviewer || "days" in reviewer
                        ? "Days"
                        : "Title-1"}
                    </span>
                  </div>
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {totalReviews ||
                        reviewerReviewCount ||
                        Comments || comments ||
                        Mentions || mentions || 0}
                    </span>
                    <span className="title">
                      {"reviewerReviewCount" in reviewer ||
                      "totalReviews" in reviewer
                        ? "Reviews"
                        : "Comments" in reviewer || "comments" in reviewer
                        ? "Comments"
                        : "Mentions" in reviewer || "mentions" in reviewer
                        ? "Mentions"
                        : "Title-2"}
                    </span>
                  </div>
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {averageRating || Today || Mentions || mentions || 0}
                    </span>
                    <span className="title">
                      {"averageRating" in reviewer
                        ? "Avg Rating"
                        : "Today" in reviewer
                        ? "Today"
                        : "Mentions" in reviewer || "mentions" in reviewer
                        ? "Mentions"
                        : "Title-3"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded mt-0  d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
        <div className="inbox-wall w-100">
          {conversation && location || page ? (
            <div className="right-feed-card rounded">
              <div className="d-flex align-items-start p-2">
                <img
                  className="user-avatar"
                  src={workspaceSettings?.logo || user_avatar_2}
                  alt="user_avatar"
                />
                <div className="user-name-provider-container">
                  <div className="ps-2 d-flex flex-column justify-content-start align-items-start">
                    <span className="user_name">
                      {workspaceSettings?.workspaceName || "Tezla Inc"}
                    </span>
                    <span className="rating-social-email">
                      {(provider === "twitter" ||
                        locationAverageReviewRating ||
                        ratingStars ||
                        locationAverageRating) && (
                        <div>
                          {locationAverageReviewRating ||
                          ratingStars ||
                          locationAverageRating ? (
                            <div>
                              {Array.from(
                                Array(
                                  Math.floor(
                                    parseFloat(
                                      locationAverageReviewRating ||
                                        ratingStars ||
                                        locationAverageRating
                                    )
                                  )
                                ).keys()
                              ).map((item: any, index: number) => {
                                return (
                                  <img
                                    className="start"
                                    key={index}
                                    src={start}
                                    alt="star"
                                  />
                                );
                              })}
                              {Array.from(
                                Array(
                                  5 -
                                    Math.floor(
                                      parseFloat(
                                        locationAverageReviewRating ||
                                          ratingStars ||
                                          locationAverageRating
                                      )
                                    )
                                ).keys()
                              ).map((item: any, index: number) => {
                                return (
                                  <img
                                    className="star"
                                    key={index}
                                    src={star_half}
                                    alt="star"
                                  />
                                );
                              })}
                              <span className="ms-2">
                                {Number(
                                  locationAverageReviewRating ||
                                    ratingStars ||
                                    locationAverageRating
                                )}
                                /5
                              </span>
                            </div>
                          ) : provider === "twitter" ? (
                            <span>{"@twitter_link"}</span>
                          ) : null}
                        </div>
                      )}
                    </span>
                  </div>
                  <img
                    className="social-logo"
                    src={getImage(provider)}
                    alt={provider}
                  />
                </div>
              </div>
              <div className="footer w-100 mt-1">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(
                        locationReviewCount || reviewCount || requestCount
                      ) ||
                        totalMentionCount ||
                        0}
                    </span>
                    <span className="title">
                      {"locationReviewCount" in (location || page) ||
                      "reviewCount" in (location || page)
                        ? "Reviews"
                        : "requestCount" in (location || page)
                        ? "Request"
                        : "totalMentionCount" in (location || page)
                        ? "Mentions"
                        : "Title-1"}
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(todaysCount || responseCount) ||
                        todaysTotalMentionCount ||
                        0}
                    </span>
                    <span className="title">
                      {"todaysCount" in (location || page)
                        ? "Today"
                        : "responseCount" in (location || page)
                        ? "Response"
                        : "averageResponse" in (location || page)
                        ? "Resp. Rate"
                        : "todaysTotalMentionCount" in (location || page)
                        ? "Today"
                        : "Title-2"}
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(
                        locationAverageReviewRating ||
                        followerCount ||
                        followersCount ||
                        Followers ||
                        averageResponse
                      ) || 0}
                    </span>
                    <span className="title">
                      {"locationAverageReviewRating" in (location || page)
                        ? "Avg Rating"
                        : ("followerCount" in (location || page) ||
                          "followersCount" in (location || page) ||
                          "Followers" in (location || page)) ? "Followers"
                        : "averageResponse" in (location || page) ? "Resp. Rate"
                        : "Title-3"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded  d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
        <div className="properties-wall w-100">
          {
            // "properties" in unifiedDetailsData || provider === "videoTestimonial" || provider === "twitter" ? (
              conversation && "details" in unifiedDetailsData ? (
              <div className="right-feed-card rounded p-2">
                <h4 className="text-start header-title">Properties</h4>
                <div className="content d-flex align-items-start justify-content-between">
                  <div className="type-to-lock d-flex flex-column">
                    <span>Type:</span>
                    <span>To:</span>
                    <span className="d-block">Lock:</span>
                  </div>
                  <div className="type-to-lock-value">
                    <span>
                      {"Twitter Mention"}
                    </span>
                    <span>
                      {workspaceSettings?.workspaceName || "Workspace Name"}
                    </span>
                    {
                      <span className="lock-unlock d-flex align-items-center justify-content-center pt-1 mt-1">
                        {properties?.replied ? (
                          <span>
                            Locked <img src={lock_icon} alt="locked" />
                          </span>
                        ) : properties?.lock_title ? (
                          <span>{properties?.lock_title}</span>
                        ) : (
                          <span>
                            Unlocked <img src={unlock_icon} alt="unlocked" />
                          </span>
                        )}
                      </span>
                    }
                  </div>
                  <div className="type-to-lock">
                    {properties?.replied ? (
                        <span className="replied">Replied</span>
                      ) : (
                        <span className="not-replied">Not Replied</span>
                      )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="right-feed-card rounded  d-flex align-items-center justify-content-center">
                <p className="nothing-show">Nothing to show </p>
              </div>
            )
          }
        </div>
        <div className="details-wall w-100">
          {conversation && details ? (
            <div className="right-feed-card rounded p-2">
              <h4 className="text-start header-title">Details</h4>
              <div className="content d-flex align-items-start" style={{gap: "5%"}}>
                <div className="req-res">
                  <span>
                    {details.hasOwnProperty("MentionedOn")
                      ? "Mentioned"
                      : details.hasOwnProperty("CreatedOn")
                      ? "Created"
                      : details.hasOwnProperty("commentedOn")
                      ? "Commented"
                      : details.hasOwnProperty("RequestedDate")
                      ? "Requested"
                      : details.hasOwnProperty("reviewedOn")
                      ? "Reviewed"
                      : null}
                    :
                  </span>
                  <span>
                    {details.hasOwnProperty("SubmittedOn")
                      ? "Submitted"
                      : details.hasOwnProperty("repliedOn")
                      ? "Replied"
                      : null}
                    :
                  </span>
                  <span>Res. Time:</span>
                  <span>
                    {details.hasOwnProperty("requestorEmail")
                      ? "Email"
                      : details.hasOwnProperty("repliedBy")
                      ? "Replied By"
                      : null}
                    :
                  </span>
                </div>
                <div className="req-res-value">
                  <span>
                    {RequestedDate
                      ? moment(RequestedDate).format("ll") +
                        " " +
                        moment(RequestedDate).format("LT")
                      : // new Date(details?.requested_time).toDateString() + " " +
                      // new Date(details?.requested_time).toLocaleTimeString()
                      reviewedOn
                      ? moment(reviewedOn).format("ll") +
                        " " +
                        moment(reviewedOn).format("LT")
                      : // new Date(details?.reviewed_time).toDateString() + " " +
                      // new Date(details?.reviewed_time).toLocaleTimeString()
                      MentionedOn
                      ? moment(MentionedOn).format("ll") +
                        " " +
                        moment(MentionedOn).format("LT")
                      : // new Date(MentionedOn).toDateString() + " " +
                        // new Date(MentionedOn).toLocaleTimeString()
                        " --- "}
                  </span>
                  <span>
                    {SubmittedOn
                      ? moment(SubmittedOn).format("ll") +
                        " " +
                        moment(SubmittedOn).format("LT")
                      : // new Date(details?.submitted_time).toDateString() + " " +
                      // new Date(details?.submitted_time).toLocaleTimeString()
                      repliedOn
                      ? moment(repliedOn).format("ll") +
                        " " +
                        moment(repliedOn).format("LT")
                      : // new Date(replied_time).toDateString() + " " +
                        // new Date(replied_time).toLocaleTimeString()
                        " --- "}
                  </span>
                  <span className="d-block">
                    {ResponseTime
                      ? ResponseTime
                      : // moment(ResponseTime).format('ll') + " " + moment(ResponseTime).format('LT')
                        // moment(details?.ResponseTime).calendar()
                        // new Date(details?.response_time).toDateString() + " " +
                        // new Date(details?.response_time).toLocaleTimeString()
                        " --- "}
                  </span>
                  {requestorEmail ? (
                    <span>{requestorEmail}</span>
                  ) : repliedBy ? (
                    <span className="replier">
                      <img src={user_avatar} alt="avatar" />
                      {repliedBy ? repliedBy : "---"}
                    </span>
                  ) : (
                    <span>{"---"}</span>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded  d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}
