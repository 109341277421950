import React, { useEffect } from 'react'
import {
    Dropdown,
    // Button, Input, Space, Divider, Card
} from 'antd';
import notification_img from '../../../../assets/icons/notification_icon.svg';
import dots from '../../../../assets/icons/dots.svg';
// import google_logo from '../../../../assets/icons/google-logo.svg';
// import twitter_logo from '../../../../assets/icons/twitter-icon.svg';
// import facebook_logo from '../../../../assets/icons/facebook-reviews.svg';
// import feedback_logo from '../../../../assets/icons/feedback-line-1.2.svg';
// import video_logo from '../../../../assets/icons/video-chat-line-1.2.svg';
import check_icon from '../../../../assets/icons/checkmark-circle.svg';
import clock_icon from '../../../../assets/icons/clock-time.svg';
import dummy_notifications from './dummy_notifications';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import { getImage } from '../../../../dashboard/image_service';
interface Props {}

const Notifications: React.FC<Props> = ({}) => {
    const [isShow, setIsShow] = React.useState<boolean>(false);
    const [showClear, setShowClear] = React.useState<boolean>(false);
    const [notifications, setNotifications] = React.useState<any[]>([]);
    useEffect(() => {
        // setNotifications(dummy_notifications);
    }, [dummy_notifications]);

    // const getImage = (type: any) => {
    //     switch (type) {
    //       case 'google':
    //         return google_logo;
    //       case 'facebook':
    //         return facebook_logo;
    //       case 'facebookComments':
    //         return facebook_logo;
    //       case 'twitter':
    //         return twitter_logo;
    //       case 'video':
    //         return video_logo;
    //       case 'feedback':
    //         return feedback_logo;
    //       default:
    //         return google_logo;
    //     }
    // };

    return <React.Fragment>
        <Dropdown
            trigger={['click']}
            placement="bottomLeft"
            overlay={
                <div
                    className='notifications mt-4'
                >
                    <div className='header d-flex justify-content-between align-items-center ps-3 pe-2 py-2'>
                        <p>Notifications <span className='notification-length'>{notifications?.length}</span></p>
                        {
                            notifications?.length
                            ?   <Dropdown
                                    trigger={['click']}
                                    placement="bottomRight"
                                    overlay={
                                        <div
                                            className='notification-clear'
                                        >
                                            <p className='px-2'
                                                onClick={() => {
                                                    setNotifications([]);
                                                    setShowClear(false);
                                                }}
                                            >
                                                Clear All
                                            </p> 
                                        </div>
                                    }
                                    open={showClear}
                                    onOpenChange={setShowClear}
                
                                >
                                    <img
                                        onClick={() => {
                                            setShowClear(!showClear);
                                        }}
                                        style={{ height: 26, cursor: 'pointer' }}
                                        src={dots} alt="dots"
                                    />
                                </Dropdown>
                            : null
                        }
                    </div>
                    <div className='pe-2'>
                        <Scrollbars
                            style={{ paddingRight: 10 }}
                            autoHide
                            autoHideTimeout={5000}
                            autoHideDuration={500}
                            autoHeight
                            autoHeightMin={152}
                            autoHeightMax={360}
                            thumbMinSize={10}
                            universal={true}
                        >
                            {
                                notifications?.map((notification: any, index: number) => {
                                    return (
                                        <div
                                            key={index}
                                            className='notification px-3 py-2'
                                        >
                                            <img  style={{height: 15, width: 15}} src={getImage(notification?.as)} alt={notification?.as} />
                                            <div className='notification-content'>
                                                <div className='ms-2'>
                                                    <p className='pe-3 m-0'>{notification?.description}</p>
                                                    <div className='d-flex mt-1'>
                                                        <img style={{height: 12, width: 12}} src={clock_icon} alt="clock" />
                                                        <span className='ms-2'>{moment(notification?.date).fromNow()}</span>
                                                    </div>
                                                </div>
                                                <img style={{height: 15, width: 15}} src={check_icon} alt="check" />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Scrollbars>
                    </div>
                </div>
            }
            open={isShow}
            onOpenChange={() => setIsShow(!isShow)}
        >
            <img style={{height: 20, width: 17, cursor: 'pointer'}} src={notification_img} alt="notification" />
            {/* <img style={{height: 20}} src="https://img.icons8.com/ios/50/000000/bell.png" alt="notification" /> */}
        </Dropdown>
    </React.Fragment>
}

export default Notifications;
