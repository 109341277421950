import React from 'react';
import moment from 'moment';
import user_avatar from '../../../../assets/icons/user-avatar.svg';
import user_avatar_2 from '../../../../assets/icons/user-avatar-2.svg';
import start from '../../../../assets/icons/star-icon.svg';
import star_half from '../../../../assets/icons/star-half-icon.svg';
import lock_icon from '../../../../assets/icons/lock-icon.svg';
import unlock_icon from '../../../../assets/icons/unlock-icon.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { getGenerateApiImageByName } from '../../../../dashboard/image_service';

export default function GoogleDetails({ workspaceSettings, getImage, conversation, unifiedDetailsData }: any) {
  const route_path = window?.location?.pathname;
  const query_string = window.location.search;
  const query_tag = query_string?.includes("reviews") ? "reviews" : query_string?.includes("mentions") ? "mentions" : query_string?.includes("comments") ? "comments" : query_string?.includes("qa") ? "qa" : "all";

  // 1
  const {reviewerDisplayName, reviewerPhoteUrl, requestedDate, responseTime, createdOn, reviewerEmail, starRating: ratingStars, provider} = conversation || {};
  // 2
  const { averageRating, Reviewer_daysFromFirstReview, ReviewsCount } = unifiedDetailsData || {};
  // 3
  const { unifiedData, isReplyingOn, isInReplyingMode }: any = useSelector((state: RootState) => state?.unified);
  
  // console.log({ conversation, unifiedDetailsData, isInReplyingMode, isReplyingOn });
  
  const {
    InboxAll_googleQandAConversation
  } = unifiedData || {};

  const all_details: any[] =
  (route_path?.includes('unified') && query_tag !== 'reviews')
  ? InboxAll_googleQandAConversation
  : [];
  
  const conversationDetails = all_details?.find((item: any) => item?.QuestionId === conversation?.reviewId);

  console.log({ all_details, conversationDetails, conversation });
  function getDateDifference(date1: any, date2: any) {
    if(!date1 || !date2) return " --- ";
    // Calculate the time difference in milliseconds
    const timeDifference = Math.abs(Number(new Date(date1)) - Number(new Date(date2)));
    console.log({ timeDifference, date1: Number(new Date(date1)), date2: Number(new Date(date2)) });
    
    // Calculate the number of days
    let days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    // Calculate the remaining hours
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    // Calculate the remaining minutes
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

    let res = "";
    let years: any = 0;
    if (days > 365) {
      years = Math.floor(days / 365);
      res += years + "Years, ";
      days -= years * 365;
      res += days + "Days ";
      days = 0;
    }
    if (days > 0) {
      res += days + "Days, ";
    }
    if (!years) {
      res += hours + "Hours, " + minutes + "Minutes";
    }
    return res;
  }
  
  
  return (
    <React.Fragment>
      {/* Mobile */}
      <div className="w-100 h-100 conversation-details-mobile">
        <div className="inbox-wall w-100">
          {unifiedDetailsData ? (
            <div className="right-feed-card rounded">
              <div className="d-flex align-items-start p-2">
                {
                  reviewerPhoteUrl
                  ? <img
                      className="user-avatar"
                      src={reviewerPhoteUrl || user_avatar}
                      alt="avatar"
                    />
                  : <img
                      className="user-avatar"
                      src={getGenerateApiImageByName(reviewerDisplayName)}
                      alt="avatar"
                    />
                }
                <div className="user-name-provider-container">
                  <div className="ps-2 d-flex flex-column justify-content-start align-items-start">
                    <div style={{marginTop: `${"QandA" in unifiedDetailsData ? "8%" : ""}`}}>
                      <span className="user_name">
                        {reviewerDisplayName || "Client"}
                      </span>
                        {
                          "QandA" in unifiedDetailsData
                          ? null
                          : <div className="rating-social-email">
                              <div className='all-stars'>
                              {Array.from(Array(Math.floor(parseFloat(unifiedDetailsData?.AverageRating) || 0)).keys())
                                .map((item: any, index: number) => {
                                  return (
                                    <img
                                      className="start"
                                      key={index}
                                      src={start}
                                      alt="star"
                                    />
                                  );
                                })}
                                
                                {/* <span className="ms-2">
                                  {(Number(ratingStars || unifiedDetailsData?.AverageRating) || "0")}
                                </span> */}
                              </div>
                            </div>
                        }
                    </div>
                  </div>
                  <img
                    className="social-logo"
                    src={getImage(provider)}
                    alt={provider}
                  />
                </div>
              </div>
              <div className="footer w-100">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {unifiedDetailsData?.Days || unifiedDetailsData?.DaysDifference || 0}
                      {/* {unifiedDetailsData?.TodaysCount || unifiedDetailsData?.Reviews || unifiedDetailsData?.Days || 0} */}
                    </span>
                    <span className="title">
                      {"Days" in unifiedDetailsData
                        ? "Days"
                        : "DaysDifference" in unifiedDetailsData
                        ? "Days"
                        : "Title-1"}
                    </span>
                  </div>
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {unifiedDetailsData?.Questions || unifiedDetailsData?.ReviewsCount || 0}
                    </span>
                    <span className="title">
                      {
                        "Questions" in unifiedDetailsData 
                        ? "Questions"
                        : "ReviewsCount" in unifiedDetailsData 
                        ? "Reviews"
                        : "Title-2"
                      }
                    </span>
                  </div>
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {unifiedDetailsData?.Replied || unifiedDetailsData?.AverageRating || 0}
                    </span>
                    <span className="title">
                      {"Replied" in unifiedDetailsData 
                        ? "Replied"
                        : "AverageRating" in unifiedDetailsData 
                        ? "Avg. Rating"
                        : "Title-3"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded mt-0 d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
        <div className="inbox-wall w-100">
          {unifiedDetailsData ? (
            <div className="right-feed-card rounded">
              <div className="d-flex align-items-start p-2">
                {
                  workspaceSettings?.logo
                    ? <img
                        className="user-avatar"
                        src={workspaceSettings?.logo || user_avatar_2}
                        alt="user-2"
                      />
                    : <img
                        className="user-avatar"
                        src={getGenerateApiImageByName(workspaceSettings?.workspaceName)}
                        alt="avatar"
                      />
                      
                }
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div className="ps-2 d-flex flex-column justify-content-start align-items-start">
                    <span className="user_name">
                      {workspaceSettings?.workspaceName || "Tezla Inc"}
                    </span>
                    <span className="rating-social-email">
                      {
                        unifiedDetailsData?.reviewRating
                        ? <div className='all-stars'>
                            {Array.from( Array(Math.floor(parseFloat(unifiedDetailsData?.reviewRating))).keys()).map((item: any, index: number) => {
                              return (
                                <img
                                  className="start"
                                  key={index}
                                  src={start}
                                  alt="star"
                                />
                              );
                            })}
                            
                            {/* <span className="ms-2">
                              
                              {(Number(unifiedDetailsData?.reviewRating) || "0")}
                            </span> */}
                          </div>
                        : null
                      }
                    </span>
                  </div>
                  <img
                    className="social-logo"
                    src={getImage(provider)}
                    alt={provider}
                  />
                </div>
              </div>
              <div className="footer w-100 mt-1">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {
                        Number(
                          unifiedDetailsData?.LocationQuestionCount ||
                          unifiedDetailsData?.totalReviews
                        )
                        || 0
                      }
                    </span>
                    <span className="title">
                      {"LocationQuestionCount" in unifiedDetailsData
                        ? "Questions"
                        : "totalReviews" in unifiedDetailsData
                        ? "Reviews"
                        : "Title-1"}
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(unifiedDetailsData?.Today || unifiedDetailsData?.ReviewsTodayCount || 0)}
                    </span>
                    <span className="title">
                      {"Today" in unifiedDetailsData
                        ? "Today"
                        : "ReviewsTodayCount" in unifiedDetailsData
                        ? "Today"
                        : "Title-2"}
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(
                        unifiedDetailsData?.unAnswered ||
                        unifiedDetailsData?.reviewRating || 0
                       )}
                    </span>
                    <span className="title">
                      {"unAnswered" in unifiedDetailsData
                        ? "Not Replied"
                        : "reviewRating" in unifiedDetailsData 
                        ? "Avg. Rating"
                        : "Title-3"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
        <div className="properties-wall w-100">
          {
            // "properties" in unifiedDetailsData || provider === "videoTestimonial" || provider === "twitter" ? (
              unifiedDetailsData ? (
              <div className="right-feed-card rounded p-2">
                <h4 className="text-start header-title">Properties</h4>
                <div className="content d-flex align-items-start justify-content-between">
                  <div className="type-to-lock d-flex flex-column">
                    <span>Type:</span>
                    <span>To:</span>
                    <span className="d-block">Lock:</span>
                  </div>
                  <div className="type-to-lock-value">
                    <span>
                      {
                        conversation?.tag?.includes("googleQandA")
                        ? "Google Q & A"
                        : "Google Review"
                      }
                    </span>
                    <span>
                      {workspaceSettings?.workspaceName || "Workspace Name"}
                    </span>
                    <span className="lock-unlock pt-1 mt-1">
                      {isInReplyingMode ? <span>
                          Locked <img src={lock_icon} alt="locked" />
                        </span>
                      : !isReplyingOn ? (
                        <span>
                          Unlocked <img src={unlock_icon} alt="unlocked" />
                        </span>
                      ) : (
                        <span>
                          Locked <img src={lock_icon} alt="locked" />
                        </span>
                      )}
                    </span>
                  </div>
                  <div className="type-to-lock">
                    {isInReplyingMode || isReplyingOn ? (
                      <span className="not-replied">Not Replied</span>
                      ) : (
                      <span className="replied">Replied</span>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="right-feed-card rounded d-flex align-items-center justify-content-center">
                <p className="nothing-show">Nothing to show </p>
              </div>
            )
          }
        </div>
        <div className="details-wall w-100">
          {unifiedDetailsData ? (
            <div className="right-feed-card rounded p-2">
              <h4 className="text-start header-title">
                Timeline
                {/* {
                  conversation?.tag?.includes("googleQandA")
                    ? "Timeline"
                    : "Details"
                } */}
              </h4>
              <div className="content d-flex align-items-start" style={{gap: "5%"}}>
                {
                  conversationDetails 
                    ? <div className="req-res">
                        <span>
                          Asked:
                        </span>
                        <span>
                          Replied:
                        </span>
                        <span>Res. Time:</span>
                        <span>
                          {/* {conversationDetails.hasOwnProperty("requestorEmail")
                            ? "Email"
                            : conversationDetails.hasOwnProperty("repliedBy")
                            ? "Replied By"
                            : null} */}
                          {/* : */}
                        </span>
                      </div>
                    : null
                }

                {
                  conversationDetails 
                    ? <div className="req-res-value">
                        <span>
                          {"QuestionCreatedOn" in conversationDetails
                            ? moment(conversationDetails?.QuestionCreatedOn).format("ll") +
                              " " +
                              moment(conversationDetails?.QuestionCreatedOn).format("LT")
                            : // new Date(MentionedOn).toDateString() + " " +
                              // new Date(MentionedOn).toLocaleTimeString()
                              " --- "}
                        </span>
                        <span>
                          {'CreatedTime' in conversationDetails
                            ? moment(
                                conversationDetails?.CreatedTime
                            ).format("ll") +
                              " " +
                              moment(
                                conversationDetails?.CreatedTime
                              ).format("LT")
                            : // new Date(replied_time).toDateString() + " " +
                              // new Date(replied_time).toLocaleTimeString()
                              " --- "}
                        </span>
                        <span className="d-block">
                          {'ResponseTime' in conversationDetails
                            ? conversationDetails?.ResponseTime
                            : // moment(ResponseTime).format('ll') + " " + moment(ResponseTime).format('LT')
                              // moment(details?.ResponseTime).calendar()
                              // new Date(details?.response_time).toDateString() + " " +
                              // new Date(details?.response_time).toLocaleTimeString()
                              " --- "}
                        </span>
                        {/* {requestorEmail ? (
                          <span>{requestorEmail}</span>
                        ) : repliedBy ? (
                          <span className="replier">
                            <img src={user_avatar} alt="avatar" />
                            {repliedBy ? repliedBy : "---"}
                          </span>
                        ) : (
                          <span>{"---"}</span>
                        )} */}
                      </div>
                    : null
                }
                
                {
                  conversation?.tag?.includes("reviews")
                    ? <div className="req-res">
                        <span>
                          Reviewed:
                        </span>
                        <span>
                          Replied:
                        </span>
                        <span>Res. Time:</span>
                        <span>
                          {/* {conversationDetails.hasOwnProperty("requestorEmail")
                            ? "Email"
                            : conversationDetails.hasOwnProperty("repliedBy")
                            ? "Replied By"
                            : null} */}
                          {/* : */}
                        </span>
                      </div>
                    : null
                }
                
                {
                  conversation?.tag?.includes("reviews") 
                    ? <div className="req-res-value">
                        <span>
                          {conversation?.createdOn
                            ? moment(conversation?.createdOn).format("ll") +
                              " " +
                              moment(conversation?.createdOn).format("LT")
                            : // new Date(MentionedOn).toDateString() + " " +
                              // new Date(MentionedOn).toLocaleTimeString()
                              " --- "}
                        </span>
                        <span>
                          {// new Date(details?.submitted_time).toDateString() + " " +
                            // new Date(details?.submitted_time).toLocaleTimeString()
                            conversation?.reviewReplyUpdatedon
                            ? moment(conversation?.reviewReplyUpdatedon).format("ll") +
                              " " +
                              moment(conversation?.reviewReplyUpdatedon).format("LT")
                            : // new Date(replied_time).toDateString() + " " +
                              // new Date(replied_time).toLocaleTimeString()
                              " --- "}
                        </span>
                        <span className="d-block">
                          {getDateDifference(conversation?.createdOn, conversation?.reviewReplyUpdatedon)}
                        </span>
                        {/* {requestorEmail ? (
                          <span>{requestorEmail}</span>
                        ) : repliedBy ? (
                          <span className="replier">
                            <img src={user_avatar} alt="avatar" />
                            {repliedBy ? repliedBy : "---"}
                          </span>
                        ) : (
                          <span>{"---"}</span>
                        )} */}
                      </div>
                    : null
                }

              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
      </div>
      {/* Laptop */}
      <div className="w-100 h-100 conversation-details-lg">
        <div className="inbox-wall w-100">
          {unifiedDetailsData ? (
            <div className="right-feed-card rounded">
              <div className="d-flex align-items-start p-2">
                {
                  reviewerPhoteUrl
                  ? <img
                      className="user-avatar"
                      src={reviewerPhoteUrl || user_avatar}
                      alt="avatar"
                    />
                  : <img
                      className="user-avatar"
                      src={getGenerateApiImageByName(reviewerDisplayName)}
                      alt="avatar"
                    />
                }
                <div className="user-name-provider-container">
                  <div className="ps-2 d-flex flex-column justify-content-start align-items-start">
                    <div style={{marginTop: `${"QandA" in unifiedDetailsData ? "8%" : ""}`}}>
                      <span className="user_name">
                        {reviewerDisplayName || "Client"}
                      </span>
                        {
                          "QandA" in unifiedDetailsData
                          ? null
                          : <div className="rating-social-email">
                              <div className='all-stars'>
                              {Array.from(Array(Math.floor(parseFloat(unifiedDetailsData?.AverageRating) || 0)).keys())
                                .map((item: any, index: number) => {
                                  return (
                                    <img
                                      className="start"
                                      key={index}
                                      src={start}
                                      alt="star"
                                    />
                                  );
                                })}
                                
                                {/* <span className="ms-2">
                                  {(Number(ratingStars || unifiedDetailsData?.AverageRating) || "0")}
                                </span> */}
                              </div>
                            </div>
                        }
                    </div>
                  </div>
                  <img
                    className="social-logo"
                    src={getImage(provider)}
                    alt={provider}
                  />
                </div>
              </div>
              <div className="footer w-100">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {unifiedDetailsData?.Days || unifiedDetailsData?.DaysDifference || 0}
                      {/* {unifiedDetailsData?.TodaysCount || unifiedDetailsData?.Reviews || unifiedDetailsData?.Days || 0} */}
                    </span>
                    <span className="title">
                      {"Days" in unifiedDetailsData
                        ? "Days"
                        : "DaysDifference" in unifiedDetailsData
                        ? "Days"
                        : "Title-1"}
                    </span>
                  </div>
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {unifiedDetailsData?.Questions || unifiedDetailsData?.ReviewsCount || 0}
                    </span>
                    <span className="title">
                      {
                        "Questions" in unifiedDetailsData 
                        ? "Questions"
                        : "ReviewsCount" in unifiedDetailsData 
                        ? "Reviews"
                        : "Title-2"
                      }
                    </span>
                  </div>
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {unifiedDetailsData?.Replied || unifiedDetailsData?.AverageRating || 0}
                    </span>
                    <span className="title">
                      {"Replied" in unifiedDetailsData 
                        ? "Replied"
                        : "AverageRating" in unifiedDetailsData 
                        ? "Avg. Rating"
                        : "Title-3"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded mt-0 d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
        <div className="inbox-wall w-100">
          {unifiedDetailsData ? (
            <div className="right-feed-card rounded">
              <div className="d-flex align-items-start p-2">
                {
                  workspaceSettings?.logo
                    ? <img
                        className="user-avatar"
                        src={workspaceSettings?.logo || user_avatar_2}
                        alt="user-2"
                      />
                    : <img
                        className="user-avatar"
                        src={getGenerateApiImageByName(workspaceSettings?.workspaceName)}
                        alt="avatar"
                      />
                      
                }
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div className="ps-2 d-flex flex-column justify-content-start align-items-start">
                    <span className="user_name">
                      {workspaceSettings?.workspaceName || "Tezla Inc"}
                    </span>
                    <span className="rating-social-email">
                      {
                        unifiedDetailsData?.reviewRating
                        ? <div className='all-stars'>
                            {Array.from( Array(Math.floor(parseFloat(unifiedDetailsData?.reviewRating))).keys()).map((item: any, index: number) => {
                              return (
                                <img
                                  className="start"
                                  key={index}
                                  src={start}
                                  alt="star"
                                />
                              );
                            })}
                            
                            {/* <span className="ms-2">
                              
                              {(Number(unifiedDetailsData?.reviewRating) || "0")}
                            </span> */}
                          </div>
                        : null
                      }
                    </span>
                  </div>
                  <img
                    className="social-logo"
                    src={getImage(provider)}
                    alt={provider}
                  />
                </div>
              </div>
              <div className="footer w-100 mt-1">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {
                        Number(
                          unifiedDetailsData?.LocationQuestionCount ||
                          unifiedDetailsData?.totalReviews
                        )
                        || 0
                      }
                    </span>
                    <span className="title">
                      {"LocationQuestionCount" in unifiedDetailsData
                        ? "Questions"
                        : "totalReviews" in unifiedDetailsData
                        ? "Reviews"
                        : "Title-1"}
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(unifiedDetailsData?.Today || unifiedDetailsData?.ReviewsTodayCount || 0)}
                    </span>
                    <span className="title">
                      {"Today" in unifiedDetailsData
                        ? "Today"
                        : "ReviewsTodayCount" in unifiedDetailsData
                        ? "Today"
                        : "Title-2"}
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(
                        unifiedDetailsData?.unAnswered ||
                        unifiedDetailsData?.reviewRating || 0
                       )}
                    </span>
                    <span className="title">
                      {"unAnswered" in unifiedDetailsData
                        ? "Not Replied"
                        : "reviewRating" in unifiedDetailsData 
                        ? "Avg. Rating"
                        : "Title-3"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
        <div className="properties-wall w-100">
          {
            // "properties" in unifiedDetailsData || provider === "videoTestimonial" || provider === "twitter" ? (
              unifiedDetailsData ? (
              <div className="right-feed-card rounded p-2">
                <h4 className="text-start header-title">Properties</h4>
                <div className="content d-flex align-items-start justify-content-between">
                  <div className="type-to-lock d-flex flex-column">
                    <span>Type:</span>
                    <span>To:</span>
                    <span className="d-block">Lock:</span>
                  </div>
                  <div className="type-to-lock-value">
                    <span>
                      {
                        conversation?.tag?.includes("googleQandA")
                        ? "Google Q & A"
                        : "Google Review"
                      }
                    </span>
                    <span>
                      {workspaceSettings?.workspaceName || "Workspace Name"}
                    </span>
                    <span className="lock-unlock pt-1 mt-1">
                      {isInReplyingMode ? <span>
                          Locked <img src={lock_icon} alt="locked" />
                        </span>
                      : !isReplyingOn ? (
                        <span>
                          Unlocked <img src={unlock_icon} alt="unlocked" />
                        </span>
                      ) : (
                        <span>
                          Locked <img src={lock_icon} alt="locked" />
                        </span>
                      )}
                    </span>
                  </div>
                  <div className="type-to-lock">
                    {isInReplyingMode || isReplyingOn ? (
                      <span className="not-replied">Not Replied</span>
                      ) : (
                      <span className="replied">Replied</span>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="right-feed-card rounded d-flex align-items-center justify-content-center">
                <p className="nothing-show">Nothing to show </p>
              </div>
            )
          }
        </div>
        <div className="details-wall w-100">
          {unifiedDetailsData ? (
            <div className="right-feed-card rounded p-2">
              <h4 className="text-start header-title">
                Timeline
                {/* {
                  conversation?.tag?.includes("googleQandA")
                    ? "Timeline"
                    : "Details"
                } */}
              </h4>
              <div className="content d-flex align-items-start" style={{gap: "5%"}}>
                {
                  conversationDetails 
                    ? <div className="req-res">
                        <span>
                          Asked:
                        </span>
                        <span>
                          Replied:
                        </span>
                        <span>Res. Time:</span>
                        <span>
                          {/* {conversationDetails.hasOwnProperty("requestorEmail")
                            ? "Email"
                            : conversationDetails.hasOwnProperty("repliedBy")
                            ? "Replied By"
                            : null} */}
                          {/* : */}
                        </span>
                      </div>
                    : null
                }

                {
                  conversationDetails 
                    ? <div className="req-res-value">
                        <span>
                          {"QuestionCreatedOn" in conversationDetails
                            ? moment(conversationDetails?.QuestionCreatedOn).format("ll") +
                              " " +
                              moment(conversationDetails?.QuestionCreatedOn).format("LT")
                            : // new Date(MentionedOn).toDateString() + " " +
                              // new Date(MentionedOn).toLocaleTimeString()
                              " --- "}
                        </span>
                        <span>
                          {'CreatedTime' in conversationDetails
                            ? moment(
                                conversationDetails?.CreatedTime
                            ).format("ll") +
                              " " +
                              moment(
                                conversationDetails?.CreatedTime
                              ).format("LT")
                            : // new Date(replied_time).toDateString() + " " +
                              // new Date(replied_time).toLocaleTimeString()
                              " --- "}
                        </span>
                        <span className="d-block">
                          {'ResponseTime' in conversationDetails
                            ? conversationDetails?.ResponseTime
                            : // moment(ResponseTime).format('ll') + " " + moment(ResponseTime).format('LT')
                              // moment(details?.ResponseTime).calendar()
                              // new Date(details?.response_time).toDateString() + " " +
                              // new Date(details?.response_time).toLocaleTimeString()
                              " --- "}
                        </span>
                        {/* {requestorEmail ? (
                          <span>{requestorEmail}</span>
                        ) : repliedBy ? (
                          <span className="replier">
                            <img src={user_avatar} alt="avatar" />
                            {repliedBy ? repliedBy : "---"}
                          </span>
                        ) : (
                          <span>{"---"}</span>
                        )} */}
                      </div>
                    : null
                }
                
                {
                  conversation?.tag?.includes("reviews")
                    ? <div className="req-res">
                        <span>
                          Reviewed:
                        </span>
                        <span>
                          Replied:
                        </span>
                        <span>Res. Time:</span>
                        <span>
                          {/* {conversationDetails.hasOwnProperty("requestorEmail")
                            ? "Email"
                            : conversationDetails.hasOwnProperty("repliedBy")
                            ? "Replied By"
                            : null} */}
                          {/* : */}
                        </span>
                      </div>
                    : null
                }
                
                {
                  conversation?.tag?.includes("reviews") 
                    ? <div className="req-res-value">
                        <span>
                          {conversation?.createdOn
                            ? moment(conversation?.createdOn).format("ll") +
                              " " +
                              moment(conversation?.createdOn).format("LT")
                            : // new Date(MentionedOn).toDateString() + " " +
                              // new Date(MentionedOn).toLocaleTimeString()
                              " --- "}
                        </span>
                        <span>
                          {// new Date(details?.submitted_time).toDateString() + " " +
                            // new Date(details?.submitted_time).toLocaleTimeString()
                            conversation?.reviewReplyUpdatedon
                            ? moment(conversation?.reviewReplyUpdatedon).format("ll") +
                              " " +
                              moment(conversation?.reviewReplyUpdatedon).format("LT")
                            : // new Date(replied_time).toDateString() + " " +
                              // new Date(replied_time).toLocaleTimeString()
                              " --- "}
                        </span>
                        <span className="d-block">
                          {getDateDifference(conversation?.createdOn, conversation?.reviewReplyUpdatedon)}
                        </span>
                        {/* {requestorEmail ? (
                          <span>{requestorEmail}</span>
                        ) : repliedBy ? (
                          <span className="replier">
                            <img src={user_avatar} alt="avatar" />
                            {repliedBy ? repliedBy : "---"}
                          </span>
                        ) : (
                          <span>{"---"}</span>
                        )} */}
                      </div>
                    : null
                }

              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}
