import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSuccessMessage } from '../../../../dashboard/reducer';
import { getGenerateApiImageByName, getImage } from '../../../../dashboard/image_service';

interface ConversationHeaderProps {
    conversation: any;
    workspaceSettings?: any;
    workSpaceName?: any;
    handleReplaceConversation: (data: any) => void;
    prev_icon?: any;
    next_icon?: any;
    refresh_icon?: any;
    user_avatar_2?: any;
    user_avatar?: any;
    header_title?: string;
}

const ConversationHeaderSD: React.FC<ConversationHeaderProps> = ({header_title, user_avatar, user_avatar_2, conversation, workspaceSettings, workSpaceName, handleReplaceConversation, prev_icon, next_icon, refresh_icon }: any) => {
    const dispatch = useDispatch();
    const { reviewerDisplayName, reviewerPhoteUrl, mentionedbyUserName, mentionedbyUserImageUrl, requestedDate,  provider } = conversation || {};
    return (
        <React.Fragment>
            <div className="header-body">
                <div className="body-left">
                    <div className="first-ws">
                        {
                        reviewerPhoteUrl
                        ? <img
                            className="user-avatar"
                            src={reviewerPhoteUrl || user_avatar}
                            alt="avatar"
                            />
                        :   <img
                                className="user-avatar"
                                src={getGenerateApiImageByName(reviewerDisplayName)}
                                alt="avatar"
                            />
                        }
                        <div className="user-info">
                        <p className="user-name">
                            {reviewerDisplayName?.substr(0, 6) || "Client"}
                        </p>
                        <span className="review-type">
                            {header_title}
                        </span>
                        <p className="ws-name">
                            {(workspaceSettings?.workspaceName || workSpaceName)?.substr(0, 6) || "WS Name"}
                        </p>
                        </div>
                    </div>
                    <div className="second-ws">
                        {/* <img
                        className="image-logo"
                        src={workspaceSettings?.logo || user_avatar_2}
                        alt="user_avatar"
                        /> */}
                        
                        {
                        workspaceSettings?.logo
                        ? <img
                            className="user-avatar"
                            src={workspaceSettings?.logo}
                            alt="user_avatar"
                            />
                        :   <img
                                className="user-avatar"
                                src={getGenerateApiImageByName(workSpaceName)}
                                alt="avatar"
                            />
                        }
                        <p className="ws-name">
                        {(workspaceSettings?.workspaceName || workSpaceName)?.substr(0, 7) ||
                            "WS Name"}
                        </p>
                    </div>
                </div>
                <div className="body-right">
                    <img
                        onClick={() =>
                        handleReplaceConversation("prev", conversation)
                        }
                        src={prev_icon}
                        alt="prev"
                        title='Previous'
                    />
                    <img
                        onClick={() =>
                        handleReplaceConversation("next", conversation)
                        }
                        src={next_icon}
                        alt="next"
                        title='Next'
                    />
                    <img
                        onClick={() => dispatch(setSuccessMessage("Refreshed"))}
                        src={refresh_icon}
                        alt="refresh"
                        title='Refresh'
                    />
                </div>
          </div>
        </React.Fragment>
    );
};

export default ConversationHeaderSD;