import React from "react";

import './ProfileManagement.scss';
import { Link, useNavigate } from "react-router-dom";
import PersonalInfo from "./Components/Personal-Info/Personal-Info";
import Notifications from "./Components/Notifications/Notifications";
import { useSelector } from "react-redux";
import { Tabs } from "antd";
import PermissionCheck from "../../components/PermissionCheck";
import user_settings_icon from "../../assets/icons/settings/user-profile/user-settings-icon.svg";
import personal_info_icon from "../../assets/icons/settings/user-profile/personal-info-icon.svg";
import notifications_icon from "../../assets/icons/settings/user-profile/notifications-icon.svg";

import { ChildPageTabs, MobilePageTabs, ParentPageTabs } from "../Page.Tabs";
import { user_routes } from "../page-routes";
import TopTabs from "../../components/layout/TopTabs";

const ProfileManagement: React.FC = () => {
    const parent_routes = user_routes;
    const {userPermissions} = useSelector((state: any) => state?.login);
    const history = useNavigate();
    const { personalInfo } = useSelector((state: any) => state?.profileManageSettingState);
    const path_name = window.location.pathname;

    const path_item: any = parent_routes?.find((item: any) => path_name.includes(item?.path));
    const tab_items: any[] = [];
    path_item?.route_children?.map((item: any) => {
        // const id = item?.name;
        // console.log({id});
        
        tab_items.push({
            name: path_item.path + item.path,
            label: item.name,
            permission: item.permission,
            component: item.component,
        });
    });
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "profile") : null;
        if(permission){
            permission?.value === 0 && history('/not-found');
        }
    }, [userPermissions?.length]);
    const {email, firstname, lastname, accountType, photo}: any = personalInfo || {};
    return (
        <div className="main-container">
            <div className="lg-sub-menu">
                <TopTabs />
                <div className="parent-tabs">
                    <div className="logo-container">
                        <img className="workspace-logo" src={photo || "https://www.w3schools.com/howto/img_avatar.png"} alt="Avatar" />
                        <p className="workspace-name">{((firstname ? (firstname + " ") : "")  + (lastname || "")) || email || "Profile Email / Workspace name"}</p>
                    </div>

                    <div className="parent-tabs-container">
                        <ParentPageTabs parent_routes={parent_routes} path_name={path_name}/>
                    </div>
                </div>
                <ChildPageTabs parent_routes={parent_routes} path_name={path_name}/>
            </div>
            <div className="mobile-sub-menu">
                <MobilePageTabs tab_items={tab_items} path_name={path_name}/>
            </div>
        </div>
    );
};

export default ProfileManagement;

// const ProfileManagement: React.FC = () => {
//     const {userPermissions} = useSelector((state: any) => state?.login);
//     const parent_routes = [
//         {
//             name: "User Settings",
//             icon: user_settings_icon || "https://www.w3schools.com/howto/img_avatar.png",
//             path: "/user",
//             permission: 'persionalSettings',
//             route_children: [
//                 {
//                     name: "Personal Info",
//                     icon: personal_info_icon || "https://www.w3schools.com/howto/img_avatar.png",
//                     path: "/personal-info",
//                     permission: 'persionalSettings',
//                     component: <PersonalInfo/>
//                 },
//                 {
//                     name: "Notifications",
//                     icon: notifications_icon || "https://www.w3schools.com/howto/img_avatar.png",
//                     path: "/notifications",
//                     permission: 'notifications',
//                     component: <Notifications/>
//                 }
//             ]
            
//         }
//     ];
//     const history = useNavigate();
//     const { personalInfo } = useSelector((state: any) => state?.profileManageSettingState);
//     const path_name = window.location.pathname;
//     // const tab_items: any = [
//     //     {
//     //         name: 'personal-info',
//     //         label: 'Profile',
//     //         permission: 'persionalSettings',
//     //         component: <PersonalInfo />,
//     //     },
//     //     {
//     //         name: 'notifications',
//     //         label: 'Notifications',
//     //         permission: 'notifications',
//     //         component: <Notifications />,
//     //     },
//     // ];
//     const path_item: any = parent_routes?.find((item: any) => path_name.includes(item?.path));
//     const tab_items: any = [];
//     path_item?.route_children?.map((item: any) => {
//         const id = item?.name;
//         // console.log({id});
        
//         tab_items.push({
//             name: item?.path,
//             label: item?.name,
//             permission: item?.permission,
//             component: item?.component,
//         });
//     });

//     React.useEffect(() => {
//         const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "profile") : null;
//         if(permission){
//             permission?.value === 0 && history('/not-found');
//         }
//     }, [userPermissions?.length]);
//     const {email, firstname, lastname, accountType, photo}: any = personalInfo || {};
//     return (
//         <div className="main-container">
//             <div className="lg-sub-menu">
//                 <div className="parent-tabs">
//                     <div className="logo-container">
//                         <img className="workspace-logo" src={photo || "https://www.w3schools.com/howto/img_avatar.png"} alt="Avatar" />
//                         <p className="workspace-name">{((firstname ? (firstname + " ") : "")  + (lastname || "")) || email || "Profile Email / Workspace name"}</p>
//                     </div>

//                     <div className="parent-tabs-container">
//                         {
//                             parent_routes.map((route, index) => (
//                                 <PermissionCheck key={index} permissionName={route?.permission}>
//                                     <Link to={route.path + route.route_children[0].path}
//                                         className={`parent-tab-link ${path_name.includes(route.path) ? "active" : ""}`}
//                                     >
//                                         <img src={route.icon} alt="tab-icon" />
//                                         <p>{route.name}</p>
//                                     </Link>
//                                 </PermissionCheck>
//                             ))
//                         }
//                     </div>
//                 </div>
//                 {
//                     parent_routes.map((route, index) => (
//                         path_name.includes(route.path)
//                         ? <div className="child-tabs" key={index}>
//                             <div className={`child-tabs-container`}>
//                                 {
//                                     route.route_children.map((child, idx) => (
//                                         <PermissionCheck key={idx} permissionName={child?.permission}>
//                                             <Link to={route.path + child.path}
//                                                 className={`child-tab-link ${path_name.includes(child.path) ? "active" : ""}`}
//                                             >
//                                                 <img src={child.icon} alt="tab-icon" />
//                                                 <p>{child.name}</p>
//                                             </Link>
//                                         </PermissionCheck>
//                                     ))
//                                 }
//                             </div>
//                             <div className="child-tab-components">
//                                 {route.route_children.map(child => (
//                                     path_name.includes(child.path)
//                                     ? <PermissionCheck key={child.path} permissionName={child?.permission}>{child.component}</PermissionCheck>
//                                     : null
//                                 ))}
//                             </div>
//                         </div>
//                         : null
//                     ))
//                 }
//                 <>
//                 </>
//             </div>
//             <div className="mobile-sub-menu">
//                 <Tabs
//                     defaultActiveKey={path_name?.includes('notifications') ? '/notifications' : '/personal-info'}
//                     centered
//                     onChange={(k: any) => {
//                         console.log({ k });
//                         history(`/user${k}`);
//                     }}
//                     items={tab_items?.map((item: any) => {
//                         const id = item?.name;
//                         return {
//                             label: item?.label,
//                             key: id,
//                             children: <PermissionCheck permissionName={item?.permission}>{item?.component}</PermissionCheck>,
//                         }})
//                     }
//                 />
//             </div>
//         </div>
//     );
// };
