import React from 'react';
import prev_icon from '../../../../assets/icons/previous-icon.svg';
import next_icon from '../../../../assets/icons/next-icon.svg';
import refresh_icon from '../../../../assets/icons/refresh-icon-unified.svg';
import user_avatar from '../../../../assets/icons/user-avatar.svg';
import user_avatar_2 from '../../../../assets/icons/user-avatar-2.svg';
import start from '../../../../assets/icons/star-icon.svg';
import back_btn from '../../../../assets/icons/back.svg';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import ConversationDetails from '../AllDetails/ConversationsDetails';
import ConversationHeaderSD from './ConversationHeaderSD';
import ConversationHeader from './ConversationHeader';
import { useDispatch, useSelector } from 'react-redux';
import { setSuccessMessage } from '../../../../dashboard/reducer';
import { getGenerateApiImageByName } from '../../../../dashboard/image_service';
export default function FeedbackConversations(props: any) {
  const dispatch = useDispatch();
  const { conversation, handleReplaceConversation, getImage, handlePrevPath, isReply, setIsReply, setReplyText, textRef, handleReply, conversationId, isGoForDetails, handleDetailChange } = props;
  const { workspaceSettings } = useSelector((state: any) => state?.workspaceManagementState);
  const { workspaces } = useSelector((state: any) => state?.headerState);
  const workSpaceName: any = workspaces?.find(
    (w: any) => w?.workSpaceId === localStorage.getItem("wuid")
  )?.workSpaceName ||  "";
  const { reviewedbyUserName, reviewerDisplayName, reviewerPhoteUrl, requestedDate, createdOn, requestorMessage, reviewComment, provider, starRating, recieverCompany, mentionedbyUserEmailId } = conversation || {};
  // console.log({conversation, workspaceSettings, workspaces, workSpaceName, id: localStorage.getItem("wuid")});
  return (
    <React.Fragment>
      <div className="conversation-section-container-lg h-100">
        <ConversationHeader
          header_title={" submitted a feedback for "}
          workSpaceName={workSpaceName}
          workspaceSettings={workspaceSettings}
          conversation={conversation}
          handleReplaceConversation={handleReplaceConversation}
          prev_icon={prev_icon}
          next_icon={next_icon}
          refresh_icon={refresh_icon}
          user_avatar_2={user_avatar_2}
          user_avatar={user_avatar}
        />
        <div className="conversation-section-video-feedback">
          <Scrollbars
            style={{
              paddingRight: 10,
              display: "flex",
              flexDirection: "column-reverse",
            }}
            // style={{ paddingRight: 10 }}
            autoHide
            autoHideTimeout={500}
            autoHideDuration={500}
            autoHeight
            // autoHeightMin={250}
            // autoHeightMax={250}
            // autoHeightMin={480}
            autoHeightMax={"100%"}
            thumbMinSize={10}
            universal={true}
          >
            <div className="conversation-body" id="scrollableDiv">
              {/* This will implement it feeds.tsx file */}
              <div className="conversation-person-1">
                <div className="details">
                  {
                    reviewerPhoteUrl
                    ? <img
                        className="user-avatar"
                        src={reviewerPhoteUrl || user_avatar}
                        alt="avatar"
                      />
                    : <img
                          className="user-avatar"
                          src={getGenerateApiImageByName(reviewerDisplayName)}
                          alt="avatar"
                      />
                  }
                  <div className="description">
                    <div
                      className={`d-flex align-items-center user-ratings-container`}
                    >
                      <div className="user_name me-2 ">
                        {reviewerDisplayName || 
                          reviewerDisplayName ||
                          "User Name"}
                      </div>
                      {
                        starRating ? (
                          <div className="rating-social-email">
                            <div>
                              {Array.from(Array(Number(starRating)).keys()).map((_: any, index: number) => {
                                return (
                                  <img
                                    className="star"
                                    key={index}
                                    src={start}
                                    alt="star"
                                  />
                                );
                              })}
                            </div>
                            <div className="rating">
                              {starRating}/5
                            </div>
                          </div>
                        ) : null
                      }
                    </div>
                    <div className="client-container mb-2">
                      {
                        // new Date(createdOn).toDateString() + ' '
                        // + (new Date(createdOn).getHours() < 10 ? '0' + new Date(createdOn).getHours() : new Date(createdOn).getHours())
                        // + ':' + (new Date(createdOn).getMinutes() < 10 ? '0' + new Date(createdOn).getMinutes() : new Date(createdOn).getMinutes())
                        // + ':' + (new Date(createdOn).getSeconds() < 10 ? '0' + new Date(createdOn).getSeconds() : new Date(createdOn).getSeconds()) +
                        moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + (recieverCompany ? (" - " + (recieverCompany || "Company Name")) : "")
                      }
                    </div>
                    <p>{reviewComment}</p>
                    {/* <p>{requestorMessage}</p> */}
                  </div>
                </div>
                <p className="text-start mt-2 date">
                  {"Review"}{" "}
                  -{" "}
                  <span>
                    {
                      // new Date(createdOn).toDateString() +
                      moment(requestedDate).format("DD MMM YYYY, hh:mm:ss A")
                      // moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + ("Tezla Inc")
                      // moment(details?.requested_time).format('Y-m-d')
                    }
                  </span>
                </p>
              </div>
              {/* {workspaceSettings && (
                <div className="conversation-person-2">
                  <div className="details">
                    <img
                      className="user-avatar"
                      src={workspaceSettings?.logo || user_avatar_2}
                      alt="user_avatar"
                    />
                    <span className="description">
                      <p>{reviewComment}</p>
                      
                    </span>
                  </div>
                  <p className="text-end mt-2 date">
                    {person_2?.date_title || "Replied"} -{" "}
                    <span>
                      {
                        moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + (workspaceSettings?.workspaceSettings?.workspaceName  || workSpaceName ||  "WS Name")
                        // moment(details?.requested_time).format('Y-m-d')
                      }
                    </span>
                  </p>
                </div>
              )} */}
              <div className='py-5'></div>
            </div>
          </Scrollbars>
        </div>
        {/* <div className="conversation-footer">
          <p className="text-start my-2 px-3 sender">
            Sending as {workspaceSettings?.workspaceSettings?.workspaceName  || workSpaceName ||  "WS Name"}
          </p>
          <div className="message-box mb-3">
            <div className="message">
              <div className="text-container">
                <textarea
                  ref={textRef}
                  className=""
                  defaultValue={person_2?.message}
                  placeholder="Write here..."
                  onChange={(e: any) => {
                    const { value } = e?.target;
                    setReplyText(value);
                  }}
                  style={{ resize: "none" }}
                  autoFocus={isReply}
                />
              </div>
              <div className="reply-btn">
                <button
                  style={{
                    background: `${isReply ? "#E4E7EC" : "#E4E7EC"}`,
                  }}
                  onClick={() => {
                    if (isReply) {
                      handleReply();
                    } else {
                      setIsReply(true);
                    }
                  }}
                  type="button"
                >
                  {person_2?.message && isReply
                    ? "Save"
                    : isReply
                    ? "Send"
                    : person_2?.message
                    ? "Edit"
                    : "Reply"}
                </button>
                <img
                  onClick={() => setIsReply(false)}
                  src={isReply ? lock_icon : unlock_icon}
                  alt="unlock"
                />
              </div>
              <p className={`click-reply-des ${isReply ? "d-none" : ""}`}>
                Click reply and start adding your reply.
              </p>
            </div>
          </div>
        </div> */}
      </div>
      <div className="conversation-container-mobile">
        <div className="conversation-header">
          <div className="header-top">
            <img
              onClick={handlePrevPath}
              className="back"
              src={back_btn}
              alt="back"
            />
            <img
              className="provider"
              src={getImage(provider)}
              alt="provider"
            />
          </div>
          <ConversationHeaderSD
            workSpaceName={workSpaceName}
            workspaceSettings={workspaceSettings}
            conversation={conversation}
            handleReplaceConversation={handleReplaceConversation}
            prev_icon={prev_icon}
            next_icon={next_icon}
            refresh_icon={refresh_icon}
            user_avatar_2={user_avatar_2}
            user_avatar={user_avatar}
          />
          {/* <div className="header-body">
            <div className="body-left">
              <div className="first-ws">
                {
                  reviewerPhoteUrl
                  ? <img
                      className="user-avatar"
                      src={reviewerPhoteUrl || user_avatar}
                      alt="avatar"
                    />
                  : <span className='border text-light image-logo rounded-circle d-flex align-items-center justify-content-center p-1' style={{fontSize: "0.75rem", background: '#14B0BF'}}>
                      {
                        reviewerDisplayName?.split(" ")?.slice(0, 2)?.length === 2
                        ? reviewerDisplayName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                        : reviewerDisplayName?.substr(0, 2).toUpperCase()
                      }
                    </span>
                }
                <div className="user-info">
                  <p className="user-name">
                    {reviewerDisplayName?.substr(0, 6) ||
                      reviewerDisplayName?.substr(0, 6) ||
                      reviewerDisplayName?.substr(0, 6) ||
                      "Client"}
                  </p>
                  <span className="review-type">
                    {"submitted a feedback for"}
                  </span>
                  <p className="ws-name">
                    {workSpaceName?.substr(0, 6) ||
                      "WS Name"}
                  </p>
                </div>
              </div>
              <div className="second-ws">
                // <img
                //   className="image-logo"
                //   src={workspaceSettings?.logo || user_avatar_2}
                //   alt="user_avatar"
                // /> 
                {
                  workspaceSettings?.logo
                    ? <img
                        className="image-logo"
                        src={workspaceSettings?.logo || user_avatar_2}
                        alt="user-2"
                      />
                    : <span className='border rounded-circle text-light image-logo d-flex align-items-center justify-content-center' style={{ fontSize: "0.75rem", background: '#14B0BF'}}>
                        {
                          workSpaceName?.split(" ")?.slice(0, 2)?.length === 2
                          ? workSpaceName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                          : workSpaceName?.substr(0, 2).toUpperCase()
                        }
                      </span>
                }
                <p className="ws-name">
                  {workSpaceName?.substr(0, 7) ||
                    "WS Name"}
                </p>
              </div>
            </div>
            <div className="body-right">
              <img
                onClick={() =>
                  handleReplaceConversation("prev", conversation?.SlNo)
                }
                src={prev_icon}
                alt="prev"
                title='Previous'
              />
              <img
                onClick={() =>
                  handleReplaceConversation("next", conversation?.SlNo)
                }
                src={next_icon}
                alt="next"
                title='Next'
              />
              <img
                onClick={() => dispatch(setSuccessMessage("Refreshed"))}
                src={refresh_icon}
                alt="refresh"
                title='Refresh'
              />
            </div>
          </div> */}

          <div className="header-bottom">
            <span
              onClick={() => isGoForDetails && handleDetailChange()}
              style={{ color: !isGoForDetails ? "#1570EF" : "" }}
            >
              Messages
            </span>
            <span
              onClick={() => !isGoForDetails && handleDetailChange()}
              style={{ color: isGoForDetails ? "#1570EF" : "" }}
            >
              Details
            </span>
          </div>
        </div>
        {isGoForDetails ? (
          <div className="conversation-details-mobile-container">
            <ConversationDetails conversationId={conversationId} />
          </div>
        ) : (
          <>
            <div className="conversation-body">
              <Scrollbars
                style={{
                  paddingRight: 10,
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
                // style={{ paddingRight: 10 }}
                autoHide
                autoHideTimeout={500}
                autoHideDuration={500}
                autoHeight
                // autoHeightMin={250}
                // autoHeightMax={250}
                // autoHeightMin={480}
                autoHeightMax={"100%"}
                thumbMinSize={10}
                universal={true}
              >
                <div className="conversation-body" id="scrollableDiv">
                  {/* This will implement it feeds.tsx file */}

                  <div className="conversation-person-1">
                    <div className="details">
                      {
                        reviewerPhoteUrl
                        ? <img
                            className="user-avatar"
                            src={reviewerPhoteUrl || user_avatar}
                            alt="avatar"
                          />
                        : <img
                              className="user-avatar"
                              src={getGenerateApiImageByName(reviewerDisplayName)}
                              alt="avatar"
                          />
                      }
                      <div className="description">
                        <div
                          className={`d-flex align-items-center user-ratings-container`}
                        >
                          <div className="user_name me-2">
                            {reviewerDisplayName || reviewerDisplayName || "User Name"}
                          </div>
                          {
                            starRating ? (
                              <div className="rating-social-email">
                                <div>
                                  {Array.from(Array(Number(starRating)).keys()).map((_: any, index: number) => {
                                    return (
                                      <img
                                        className="star"
                                        key={index}
                                        src={start}
                                        alt="star"
                                      />
                                    );
                                  })}
                                </div>
                                <div className="rating">
                                  {starRating}/5
                                </div>
                              </div>
                            ) : null
                          }
                          </div>
                          <div className="client-container mb-2">
                          {
                            // new Date(createdOn).toDateString() + ' '
                            // + (new Date(createdOn).getHours() < 10 ? '0' + new Date(createdOn).getHours() : new Date(createdOn).getHours())
                            // + ':' + (new Date(createdOn).getMinutes() < 10 ? '0' + new Date(createdOn).getMinutes() : new Date(createdOn).getMinutes())
                            // + ':' + (new Date(createdOn).getSeconds() < 10 ? '0' + new Date(createdOn).getSeconds() : new Date(createdOn).getSeconds()) +
                            moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + (recieverCompany ? (" - " + (recieverCompany || "Company Name")) : "")
                          }
                        </div>
                        <p>{reviewComment}</p>
                        {/* <p>{requestorMessage}</p> */}
                      </div>
                    </div>
                    <p className="text-start mt-2 date">
                      {"Review"}{" "}
                      -{" "}
                      <span>
                        {
                          // new Date(createdOn).toDateString() +
                          moment(requestedDate).format("DD MMM YYYY, hh:mm:ss A")
                          // moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + ("Tezla Inc")
                          // moment(details?.requested_time).format('Y-m-d')
                        }
                      </span>
                    </p>
                  </div>
                  {/* {workspaceSettings && (
                    // {reviewComment2 && (
                    <div className="conversation-person-2">
                      <div className="details">
                        <img
                          className="user-avatar"
                          src={workspaceSettings?.logo || user_avatar_2}
                          alt="user_avatar"
                        />
                        <span className="description">
                          <p>{reviewComment}</p>
                        </span>
                      </div>
                      <p className="text-end mt-2 date">
                        {person_2?.date_title || "Replied"} -{" "}
                        <span>
                          {
                            moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") +
                              " by " +
                              (workspaceSettings?.workspaceSettings?.workspaceName  || workSpaceName || 
                                "WS Name")
                            // moment(details?.requested_time).format('Y-m-d')
                          }
                        </span>
                      </p>
                    </div>
                  )} */}
                  <div className='py-5'></div>
                </div>
              </Scrollbars>
            </div>
            {/* <div className="conversation-footer">
              <div
                style={{ padding: "0 10px" }}
                className="d-flex align-items-center justify-content-between my-2"
              >
                <p className="text-start sender">
                  Sending as {workspaceSettings?.workspaceSettings?.workspaceName  || workSpaceName ||  "WS Name"}
                </p>
                <img
                  onClick={() => setIsReply(false)}
                  src={isReply ? lock_icon : unlock_icon}
                  alt="unlock"
                />
              </div>
              <div className="message-box mb-3">
                <div className="message">
                  <div className="text-container">
                    <textarea
                      ref={textRef}
                      className=""
                      defaultValue={person_2?.message}
                      placeholder="Write here..."
                      onChange={(e: any) => {
                        const { value } = e?.target;
                        setReplyText(value);
                      }}
                      style={{ resize: "none" }}
                      autoFocus={isReply}
                    />
                  </div>
                  <div className="send-reply-action">
                    <div
                      onClick={() => {
                        if (isReply) {
                          handleReply();
                        } else {
                          setIsReply(true);
                        }
                      }}
                      // type="button"
                    >
                      {person_2?.message || isReply ? (
                        <img src={send_icon} alt="save-account" />
                      ) : person_2?.message ? (
                        <img src={edit_icon} alt="edit" />
                      ) : (
                        <img src={reply_icon} alt="reply" />
                      )}
                    </div>
                  </div>
                  <p
                    className={`click-reply-des ${isReply ? "d-none" : ""}`}
                  >
                    Click reply and start adding your reply.
                  </p>
                </div>
              </div>
            </div> */}
          </>
        )}
      </div>
    </React.Fragment>
  )
}
