import React from 'react';
// import emptyBox from '../../../assets/img/Empty.svg';
// import prev_icon from '../../../assets/icons/previous-icon.svg';
// import next_icon from '../../../assets/icons/next-icon.svg';
// import refresh_icon from '../../../assets/icons/refresh-icon-unified.svg';
// import user_avatar_2 from '../../../assets/icons/user-avatar-2.svg';
// import star_half from '../../../assets/icons/star-half-icon.svg';
// import unlock_icon from '../../../assets/icons/unlock-icon.svg';
// import start from '../../../assets/icons/star-icon.svg';
// import user_avatar from '../../../assets/icons/user-avatar.svg';
// import { useEffect, useState } from 'react';
// import moment from 'moment';
// import Scrollbars from 'react-custom-scrollbars';
// import { useNavigate } from 'react-router-dom';
import { setBoxId, triggerAddToWallData } from '../../../dashboard/reducer';
import { useDispatch } from 'react-redux';
// import { RootState } from '../../../store';
// import { useSelector } from 'react-redux';
import GoogleCard from './Cards/GoogleCard';
import FeedbackCard from './Cards/FeedbackCard';
import VideoCard from './Cards/VideoCard';
import FacebookCard from './Cards/FacebookCard';
import TwitterCard from './Cards/TwitterCard';
// import CommonModal from '../../CommonModal/CommonModal';
// import Button from '../../button/button-container';
import { getImage } from '../../../dashboard/image_service';

interface Props {
  inbox?: any;
  activePage?: any;
  activeTab?: any;
  wallData?: any[];
  routePath?: string;
  setWallData?: any;
  type?: string;
  isFromModalBox?: boolean;
  last?: number;
  handleSeeOtherConversation?: any;
}

const InboxCard: React.FC<Props> = ({ inbox, handleSeeOtherConversation, type, activePage, activeTab, wallData: wallUnifiedData, isFromModalBox, last }) => {
  
  // const { wallBoxId, unifiedData } = useSelector((state: RootState) => state?.unified);
  
  // const navigate = useNavigate();
  const route_path = window.location.pathname;
  const query_string = window.location.search;
  const dispatch = useDispatch();

  const handleTriggerWallData = (addToWallPayload?: any, inbox_data?: any, array_key?: any) => {
    console.log({ inbox_data, array_key });
    
    // const tag = query_string?.includes("reviews") ? "reviews" : query_string?.includes("mentions") ? "mentions" : query_string?.includes("comments") ? "comments" : query_string?.includes("qa") ? "qa" : "all";

    // at first I have to publish

    dispatch(triggerAddToWallData({addToWallPayload, key: array_key, data: inbox_data}));
  }

  const wallDataKey: any =
    route_path?.includes('/unified')
    ? 'InboxWall'
    : route_path?.includes('/customer-feedback')
    ? 'CFWall'
    : route_path?.includes('/video-testimonial')
    ? 'VTWall'
    : route_path?.includes('/google')
    ? 'GoogleWall'
    : route_path?.includes('/facebook')
    ? 'FacebookWall'
    : route_path?.includes('/twitter')
    ? 'TwitterWall'
    : 'InboxWall';

  const handleAddToWall = (inbox: any) => {
      console.log({inbox});
      const isAlreadyAdded = wallUnifiedData?.find((wd: any) => wd?.reviewId === inbox?.reviewId);
      console.log({isAlreadyAdded});
      
      if(!isAlreadyAdded) {
          // setWallData([...wallUnifiedData, inbox]);
          const addToWallPayload = {
              id: inbox?.reviewId,
              clapupId: sessionStorage.getItem('cuid'),
              workSpaceId: sessionStorage.getItem('wuid'),
              roleId: sessionStorage.getItem('roleId'),
              // provider: capitalize(inbox?.provider),
              providerType: inbox?.tag === "mentions" && inbox?.provider === "twitter" ? "twitterMentions" 
                          : inbox?.tag === "mentions" && inbox?.provider?.includes("facebook") ? "facebookMentions"
                          : inbox?.tag === "comments" ? "facebookComments" 
                          : inbox?.provider === "videoTestimonial" ? "videoTestimonial" 
                          : inbox?.provider === "customerFeedBack" ? "customerFeedback" 
                          : inbox?.tag === "googleQandA" ? "googleQandA" 
                          : (inbox?.provider === "google" && inbox?.tag === "reviews") ? "googleReviews" 
                          : (inbox?.provider === "facebook" && inbox?.tag === "reviews") ? "facebookReviews" 
                          : "",
              wallType: route_path?.includes("unified") ? "inbox" : "general",
              actionFlag: 1,
          }

          // console.log({addToWallPayload});
          
          // dispatch add to wall action
          handleTriggerWallData(addToWallPayload, inbox, wallDataKey);
      }
  };

  // const handleSeeOtherBotConversation = (inbox: any) => {
  //   handleSeeOtherConversation(inbox);
  // };

  // console.log({wallUnifiedData});
  
  const { provider }: any = inbox || {};


  return (
    <React.Fragment>
      {
        provider?.includes("google")
        ? <GoogleCard last={last} isFromModalBox={isFromModalBox} query_string={query_string} wallUnifiedData={wallUnifiedData} getImage={getImage} handleAddToWall={handleAddToWall} inbox={inbox} type={type} activePage={activePage} activeTab={activeTab} routePath={route_path} />
        : provider?.includes("facebook")
        ? <FacebookCard last={last} isFromModalBox={isFromModalBox} query_string={query_string} wallUnifiedData={wallUnifiedData} getImage={getImage} handleAddToWall={handleAddToWall} inbox={inbox} type={type} activePage={activePage} activeTab={activeTab} routePath={route_path} />
        : provider?.includes("twitter")
        ? <TwitterCard last={last} isFromModalBox={isFromModalBox} query_string={query_string} wallUnifiedData={wallUnifiedData} getImage={getImage} handleAddToWall={handleAddToWall} inbox={inbox} type={type} activePage={activePage} activeTab={activeTab} routePath={route_path} />
        : provider?.includes("customer")
        ? <FeedbackCard last={last} isFromModalBox={isFromModalBox} query_string={query_string} wallUnifiedData={wallUnifiedData} getImage={getImage} handleAddToWall={handleAddToWall} inbox={inbox} type={type} activePage={activePage} activeTab={activeTab} routePath={route_path} />
        : provider?.includes("video")
        ? <VideoCard last={last} isFromModalBox={isFromModalBox} query_string={query_string} wallUnifiedData={wallUnifiedData} getImage={getImage} handleAddToWall={handleAddToWall} inbox={inbox} type={type} activePage={activePage} activeTab={activeTab} routePath={route_path} />
        : null
      }

    </React.Fragment>
  );
}

export default InboxCard;

{/* <div>
  <div className="feeds-card-header d-flex justify-content-between align-items-start">
    <img
      className="user-avatar"
      src={
        inbox?.reviewerPhoteUrl ||
        inbox?.mentionedbyUserImageUrl ||
        user_avatar
      }
      alt="avatar"
    />
    <div
      className="feeds-card-header-content d-flex justify-content-between align-items-start"
      style={{ width: "100%" }}
    >
      <div>
        <div className="name-rating d-flex m-0 align-items-center">
          <h6 className="user-name">
            {inbox?.reviewerDisplayName ||
              inbox?.reviewedbyUserName ||
              inbox?.mentionedbyUserName}
          </h6>
          <p className="my-0 ms-2">
            {inbox?.starRating || inbox?.ratingStars ? (
              <span className="d-flex align-items-start">
                <span className="d-flex rating align-items-start">
                  {Array.from(
                    Array(inbox?.starRating || inbox?.ratingStars).keys()
                  ).map((item: any, index: number) => {
                    return (
                      <img
                        style={{ marginRight: 4 }}
                        key={index}
                        src={start}
                        alt="star"
                      />
                    );
                  })}
                </span>
                <span className="rating-value">
                  {inbox?.starRating || inbox?.ratingStars}/5
                </span>
              </span>
            ) : inbox?.reviewtype ? (
              <span className="d-flex align-items-center">
                {inbox?.reviewtype === "Positive" && (
                  <img
                    style={{ height: 9, width: 9 }}
                    src={getImage("fb-recommend")}
                    alt="fb-recommend"
                  />
                )}
                {inbox?.reviewtype === "Negative" && (
                  <img
                    style={{ height: 9, width: 9 }}
                    src={getImage("fb-dont-recommend")}
                    alt="fb-dont-recommend"
                  />
                )}
                <span className="mx-1 client-container d-block mb-1">
                  {inbox?.reviewtype === "Positive" ? (
                    <span style={{ fontSize: "80%" }}>recommends</span>
                  ) : inbox?.reviewtype === "Negative" ? (
                    <span style={{ fontSize: "70%" }}>
                      doesn't recommend
                    </span>
                  ) : null}{" "}
                  <span className="client-name">{inbox?.client}</span>
                </span>
              </span>
            ) : null}
          </p>
        </div>
        <p className="text-start date">
          {inbox?.provider === "twitter" && (
            <a
              className="me-1"
              href={inbox?.twitter_link}
              target="_blank"
              rel="noreferrer"
            >
              {inbox?.twitter_link_title || "@kuber_mannur"}
            </a>
          )}
          {new Date(inbox?.createdOn).toDateString()}
        </p>
        <p className="text-start description mb-2">
          {inbox?.reviewComment?.substring(0, 26) + ".."}
          <span>Read More</span>
        </p>
      </div>

      <img
        style={{ height: 15, width: 15 }}
        src={getImage(inbox?.provider)}
        alt="verified"
      />
    </div>
  </div>
  <div className="feeds-card-footer w-100 d-flex">
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center">
        {inbox?.provider === "twitter" ? (
          <span className="d-flex align-items-center">
            <img
              style={{ height: 15, width: 15 }}
              className="d-block me-1"
              src={getImage("retweet")}
              alt="retweet"
            />
            <span className="d-flex align-items-center">
              <img
                style={{ height: 10, width: 10 }}
                className="d-block ms-2 me-1"
                src={getImage("heart")}
                alt="heart"
              />
              {inbox?.retweetCount !== null ? (
                <span className="">
                  {inbox?.retweetCount === 0
                    ? 0
                    : inbox?.retweetCount === 1
                    ? `${inbox?.retweetCount} like`
                    : `${inbox?.retweetCount} likes`}
                </span>
              ) : null}
            </span>
          </span>
        ) : inbox?.locationName ? (
          <span className="d-block client">{inbox?.locationName}</span>
        ) : inbox?.hasOwnProperty("likeCount") &&
          inbox?.likeCount !== null ? (
          <span className="d-flex">
            <img
              style={{ height: 10, width: 10 }}
              className="d-block"
              src={getImage("like")}
              alt="like"
            />
            <span className="mx-1 likes">
              {inbox?.likeCount === 0
                ? 0
                : inbox?.likeCount === 1
                ? inbox?.likeCount + " Like"
                : inbox?.likeCount + " Likes"}
            </span>
          </span>
        ) : null}
      </div>
      <div className="">
        {inbox?.isLock && (
          <img
            style={{ height: 10, width: 10 }}
            className="mx-3 my-auto"
            src={getImage("lock_icon")}
            alt="lock"
          />
        )}
        <button
          className={`${
            (wallUnifiedData?.find((wd: any) => wd?.reviewId === inbox?.reviewId) && activePage !== "conversations"
            ? ""
            : query_string?.includes("wall")) &&
            "add-to-wall-btn-hover"
          } add-to-wall-btn`}
          type="button"
          disabled={wallUnifiedData?.find(
            (wd: any) =>
              wd?.reviewId === inbox?.reviewId &&
              activePage !== "conversations"
          )}
          onClick={() => {
            if (activePage !== "conversations") {
              handleAddToWall(inbox);
            }
          }}
        >
          {wallUnifiedData?.find(
            (wd: any) => wd?.reviewId === inbox?.reviewId
          ) && activePage !== "conversations"
            ? "Added"
            : "Add to Wall"}
        </button>
      </div>
    </div>
  </div>
</div> */}