import React from "react";
import {
    Navbar, 
    Nav,
    // Card,
    // Container, NavDropdown, 
    // DropdownButton,
    // Dropdown
} from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
// import profile from '../../../assets/img/s_dp.png';
// import user from '../../../assets/icons/user.svg';
import nav_icon from '../../../assets/icons/nav-hamburger.svg';
import {
    SettingOutlined, 
    // UserOutlined, LogoutOutlined,
    // CopyOutlined
} from '@ant-design/icons';
import Workspace from "./components/workspace";
// import {
//     Space, Button, Dropdown,
//     // Divider
// } from "antd";
import Notifications from "./components/Notifications";
import PermissionCheck from "../../PermissionCheck";
// import Constants from "../../../constants";
import MobileMenu  from "../global-menu/MobileMenu";
import { openMobileDrawer } from "../../../profile/reducer";
import { changePage } from "../../../dashboard/reducer";
import HeaderDropdown from "././components/HeaderDropdown";
// import { setProfileInfo } from "../../../pages/manage_profile/profileManageeducer";
// import { RootState } from "../../store";
import './header.scss';

interface HeaderProps {
    btnRequest?: string;
    onClick?: any
}

const Header: React.FC<HeaderProps> = ({ btnRequest, onClick }) => {
    const { workspaceSettings } = useSelector(
      (state: any) => state?.workspaceManagementState
    );
    // const {workspaces} = useSelector((state: any) => state?.headerState);
    // // const { isReplyingOn } = useSelector((state: any) => state?.unified);
    // const [path, setPath] = React.useState('');
    // const [open, setOpen] = React.useState(false);
    const query_string = window.location.search;
    const path_name = window.location.pathname;
    // const user_info = JSON.parse(
    //   sessionStorage.getItem("accessed_user_details") || "{}"
    // );
    // React.useEffect(() => {
    //     // const query = query_string.replace('?', '');
    //     // console.log({ path_name, query, user_info });
    //     setPath(path_name);
        
    // }, [query_string, path_name]);
    
    // const history = useNavigate();
    const dispatch = useDispatch()


    // const handleLogout = () => {
    //     console.log("Logout");
    //     dispatch({type: Constants.LOGOUT});
    //     // dispatch(triggerLogout())
    //     return history("/login");
    // }

    // let user_name = "Mehedi"?.split(" ")?.slice(0, 2)?.length === 2
    // const user_name = user_info?.name?.split(" ")?.slice(0, 2)?.length === 2
    //     ? user_info?.name?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("")
    //     : user_info?.name?.substr(0, 2).toUpperCase();
    return (
        <React.Fragment>
            <MobileMenu />
            {/* Mobile device showing */}
            {/* not for routing => messages & conversations and wall of unified, google, facebook, customer-feedback, video-testimonial */}
            <Navbar className={`${(
                // (!path_name?.includes('gallery') || query_string?.includes('=contents')) &&
                (!path_name?.includes('gallery') && !query_string?.includes('=contents')) &&
                // (!query_string?.includes('=contents')) &&
                !path_name?.includes('dashboard') &&
                path_name !== '/' && !path_name?.includes('promotions') &&
                !path_name?.includes('workspace') && 
                !path_name?.includes('account') &&
                !path_name?.includes('biopage') &&
                !path_name?.includes('bot-management') &&
                !path_name?.includes('user')) && "d-none"}
                 mobile-navbar border-bottom ${query_string?.includes('=contents') && "bg-white"}`}
            >
                <div className="w-100 d-flex justify-content-between align-items-center nav-mobile-container">
                    <div className="nav-mobile-item special-sm-device">
                        <img style={{cursor: 'pointer'}} onClick={() => dispatch(openMobileDrawer())} height={12} width={18} src={nav_icon} alt="nav"/>
                    </div>
                    <div className="nav-mobile-item special-sm-device">
                        <p className="page-title">
                            {
                                (path_name?.includes('dashboard')
                                || path_name === "/") && "Dashboard"
                            }
                        </p>
                    </div>

                    <div className="w-100 nav-mobile-item special-lg-md-device">
                        <div className="w-100 d-flex flex-row justify-content-end">
                            <Nav
                                className='d-flex flex-row justify-content-end align-items-center text-end nav-gap'
                                style={{ gap: 15 }}
                            >
                                <Nav.Item className='text-decoration-none my-auto me-3'>
                                    <Workspace />
                                </Nav.Item> 

                                <Nav.Item className="my-auto">
                                    <PermissionCheck permissionName="workspaceSettings">
                                        <div
                                            style={{ cursor: 'pointer' }}
                                            title="Settings"
                                            className='text-decoration-none text-dark' 
                                            onClick={() => !path_name?.includes("/workspace") &&
                                                !path_name?.includes("/user-management") &&
                                                !path_name?.includes("/biopage") &&
                                                dispatch(changePage("/workspace/general-settings")) 
                                                // (isReplyingOn ?  dispatch(changePage("/workspace/general-settings")) : history("/workspace/general-settings", { replace: true }))
                                            }
                                        >
                                            {/* <img src={settings} height={30} className='' /> */}
                                            <SettingOutlined style={{fontSize: "20px"}}/>
                                            {/* <FontAwesomeIcon icon={faUser} /> */}
                                        </div>
                                    </PermissionCheck>
                                </Nav.Item>
                                
                                <Nav.Item className="my-auto">
                                    <PermissionCheck permissionName="notifications">
                                        <div title="Notification"><Notifications/></div>
                                    </PermissionCheck>
                                </Nav.Item>

                                <Nav.Item className="my-auto">
                                    <HeaderDropdown/>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </div>

                    <div className="nav-mobile-item special-sm-device">
                        <div className="d-flex align-items-center" style={{gap: 18}}>
                            {
                                (
                                    path_name?.includes('biopage') || path_name?.includes('account')
                                    || path_name?.includes('workspace') || path_name?.includes('user'))
                                    || path_name?.includes('bot-management')
                                    ? <p className="workspace-name">{workspaceSettings?.workspaceName}</p>
                                    : null
                            }
                            <div className='workspace-logo'>
                                <Workspace isForWorkspaceLogo={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </Navbar>
            
            <Navbar className={`lg-navbar border-bottom px-3`}
                collapseOnSelect expand="md"
            >
                <Navbar.Toggle />
                <Navbar.Collapse>
                    <Nav  className='w-100 d-flex flex-row justify-content-end text-end nav-gap'>

                        <Nav.Item className='text-decoration-none my-auto me-3'>
                            <Workspace />
                        </Nav.Item> 

                        <Nav.Item className="my-auto">
                            <PermissionCheck permissionName="workspaceSettings">
                                <div
                                    style={{ cursor: 'pointer' }}
                                    title="Settings"
                                    className='text-decoration-none text-dark' 
                                    onClick={() => !path_name?.includes("/workspace") &&
                                        !path_name?.includes("/user-management") &&
                                        !path_name?.includes("/biopage") &&
                                        dispatch(changePage("/workspace/general-settings")) 
                                        // (isReplyingOn ?  dispatch(changePage("/workspace/general-settings")) : history("/workspace/general-settings", { replace: true }))
                                        // {/* <img src={settings} height={30} className='' /> */}
                                    }
                                >
                                    <SettingOutlined style={{fontSize: "20px"}}/>
                                </div>
                            </PermissionCheck>
                        </Nav.Item>
                        
                        <Nav.Item className="my-auto">
                            <PermissionCheck permissionName="notifications">
                                <div title="Notification"><Notifications/></div>
                            </PermissionCheck>
                        </Nav.Item>

                        <Nav.Item className="my-auto">
                            <HeaderDropdown/>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </React.Fragment>
    )
}

export default Header;