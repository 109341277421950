import React, { useEffect } from "react";
import { Spinner } from 'react-bootstrap';
// import Button from "../components/button/button-container";
// import Layout from "../components/layout/layout";
import Reviews from "../components/reviews/reviews";
import './dashboard.scss';
import ContentWrap from '../components/content-wrap/content-wrap';
import Testimonials from "../components/feedsLeft/feedsLeft";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { getChartData, setChartPage, triggerDashboard } from "./reducer";
// import google from '../assets/icons/google-line-1.2.svg';
// import videos from '../assets/icons/video-chat-line-1.2.svg';
// import feedbacks from '../assets/icons/feedback-line-1.2.svg';
// import facebook from '../assets/icons/facebook-reviews.svg';
import user_avatar from '../assets/icons/user.svg';
import thumbs_up from '../assets/icons/thumbs-up.svg';
import thumbs_down from '../assets/icons/thumbs-down.svg';
import DashboardChart from "./dashboard-chart";
import { useNavigate } from "react-router-dom";
import TopTabs from "../components/layout/TopTabs";
import UnPermission from '../pages/un-permission/UnPermission';
import { getImage } from "./image_service";

const Dashboard = () => {
  // const {clapupUserId: clapupID} = useSelector((state: any) => state?.login);
  const { dashboardData: dashboard, chart_data, chart_page } = useSelector(
    (state: RootState) => state?.unified
  );
  const dispatch = useDispatch();
  // const history = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  
  useEffect(() => {
    // console.log('dashboard', dashboard, typeof (dashboard));
    
    if (dashboard) {
      // const clapUpUserID = sessionStorage.getItem("cuid");
      // const clapUpWorkspaceID = sessionStorage.getItem("wuid");
  
      // const dashboardObject = {
      //   clapupUserId: clapUpUserID,
      //   workspaceId: clapUpWorkspaceID,
      //   period: 0,
      // };
      // dispatch(triggerDashboard(dashboardObject));
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, [dispatch, dashboard]);
  
  // useEffect(() => {
  //   // console.log('dashboard', dashboard, typeof (dashboard));
    
  //   if(!chart_data?.length){
  //     const clapUpUserID = sessionStorage.getItem("cuid");
  //     const clapUpWorkspaceID = sessionStorage.getItem("wuid");
  
  //     const dashboardObject = {
  //       clapupUserId: clapUpUserID,
  //       workspaceId: clapUpWorkspaceID,
  //       period: 0,
  //     };
  //     dispatch(getChartData(dashboardObject));
  //   }
  // }, [dispatch, chart_data?.length]);


  // const getImage = (img?: any, data?: any) => {
  //   // console.log({image_object: data});
    
  //   switch (img) {
  //       case "google":
  //           return google
  //       // case "star":
  //       //     return star
  //       case "facebook":
  //           return facebook
  //       case "customerfeedback":
  //           return feedbacks;
  //       case "videotestimonial":
  //           return videos;
  //       default:
  //           return google;
  //   }
  // }

  const { userPermissions } = useSelector((state: any) => state?.login);
  const [isPermissible, setIsPermissible] = React.useState(true);
  React.useEffect(() => {
      const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "promotions") : null;
      if( permission?.value === 0){
          setIsPermissible(false);
      }
  }, [userPermissions?.length]);
  
  return (
    <React.Fragment>
      <TopTabs />
      {
        isPermissible
          ?
          <div className="dashboard-wrapper">
            {/* <h3>Dashboard</h3> */}
            <div className="review-section w-100">
              {dashboard ? (
                <div className="review-section-container">
                  <div className="review-testimonial-container">
                    <div>
                      <div className="review-container w-100">
                        <div className="feedback-review" style={{ marginRight: 0, marginLeft: 0}}>
                          <Reviews
                            title={"Google Reviews"}
                            data={dashboard?.GoogleReviews}
                            img={"google"}
                          />
                        </div>
                        <div className="feedback-review" style={{ marginRight: 0, marginLeft: 0}}>
                          <Reviews
                            title={"Facebook Comments"}
                            data={dashboard?.FacebookComments}
                            img={"facebook"}
                          />
                        </div>
                      </div>
                      <div className="review-container">
                      {dashboard?.CustomerFeedback?.length ? (
                        <div className="feedback-review" style={{ marginRight: 0, marginLeft: 0}}>
                          <Reviews
                            title={"Customer Feedback"}
                            data={dashboard?.CustomerFeedback}
                            img={"CustomerFeedback"}
                          />
                        </div>
                      ) : (
                        <div className="feedback-review" style={{ marginRight: 0, marginLeft: 0}}>
                          <div
                            style={{ height: "100%" }}
                            className="d-flex flex-column justify-content-center align-items-center"
                          >
                            <p>No data found</p>
                          </div>
                        </div>
                      )}
                      {dashboard?.VideoTestimonials?.length ? (
                        <div className="feedback-review">
                          <Reviews
                            title={"Video Testimonials"}
                            data={dashboard?.VideoTestimonials}
                            img={"VideoTestimonials"}
                          />
                        </div>
                      ) : (
                        <div className="feedback-review mb-0">
                          <div
                            style={{ height: "100%" }}
                            className="d-flex flex-column justify-content-center align-items-center"
                          >
                            <p>No data found</p>
                          </div>
                        </div>
                      )}
                      </div>
                    </div>
                    <div className="testimonial-section">
                      {/* Pagination for chart */}
                      <div className="review-container">
                        <ContentWrap
                          style={{ height: "fit-content", margin: "0 auto" }}
                          title="Review Trends"
                          pagination={
                            <div className="d-flex flex-row">
                              <span
                                onClick={() => dispatch(setChartPage("All"))}
                                className={`pagination px-2 ${
                                  chart_page === "All" &&
                                  "bg-primary text-white"
                                }`}
                              >
                                All
                              </span>
                              <span
                                onClick={() => dispatch(setChartPage(12))}
                                className={`pagination mx-1 ${
                                  chart_page === 12 && "bg-primary text-white"
                                }`}
                              >
                                12
                              </span>
                              <span
                                onClick={() => dispatch(setChartPage(9))}
                                className={`pagination mx-1 ${
                                  chart_page === 9 && "bg-primary text-white"
                                }`}
                              >
                                9
                              </span>
                              <span
                                onClick={() => dispatch(setChartPage(6))}
                                className={`pagination mx-1 ${
                                  chart_page === 6 && "bg-primary text-white"
                                }`}
                              >
                                6
                              </span>
                              <span
                                onClick={() => dispatch(setChartPage(3))}
                                className={`pagination mx-1 ${
                                  chart_page === 3 && "bg-primary text-white"
                                }`}
                              >
                                3
                              </span>
                              <span
                                onClick={() => dispatch(setChartPage(1))}
                                className={`pagination mx-1 ${
                                  chart_page === 1 && "bg-primary text-white"
                                }`}
                              >
                                1
                              </span>
                            </div>
                          }
                        >
                          <DashboardChart />
                        </ContentWrap>
                      </div>
                      <div className="review-container low-rated-review-wrapping">
                        <ContentWrap
                          style={{ height: "fit-content", margin: "0 auto" }}
                          title="Latest 5 Low Rated Google Reviews"
                        >
                          <Testimonials
                            // data={dashboard?.LatestReviewsfromAllSources}
                            data={dashboard?.LatestLowRatedGoogleReviews}
                            type={"low-rated-google-reviews"}
                          />
                        </ContentWrap>
                      </div>
                    </div>
                  </div>
                  <div className="review-table-section">
                    <div className="latest-last-review h-50">
                      <ContentWrap
                        // style={{ height: 278 }}
                        title="Latest 5 Reviews from All Source"
                      >
                        <div className="review-table">
                          {dashboard?.LatestReviewsfromAllSources?.length ? (
                            <table style={{ height: "100%" }}>
                              <thead></thead>
                              <tbody>
                                {dashboard?.LatestReviewsfromAllSources?.length
                                  ? dashboard?.LatestReviewsfromAllSources?.map(
                                      (e: any, i: any) => {
                                        return (
                                          <tr key={i} className=" border-bottom ">
                                            <td
                                              className="text-start d-flex align-items-center"
                                              style={{
                                                // fontWeight: 600,
                                                // fontSize: "100%",
                                                verticalAlign: "middle",
                                                width: "40%",
                                                gap: '0.4em'
                                              }}
                                            >
                                              {
                                                e?.imageUrl
                                                ? <img
                                                    className="border rounded-circle all-source-avatar"
                                                    src={e?.imageUrl || user_avatar}
                                                    alt="avatar"
                                                  />
                                                : <span className='special-letter-image'>
                                                    {
                                                      e.reviewerDisplayName?.split(" ")?.slice(0, 2)?.length === 2
                                                      ? e.reviewerDisplayName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                                                      : e.reviewerDisplayName?.substr(0, 2).toUpperCase()
                                                    }
                                                  </span>
                                              }
                                              <span className="mx-1 user-name">
                                                {e?.reviewerDisplayName
                                                  ? e?.reviewerDisplayName
                                                      ?.length > 10
                                                    ? e?.reviewerDisplayName?.slice(
                                                        0,
                                                        10
                                                      ) + ""
                                                    : e?.reviewerDisplayName
                                                  : "User"}
                                              </span>
                                            </td>
                                            <td
                                              className="text-start td-description"
                                              style={{
                                                width: "60%",
                                                verticalAlign: "middle",
                                              }}
                                            >
                                              <span className="source-des">
                                                {e.reviewComment?.length > 20
                                                  ? e?.reviewComment?.substring(
                                                      0,
                                                      20
                                                    ) + "..."
                                                  : e?.reviewComment ||
                                                    "As the brand, so is the service"}
                                              </span>
                                              <span className="source-des-mobile">
                                                {e.reviewComment?.length > 20
                                                  ? e?.reviewComment?.substring(
                                                      0,
                                                      20
                                                    ) + "..."
                                                  : e?.reviewComment ||
                                                    "As the brand, so is the service"}
                                              </span>
                                            </td>
                                            <td
                                              className="text-center rating"
                                              style={{
                                                verticalAlign: "middle",
                                                width: "12%",
                                              }}
                                            >
                                              <span className="rating">
                                                {e?.rating ? (
                                                  e?.Provider === "Facebook" ? (
                                                    <img
                                                      style={{
                                                        height: 10,
                                                        width: 10,
                                                      }}
                                                      src={
                                                        Number(e?.rating) > 4
                                                          ? thumbs_up
                                                          : thumbs_down
                                                      }
                                                      alt="fb-thumbs"
                                                    />
                                                  ) : (
                                                    e?.rating
                                                  )
                                                ) : (
                                                  0
                                                )}
                                              </span>
                                            </td>
                                            <td
                                              className="text-center"
                                              style={{
                                                verticalAlign: "middle",
                                                width: "5%",
                                              }}
                                            >
                                              <img
                                                className="social-media"
                                                src={getImage(e?.Provider?.toLowerCase())}
                                                alt="provider"
                                              />
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )
                                  : null}
                              </tbody>
                            </table>
                          ) : (
                            <div
                              style={{ height: "100%" }}
                              className="d-flex justify-content-center align-items-center"
                            >
                              <p>No data found</p>
                            </div>
                          )}
                        </div>
                      </ContentWrap>
                    </div>
                    <div className="latest-last-review h-50">
                      <ContentWrap
                        // style={{ height: 278 }}
                        title="Latest 5 Low Rated Reviews"
                      >
                        <div className="review-table">
                          {dashboard?.LowRatedReviews?.length ? (
                            <table
                              className="rated-review"
                              style={{ height: "100%" }}
                            >
                              <thead></thead>
                              <tbody>
                                {dashboard?.LowRatedReviews?.map(
                                  (e: any, i: any) => {
                                    return (
                                      <tr key={i} className=" border-bottom ">
                                        <td
                                          className="text-start d-flex align-items-center "
                                          style={{
                                            verticalAlign: "middle",
                                            width: "40%",
                                            gap: '0.4em'
                                          }}
                                        >
                                          {
                                            e?.imageUrl
                                            ? <img
                                                className="border rounded-circle all-source-avatar"
                                                src={e?.imageUrl || user_avatar}
                                                alt="avatar"
                                              />
                                            : <span className='special-letter-image'>
                                                {
                                                  e.ProviderUser?.split(" ")?.slice(0, 2)?.length === 2
                                                  ? e.ProviderUser?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                                                  : e.ProviderUser?.substr(0, 2).toUpperCase()
                                                }
                                              </span>
                                          }
                                          <span className="mx-1 user-name">
                                            {e?.ProviderUser
                                              ? e?.ProviderUser?.length > 10
                                                ? e?.ProviderUser?.slice(0, 10) +
                                                  ""
                                                : e?.ProviderUser
                                              : "User"}
                                          </span>
                                        </td>
                                        <td
                                          className="text-start "
                                          style={{
                                            width: "60%",
                                            verticalAlign: "middle",
                                          }}
                                        >
                                          <span className="source-des">
                                            {e.reviewComment?.length > 30
                                              ? e?.reviewComment?.substring(
                                                  0,
                                                  30
                                                ) + "..."
                                              : e?.reviewComment ||
                                                "As the brand, so is the service"}
                                          </span>
                                          <span
                                            className="source-des-mobile"
                                            // style={{
                                            //   fontSize: 12,
                                            //   fontWeight: 500,
                                            // }}
                                          >
                                            {e.reviewComment?.length > 25
                                              ? e?.reviewComment?.substring(
                                                  0,
                                                  25
                                                ) + "..."
                                              : e?.reviewComment ||
                                                "As the brand, so is the service"}
                                          </span>
                                        </td>
                                        <td
                                          className="text-center rating"
                                          style={{
                                            verticalAlign: "middle",
                                            width: "10%",
                                          }}
                                        >
                                          <span className="rating">
                                            {e?.rating ? (
                                              e?.Provider === "Facebook" ? (
                                                <img
                                                  style={{
                                                    height: 10,
                                                    width: 10,
                                                  }}
                                                  src={
                                                    e?.rating > 4
                                                      ? thumbs_up
                                                      : thumbs_down
                                                  }
                                                  alt="fb-thumbs"
                                                />
                                              ) : (
                                                e?.rating
                                              )
                                            ) : (
                                              0
                                            )}
                                          </span>
                                        </td>
                                        <td
                                          className="text-center"
                                          style={{
                                            verticalAlign: "middle",
                                            width: "5%",
                                          }}
                                        >
                                          <img
                                            className="social-media"
                                            src={getImage(e?.Provider?.toLowerCase())}
                                            alt="provider"
                                          />
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          ) : (
                            <div
                              style={{ height: "100%" }}
                              className="d-flex justify-content-center align-items-center"
                            >
                              <p>No data found</p>
                            </div>
                          )}
                        </div>
                      </ContentWrap>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{ height: "90vh" }}
                  className="d-flex justify-content-center align-items-center"
                >
                  {isLoading ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <p style={{ fontSize: 24 }}>No data found</p>
                  )}
                </div>
              )}
            </div>
          </div>
          : <UnPermission/>
      }
    </React.Fragment>
  );
}

export default Dashboard;