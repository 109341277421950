import Constants from "../constants";
import { api_url } from "../utils/server";

const initialState: any = {
    twitterMentions: [],
    dashboardData: null,
    // mmhk-region-start
    isSpinning: false,
    wallBoxId: null,
    searchText: '',
    searchWallText: '',
    searchStartDate: '',
    searchEndDate: '',
    searchFilterStatus: '',
    integration_type: '',
    nextDate: [],
    botInboxData: null,
    is_main_whatsapp_inbox_updated: false,
    is_main_bot_inbox_updated: false,
    bot_inbox_messages_payload: null,
    whatsapp_inbox_messages_payload: null,
    whatsAppInboxData: null,
    global_message_type: '',
    is_empty_whatsapp_inbox: false,
    is_empty_ai_bot_inbox: false,
    isNewConversationDetails: false,
    isCountDownOn: false,
    unifiedData: null,
    wallUnifiedData: null,
    isWallUnifiedData: true,
    embedObject: {},
    unifiedDetailsData: {},
    conversationDetailsData: null,
    conversation_details_payload: null,
    requestReviewData: null,
    locations: [],
    success_message: null,
    chart_data: [],
    chart_page: "All",
    pageRoute: null,
    isReplyingOn: false,
    isInReplyingMode: false,
    isClosedWhatsAppInboxThread: null,
    whats_app_sender_state: null,
    whatsapp_replying_text: null,
    unified_inbox_payload: null,
    wall_unified_inbox_payload: null,
    is_first_time_unified_inbox: false,
    is_first_time_wall_unified_inbox: false,
    // mmhk-region-end
}

export const setIsCountDownOn = (data: any) => {
    return {
        type: Constants.TOGGLE_COUNT_DOWN,
        payload: data
    }
};

export const triggerClosedWhatsApp = (data: any) => {
    return {
        type: Constants.TRIGGER_CLOSED_WHATSAPP,
        payload: data
    }
};

export const setClosedWhatsApp = (data: any) => {
    // console.log({ data: data });
    return {
        type: Constants.SET_CLOSED_WHATSAPP,
        payload: data
    }
};

export const toggleClosedWhatsApp = (data: any) => {
    // console.log({ data: data });
    return {
        type: "TOGGLE_CLOSED_WHATS_APP",
        payload: data
    }
};

export const toggleWhatsAppSenderState = (data: any) => {
    // console.log({ data: data });
    return {
        type: "TOGGLE_WHATS_APP_SENDER_STATE",
        payload: data
    }
};

export const setWhatsAppReplyingText = (data: any) => {
    // console.log({ data: data });
    return {
        type: "SET_WHATS_APP_REPLYING_TEXT",
        payload: data
    }
};

export const triggerUpdateWhatsappPhoneOrEmail = (data: any) => {
    return {
        type: Constants.TRIGGER_UPDATE_WHATSAPP_PHONE_OR_EMAIL,
        payload: data
    }
};

export const setUpdatedWhatsappPhoneOrEmail = (data: any) => {
    return {
        type: Constants.SET_UPDATED_WHATSAPP_PHONE_OR_EMAIL,
        payload: data
    }
};

export const twitterMentions = (data: any) => {
    return {
        type: Constants.TWITTER_MENTIONS,
        payload: data
    }
}

export const triggerDashboard = (data: any) => {
    return {
        type: Constants.TRIGGER_DASHBOARD_DATA,
        payload: data
    }
}

export const receiveDashboard = (data: any) => {
    return {
        type: Constants.SET_DASHBOARD_DATA,
        payload: data
    }
}

// mmhk-region-start

export const getChartData = (data: any) => {
    return {
        type: Constants.GET_CHART_DATA,
        payload: data
    }
}

export const setChartData = (data: any) => {
    return {
        type: Constants.SET_CHART_DATA,
        payload: data
    }
}

// mmhk-region-end




// Actions
// trigger unified inbox
export const changePage = (data: any) => {
    return {
        type: Constants.TOGGLE_PAGE_ROUTE,
        payload: data
    }
}

export const toggleReplyingOn = (data: any) => {
    return {
        type: Constants.TOGGLE_REPLYING_ON,
        payload: data
    }
}


export const toggleIsInReplyingMode = (data: any) => {
    return {
        type: Constants.TOGGLE_IS_IN_REPLYING_MODE,
        payload: data
    }
}
export const toggleNewConversationDetails = (data: any) => {
    return {
        type: Constants.TOGGLE_NEW_CONVERSATION_DETAILS,
        payload: data
    }
}

// facebook like-unlike post
export const likeUnlikeFacebookPost = (data: any) => {
    return {
        type: Constants.LIKE_UNLIKE_FACEBOOK_POST,
        payload: data
    }
}

// facebook replying post
export const replyFacebookPost = (data: any) => {
    return {
        type: Constants.REPLYING_FACEBOOK_POST,
        payload: data
    }
}

export const setWallUnifiedInboxPayload = (data: any) => {
    return {
        type: "SET_WALL_UNIFIED_INBOX_PAYLOAD",
        payload: data
    }
}

export const setUnifiedInboxPayload = (data: any) => {
    return {
        type: "SET_UNIFIED_INBOX_PAYLOAD",
        payload: data
    }
}

export const triggerUnified = (data: any) => {
    const { nextDate, isFirstTime, readStatus, searchText, fromDate, toDate } = data;
    delete data.nextDate;
    // delete data.tag;
    const dataObj: any = { ...data };
    // dataObj.traverseOrder = dataObj.traverseOrder ? dataObj.traverseOrder : 0;
    if (isFirstTime && (readStatus || searchText || fromDate)) {
        dataObj.googleReviewsCreatedOn = new Date().toISOString().replace('Z', '');
        dataObj.facebookReviewsCreatedOn = new Date().toISOString().replace('Z', '');
        // dataObj.twitterMentionsCreatedOn = new Date().toISOString().replace('Z', '');
        dataObj.customerFeedbackCreatedOn = new Date().toISOString().replace('Z', '');
        dataObj.videoTestimonialCreatedOn = new Date().toISOString().replace('Z', '');
        dataObj.facebookCommentsCreatedOn = new Date().toISOString().replace('Z', '');
        dataObj.facebookMentionsCreatedOn = new Date().toISOString().replace('Z', '');
        dataObj.googleQuestionsCreatedOn = new Date().toISOString().replace('Z', '');
    }

    if (nextDate?.length && !isFirstTime) {
        if (readStatus || searchText || fromDate || toDate) {
            dataObj.googleReviewsCreatedOn = null;
            dataObj.facebookReviewsCreatedOn = null;
            dataObj.facebookCommentsCreatedOn = null;
            dataObj.facebookMentionsCreatedOn = null;
            dataObj.customerFeedbackCreatedOn = null;
            dataObj.videoTestimonialCreatedOn = null;
            dataObj.googleQuestionsCreatedOn = null;
            // dataObj.twitterMentionsCreatedOn = null;
            // dataObj.googleReviewsCreatedOn = "";
            // dataObj.facebookReviewsCreatedOn = "";
            // dataObj.facebookCommentsCreatedOn = "";
            // dataObj.facebookMentionsCreatedOn = "";
            // dataObj.customerFeedbackCreatedOn = "";
            // dataObj.videoTestimonialCreatedOn = "";
            // dataObj.googleQuestionsCreatedOn = "";
            // dataObj.twitterMentionsCreatedOn = "";
        }
        nextDate?.map((item: any) => {
            if (item?.provider?.includes("googleReviews")) {
                dataObj.googleReviewsCreatedOn = item?.createdOn || item?.CreatedOn;
            }
            else if (item?.provider?.includes("facebookReviews")) {
                dataObj.facebookReviewsCreatedOn = item?.createdOn || item?.CreatedOn;
            }
            else if (item?.provider?.includes("facebookComments")) {
                dataObj.facebookCommentsCreatedOn = item?.createdOn || item?.CreatedOn;
            }
            else if (item?.provider?.includes("facebookMentions")) {
                dataObj.facebookMentionsCreatedOn = item?.createdOn || item?.CreatedOn;
            }
            else if (item?.provider?.includes("customerFeedBackReviews")) {
                dataObj.customerFeedbackCreatedOn = item?.createdOn || item?.CreatedOn;
            }
            else if (item?.provider?.includes("videoTestimonialReviews")) {
                dataObj.videoTestimonialCreatedOn = item?.createdOn || item?.CreatedOn;
            }
            else if (item?.provider?.includes("googleQandA")) {
                dataObj.googleQuestionsCreatedOn = item?.createdOn || item?.CreatedOn;
                // dataObj.googleQandACreatedOn = item?.createdOn;
            }
            else if (item?.provider?.includes("twitterMentions")) {
                dataObj.twitterMentionsCreatedOn = item?.createdOn || item?.CreatedOn;
            }
        });
    }

    if (dataObj.searchText || dataObj.readStatus) {
        dataObj.fromDate = "2000-01-01T00:00:00.00000";
        dataObj.toDate = new Date().toISOString().replace('Z', '');
    }
    console.log("nextDate", nextDate);
    console.log("dataObj", dataObj);
    return {
        type: Constants.TRIGGER_INBOX_AND_WALL,
        payload: dataObj
    }
}


export const setUnifiedData = (data?: any, searchPayload?: any, key?: any, nextKey?: any) => {
    return {
        type: Constants.SET_UNIFIED_INBOX,
        payload: { data, searchPayload, key, nextKey },
    }
}

// trigger bot inbox messages
export const triggerGetWhatsAppInboxData = (data: any) => {
    const { nextDate, isFirstTime, readStatus, searchText, fromDate, toDate } = data;
    // delete data.type;
    // delete data.nextDate;
    const dataObj: any = {
        ...data,
        workspaceId: sessionStorage.getItem('wuid'),
    };
    // delete dataObj.isFirstTime;
    // delete dataObj.tag;
    // delete dataObj.readStatus;
    // delete dataObj.searchText;
    // delete dataObj.fromDate;
    // delete dataObj.toDate;
    // delete dataObj.providerType;
    // console.log({ nextDate, type, dataObj });
    // Only for wall search
    if (isFirstTime && (searchText || toDate || fromDate)) {
        // nextDate will come here
        dataObj.createdOn = new Date().toISOString().replace('Z', '');
        // dataObj.facebookReviewsCreatedOn = new Date().toISOString().replace('Z', '');

    };
    // nextDate?.map((item: any) => {
    //     if (item?.provider?.includes("googleReviews")) {
    //         dataObj.googleReviewsCreatedOn = item?.CreatedOn || item?.createdOn;
    //     }
    //     else if (item?.provider?.includes("facebookReviews")) {
    //         dataObj.facebookReviewsCreatedOn = item?.CreatedOn || item?.createdOn;
    //     }
    //     else if (item?.provider?.includes("facebookComments")) {
    //         dataObj.facebookCommentsCreatedOn = item?.CreatedOn || item?.createdOn;
    //     }
    //     else if (item?.provider?.includes("facebookMentions")) {
    //         dataObj.facebookMentionsCreatedOn = item?.CreatedOn || item?.createdOn;
    //     }
    //     else if (item?.provider?.includes("customerFeedBackReviews")) {
    //         dataObj.customerFeedbackCreatedOn = item?.CreatedOn || item?.createdOn;
    //     }
    //     else if (item?.provider?.includes("videoTestimonialReviews")) {
    //         dataObj.videoTestimonialCreatedOn = item?.CreatedOn || item?.createdOn;
    //     }
    //     else if (item?.provider?.includes("googleQandA")) {
    //         dataObj.googleQuestionsCreatedOn = item?.CreatedOn || item?.createdOn;
    //         // dataObj.googleQandACreatedOn = item?.createdOn;
    //     }
    //     else if (item?.provider?.includes("twitterMentions")) {
    //         dataObj.twitterMentionsCreatedOn = item?.CreatedOn || item?.createdOn;
    //     }
    // });

    // if (dataObj.searchText || dataObj.readStatus) {
    if (dataObj.searchText) {
        dataObj.fromDate = fromDate || "2000-01-01T00:00:00.00000";
        dataObj.toDate = toDate || new Date().toISOString().replace('Z', '');
    }

    return {
        type: Constants.GET_FIRST_TIME_WHATSAPP_DATA_INBOX,
        payload: dataObj
    }
}


export const setWhatsAppInboxData = (data?: any, key?: any, nextKey?: any) => {
    return {
        type: Constants.SET_FIRST_TIME_WHATSAPP_DATA_INBOX,
        payload: { data, key, nextKey }
    }
}

export const setWhatsappInboxMessagePayload = (data?: any) => {
    return {
        type: "SET_WHATSAPP_INBOX_MESSAGES_PAYLOAD",
        payload: data
    }
}

export const setBotInboxMessagePayload = (data?: any) => {
    return {
        type: "SET_BOT_INBOX_MESSAGES_PAYLOAD",
        payload: data
    }
}
// trigger bot inbox messages
export const triggerGetBotData = (data: any) => {
    const { nextDate, isFirstTime, isForSearched, searchText, fromDate, toDate } = data;
    // delete data.type;
    // delete data.nextDate;
    const dataObj: any = {
        ...data,
        isForSearched,
        workspaceId: sessionStorage.getItem('wuid'),
    };
    // delete dataObj.isFirstTime;
    // delete dataObj.tag;
    // delete dataObj.readStatus;
    // delete dataObj.searchText;
    // delete dataObj.fromDate;
    // delete dataObj.toDate;
    // delete dataObj.providerType;
    // console.log({ nextDate, type, dataObj });
    // Only for wall search
    if (isFirstTime && (searchText || toDate || fromDate)) {
        // nextDate will come here
        dataObj.createdOn = new Date().toISOString().replace('Z', '');
        // dataObj.facebookReviewsCreatedOn = new Date().toISOString().replace('Z', '');

    };
    // nextDate?.map((item: any) => {
    //     if (item?.provider?.includes("googleReviews")) {
    //         dataObj.googleReviewsCreatedOn = item?.CreatedOn || item?.createdOn;
    //     }
    //     else if (item?.provider?.includes("facebookReviews")) {
    //         dataObj.facebookReviewsCreatedOn = item?.CreatedOn || item?.createdOn;
    //     }
    //     else if (item?.provider?.includes("facebookComments")) {
    //         dataObj.facebookCommentsCreatedOn = item?.CreatedOn || item?.createdOn;
    //     }
    //     else if (item?.provider?.includes("facebookMentions")) {
    //         dataObj.facebookMentionsCreatedOn = item?.CreatedOn || item?.createdOn;
    //     }
    //     else if (item?.provider?.includes("customerFeedBackReviews")) {
    //         dataObj.customerFeedbackCreatedOn = item?.CreatedOn || item?.createdOn;
    //     }
    //     else if (item?.provider?.includes("videoTestimonialReviews")) {
    //         dataObj.videoTestimonialCreatedOn = item?.CreatedOn || item?.createdOn;
    //     }
    //     else if (item?.provider?.includes("googleQandA")) {
    //         dataObj.googleQuestionsCreatedOn = item?.CreatedOn || item?.createdOn;
    //         // dataObj.googleQandACreatedOn = item?.createdOn;
    //     }
    //     else if (item?.provider?.includes("twitterMentions")) {
    //         dataObj.twitterMentionsCreatedOn = item?.CreatedOn || item?.createdOn;
    //     }
    // });

    // if (dataObj.searchText || dataObj.readStatus) {
    if (isForSearched || (searchText || toDate || fromDate)) {
        dataObj.fromDate = fromDate || "2000-01-01T00:00:00.00000";
        dataObj.toDate = toDate || new Date().toISOString().replace('Z', '');
    }

    return {
        type: Constants.GET_FIRST_TIME_BOT_DATA_INBOX,
        payload: dataObj
    }
}

export const setBotData = (data?: any, key?: any, nextKey?: any) => {
    return {
        type: Constants.SET_FIRST_TIME_BOT_DATA_INBOX,
        payload: { data, key, nextKey }
    }
}

// trigger wall unified inbox
export const triggerWallUnified = (data: any) => {
    const { nextDate, isFirstTime, readStatus, searchText, fromDate } = data;
    delete data.type;
    delete data.nextDate;
    const dataObj: any = { ...data };
    delete dataObj.isFirstTime;
    // delete dataObj.tag;
    // delete dataObj.readStatus;
    // delete dataObj.searchText;
    // delete dataObj.fromDate;
    // delete dataObj.toDate;
    // delete dataObj.providerType;
    // console.log({ nextDate, type, dataObj });
    // Only for wall search
    if (isFirstTime && (searchText || readStatus || fromDate)) {
        dataObj.googleReviewsCreatedOn = new Date().toISOString().replace('Z', '');
        dataObj.facebookReviewsCreatedOn = new Date().toISOString().replace('Z', '');
        // dataObj.twitterMentionsCreatedOn = new Date().toISOString().replace('Z', '');
        dataObj.customerFeedbackCreatedOn = new Date().toISOString().replace('Z', '');
        dataObj.videoTestimonialCreatedOn = new Date().toISOString().replace('Z', '');
        dataObj.facebookCommentsCreatedOn = new Date().toISOString().replace('Z', '');
        dataObj.facebookMentionsCreatedOn = new Date().toISOString().replace('Z', '');
        dataObj.googleQuestionsCreatedOn = new Date().toISOString().replace('Z', '');
    };
    nextDate?.map((item: any) => {
        if (item?.provider?.includes("googleReviews")) {
            dataObj.googleReviewsCreatedOn = item?.CreatedOn || item?.createdOn;
        }
        else if (item?.provider?.includes("facebookReviews")) {
            dataObj.facebookReviewsCreatedOn = item?.CreatedOn || item?.createdOn;
        }
        else if (item?.provider?.includes("facebookComments")) {
            dataObj.facebookCommentsCreatedOn = item?.CreatedOn || item?.createdOn;
        }
        else if (item?.provider?.includes("facebookMentions")) {
            dataObj.facebookMentionsCreatedOn = item?.CreatedOn || item?.createdOn;
        }
        else if (item?.provider?.includes("customerFeedBackReviews")) {
            dataObj.customerFeedbackCreatedOn = item?.CreatedOn || item?.createdOn;
        }
        else if (item?.provider?.includes("videoTestimonialReviews")) {
            dataObj.videoTestimonialCreatedOn = item?.CreatedOn || item?.createdOn;
        }
        else if (item?.provider?.includes("googleQandA")) {
            dataObj.googleQuestionsCreatedOn = item?.CreatedOn || item?.createdOn;
            // dataObj.googleQandACreatedOn = item?.createdOn;
        }
        else if (item?.provider?.includes("twitterMentions")) {
            dataObj.twitterMentionsCreatedOn = item?.CreatedOn || item?.createdOn;
        }
    });

    if (dataObj.searchText || dataObj.readStatus) {
        dataObj.fromDate = "2000-01-01T00:00:00.00000";
        dataObj.toDate = new Date().toISOString().replace('Z', '');
    }

    return {
        type: Constants.TRIGGER_WALL_UNIFIED_INBOX,
        payload: dataObj
    }
}


export const appendedSignalR_Data = (data: any) => {
    return {
        type: Constants.APPEND_SIGNAL_R_DATA,
        payload: data
    }
}

export const appendedSignalR_DataForWhatsapp_Inbox = (data: any) => {
    return {
        type: Constants.APPEND_SIGNAL_R_DATA_FOR_WHATSAPP_INBOX,
        payload: data
    }
}


export const toggleWhatsappInboxUpdated = (data: any) => {
    return {
        type: "TOGGLE_SIGNAL_UPDATE_WHATSAPP_INBOX",
        payload: data
    }
}

export const toggleBotInboxUpdated = (data: any) => {
    return {
        type: "TOGGLE_SIGNAL_UPDATE_BOT_INBOX",
        payload: data
    }
}

export const appendedSignalR_DataForAI_app_Inbox = (data: any) => {
    return {
        type: Constants.APPEND_SIGNAL_R_DATA_FOR_AI_CHAT_INBOX,
        payload: data
    }
}

export const triggerSearchWallData = (dataObj: any) => {
    return {
        type: Constants.TRIGGER_SEARCH_WALL_DATA,
        payload: dataObj
    }
}


export const setWallUnifiedData = (data?: any, embedObject?: any, key?: any, nextKey?: any) => {
    return {
        type: Constants.SET_WALL_UNIFIED_INBOX,
        payload: { data, embedObject, key, nextKey }
    }
}

// details for unified inbox data
// provider google
export const triggerGoogleReviewsUnifiedDetails = (data: any) => {
    return {
        type: Constants.TRIGGER_GOOGLE_DATA,
        payload: data
    }
}
// provider facebook comment
export const triggerFacebookCommentsUnifiedDetails = (data: any) => {
    return {
        type: Constants.TRIGGER_FACEBOOK_COMMENT_DATA,
        payload: data
    }
}

export const setConversationDetailsPayload = (data?: any, url?: string) => {
    return {
        type: Constants.SET_CONVERSATION_DETAILS_PAYLOAD,
        payload: url ? { payload_object: data, url: url, isNewTime: Number(new Date()) } : null
    }
}

export const triggerConversationDetails = (data?: any, url?: string, method?: string) => {
    return {
        type: Constants.TRIGGER_CONVERSATION_DETAILS_DATA,
        payload: data,
        url,
        method
    }
}

export const setConversationDetailsData = (data: any) => {
    return {
        type: Constants.SET_CONVERSATION_DETAILS_DATA,
        payload: data
    }
}

// provider facebook mention
export const triggerFacebookMentionsUnifiedDetails = (data: any) => {
    return {
        type: Constants.TRIGGER_FACEBOOK_MENTION_DATA,
        payload: data
    }
}

// provider facebook review
export const triggerFacebookReviewsUnifiedDetails = (data: any) => {
    return {
        type: Constants.TRIGGER_FACEBOOK_REVIEW_DATA,
        payload: data
    }
}
// provider customer feedback
export const triggerCustomerFeedbackReviewsUnifiedDetails = (data: any) => {
    return {
        type: Constants.TRIGGER_CUSTOMER_FEEDBACK_DATA,
        payload: data
    }
}
// provider video testimonial
export const triggerVideoTestimonialReviewsUnifiedDetails = (data: any) => {
    return {
        type: Constants.TRIGGER_VIDEO_TESTIMONIAL_DATA,
        payload: data
    }
}
// provider twitter
export const triggerTwitterReviewsUnifiedDetails = (data: any) => {
    return {
        type: Constants.TRIGGER_TWITTER_DATA,
        payload: data
    }
}

export const setUnifiedDetailsData = (data: any) => {
    return {
        type: Constants.SET_UNIFIED_DETAILS_DATA,
        payload: data
    }
}

// add to wall data
export const triggerAddToWallData = (payload?: any) => {
    // const { addToWallPayload, wallUnifiedPayload } = data;
    const { addToWallPayload, key, data } = payload;
    console.log({ key, data });

    return {
        type: Constants.TRIGGER_ADD_TO_WALL,
        addToWallPayload: addToWallPayload,
        data, key
        // wallUnifiedPayload: wallUnifiedPayload
    }
}

export const addToWallDataByKey = (key: any, data: any) => {
    return {
        type: Constants.ADD_TO_WALL,
        payload: { key, data },
    }
}

// delete to wall data
export const triggerDeleteWallUnifiedData = (payload: any) => {
    const { deleteWallPayload, key, data } = payload;
    // const { deleteWallPayload, wallUnifiedPayload } = data;
    return {
        type: Constants.TRIGGER_DELETE_FROM_WALL,
        deleteWallPayload: deleteWallPayload,
        data, key
    }
}

export const removeFromWallDataByKey = (key: any, data: any) => {
    return {
        type: Constants.REMOVE_FROM_WALL,
        payload: { key, data },
    }
}

// request review
export const triggerRequestReview = (data: any) => {
    return {
        type: Constants.TRIGGER_REQUEST_REVIEW,
        payload: data
    }
}

// set request review data
export const setRequestReviewData = (data: any) => {
    return {
        type: Constants.SET_REQUEST_REVIEW_DATA,
        payload: data
    }
}

// reset request review data
export const resetRequestReviewData = () => {
    return {
        type: Constants.RESET_REQUEST_REVIEW_DATA
    }
}

export const resetUnifiedInbox = () => {
    console.log("Reset Unified Inbox");

    return {
        type: Constants.RESET_UNIFIED_INBOX
    }
}

export const resetWallUnifiedInbox = () => {
    console.log("Reset Wall Unified Inbox");

    return {
        type: "RESET_WALL_UNIFIED_INBOX",
        payload: true,
    }
}

// get location with code
export const getCountryWithCode = (flag?: any) => {
    let url = null;
    const workspaceId = sessionStorage.getItem("wuid");
    if (flag === 'google') {
        url = api_url + `/api/v1.2/google/GetGoogleLocationList?workspaceId=${workspaceId}`;
    } else if (flag === "facebook") {
        url = api_url + `/api/v1.2/facebook/GetFacebookPageList?workspaceId=${workspaceId}`;
    }
    return {
        type: Constants.GET_LOCATIONS,
        payload: {
            url
        }
    }
}

// set success message
export const setSuccessMessage = (data: any) => {
    return {
        type: Constants.SET_SUCCESS_MESSAGE,
        payload: data
    }
}

// set success message
export const setInfoMessage = (data: any) => {
    return {
        type: Constants.SET_INFO_MESSAGE,
        payload: data
    }
}

// set error message
export const setErrorMessage = (data: any) => {
    return {
        type: Constants.SET_ERROR_MESSAGE,
        payload: data
    }
}

// set chart page
export const setChartPage = (data: any) => {
    return {
        type: Constants.SET_CHART_PAGE,
        payload: data
    }
}

// set box id
export const setBoxId = (data: any) => {
    return {
        type: Constants.SET_BOX_ID,
        payload: data
    }
}

// set message type
export const setGlobalMessageType = (data: any) => {
    return {
        type: "SET_MESSAGE_TYPE",
        payload: data
    }
}

export const toggleFirstTimeGetInboxData = (flag?: boolean) => {
    return {
        type: "TOGGLE_IS_FIRST_TIME_UNIFIED_INBOX",
        payload: flag
    }
}

export const toggleFirstTimeGetWallInboxData = (flag?: boolean) => {
    return {
        type: "TOGGLE_IS_FIRST_TIME_WALL_UNIFIED_INBOX",
        payload: flag
    }
}


//Reducers
export const toggleIsWhatsappInboxEmpty = (data?: boolean) => {
    return {
        type: "TOGGLE_IS_WHATSAPP_INBOX_EMPTY",
        payload: data
    }
};

export const toggleIsAiBotInboxEmpty = (data?: boolean) => {
    return {
        type: "TOGGLE_IS_AI_BOT_INBOX_EMPTY",
        payload: data
    }
};

const unifiedReducer = (state = initialState, action: any) => {
    switch (action.type) {
        // mmhk-region-start
        case "TOGGLE_IS_AI_BOT_INBOX_EMPTY":
            return {
                ...state,
                is_empty_ai_bot_inbox: action.payload
            }
        case "TOGGLE_IS_WHATSAPP_INBOX_EMPTY":
            return {
                ...state,
                is_empty_whatsapp_inbox: action.payload
            }
        case "SET_MESSAGE_TYPE":
            return { ...state, global_message_type: action.payload };
        case Constants.TOGGLE_COUNT_DOWN:
            return { ...state, isCountDownOn: action.payload };
        case Constants.SET_CLOSED_WHATSAPP:
            const { whatsAppInboxData } = state;
            const { userName, ticketNo, status } = action.payload;
            const { main } = whatsAppInboxData;
            const main_item = main?.map((wid: any) => {
                if (wid?.userName === userName) {
                    const ticketWisedData = wid?.userWiseMessageDetailsSubs?.map((chat: any) => {
                        if (chat?.ticketNo === ticketNo) {
                            chat.status = status;
                        }
                        return chat;
                    });
                    wid.userWiseMessageDetailsSubs = ticketWisedData;
                }
                return wid;
            });
            // let stat_details = statDetails?.find((sd: any) => sd?.userName === user);
            // const ticketWisedData = main_item?.userWiseMessageDetailsSubs?.find((chat: any) => chat?.ticketNo === ticketNo);
            console.log({ whatsAppInboxData, main_item, ticketNo });
            return {
                ...state,
                whatsAppInboxData: {
                    ...whatsAppInboxData,
                    main: main_item,
                },
                isClosedWhatsAppInboxThread: ticketNo,
            }
        case "TOGGLE_CLOSED_WHATS_APP":
            return { ...state, isClosedWhatsAppInboxThread: action.payload }
        case "TOGGLE_WHATS_APP_SENDER_STATE":
            return { ...state, whats_app_sender_state: action.payload }
        case "SET_WHATS_APP_REPLYING_TEXT":
            return { ...state, whatsapp_replying_text: action.payload }
        case Constants.SET_UPDATED_WHATSAPP_PHONE_OR_EMAIL:
            const { whatsAppInboxData: whatsapp_details_data } = state;
            const { userName: user_name, fromPhoneNumber, userEmail: user_email } = action.payload;
            const { main: main_details } = whatsapp_details_data;
            const main_item_details = main_details?.map((wid: any) => {
                if (wid?.userName === user_name) {
                    user_email && (wid.userEmail = user_email);
                    fromPhoneNumber && (wid.fromPhoneNumber = fromPhoneNumber);
                }
                return wid;
            });
            // console.log({ whatsapp_details_data, main_item_details, data: action.payload });
            return {
                ...state,
                whatsAppInboxData: {
                    ...whatsapp_details_data,
                    main: main_item_details,
                },
            }
        case "SET_WHATSAPP_INBOX_MESSAGES_PAYLOAD":
            return {
                ...state,
                whatsapp_inbox_messages_payload: action.payload
            }
        case Constants.SET_FIRST_TIME_WHATSAPP_DATA_INBOX:
            const { data: whatsAppData, key: whatsAppKey, nextKey: whatsAppNextKey } = action.payload;
            console.log({ whatsAppData, whatsAppKey, whatsAppNextKey });

            // const expectedBotData: any = {
            //     ...state.botInboxData,
            // };
            // expectedBotData[botKey] = botData[botKey];
            // expectedBotData[botNextKey] = botData[botNextKey];
            return {
                ...state,
                whatsAppInboxData: {
                    // ...expectedBotData
                    ...whatsAppData
                }
            }
        case "TOGGLE_SIGNAL_UPDATE_WHATSAPP_INBOX":
            return {
                ...state,
                is_main_whatsapp_inbox_updated: action.payload,
            }
        case Constants.APPEND_SIGNAL_R_DATA_FOR_WHATSAPP_INBOX:
            const { msgDetails: chatDetails_whatsapp_inbox, main: main_whatsapp_inbox, statDetails: statDetails_whatsapp_inbox, chatSummaryList: chatSummaryList_whatsapp_inbox }: any = action.payload;
            const expected_whatsapp_inbox_data: any = {
                msgDetails: [], main: [], statDetails: [], chatSummaryList: [],
                ...state?.whatsAppInboxData
            };

            if (chatDetails_whatsapp_inbox?.length) {
                expected_whatsapp_inbox_data["msgDetails"] = [...chatDetails_whatsapp_inbox, ...expected_whatsapp_inbox_data.msgDetails];
            }

            if (main_whatsapp_inbox?.length) {
                const main_whatsapp_item = main_whatsapp_inbox[0];
                const check_is_available_main_whatsapp_item = expected_whatsapp_inbox_data?.main?.find((item: any) => item?.userName === main_whatsapp_item.userName && item?.fromPhoneNumber === main_whatsapp_item?.fromPhoneNumber);
                if (check_is_available_main_whatsapp_item) {
                    const main_new_whatsapp_item = {
                        userWiseMessageDetailsSubs: [],
                        ...check_is_available_main_whatsapp_item,
                    };
                    // main array should contain unique ticketNo 
                    const uniqueUserWiseChatDetailsSubs = [...main_whatsapp_item?.userWiseMessageDetailsSubs, ...check_is_available_main_whatsapp_item?.userWiseMessageDetailsSubs]
                        ?.reduce((uniqueArray, currentItem) => {
                            if (!uniqueArray.some((item: any) => item.ticketNo === currentItem.ticketNo)) {
                                uniqueArray.push(currentItem);
                            }
                            return uniqueArray;
                        }, []);
                    main_new_whatsapp_item.userWiseMessageDetailsSubs = uniqueUserWiseChatDetailsSubs;
                    const filtered_new_main_whatsapp_inbox_data = expected_whatsapp_inbox_data?.main?.filter((item: any) => item?.userName !== main_whatsapp_item.userName && item?.fromPhoneNumber !== main_whatsapp_item?.fromPhoneNumber);
                    expected_whatsapp_inbox_data["main"] = [main_new_whatsapp_item, ...filtered_new_main_whatsapp_inbox_data];
                } else {
                    expected_whatsapp_inbox_data["main"] = [...main_whatsapp_inbox, ...expected_whatsapp_inbox_data.main];
                }
            }

            if (statDetails_whatsapp_inbox?.length) {
                const statDetails_whatsapp_item = statDetails_whatsapp_inbox[0];
                const check_is_available_statDetails_whatsapp_item = expected_whatsapp_inbox_data?.statDetails?.find((item: any) => item?.userName === statDetails_whatsapp_item.userName);
                if (check_is_available_statDetails_whatsapp_item) {
                    const filtered_new_statDetails_whatsapp_inbox_data = expected_whatsapp_inbox_data?.statDetails?.filter((item: any) => item?.userName !== check_is_available_statDetails_whatsapp_item.userName);
                    const uniqueUserWiseStatDetails = [statDetails_whatsapp_item, ...filtered_new_statDetails_whatsapp_inbox_data]?.reduce((uniqueArray, currentItem) => {
                        if (!uniqueArray.some((item: any) => item.userName === currentItem.userName && item?.fromPhoneNumber === currentItem?.fromPhoneNumber)) {
                            uniqueArray.push(currentItem);
                        }
                        return uniqueArray;
                    }, []);
                    expected_whatsapp_inbox_data["statDetails"] = [...uniqueUserWiseStatDetails];
                } else {
                    expected_whatsapp_inbox_data["statDetails"] = [...statDetails_whatsapp_inbox, ...expected_whatsapp_inbox_data.statDetails];
                }
            }

            if (chatSummaryList_whatsapp_inbox?.length) {
                const chatSummaryList_whatsapp_item = chatSummaryList_whatsapp_inbox[0];
                const check_is_available_chatSummaryList_whatsapp_item = expected_whatsapp_inbox_data?.chatSummaryList?.find((item: any) => item?.ticketNo === chatSummaryList_whatsapp_item.ticketNo);
                if (check_is_available_chatSummaryList_whatsapp_item) {
                    const filtered_new_chatSummaryList_whatsapp_inbox_data = expected_whatsapp_inbox_data?.chatSummaryList?.filter((item: any) => item?.ticketNo !== check_is_available_chatSummaryList_whatsapp_item.ticketNo);
                    const uniqueUserWiseStatDetails = [chatSummaryList_whatsapp_item, ...filtered_new_chatSummaryList_whatsapp_inbox_data]?.reduce((uniqueArray, currentItem) => {
                        if (!uniqueArray.some((item: any) => item.ticketNo === currentItem.ticketNo)) {
                            uniqueArray.push(currentItem);
                        }
                        return uniqueArray;
                    }, []);
                    expected_whatsapp_inbox_data["chatSummaryList"] = [...uniqueUserWiseStatDetails];
                } else {
                    expected_whatsapp_inbox_data["chatSummaryList"] = [...chatSummaryList_whatsapp_inbox, ...expected_whatsapp_inbox_data.chatSummaryList];
                }

            }
            // console.log({
            //     // chatDetails_bot_inbox, main_bot_inbox,
            //     // statDetails_bot_inbox, chatSummaryList_bot_inbox,
            //     Signal_R_whatsapp_inbox: action.payload,
            //     expected_whatsapp_inbox_data,
            //     previous_whatsapp_inbox_data: state?.whatsAppInboxData
            // });

            return {
                ...state,
                whatsAppInboxData: {
                    ...expected_whatsapp_inbox_data,
                },
                is_main_whatsapp_inbox_updated: true,
            }
        case "SET_BOT_INBOX_MESSAGES_PAYLOAD":
            return {
                ...state,
                bot_inbox_messages_payload: action.payload
            }
        case Constants.SET_FIRST_TIME_BOT_DATA_INBOX:
            const { data: botData, key: botKey, nextKey: botNextKey } = action.payload;
            // console.log({ botData, botKey, botNextKey });

            // const expectedBotData: any = {
            //     ...state.botInboxData,
            // };
            // expectedBotData[botKey] = botData[botKey];
            // expectedBotData[botNextKey] = botData[botNextKey];
            return {
                ...state,
                botInboxData: {
                    // ...expectedBotData
                    ...botData
                }
            }
        case "TOGGLE_SIGNAL_UPDATE_BOT_INBOX":
            return {
                ...state,
                is_main_bot_inbox_updated: action.payload,
            }

        case Constants.APPEND_SIGNAL_R_DATA_FOR_AI_CHAT_INBOX:
            const { chatDetails: chatDetails_bot_inbox, main: main_bot_inbox, statDetails: statDetails_bot_inbox, chatSummaryList: chatSummaryList_bot_inbox }: any = action.payload;
            const expected_bot_inbox_data: any = {
                chatDetails: [], main: [], statDetails: [], chatSummaryList: [],
                ...state?.botInboxData
            };

            if (chatDetails_bot_inbox?.length) {
                expected_bot_inbox_data["chatDetails"] = [...chatDetails_bot_inbox, ...expected_bot_inbox_data.chatDetails];
            }

            if (main_bot_inbox?.length) {
                const main_bot_item = main_bot_inbox[0];
                const check_is_available_main_bot_item = expected_bot_inbox_data?.main?.find((item: any) => item?.userName === main_bot_item.userName && item?.userEmail === main_bot_item?.userEmail);
                if (check_is_available_main_bot_item) {
                    const main_new_bot_item = {
                        userWiseChatDetailsSubs: [],
                        ...check_is_available_main_bot_item,
                    };
                    const uniqueUserWiseChatDetailsSubs = [...main_bot_item?.userWiseChatDetailsSubs, ...check_is_available_main_bot_item?.userWiseChatDetailsSubs]?.reduce((uniqueArray, currentItem) => {
                        if (!uniqueArray.some((item: any) => item.ticketNo === currentItem.ticketNo)) {
                            uniqueArray.push(currentItem);
                        }
                        return uniqueArray;
                    }, []);
                    main_new_bot_item.userWiseChatDetailsSubs = uniqueUserWiseChatDetailsSubs;
                    const filtered_new_main_bot_inbox_data = expected_bot_inbox_data?.main?.filter((item: any) => item?.userName !== main_bot_item.userName && item?.userEmail !== main_bot_item?.userEmail);
                    expected_bot_inbox_data["main"] = [main_new_bot_item, ...filtered_new_main_bot_inbox_data];
                } else {
                    expected_bot_inbox_data["main"] = [...main_bot_inbox, ...expected_bot_inbox_data.main];
                }
            }

            if (statDetails_bot_inbox?.length) {
                const statDetails_bot_item = statDetails_bot_inbox[0];
                const check_is_available_statDetails_bot_item = expected_bot_inbox_data?.statDetails?.find((item: any) => item?.userName === statDetails_bot_item.userName);
                if (check_is_available_statDetails_bot_item) {
                    const filtered_new_statDetails_bot_inbox_data = expected_bot_inbox_data?.statDetails?.filter((item: any) => item?.userName !== check_is_available_statDetails_bot_item.userName);
                    const uniqueUserWiseStatDetails = [statDetails_bot_item, ...filtered_new_statDetails_bot_inbox_data]?.reduce((uniqueArray, currentItem) => {
                        if (!uniqueArray.some((item: any) => item.userName === currentItem.userName)) {
                            uniqueArray.push(currentItem);
                        }
                        return uniqueArray;
                    }, []);
                    expected_bot_inbox_data["statDetails"] = [...uniqueUserWiseStatDetails];
                } else {
                    expected_bot_inbox_data["statDetails"] = [...statDetails_bot_inbox, ...expected_bot_inbox_data.statDetails];
                }
            }

            if (chatSummaryList_bot_inbox?.length) {
                const chatSummaryList_bot_item = chatSummaryList_bot_inbox[0];
                const check_is_available_chatSummaryList_bot_item = expected_bot_inbox_data?.chatSummaryList?.find((item: any) => item?.ticketNo === chatSummaryList_bot_item.ticketNo);
                if (check_is_available_chatSummaryList_bot_item) {
                    const filtered_new_chatSummaryList_bot_inbox_data = expected_bot_inbox_data?.chatSummaryList?.filter((item: any) => item?.ticketNo !== check_is_available_chatSummaryList_bot_item.ticketNo);
                    const uniqueUserWiseStatDetails = [chatSummaryList_bot_item, ...filtered_new_chatSummaryList_bot_inbox_data]?.reduce((uniqueArray, currentItem) => {
                        if (!uniqueArray.some((item: any) => item.ticketNo === currentItem.ticketNo)) {
                            uniqueArray.push(currentItem);
                        }
                        return uniqueArray;
                    }, []);
                    expected_bot_inbox_data["chatSummaryList"] = [...uniqueUserWiseStatDetails];
                } else {
                    expected_bot_inbox_data["chatSummaryList"] = [...chatSummaryList_bot_inbox, ...expected_bot_inbox_data.chatSummaryList];
                }

            }
            // console.log({
            //     // chatDetails_bot_inbox, main_bot_inbox,
            //     // statDetails_bot_inbox, chatSummaryList_bot_inbox,
            //     Signal_R_bot_inbox: action.payload,
            //     expected_bot_inbox_data,
            //     previous_bot_inbox_data: state?.botInboxData
            // });

            return {
                ...state,
                botInboxData: {
                    ...expected_bot_inbox_data,
                },
                is_main_bot_inbox_updated: true,
            }
        case Constants.TOGGLE_PAGE_ROUTE:
            return { ...state, pageRoute: action.payload }
        case Constants.TOGGLE_NEW_CONVERSATION_DETAILS:
            return { ...state, isNewConversationDetails: action.payload }
        case Constants.TOGGLE_IS_IN_REPLYING_MODE:
            return { ...state, isInReplyingMode: action.payload }
        case Constants.TOGGLE_REPLYING_ON:
            return { ...state, isReplyingOn: action.payload }
        case Constants.SET_BOX_ID:
            return { ...state, wallBoxId: action.payload };
        case Constants.SET_CONVERSATION_DETAILS_PAYLOAD:
            return { ...state, conversation_details_payload: action.payload };
        case Constants.SET_CONVERSATION_DETAILS_DATA:
            return { ...state, conversationDetailsData: action.payload, isNewConversationDetails: true };
        case Constants.SET_CHART_PAGE:
            return { ...state, chart_page: action.payload };
        case Constants.SET_CHART_DATA:
            return { ...state, chart_data: action.payload };
        case Constants.SET_ERROR_MESSAGE:
            return { ...state, error_message: action.payload };
        case Constants.SET_SUCCESS_MESSAGE:
            return { ...state, success_message: action.payload };
        case Constants.SET_INFO_MESSAGE:
            return { ...state, info_message: action.payload };
        case Constants.SET_LOCATIONS:
            return { ...state, locations: action.payload };
        case Constants.SET_REQUEST_REVIEW_DATA:
            return { ...state, requestReviewData: action.payload };
        case Constants.RESET_REQUEST_REVIEW_DATA:
            return { ...state, requestReviewData: null };
        case Constants.SET_WALL_UNIFIED_INBOX:
            const { data: wallData, embedObject, key: wallKey, nextKey: wallNextKey } = action.payload;
            const {
                lastSerialNoGoogleAll,
                lastSerialNoGoogleReviews,
                lastSerialNoGoogleQandA,
                lastSerialNoFacebookAll,
                lastSerialNoFacebookReviews,
                lastSerialNoFacebookComments,
                lastSerialNoFacebookMentions,
                lastSerialNoCFReviews,
                lastSerialNoVTReviews,
                lastSerialNoInboxWall,
                lastSerialNoCFWall,
                lastSerialNoVTWall,
                lastSerialNoGoogleWall,
                lastSerialNoFacebookWall,
                lastSerialNo
            } = embedObject || {};
            let expectedWallData: any = {
                ...wallData,
            };
            // console.log({ embedObject, expectedWallData, wallData, lastSerialNo, wallKey, wallNextKey });
            if (lastSerialNo != undefined) {
                expectedWallData = {
                    ...state.wallUnifiedData,
                }
                if (lastSerialNo > 0) {
                    expectedWallData[wallKey] = state.wallUnifiedData[wallKey]?.length ? [...state.wallUnifiedData[wallKey], ...wallData[wallKey]] : wallData[wallKey];
                } else {
                    expectedWallData[wallKey] = wallData[wallKey];
                }
                expectedWallData[wallNextKey] = wallData[wallKey]?.length ? wallData[wallNextKey] : [];
                // console.log({ embedObject: expectedWallData[wallKey] });
            } else if (
                lastSerialNoGoogleAll ||
                lastSerialNoGoogleReviews ||
                lastSerialNoGoogleQandA ||
                lastSerialNoFacebookAll ||
                lastSerialNoFacebookReviews ||
                lastSerialNoFacebookComments ||
                lastSerialNoFacebookMentions ||
                lastSerialNoCFReviews ||
                lastSerialNoVTReviews ||
                lastSerialNoInboxWall ||
                lastSerialNoCFWall ||
                lastSerialNoVTWall ||
                lastSerialNoGoogleWall ||
                lastSerialNoFacebookWall
            ) {
                expectedWallData = {
                    ...state.wallUnifiedData,
                }
                expectedWallData[wallKey] = state.wallUnifiedData[wallKey]?.length ? [...state.wallUnifiedData[wallKey], ...wallData[wallKey]] : wallData[wallKey];
                expectedWallData[wallNextKey] = wallData[wallKey]?.length ? wallData[wallNextKey] : [];
                // expectedWallData[wallNextKey] = wallData[wallNextKey];
            }
            return {
                ...state,
                wallUnifiedData: {
                    ...expectedWallData
                },
                embedObject: embedObject,
                isWallUnifiedData: false
            };
        case Constants.ADD_TO_WALL:
            const { key: WallArrayKey, data: WallArrayData } = action.payload;
            const expectedArrayObject: any = {};
            expectedArrayObject["wallUnifiedData"] = state.wallUnifiedData;
            expectedArrayObject["wallUnifiedData"][WallArrayKey] = state.wallUnifiedData[WallArrayKey]?.length ? [WallArrayData, ...state.wallUnifiedData[WallArrayKey]] : [WallArrayData];

            // console.log({ unified: state.unifiedData, expectedArrayObject, WallArrayKey, WallArrayData });

            return {
                ...state,
                ...expectedArrayObject
            }
        case Constants.REMOVE_FROM_WALL:
            const { key: WallRemoveArrayKey, data: WallRemoveArrayData } = action.payload;
            // console.log({ WallRemoveArrayData, WallRemoveArrayKey });

            const expectedRemoveArrayObject: any = {};
            expectedRemoveArrayObject["wallUnifiedData"] = state.wallUnifiedData;
            expectedRemoveArrayObject["wallUnifiedData"][WallRemoveArrayKey] = state.wallUnifiedData[WallRemoveArrayKey]?.length ? state.wallUnifiedData[WallRemoveArrayKey].filter((item: any) => item.reviewId !== WallRemoveArrayData.reviewId) : [];
            return {
                ...state,
                ...expectedRemoveArrayObject
            }
        case "SET_UNIFIED_INBOX_PAYLOAD":
            return {
                ...state,
                unified_inbox_payload: action.payload
            }
        case "SET_WALL_UNIFIED_INBOX_PAYLOAD":
            return {
                ...state,
                wall_unified_inbox_payload: action.payload
            }
        case Constants.SET_UNIFIED_INBOX:
            const { data, searchPayload, key, nextKey } = action.payload;
            // const unifiedData = isFirstTime ?
            //     [...data]?.map((item: any, index: number) => {
            //         return {
            //             ...item,
            //             idx: index
            //         }
            //     }) : [...state.unifiedData, ...data]?.map((item: any, index: number) => {
            //         return {
            //             ...item,
            //             idx: index
            //         }
            //     });
            // console.log({ data, searchPayload, key, nextKey });

            const unifiedData = data || [];
            let expectedData: any = {
                ...unifiedData,
            };
            const {
                lastSerialNoCommentsAll,
                lastSerialNoInboxAll,
                lastSerialNoMentionsAll,
                lastSerialNoQandAAll,
                lastSerialNoReviewsAll,
                lastSerialNo: lastSerialNoInbox
            } = searchPayload || {};

            if (lastSerialNoInbox != undefined) {
                expectedData = {
                    ...state.unifiedData,
                };

                if (lastSerialNoInbox > 0) {
                    expectedData[key] = state.unifiedData[key]?.length ? [...state.unifiedData[key], ...unifiedData[key]] : unifiedData[key];
                } else {
                    expectedData[key] = unifiedData[key];
                }
                expectedData[nextKey] = unifiedData[key]?.length ? unifiedData[nextKey] : [];
            } else if (
                lastSerialNoInboxAll || lastSerialNoCommentsAll
                || lastSerialNoMentionsAll || lastSerialNoQandAAll ||
                lastSerialNoReviewsAll
            ) {
                expectedData = {
                    ...state.unifiedData,
                };
                expectedData[key] = state.unifiedData[key]?.length
                    ? [...state.unifiedData[key], ...unifiedData[key]]
                    : unifiedData[key];
                // expectedData[nextKey] = unifiedData[nextKey];
                expectedData[nextKey] = unifiedData[key]?.length ? unifiedData[nextKey] : [];

            }
            return {
                ...state,
                unifiedData: {
                    ...expectedData
                },
                searchPayload,
                isSpinning: false,
            };
        case Constants.APPEND_SIGNAL_R_DATA:
            // const unified_data = state?.unifiedData;
            // const wall_unified_data = state?.wallUnifiedData;
            const expected_unified_data: any = state?.unifiedData || {
                InboxAll_InboxAll: [],
                InboxQandA_InboxQandA: [],
                InboxReviews_InboxReviews: [],
                InboxComments_InboxComments: [],
                InboxMentions_InboxMentions: [],
            };

            const expected_wall_unified_data: any = state?.wallUnifiedData || {
                CFAll_All: [],
                VTAll_All: [],
                FacebookAll_All: [],
                FacebookReviews_Reviews: [],
                FacebookMentions_Mentions: [],
                FacebookComments_Comments: [],
                GoogleAll_All: [],
                GoogleReviews_Reviews: [],
                GoogleQandA_QandA: [],
            };
            const {
                // tag_type: signalRKey,
                signalRData
            } = action.payload;
            let expected_unified_keys = [];
            let expected_wall_keys = [];
            const is_already_have = expected_unified_data["InboxAll_InboxAll"]?.find((item: any) => item?.reviewId === signalRData?.reviewId);
            console.log({ is_already_have, expected_unified_data, signalRData });
            if (is_already_have) {
                return { ...state };
            }
            // unified -> "InboxAll_InboxAll", "InboxReviews_InboxReviews", 
            // "InboxComments_InboxComments", "InboxMentions_InboxMentions", 
            // "InboxQandA_InboxQandA"

            // facebook -> FacebookAll_All, FacebookAll_nextDate,
            // FacebookReviews_Reviews, FacebookReviews_ReviewsNextDate,
            // FacebookComments_Comments, FacebookComments_CommentsNextDate,
            // FacebookMentions_Mentions, FacebookMentions_MentionsNextDate,
            if (signalRData?.tag?.includes("review")) {
                expected_unified_data["InboxAll_InboxAll"] = expected_unified_data["InboxAll_InboxAll"]?.length ? [signalRData, ...expected_unified_data["InboxAll_InboxAll"]] : [signalRData];
                expected_unified_keys.push("InboxAll_InboxAll");
                expected_unified_data["InboxReviews_InboxReviews"] = expected_unified_data["InboxReviews_InboxReviews"]?.length ? [signalRData, ...expected_unified_data["InboxReviews_InboxReviews"]] : [signalRData];
                expected_unified_keys.push("InboxReviews_InboxReviews");
                if (signalRData?.provider === "facebook" || signalRData?.provider?.includes("customer") || signalRData?.provider?.includes("video")) {
                    if (signalRData?.provider?.includes("customer")) { // customerFeedBack wall
                        expected_wall_unified_data["CFAll_All"] = expected_wall_unified_data["CFAll_All"]?.length ? [signalRData, ...expected_wall_unified_data["CFAll_All"]] : [signalRData];
                        expected_wall_keys.push("CFAll_All");
                    } else if (signalRData?.provider === "videoTestimonial") { // videoTestimonial wall
                        expected_wall_unified_data["VTAll_All"] = expected_wall_unified_data["VTAll_All"]?.length ? [signalRData, ...expected_wall_unified_data["VTAll_All"]] : [signalRData];
                        expected_wall_keys.push("VTAll_All");
                    } else if (signalRData?.provider === "facebook") { // facebook wall
                        expected_wall_unified_data["FacebookAll_All"] = expected_wall_unified_data["FacebookAll_All"]?.length ? [signalRData, ...expected_wall_unified_data["FacebookAll_All"]] : [signalRData];
                        expected_wall_keys.push("FacebookAll_All");
                        expected_wall_unified_data["FacebookReviews_Reviews"] = expected_wall_unified_data["FacebookReviews_Reviews"]?.length ? [signalRData, ...expected_wall_unified_data["FacebookReviews_Reviews"]] : [signalRData];
                        expected_wall_keys.push("FacebookReviews_Reviews")
                    }
                } else if (signalRData?.provider === "google") { // google wall
                    // GoogleAll_All, GoogleAll_nextDate,
                    // GoogleReviews_Reviews, GoogleReviews_nextDate
                    expected_wall_unified_data["GoogleAll_All"] = expected_wall_unified_data["GoogleAll_All"]?.length ? [signalRData, ...expected_wall_unified_data["GoogleAll_All"]] : [signalRData];
                    expected_wall_keys.push("GoogleAll_All");
                    expected_wall_unified_data["GoogleReviews_Reviews"] = expected_wall_unified_data["GoogleReviews_Reviews"]?.length ? [signalRData, ...expected_wall_unified_data["GoogleReviews_Reviews"]] : [signalRData];
                    expected_wall_keys.push("GoogleReviews_Reviews");
                }
            } else if (signalRData?.tag === "comments") {
                expected_unified_data["InboxAll_InboxAll"] = expected_unified_data["InboxAll_InboxAll"]?.length ? [signalRData, ...expected_unified_data["InboxAll_InboxAll"]] : [signalRData];
                expected_unified_keys.push("InboxAll_InboxAll");
                expected_unified_data["InboxComments_InboxComments"] = expected_unified_data["InboxComments_InboxComments"]?.length ? [signalRData, ...expected_unified_data["InboxComments_InboxComments"]] : [signalRData];
                expected_unified_keys.push("InboxComments_InboxComments");
                if (signalRData?.provider === "facebook") { // facebook wall
                    expected_wall_unified_data["FacebookAll_All"] = expected_wall_unified_data["FacebookAll_All"]?.length ? [signalRData, ...expected_wall_unified_data["FacebookAll_All"]] : [signalRData];
                    expected_wall_keys.push("FacebookAll_All");
                    expected_wall_unified_data["FacebookComments_Comments"] = expected_wall_unified_data["FacebookComments_Comments"]?.length ? [signalRData, ...expected_wall_unified_data["FacebookComments_Comments"]] : [signalRData];
                    expected_wall_keys.push("FacebookComments_Comments");
                }
            } else if (signalRData?.tag === "mentions") {
                expected_unified_data["InboxAll_InboxAll"] = expected_unified_data["InboxAll_InboxAll"]?.length ? [signalRData, ...expected_unified_data["InboxAll_InboxAll"]] : [signalRData];
                expected_unified_keys.push("InboxAll_InboxAll");
                expected_unified_data["InboxMentions_InboxMentions"] = expected_unified_data["InboxMentions_InboxMentions"]?.length ? [signalRData, ...expected_unified_data["InboxMentions_InboxMentions"]] : [signalRData];
                expected_unified_keys.push("InboxMentions_InboxMentions");
                if (signalRData?.provider === "facebook") { // facebook wall
                    expected_wall_unified_data["FacebookAll_All"] = expected_wall_unified_data["FacebookAll_All"]?.length ? [signalRData, ...expected_wall_unified_data["FacebookAll_All"]] : [signalRData];
                    expected_wall_keys.push("FacebookAll_All");
                    expected_wall_unified_data["FacebookMentions_Mentions"] = expected_wall_unified_data["FacebookMentions_Mentions"]?.length ? [signalRData, ...expected_wall_unified_data["FacebookMentions_Mentions"]] : [signalRData];
                    expected_wall_keys.push("FacebookMentions_Mentions");
                }
            } else if (signalRData?.tag === "googleQandA") {
                expected_unified_data["InboxAll_InboxAll"] = expected_unified_data["InboxAll_InboxAll"]?.length ? [signalRData, ...expected_unified_data["InboxAll_InboxAll"]] : [signalRData];
                expected_unified_keys.push("InboxAll_InboxAll");
                expected_unified_data["InboxQandA_InboxQandA"] = expected_unified_data["InboxQandA_InboxQandA"]?.length ? [signalRData, ...expected_unified_data["InboxQandA_InboxQandA"]] : [signalRData];
                expected_unified_keys.push("InboxQandA_InboxQandA");
                // google wall 
                expected_wall_unified_data["GoogleAll_All"] = expected_wall_unified_data["GoogleAll_All"]?.length ? [signalRData, ...expected_wall_unified_data["GoogleAll_All"]] : [signalRData];
                expected_wall_unified_data["GoogleQandA_QandA"] = expected_wall_unified_data["GoogleQandA_QandA"]?.length ? [signalRData, ...expected_wall_unified_data["GoogleQandA_QandA"]] : [signalRData];
                expected_wall_keys.push("GoogleQandA_QandA");
            }
            console.log({
                expected_unified_data, signalRData,
                expected_unified_keys, expected_wall_keys
            });
            return {
                ...state,
                unifiedData: {
                    ...state.unifiedData,
                    ...expected_unified_data
                    // [action.payload.key]: action.payload.data
                },
                wallUnifiedData: {
                    ...state.wallUnifiedData,
                    ...expected_wall_unified_data,
                }
            }
        case Constants.RESET_UNIFIED_INBOX:
            return { ...state, unifiedData: null, wallUnifiedData: null, nextDate: [] };
        case Constants.RESET_WALL_UNIFIED_INBOX:
            return { ...state, isWallUnifiedData: true };
        // return { ...state, wallUnifiedData: null, embedObject: {}, isWallUnifiedData: true };
        case Constants.SET_UNIFIED_DETAILS_DATA:
            return { ...state, unifiedDetailsData: action.payload };
        // mmhk-region-end
        case Constants.SET_DASHBOARD_DATA:
            return { ...state, dashboardData: action.payload };
        case "SET_SPINNER":
            return { ...state, isSpinning: true };
        case "REMOVE_SPINNER":
            return { ...state, isSpinning: false };
        case "FILTER_BY_SEARCH_TEXT":
            return { ...state, searchText: action.payload };
        case "FILTER_BY_WALL_SEARCH_TEXT":
            return { ...state, searchWallText: action.payload };
        case "REMOVE_SEARCH_TEXT":
            return { ...state, searchText: '' };
        case "FILTER_BY_SEARCH_FROM_TO_DATE":
            return { ...state, searchStartDate: action.payload.fromDate, searchEndDate: action.payload.toDate };
        case "REMOVE_SEARCH_FROM_TO_DATE":
            return { ...state, searchStartDate: '', searchEndDate: '' };
        case "FILTER_BY_READ_STATUS":
            return { ...state, searchFilterStatus: action.payload };
        case "REMOVE_FILTER_BY_READ_STATUS":
            return { ...state, searchFilterStatus: '' };
        case "SET_SHOWING_SAVE_BUTTON":
            return { ...state, showing_save_button: action.payload };
        case "TOGGLE_IS_FIRST_TIME_UNIFIED_INBOX":
            return {
                ...state,
                is_first_time_unified_inbox: action.payload,
            };
        case "TOGGLE_IS_FIRST_TIME_WALL_UNIFIED_INBOX":
            return {
                ...state,
                is_first_time_wall_unified_inbox: action.payload,
            };
        case Constants.LOGOUT:
            return {
                ...state,
                twitterMentions: [],
                dashboardData: [],
                // mmhk-region-start
                isSpinning: false,
                wallBoxId: null,
                searchText: '',
                searchWallText: '',
                searchStartDate: '',
                searchEndDate: '',
                searchFilterStatus: '',
                integration_type: '',
                nextDate: [],
                unifiedData: null,
                wallUnifiedData: null,
                isWallUnifiedData: false,
                unified_inbox_payload: null,
                wall_unified_inbox_payload: null,
                is_first_time_unified_inbox: false,
                is_first_time_wall_unified_inbox: false,
                embedObject: {},
                isNewConversationDetails: false,
                unifiedDetailsData: {},
                botInboxData: null,
                is_empty_whatsapp_inbox: false,
                is_empty_ai_bot_inbox: false,
                is_main_whatsapp_inbox_updated: false,
                is_main_bot_inbox_updated: false,
                bot_inbox_messages_payload: null,
                whatsapp_inbox_messages_payload: null,
                whats_app_sender_state: null,
                whatsapp_replying_text: null,
                whatsAppInboxData: null,
                isCountDownOn: false,
                conversationDetailsData: null,
                conversation_details_payload: null,
                requestReviewData: null,
                locations: [],
                success_message: null,
                chart_data: [],
                chart_page: "All",
                isReplyingOn: false,
                isInReplyingMode: false,
                pageRoute: null,
            };
        default:
            return state;
    }
}

export default unifiedReducer;