import React, { useEffect, useState } from 'react';
import Button from '../button/button-container';
import { DebounceInput } from 'react-debounce-input';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import moment from 'moment';
import search from '../../assets/icons/search-icon.svg';
// import { Spinner } from 'react-bootstrap';
// import { setErrorMessage, setInfoMessage } from '../../dashboard/reducer';
// import { convertImageToBase64 } from '../../utils/convertingFunction';
import Dialog from '../dialogs/dialog';
import { getImage } from '../../dashboard/image_service';
import CampaignForm from './PromotionComponents/Campaign/CampaignForm';
import Campaign from './PromotionComponents/Campaign/Campaign';
import { export_phone_number_summary_CSV } from '../../utils/serviceXLSX';
import { getFirstTimeTemplateList, insertCampaignData, setCampaignDetailsPayload, setCampaignText, setSearchedCampaignList } from './reducer';
import { setErrorMessage, setSuccessMessage } from '../../dashboard/reducer';
import UnPermission from '../../pages/un-permission/UnPermission';
// import './Promotions.scss';
const PromotionsCampaign = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const route_path = window.location.pathname;
  // const query_string = window.location.search;
  // const path = query_string.replace('?', '')?.replace('&', "=");
  // const path_array = path.split("=");

  // Campaign related management
  const { template_list: Templates, campaign_list: Campaigns, campaign_details_payload }: any = useSelector((state: RootState) => state?.promotionsState);
  
  useEffect(() => {
    if (!Templates?.length) {
        dispatch(getFirstTimeTemplateList());
    }
  }, [dispatch, Templates?.length]);
  
  const [showAddCampaign, setShowAddCampaign] = useState<boolean>(false);
  const [showEditCampaign, setShowEditCampaign] = useState<any>(null);
  const [showDeleteCampaign, setShowDeleteCampaign] = useState<any>(false);

  const handleCreateNewCampaign = (login_user_data?: any) => {
    const { campaignId, selected_template, selected_date, selected_time, selected_time_zone, campaignName, campaign_list }: any = campaign_details_payload;
    // Combine date and time strings into a single date string
    const combinedDateTimeString = selected_date.split('T')[0] + ' ' + selected_time;
    // Create a new Date object from the combined date and time string
    const combinedDateTime = new Date(combinedDateTimeString)?.toISOString();
    // const combinedDateTime = new Date(combinedDateTimeString)?.toISOString()?.replace('Z', '');
    // console.log({ 
    //   selected_date, selected_time, 
    //   selected_time_zone, 
    //   combinedDateTimeString,
    //   combinedDateTime
    // });
    // return;
    if (campaignId) {
      dispatch(setErrorMessage("Edition campaign coming soon!"));
      return;
    }
    const data = {
      ...login_user_data,
      campaignName,
      templateId: selected_template?.templateId,
      // scheduledOn: combinedDateTime,
      scheduledOn: combinedDateTimeString,
      timezone: selected_time_zone,
    }
    // this scheduleOn is always for 
    // both selected_date and selected_time while editing a campaign
    // console.log({campaign_details_payload, data});
    dispatch(insertCampaignData({
      insert_campaign_payload: data,
      campaign_details: campaign_details_payload
    }));
    // dispatch(setSuccessMessage("Created Successfully"));
    setTimeout(() => {
      setShowAddCampaign(false);
    }, 1500);
  };

  const handleUpdateCampaign = (data: any, file?: any) => {
      // dispatch(manageBioPageCampaigns(data, file));
      setShowEditCampaign(null);
      // dispatch(setSuccessMessage("Updated Successfully"));
  }

  const handleRemoveCampaign = (item: any) => {
      const data = {
          clapupUserId: sessionStorage.getItem('cuid'),
          workSpaceId: sessionStorage.getItem('wuid'),
          roleId: sessionStorage.getItem('roleId'),
          id: item?.id,
          contentId: item?.contentId,
          displayText: "",
          linkUrl: "",
          logoUrl: "",
          CampaignText: "",
          startDate: new Date().toISOString().split('T')[0],
          endDate: new Date().toISOString().split('T')[0],
          // I want to use moment for 12 hours format
          startTime: moment().format('hh:mm:ss A'),
          endTime: moment().format('hh:mm:ss A'),
          mode: "delete",
      }
      // console.log({ data });
      
      // dispatch(manageBioPageCampaigns(data));
      setShowDeleteCampaign(false);
      // dispatch(setSuccessMessage("Deleted Successfully"));
  }

  React.useEffect(() => {
      // if(!Campaign?.length){
      //     dispatch(getBioPageSettings());
      // }
      setShowAddCampaign(false);
      setShowEditCampaign(null);
  }, [dispatch, Campaigns?.length]);

  const downloadTemplate = async () => {
    const columnHeadings = [
        // 'Sl No',
        'Phone_Number'
    ];
    let template_data: any[] =  [1]?.reduce((acc: any, item: any) => { 
                                    acc.push({
                                        // 'Sl No': item + "",
                                        'Phone_Number': "",
                                    });
                                    return acc;
                                }, []);
    export_phone_number_summary_CSV({data: template_data, columnHeadings, title: "Template", excel_file_name: "template"})
  };

  const handleSearch = (value: any) => {
    console.log({ value });
    dispatch(setCampaignText(value));
    if (value) {
      const searched_campaign_list = Campaigns?.filter((item: any) => item?.campaignName?.toLowerCase().includes(value?.toLowerCase()));
      dispatch(setSearchedCampaignList(searched_campaign_list));
    } else {
      dispatch(setSearchedCampaignList([]));
    }
  };

  const {userPermissions} = useSelector((state: any) => state?.login);
  const [isPermissible, setIsPermissible] = React.useState(true);
  React.useEffect(() => {
      const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "Campaign") : null;
      if( permission?.value === 0){
          setIsPermissible(false);
      }
  }, [userPermissions?.length]);

  const { whats_app_connected_users }: any = useSelector((state: RootState) => state?.login);
  const handleAddNewCampaign = () => {
    if (whats_app_connected_users?.length) {
      setShowAddCampaign(true);
      return;
    }
    dispatch(setErrorMessage("No mobile no. is registered with this workspace. Please register!"));
  }
  return (
    <React.Fragment>
      {
        isPermissible
          ?
          <>
            <Dialog
              open={showAddCampaign}
              parentCallback={(flag?: any) => {
                setShowAddCampaign(flag);
                dispatch(setCampaignDetailsPayload({}));
              }}
              classes='dialog-container-commerce-form'
              // header='Add Campaign'
              closeIcon={getImage("form-close-icon")}
              footer={false}
            >
              <CampaignForm
                onCancel={() => {
                  setShowAddCampaign(false);
                  dispatch(setCampaignDetailsPayload({}));
                }}
                onParentCallback={(data?: any) => {
                  handleCreateNewCampaign(data);
                }}
              />
            </Dialog>
            {
              // showAddCampaign
              // ?   <Dialog
              //         open={showAddCampaign}
              //         parentCallback={(flag?: any) => setShowAddCampaign(flag)}
              //         classes='dialog-container-link-form'
              //         // header='Add Campaign'
              //         footer={false}
              //     >
              //         <CampaignForm 
              //             onCancel={() => setShowAddCampaign(false)}
              //             onParentCallback={(data: any, file?: any) => {
              //                 handleCreateNewCampaign(data, file);
              //             }}      
              //         />
              //     </Dialog>
              // :
              showEditCampaign !== null
                ? <Dialog
                  open={showEditCampaign}
                  parentCallback={(flag?: any) => setShowEditCampaign(flag)}
                  classes='dialog-container-link-form'
                  header='Edit Campaign'
                  footer={false}
                >
                  <CampaignForm
                    onCancel={() => setShowEditCampaign(false)}
                    onParentCallback={(data: any, file?: any) => {
                      // console.log({ data });
                      handleUpdateCampaign(data, file);
                    }}
                  />
                </Dialog>
                : showDeleteCampaign
                  ? <Dialog
                    open={showDeleteCampaign}
                    onCancel={() => setShowDeleteCampaign(false)}
                    parentCallback={(flag?: any) => {
                      handleRemoveCampaign(flag);
                    }}
                    classes='dialog-container-content'
                    header='Delete Campaign?'
                    warning_dialog_content={"By clicking delete button, the Campaign will be deleted, and will not be visible in biopage."}
                    footer={true}
                    left_button_text='No, Cancel'
                    right_button_text='Yes, Continue'
                  // hover={true}
                  />
                  : null
            }
          
            {/* Campaign */}
            <div className="commerce-section">
              <div className={"content-section"}>
                <div className="content-search-section position-sticky top-0 p-2">
                  <div className="search-wrap">
                    <DebounceInput
                      minLength={0}
                      debounceTimeout={500}
                      onChange={(e: any) => handleSearch(e.target.value)}
                      type="search"
                      placeholder={"Search for Templates"}
                    />
                    {/* <input onChange={(e: any) => handleSearch(e.target.value)} type="search" alt="" placeholder="Search" /> */}
                    <img className="search-icon" src={search} alt="search" />
                  </div>
                  <div className='content-buttons'>
                    <p className='template-download-btn' onClick={() => downloadTemplate()}>Download Template</p>
                    <Button
                      classes="mx-2 secondary-btn create-btn"
                      children={<p>New Campaign +</p>}
                      onClick={() => {
                        // dispatch(toggleCreateFolderModal(true));
                        // setShowAddCampaign(true);
                        handleAddNewCampaign();
                      }}
                    />
                  </div>
                </div>
                <Campaign />
              </div>
            </div>
          </>
          : <UnPermission/>
      }
    </React.Fragment>
  )
}

export default PromotionsCampaign;
