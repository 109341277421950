import React, {useState, useEffect, useRef } from 'react';
import { Carousel, Spinner } from 'react-bootstrap';
import "./ShowFiles.scss";
import { getFileSizeByURL } from '../../../../../../utils/mediaService';
import { getImage } from '../../../../../../dashboard/image_service';
import Dialog from '../../../../../dialogs/dialog';
import ShowFilePreview from './ShowFilePreview';
const ShowFiles = ({ data }: any) => {
    // const query_string = window.location.search;
    const slider: any = useRef<any>(null);
    const [post_content_index, setPostContentIndex] = useState(0);
    const [contents, setContents] = useState<any>([]);
    const [showPreview, setShowPreview] = useState<any>(false);
    const getFileInfo = async(item: any) => { 
        try {
            // Extract the file name from the URL
            const url = item?.attachmentUrl || "";
            // Create a new URL object
            const urlObj = new URL(url);
            // Get the pathname from the URL
            const pathname = urlObj.pathname;
            // Extract the file extension by splitting the pathname and taking the last part
            // const fileType = pathname.split('.').pop().toUpperCase();
            const fileName = url.substring(url.lastIndexOf('/') + 1);
            // const fileName = pathname.split('.').pop().toUpperCase();
    
            // Extract the file extension and convert it to uppercase for fileSize
            const fileType = pathname.split('.')?.pop()?.toUpperCase();
            const fileSize = await getFileSizeByURL(url);
            // console.log("file_info: ", { name: fileName, url, type: fileType, size: fileSize});
            return {
                name: "FB_"+item?.attachmentType+"."+fileType,
                type: fileType,
                size: fileSize,
                url: url,
                attachmentType: item?.attachmentType,
                ...item
            };
        } catch(err: any) {
            console.log(err);
        }
    };

    useEffect(() => {
        try {
            if (!contents?.length) {
                let content_data: any[] = [];
                if (data?.length) {
                    // content_data = JSON.parse(data);
                    content_data = data;
                }
                // else {
                //     content_data =
                //     //     // [
                //     //     //     {
                //     //     //         "id": 2,
                //     //     //         "attachmentMsg": "Photos from 3Potters's post",
                //     //     //         "attachmentUrl": "https://www.facebook.com/988181489979526/videos/848059547243069",
                //     //     //         "attachmentType": "video"
                //     //     //     },
                //     //     //     {
                //     //     //         "id": 3,
                //     //     //         "attachmentMsg": "Photos from 3Potters's post",
                //     //     //         "attachmentUrl": "https://www.facebook.com/photo.php?fbid=988169989980676&set=a.496015195862827&type=3",
                //     //     //         "attachmentType": "photo"
                //     //     //     },
                //     //     //     {
                //     //     //         "id": 4,
                //     //     //         "attachmentMsg": "Photos from 3Potters's post",
                //     //     //         "attachmentUrl": "https://www.facebook.com/988181489979526/videos/496548736390974",
                //     //     //         "attachmentType": "video"
                //     //     //     },
                //     //     //     {
                //     //     //         "id": 5,
                //     //     //         "attachmentMsg": "Photos from 3Potters's post",
                //     //     //         "attachmentUrl": "https://www.facebook.com/988181489979526/videos/1919055745200170",
                //     //     //         "attachmentType": "video"
                //     //     //     },
                //     //     //     {
                //     //     //         "id": 6,
                //     //     //         "attachmentMsg": "Photos from 3Potters's post",
                //     //     //         "attachmentUrl": "https://www.facebook.com/988181489979526/videos/1212714146550225",
                //     //     //         "attachmentType": "video"
                //     //     //     }
                //     //     // ]
                //         [
                //             {
                //                 attachmentUrl: "https://images.clapup.io/website/offer.jpg",
                //                 attachmentType: "image"
                //             },
                //             {
                //                 attachmentUrl: "https://oss-ycloud-publicread.oss-ap-southeast-1.aliyuncs.com/api-docs/sample/sample.jpg",
                //                 attachmentType: "image"
                //             },
                //             {
                //                 attachmentUrl: "https://www.w3schools.com/tags/movie.mp4",
                //                 attachmentType: "video"
                //             }
                //         ];
                // }
        
                if (content_data?.length) {
                    // Fetch file information for all URLs
                    console.log({ content_data });
                    Promise.all(content_data?.map(item => getFileInfo(item)))
                    .then(results => {
                            // Filter out any null results (in case of errors)
                            const validResults = results.filter(result => result !== null);
                            console.log({ content_data, validResults });
                            setContents(validResults);
                        })
                        .catch(err => {
                            console.log('Error fetching file information:', err);
                        });
                }
            }
        } catch (err) {
            console.log('Error fetching file information:', err);
        }
        

    }, [data?.length, contents?.length]);

    // console.log({ contents });
    return (
        <React.Fragment>
            <Dialog
                open={showPreview}
                parentCallback={(flag?: any) => setShowPreview(flag)}
                wrapper_styles={{padding: 0}}
                styles={{padding: 0, width: "40%"}}
                footer={false}
            >
                <ShowFilePreview
                    contents={contents}
                    post_content_index={post_content_index}
                    handleParentCallBack={() => {setShowPreview(false)}}
                />
            </Dialog>
            <div className={`non-preview-files-carousel-container ${!contents?.length ? "d-none": ""}`}>
                {
                    contents?.length > 1
                    ?
                        <button
                            className=''
                            type='button'
                            // disabled={!contents?.length}
                            onClick={() => { 
                                // playVideo();
                                // dispatch(setShowingSaveButton(true));
                                // console.log("click prev");
                                slider.current.prev();
                            }}
                        >
                            <img src={getImage("mini_carousel_left_button")} alt="prev" />
                        </button>
                    : null
                }

                <Carousel
                    className='w-100'
                    ref={slider}
                    interval={null}
                    indicators={false}
                    nextIcon={false}
                    prevIcon={false}
                    // activeIndex={post_content_index}
                    defaultActiveIndex={post_content_index}
                    onSlide={(e: any) => {
                        console.log({ index: e });
                        setPostContentIndex(e);
                        // dispatch(setDefaultContentIdx(e));
                        
                    }}
                >
                  {
                    contents?.length
                    ? contents?.map((item: any, index: number) => {
                      return (
                        <Carousel.Item key={index} className='w-100'>
                            <div className='file-container'>
                                <div className='left-part'>
                                    {
                                        item?.type
                                        ? <img className='file-image' src={getImage(`${item?.type}`)} alt='file-icon' />
                                        : null
                                    }
                                    <div className='header-title'>
                                        <p>{item?.name}</p>
                                        <div 
                                            className='file-type-size'
                                        >
                                            <p style={{margin: 0, padding: 0}}>{item?.type}</p>
                                            {
                                                item?.size
                                                ? <>
                                                    <p style={{
                                                        background: "#000000",
                                                        borderRadius: '50%',
                                                        padding: '3px',
                                                        margin: 0
                                                    }}></p>
                                                    <p style={{margin: 0, padding: 0}}>{item?.size || "0.00 KB"}</p>
                                                </>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='right-part'>
                                    <img 
                                        className='preview'
                                        onClick={() => setShowPreview(true)}
                                        src={getImage("mini_carousel_preview_button")}
                                        alt="download"
                                    />
                                </div>
                            </div>
                        </Carousel.Item>
                      );
                    })
                    : null
                  }
                </Carousel>

                {
                    contents?.length > 1
                    ?
                        <button
                            className=' carousel-bt'
                            type='button'
                            // disabled={!contents?.length}
                            onClick={() => {
                                // playVideo();
                                // dispatch(setShowingSaveButton(true));
                                // console.log("click next");
                                slider?.current?.next();
                            }}
                        >
                            <img src={getImage("mini_carousel_right_button")} alt="next" />
                        </button>
                    : null
                }
            </div>
        </React.Fragment>
    );
}

export default ShowFiles;