import React, { useEffect, useState } from 'react';
// import { Button, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import { RootState } from '../../../../../../../store';
import { toggleTemplateEdition } from '../../../../../reducer';
import { setErrorMessage } from '../../../../../../../dashboard/reducer';
import { checkVariable, normalizeString } from '../../../../../../../utils/convertingFunction';
// import 'quill-emoji/dist/quill-emoji.css';
// import Quill from 'quill';
// Define the toolbar options
const toolbarOptions = [
    // Show only headline options
    // [{ 'header': [1, 2, 3, false] }],

    // Basic text formatting
    [
        'bold', 'italic', 'strike',
        // 'underline', 
    ],

    // Include emoji toolbar option
    // not working
    // [{ emoji: ["😀", "😃", "😄", "😁", "😆", "😅", "🤣", "😂", "🙂", "🙃"] }],

    // Text color and background
    // [{ 'color': [] }, { 'background': [] }],

    // Insert link and image
    // ['link', 'image'], 

    // Clear formatting
    // ['clean'],

    // Advanced List
    // [{ 'list': 'ordered'}, { 'list': 'bullet' }],

    // Text alignment
    // [{ 'align': [] }],

    // Text direction
    // [{ 'direction': 'rtl' }],

    // Text size
    // [{ 'size': ['small', false, 'large', 'huge'] }],

    // Text font
    // [{ 'font': [] }],
];

const BodyForm: React.FC<any> = () => {
    const dispatch = useDispatch();
    const { toggle_template_edition }: any = useSelector((state: RootState) => state?.promotionsState);
    const [value, setValue] = useState<any>('');

    useEffect(() => {
        if (!value && toggle_template_edition?.templateBody) {
            setValue(toggle_template_edition?.templateBody || "");
        }
    }, [toggle_template_edition?.templateBody]);
    
    // Debounce function
    const debounce = <F extends (...args: any[]) => any>(func: F, delay: number) => {
        let timer: NodeJS.Timeout;
        return (...args: Parameters<F>): void => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    // Debounced handleChange function with 300ms delay
    const debouncedHandleChange = debounce((newValue: string) => {
        // console.log({newValue});
        const regex = /\{\{[^}]+\}\}/g;
        const matches = newValue?.match(regex);
        
        // checking proper variable or not
        if (matches?.length) {
            const is_not_variable_exist = matches?.find((match: any) => !checkVariable(match?.replace("{{", "").replace("}}", "")));
            // console.log({ is_not_variable_exist, matches });
            if (is_not_variable_exist) {
                dispatch(setErrorMessage("Invalid variable!"));
                return;
            }
        }
        const body = normalizeString(newValue);
        // console.log({ body, newValue });
        // setValue(body);
        setValue(newValue);
        // handleChange(newValue);
    }, 300);

    const handleValueChange = (newValue: string) => {
        debouncedHandleChange(newValue);
    };

    useEffect(() => { 
        if (value) {
            handleChange(value);
        }
    }, [value]);

    const handleChange = (new_value: string) => {
        // console.log({ data });
        const regex = /\{\{[^}]+\}\}/g;
        const matches = value?.match(regex);

        if (matches?.length > 10) {
            dispatch(setErrorMessage("Max 10 variables are allowed."));
            return;
        }
        if (new_value?.length > 1024) {
            // dispatch(setErrorMessage("Body text should not more than 1024 characters!"));
            return;
        }
        const data = {
            ...toggle_template_edition,
            templateBody: new_value
        };
        dispatch(toggleTemplateEdition(data));
    }

    return (
        <React.Fragment>
            <div className='body-form-container'>
                <div className='title-content w-100'>
                    <div className='title'>Body</div>
                    <div className='optional'>{"(Required)"}</div>
                    <div className='optional w-100'>{"(Max 1024 Characters)"}</div>
                    <div className="optional w-100 text-end">{(value?.length - 1)}</div>
                </div>
                <div className="body-form">
                    <ReactQuill
                        className="custom-quill-editor"
                        style={{width: '100%'}}
                        theme="snow"
                        value={value}
                        placeholder='Enter your body text'
                        modules={{
                            toolbar: toolbarOptions,
                        }}
                        onChange={handleValueChange}
                    />
                </div>
            </div>
        </React.Fragment>
    )
};

export default BodyForm;
