import React, { useEffect } from 'react';
import { Table, Select } from 'antd';
import './UserTable.scss';

const UserTable = ({ selectedRows, setSelectedRows, is_bordered = true, loading, table_data, roles, updateRole, updateStatus, deleteFromTable, table_columns, height, width, rowSelection_data}: any) => {
    // const [currentRows, setCurrentRows] = React.useState<any>([]);
    const [isAllRowSelected, setIsAllRowSelect] = React.useState(false);
        
    const handleSelectRow = (selectedRowKey: any, flag: boolean) => {
        console.log({selectedRowKey, flag, selectedRows});
        if (flag) {
            const newSelectedRows = [...selectedRows, selectedRowKey];
            setSelectedRows(newSelectedRows);
        } else {
            const newSelectedRows = selectedRows.filter((row: any) => row !== selectedRowKey);
            setSelectedRows(newSelectedRows);
        }
    }

    const handleSelectAllRow = (flag: boolean) => {
        // console.log({flag});
        setIsAllRowSelect(flag);
        if (flag) {
            const newSelectedRows = table_data.map((row: any) => row.userId);
            setSelectedRows(newSelectedRows);
        } else {
            setSelectedRows([]);
        }
    }

    return (
        <React.Fragment>
            {/* <div className='table-responsive'>
                <table className="table table-hover user-table w-100">
                    <thead>
                    <tr className="table_header border-bottom">
                        <th>
                            <div className='d-flex gap-2'>
                                <span className='check-btn'>
                                    <input 
                                        className='form-check-input' 
                                        checked={isAllRowSelected}
                                        onChange={(e) => {
                                            handleSelectAllRow(e.target.checked);
                                        }}
                                        type="checkbox" 
                                    />
                                </span>
                                <span className='mx-4 table-header-title'>Name</span>
                            </div>
                        </th>
                        <th>
                            <div>
                                <span className='mx-4 table-header-title'>Email</span>
                            </div>
                        </th>
                        <th>
                            <div>
                                <span className='mx-4 table-header-title'>Role</span>
                            </div>
                        </th>
                        <th>
                            <div>
                                <span className='mx-4 table-header-title'>Status</span>
                            </div>
                        </th>
                        <th>
                            <div>
                                <span className='mx-4 table-header-title'>Enable/Disable</span>
                            </div>
                        </th>
                        <th className='text-center'>
                            <div className='last-header-title'>
                                <span className='mx-4 table-header-title'>Action</span>
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        table_data?.map((record: any, idx: number) => <tr key={idx} className="table_body border-bottom">
                            <td>
                                <div className='d-flex gap-2'>
                                    <span className='check-btn'>
                                        <input 
                                            checked={selectedRows?.includes(record?.userId)}
                                            onChange={(e) => {
                                                handleSelectRow(record?.userId, e.target.checked);
                                            }}
                                            className='form-check-input' 
                                            type="checkbox" 
                                        />
                                    </span>
                                    <span className='mx-4 table-body-title'>{record?.name}</span>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <span className='mx-4 table-body-title'>{record?.email}</span>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <span className='mx-4 table-body-title'>
                                        <Select
                                            defaultValue={record?.role === "Super Admin" ? "Super Admin" : record?.role}
                                            style={{ width: 120, fontSize: 10 }}
                                            onChange={(value: any) => {
                                                updateRole({userId: record?.userId, roleId: value, workspaceId: sessionStorage.getItem('wuid')});
                                            }}
                                            placeholder="Select"
                                            allowClear
                                            disabled={record?.role === "Super Admin" || false}
                                        >
                                            {
                                                roles?.map((item: any, idx: number) => {
                                                    return (
                                                        <Select.Option key={idx} value={item?.id}>{item.roleName}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <span className='mx-4 table-body-title'>
                                        {record?.status}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <span className='mx-4 table-body-title'>
                                        <Select
                                            defaultValue={record?.role === "Super Admin" ? "Enabled" : record?.status === "Active" ? "Enabled" : "Disabled"}
                                            style={{ width: 120, fontSize: 10 }}
                                            onChange={(value: any) => {
                                                console.log('value', value);
                                                updateStatus({userId: record?.userId, status: value, workspaceId: sessionStorage.getItem('wuid')});
                                            }}
                                            placeholder="Select"
                                            allowClear
                                            disabled={record?.role === "Super Admin" || false}
                                        >
                                            <Select.Option key={"enable"} value="Enable"><span style={{height: 3, width: 3, padding: "0 6px", margin: "2px 8px 2px 5px"}} className='border border-success rounded-circle'></span> Enable</Select.Option>
                                            <Select.Option key={"disable"} value="Disable"><span style={{height: 3, width: 3, padding: "0 6px", margin: "2px 8px 2px 5px"}} className='border border-danger rounded-circle'></span> Disable</Select.Option>
                                        </Select>
                                    </span>
                                </div>
                            </td>
                            <td className='text-center'>
                                <div className='last-body-title'>
                                    <span className='mx-4 table-body-title'
                                     onClick={() => {
                                            deleteFromTable({userId: record?.userId, status: "Delete", workspaceId: sessionStorage.getItem('wuid')});
                                        }}
                                    >
                                        {
                                            record?.role === "Super Admin"
                                            ? null
                                            : <img 
                                                width={11} 
                                                src={delete_icon} 
                                                className="img-fluid" 
                                                style={{cursor: 'pointer'}} 
                                            />
                                        }
                                    </span>
                                </div>
                            </td>
                        </tr>
                        )
                    }
                    </tbody>
                </table>
            </div> */}
            <div className='lg-device user-table'>
                <Table
                    loading={loading}
                    // className="table-style"
                    columns={table_columns}
                    dataSource={table_data}
                    pagination={false}
                    bordered={false}
                    // rowKey={(record: any) => record?.userId}
                    // rowSelection={rowSelection_data || {
                    //     type: 'checkbox',
                    //     onChange: (selectedRowKeys: any, selectedRows: any) => {
                    //         console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                    //     },
                    // }}
                    // rowClassName={() => "rowClassName1"}
                    // scroll={{ x: width || 540 }}
                    // style={{height: height}}
                    scroll={{ x: width || 550, y: height || 380 }}
                />
            </div>
            <div className='sm-device user-table'>
                <Table
                    loading={loading}
                    className="table-style"
                    columns={table_columns}
                    dataSource={table_data}
                    pagination={false}
                    bordered={false}
                    // rowKey={(record: any) => record?.userId}
                    // rowSelection={rowSelection_data || {
                    //     type: 'checkbox',
                    //     onChange: (selectedRowKeys: any, selectedRows: any) => {
                    //         console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                    //     },
                    // }}
                    // rowClassName={() => "rowClassName1"}
                    scroll={{ x: 1024 }}
                    // style={{height: height}}
                    // scroll={{ x: 1024 }}
                />
            </div>
        </React.Fragment>
    );
};

export default UserTable;