import {
    Fragment, useEffect,
    // useState
} from "react";
import Header from "./header/header";
import Navigation from "./global-menu/menu";
import './layout.scss';
// import LogoutOnWindowClose from "../../LogoutOnWindowClose";
import { useDispatch } from "react-redux";
import {
    closeMobileDrawer,
    // generateAccessToken
} from "../../profile/reducer";
import { getProfileInfo } from "../../pages/profile-management/profileManageReducer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Dialog from "../dialogs/dialog";
import {
    appendedSignalR_Data,
    appendedSignalR_DataForAI_app_Inbox,
    appendedSignalR_DataForWhatsapp_Inbox,
    changePage,
    getChartData,
    setBotInboxMessagePayload, setWhatsappInboxMessagePayload,
    triggerGetBotData, triggerGetWhatsAppInboxData,
    setUnifiedInboxPayload, setWallUnifiedInboxPayload,
    triggerUnified, triggerWallUnified,
    toggleReplyingOn, setWhatsAppReplyingText,
    triggerDashboard,
    triggerConversationDetails,
    setConversationDetailsPayload,
    setIsCountDownOn,
} from "../../dashboard/reducer";
import {
    getFacebookConnectionPages,
    getWorkspaceLocations,
    getWorkspaceSettings,
    getConnectedMapGoogleLocations,
    getGoogleConnectionLocations,
    setGoogleLocationPayload,
    
} from "../../pages/workspace-management/workSpaceReducer";
import {
    getBioPageAnnouncements, getBioPageLinks, getBioPageSettings, getBioPageTemplates,
    // getEmbedImageRecords, getEmbedVideoRecords
} from "../../pages/biopage-management/biopageReducer";

import { getBioPageFolders, getFirstTimeFolders } from "../MediaContents/mediaReducer";
import { appendTemplateListBySignalR, getFirstTimeCampaignList, getFirstTimeTemplateList, getTimeZones } from "../Promotions/reducer";
import { findWorkspaceList, getRoles } from "./header/headerReducer";
import { RootState } from "../../store";
import { getUserPermissions, triggerGetIntegrationConnections, getWhatsAppConnectedUsers, triggerLogout } from "../../signup-login/loginReducer";
import { getBotDetailsSettings } from "../../pages/bot-management/botReducer";
import {
    getUsers, toggleIsReadyToGetUsers,
    convertToClapUpUser
} from "../../pages/user-management/userManagementReducer";
import connection from "../../utils/signalRconnection";
import { wall_menu_routes } from "./global-menu/menu.config";
interface LayoutProps {
    children?: React.ReactNode;
    pad?: boolean;
    btnRequest?: string;
    click?: any;
}
const Layout: React.FC<LayoutProps> = ({ children, pad, btnRequest, click }) => {
    const path: any = window.location.pathname;
    const query_string = window.location.search;
    const navigate = useNavigate();
    const { userPermissions, integrationConnections, login_error } = useSelector((state: any) => state?.login);
    const {roles, workspaces} = useSelector((state: any) => state?.headerState);
    const { isGotBioPageSettings  } = useSelector((state: any) => state?.bioPageSettingState);
    const { dashboardData: dashboard, chart_data } = useSelector((state: RootState) => state?.unified);
    const { pageRoute, isReplyingOn, conversation_details_payload } = useSelector((state: any) => state?.unified);
    const { personalInfo } = useSelector((state: any) => state?.profileManageSettingState);
    const { all_folders, bio_page_folders } = useSelector((state: any) => state?.mediaState);
    const { time_zones } = useSelector((state: any) => state?.promotionsState);
    // const [padding, setpadding] = useState(pad);
    const dispatch = useDispatch();
    // // template of promotions
    // const { template_list, is_updated_template_by_signal_r, toggle_modal_template, search_template_text, search_template_list } = useSelector((state: any) => state?.promotionsState);
    // conversation details of unified inbox data 
    useEffect(() => {
        if (conversation_details_payload?.isNewTime && path === "/unified") {
            const { payload_object, url }: any = conversation_details_payload;
            console.log({ conversation_details_payload });
            dispatch(triggerConversationDetails(payload_object, url));
            dispatch(setConversationDetailsPayload(null))
        }
    }, [conversation_details_payload?.isNewTime]);
    
    // ======== Get Inbox and wall data ========
    const { bot_inbox_messages_payload, whatsapp_inbox_messages_payload, wall_unified_inbox_payload, unified_inbox_payload } = useSelector((state: any) => state?.unified);

    useEffect(() => {
        if (unified_inbox_payload) {
            console.log({unified_inbox_payload});
            dispatch(triggerUnified(unified_inbox_payload));
            setTimeout(() => {
                dispatch(setUnifiedInboxPayload(null));
            }, 2000);
        }
    }, [unified_inbox_payload]);
    
    useEffect(() => {
        if (wall_unified_inbox_payload) {
            console.log({wall_unified_inbox_payload});
            dispatch(triggerWallUnified(wall_unified_inbox_payload));
            setTimeout(() => {
                dispatch(setWallUnifiedInboxPayload(null));
            }, 2000);
        }
    }, [wall_unified_inbox_payload]);

    // ======== x ========
    
    // ===== whatsapp and ai messages ======
    useEffect(() => {
        if (bot_inbox_messages_payload) {
            console.log({bot_inbox_messages_payload});
            dispatch(triggerGetBotData(bot_inbox_messages_payload));
            setTimeout(() => {
                dispatch(setBotInboxMessagePayload(null));
            }, 2000);
        }
    }, [bot_inbox_messages_payload]);
    
    useEffect(() => {
        if (whatsapp_inbox_messages_payload) {
            console.log({whatsapp_inbox_messages_payload});
            dispatch(triggerGetWhatsAppInboxData(whatsapp_inbox_messages_payload));
            setTimeout(() => {
                dispatch(setWhatsappInboxMessagePayload(null));
            }, 2000);
        }
    }, [whatsapp_inbox_messages_payload]);

    // ======== x ========

    // integrations -> workspace
    const {
        facebook_connected_mapped_pages,
        // show_fb_page_map_modal,
        google_location_payload,
        google_connected_mapped_locations,
        google_connection_location_data,
      } = useSelector((state: any) => state?.workspaceManagementState);
    const get_integration_connections = () => {
        const workspaceId = sessionStorage.getItem('wuid');
        dispatch(triggerGetIntegrationConnections(workspaceId));
    };

    // it is needed for all review inbox and may be wall components
    useEffect(() => {
        if (!integrationConnections?.length &&(path === "/unified" || path === '/workspace/integrations')) {
            get_integration_connections();
        }
    }, [path, integrationConnections?.length]);
    
    useEffect(() => {
        if (!facebook_connected_mapped_pages?.length && path === '/workspace/integrations') {
            dispatch(getFacebookConnectionPages());
        }
    }, [path, facebook_connected_mapped_pages?.length]);

    // workspace for all pages
    // for campaign, template and workspace integration of whatsapp
    const {
        whats_app_connected_users
    }: any = useSelector((state: RootState) => state?.login);
    useEffect(() => {
        if (!whats_app_connected_users?.length && path !== "/profile") {
            console.log({whats_app_connected_users, path});
            const workspaceId = sessionStorage.getItem('wuid') || localStorage.getItem('wuid') || "";
            workspaceId && dispatch(getWhatsAppConnectedUsers(workspaceId));
        }
    }, [whats_app_connected_users?.length, path]);
    
    useEffect(() => {
        const appAccessType = localStorage.getItem("appAccessType");
        if (!workspaces?.length && (path !== "/profile" || appAccessType === "google-login" || appAccessType === "google-connection")) {
            dispatch(findWorkspaceList());
            dispatch(getWorkspaceSettings());
            dispatch(getWorkspaceLocations());
        }

        const inviteId = localStorage.getItem("invitation_id");
        if(path === "/profile" && inviteId) {
            // console.log({workspaces});
            const access_type = localStorage.getItem('appAccessType');
            const user_details = sessionStorage.getItem("accessed_user_details");
            const user = JSON.parse(user_details || "{}");
            const workspaceId = localStorage.getItem("workspace_id");
            // console.log({ user, clapupUserId, inviteId, workspaceId });
            if(user && user?.email && inviteId && workspaceId && access_type === 'google-login') {
                const convertTOuserDataObject: any = {
                    userEmail: user?.email,
                    workspaceId,
                    inviteId,
                    clapupUserId: localStorage.getItem("cuid") || sessionStorage.getItem("cuid") || ""
                }
                localStorage.removeItem("invitation_id");
                console.log({ convertTOuserDataObject });
                dispatch(convertToClapUpUser(convertTOuserDataObject));
            }
        }
    }, [path, workspaces?.length]);

    
    useEffect(() => {
        if (path !== "/profile" && (!google_connected_mapped_locations?.length || google_connection_location_data)) {
            dispatch(getConnectedMapGoogleLocations());
        }
    }, [path, google_connected_mapped_locations?.length, google_connection_location_data]);
    
    useEffect(() => {
        if (path !== "/profile" && google_location_payload) {
            dispatch(getGoogleConnectionLocations(google_location_payload));
            dispatch(setGoogleLocationPayload(null));
        }
    }, [path, google_location_payload]);

    useEffect(() => {
        if (workspaces?.length) {
          const sessionAuth = (sessionStorage.getItem('workspaceName') && sessionStorage.getItem("cuid") && sessionStorage.getItem("roleId") && sessionStorage.getItem("wuid")) ? true : false;
          // console.log({sessionAuth});
          if (sessionAuth) {
              const workspace: any = workspaces?.find(
                (w: any) => w?.workSpaceId === sessionStorage.getItem("wuid")
              );
              // console.log({wuid: sessionStorage.getItem('wuid')});
              console.log("================159=========", { workspace });
    
              const {
                userId,
                roleId,
                workSpaceId: workspaceId,
                roleName,
                workSpaceName,
              }: any = workspace || {};
    
              if (workspace) {
                // sessionStorage.setItem('roleName', roleName);
                // sessionStorage.setItem('roleId', roleId);
                sessionStorage.setItem('wuid', workspaceId);
                // sessionStorage.setItem('muid', googleUserId);
                sessionStorage.setItem("workspaceName", workSpaceName);
                const workspace_object: any = {
                    userId,
                    roleId,
                    workspaceId,
                };
                if (!userPermissions?.length) {
                    dispatch(getUserPermissions(workspace_object));
                }
                if (!roles?.length) {
                    dispatch(getRoles(workspace_object));
                }
              }
            
            // const workspace: any = workspaces?.find((w: any) => w?.workSpaceId === sessionStorage.getItem('wuid'));
            // setWorkspaces(workspaces);
          };
        }
    }, [workspaces?.length]);

    useEffect(() => {
        if (login_error) {
            // dispatch(setLoginError(false));
            dispatch(triggerLogout());
            navigate("/login");
        }
    }, [login_error]);
    
    // useEffect(() => {
    //     const {email, firstname, lastname, accountType, photo}: any = personalInfo || {};
    //     if (!email || !firstname || !lastname || !accountType || !photo) {
    //         dispatch(getProfileInfo());
    //     }   
    // }, [dispatch, personalInfo]);
    
    
    // only for dashboard and root page and graph-chart data
    useEffect(() => {
        // console.log('dashboard', dashboard, typeof (dashboard));
        if (path === "/" || path === "/dashboard") {
            if (!dashboard) {
                const clapUpUserID = sessionStorage.getItem("cuid");
                const clapUpWorkspaceID = sessionStorage.getItem("wuid");
            
                const dashboardObject = {
                    clapupUserId: clapUpUserID,
                    workspaceId: clapUpWorkspaceID,
                    period: 0,
                };
                dispatch(triggerDashboard(dashboardObject));
            }
        }
    }, [path, dashboard, path]);

    useEffect(() => {
        // console.log('dashboard', dashboard, typeof (dashboard));
        if (path === "/" || path === "/dashboard") { 
            if(!chart_data?.length){
              const clapUpUserID = sessionStorage.getItem("cuid");
              const clapUpWorkspaceID = sessionStorage.getItem("wuid");
          
              const dashboardObject = {
                clapupUserId: clapUpUserID,
                workspaceId: clapUpWorkspaceID,
                period: 0,
              };
              dispatch(getChartData(dashboardObject));
            }
        }
    }, [dispatch, chart_data?.length, path]);
    
    // only for promotions page
    useEffect(() => {
        if (path === "/promotions") {
            if (!time_zones?.length) {
                dispatch(getTimeZones());
                dispatch(getFirstTimeTemplateList());
                dispatch(getFirstTimeCampaignList());
            }
        }
    }, [path, time_zones?.length]);
    
    // only for gallery page
    useEffect(() => {
        if (path === "/gallery") {
            if (!bio_page_folders?.length) {
                dispatch(getBioPageFolders());
            }
            if (!all_folders?.length) {
                dispatch(getFirstTimeFolders());
            }
        }
    }, [path]);

    // only for biopage settings page
    useEffect(() => {
        // const setting_flag = (path?.includes('/workspace')
        //     || path?.includes("/biopage")) ? true : false;
        if (!isGotBioPageSettings && path?.includes("/biopage")) {
            dispatch(getBioPageSettings());
            dispatch(getBioPageLinks());
            dispatch(getBioPageTemplates());
            // dispatch(getEmbedVideoRecords());
            // dispatch(getEmbedImageRecords());
            dispatch(getBioPageAnnouncements());
        }

            
    }, [dispatch, isGotBioPageSettings, path]);
    
    // only for bot management page
    const { all_bot_details } = useSelector((state: any) => state?.botState);
    useEffect(() => {
        const setting_flag = path?.includes("/bot-management") ? true : false;
        if (setting_flag && !all_bot_details?.length) {
            dispatch(getBotDetailsSettings());
        }
    }, [dispatch, all_bot_details?.length, path]);
    
    // only for user management page
    const { users, isReadyToGetUsers } = useSelector((state: any) => state?.userManagementState);
    useEffect(() => {
        const page_flag = path?.includes("/user-management") ? true : false;
        if (page_flag && (!users?.length || isReadyToGetUsers)) {
            const currentUser: any = workspaces?.find((item: any) => item?.userId === sessionStorage.getItem('cuid'));
            console.log("========cur use======",{currentUser});
            
            const dataObject: any = {
                userId:         sessionStorage.getItem('cuid'), 
                roleId:         sessionStorage.getItem('roleId'), 
                workspaceId:    sessionStorage.getItem('wuid'),
            };
            
            // dataObject.userId = currentUser?.userId;
            // dispatch(getRoles({...dataObject}));
            delete dataObject.userId;
            dataObject.clapupUserId = currentUser?.userId;
            console.log("dataObject ==== ", dataObject);
            dispatch(getUsers({ ...dataObject }));
            dispatch(toggleIsReadyToGetUsers(false));
        }
    }, [dispatch, users?.length, path, isReadyToGetUsers]);
        
    useEffect(() => {
        // It will be removed after pageRoute is used for all pages
        if (pageRoute && !isReplyingOn) {
            // if (!query_string?.includes('wall')) {
            //     dispatch(resetWallUnifiedInbox());
            // }
            navigate(pageRoute);
            dispatch(changePage(null));
        }
        
    }, [dispatch, pageRoute]);

    const handlePageChange = (flag?: any) => { 
        if (flag) {
            // if (!query_string?.includes('wall')) {
            //     dispatch(resetWallUnifiedInbox());
            // }
            navigate(pageRoute);
            dispatch(setIsCountDownOn(false));
            dispatch(changePage(null));
            dispatch(toggleReplyingOn(false));
            dispatch(setWhatsAppReplyingText(''));
            dispatch(closeMobileDrawer());
        } else {
            dispatch(changePage(null));
        }
    };

    // =========== Inbox Data =============
    // =========== SignalR - start ============== 
    
    // feedback, video-testimonial, facebook, google inbox
    useEffect(() => {
        const connection_path = wall_menu_routes?.find((item: any) => item?.url === path && path !== "/dashboard")
        // console.log({ SignalR_n_connection_state: connection?.state, connection_path });

        if (connection?.state === 'Connected' && (connection_path?.url || path === "/messages" || path === "/whatsapp" || path === "/promotions")) {
        // if (connection?.state === 'Connected' ) {
            // Listen for new messages from the SignalR hub
            // connection.on('ReceiveNewCustomerFeedbackRecord', (record: any) => {
            //     // Update state with the new message record
            //     console.log('New message received:', {record});
            //     // setMessages(prevMessages => [...prevMessages, record]);
            // });

            const handleNewSignalR_Data = (record: any) => {
                if (record) {
                    console.log("SignalR_n: New record received: ", record);
                    try {
                        const signalRData = JSON.parse(record);
                        console.log("SignalR_n: ", { signalRData });
                        dispatch(appendedSignalR_Data({signalRData}));
                    } catch (error) { 
                        console.error("SignalR: Error parsing JSON data: ", error);
                    }
                }        
            };
    
            // Register the event handler wall and conversion connection
            if (connection_path?.url) {
                console.log({ SignalR_n_connection_state: connection?.state, connection_path, path });
                
                connection.on("ReceiveNewFacebookReviewRecord", handleNewSignalR_Data);
                connection.on("ReceiveNewFacebookCommentRecord", handleNewSignalR_Data);
                connection.on("ReceiveNewFacebookMentionRecord", handleNewSignalR_Data);
                connection.on("ReceiveNewGoogleQandARecord", handleNewSignalR_Data);
                connection.on("ReceiveNewGoogleReviewRecord", handleNewSignalR_Data);
                connection.on("ReceiveNewVideoTestimonialRecord", handleNewSignalR_Data);
                connection.on("ReceiveNewCustomerFeedbackRecord", handleNewSignalR_Data);
            }
    
            // template of promotions 
            const handleNewSignalR_DataWhatsapp_Template = (record: any) => {
                if (record) {
                    console.log("Whatsapp_template_SignalR_n: New record received: ", record);
                    try {
                        const signalRData = JSON.parse(record);
                        console.log("Whatsapp_template_SignalR_n: ", { signalRData });
                        // === demo record ===
                        dispatch(appendTemplateListBySignalR({
                            workspaceId: "dc474190-f363-4e8e-9949-8cdac65b4d44",
                            templateId: "3f6c64f6-18ff-11ef-8382-6adf3b91f978",
                            Status: "APPROVED"
                        }));
                        // === X ===
                        dispatch(appendTemplateListBySignalR(signalRData));
                    } catch (error) { 
                        console.error("Whatsapp_template_SignalR: Error parsing JSON data: ", error);
                    }
                }
                    
            };
            
            // whatsapp and ai app inbox
            const handleNewSignalR_DataWhatsapp = (record: any) => {
                if (record) {
                    console.log("Whatsapp_SignalR_n: New record received: ", record);
                    try {
                        const signalRData = JSON.parse(record);
                        console.log("Whatsapp_SignalR_n: ", { signalRData });
                        dispatch(appendedSignalR_DataForWhatsapp_Inbox(signalRData));
                    } catch (error) { 
                        console.error("Whatsapp_SignalR: Error parsing JSON data: ", error);
                    }
                }
                    
            };

            const handleNewSignalR_Data_Ai_Chat_App = (record: any) => {
                if (record) {
                    console.log("Ai_SignalR_n: received: ", record);
                    try {
                        const signalRData = JSON.parse(record);
                        console.log("Ai_SignalR_n: ", { signalRData });
                        dispatch(appendedSignalR_DataForAI_app_Inbox(signalRData));
                    } catch (error) { 
                        console.error("Ai_SignalR: Error parsing JSON data: ", error);
                    }
                }
                    
            };
    
            // Register the event handler for whatsapp and ai chat app
            if (path === "/messages") {
                console.log({ Ai_SignalR_n_connection_state: connection?.state, path });
                connection.on("ReceiveNewAIChatBotMessageRecord", handleNewSignalR_Data_Ai_Chat_App);
            }

            if (path === "/whatsapp") {
                console.log({ Whatsapp_SignalR_n_connection_state: connection?.state, path });
                connection.on("ReceiveNewWhatsappMessageRecord", handleNewSignalR_DataWhatsapp);
            }

            if (path === "/promotions") {
                console.log({ Whatsapp_Template_SignalR_n_connection_state: connection?.state, path });
                connection.on("ReceiveWhatsappTemplateUpdatedRecord", handleNewSignalR_DataWhatsapp_Template);
            }
    
            // Join a workspace (replace with actual workspace ID)
            const joinWorkspace = async (workspaceId: any) => {
                try {
                    await connection.invoke("JoinWorkspace", workspaceId);
                    // const whatsapp_test_data = {
                    //     "workspaceId": "dc474190-f363-4e8e-9949-8cdac65b4d44",
                    //     "main": [
                    //         {
                    //             "userName": "Rasheed",
                    //             "userEmail": "",
                    //             "userWiseMessageDetailsSubs": [
                    //                 {
                    //                     "ticketNo": "6bb44d66-18ed-11ef-8382-6adf3b91f978AA",
                    //                     "averageRating": "0",
                    //                     "userName": "Rasheed",
                    //                     "createdOn": "2024-05-23 11:39:22.000000",
                    //                     "chatStatus": "closed",
                    //                     "lastMessage": "Yes pl",
                    //                     "color": "black"
                    //                 }
                    //             ],
                    //             "fromPhoneNumber": "+91998899883322",
                    //             "toPhoneNumber": "+918921834334"
                    //         }
                    //     ],
                    //     "msgDetails": [
                    //         {
                    //             "ticketNo": "6bb44d66-18ed-11ef-8382-6adf3b91f978AA",
                    //             "userName": "Rasheed",
                    //             "message": "Yes pl",
                    //             "createdOn": "23-05-2024 11:39:22",
                    //             "messageType": "text",
                    //             "campaignString": null
                    //         }
                    //     ],
                    //     "statDetails": [
                    //         {
                    //             "userName": "Rasheed",
                    //             "days": "45",
                    //             "threads": "1",
                    //             "totalMessages": "1",
                    //             "fromPhoneNumber": "+91998899883322"
                    //         }
                    //     ],
                    //     "chatSummaryList": [
                    //         {
                    //             "ticketNo": "6bb44d66-18ed-11ef-8382-6adf3b91f978AA",
                    //             "summary": "This chat between the user and us is progressing and not yet completed. So the summary is yet to finalize."
                    //         }
                    //     ]
                    // };
                    // dispatch(appendedSignalR_DataForWhatsapp_Inbox(whatsapp_test_data));

                    console.log(`SignalR_n: Joined workspace: ${workspaceId}`);
                } catch (error) {
                    console.error("SignalR: Error joining workspace:", error);
                }
            };
    
            if (sessionStorage.getItem('wuid') || localStorage.getItem('wuid')) {
                joinWorkspace(sessionStorage.getItem('wuid') || localStorage.getItem('wuid'));
            }
            // joinWorkspace(sessionStorage.getItem('wuid') || localStorage.getItem('wuid') || null);

            // Clean up SignalR connection on component unmount
            return () => {
                connection.off('ReceiveNewFacebookReviewRecord', handleNewSignalR_Data);
                connection.off('ReceiveNewFacebookCommentRecord', handleNewSignalR_Data);
                connection.off('ReceiveNewFacebookMentionRecord', handleNewSignalR_Data);
                connection.off('ReceiveNewGoogleQandARecord', handleNewSignalR_Data);
                connection.off('ReceiveNewGoogleReviewRecord', handleNewSignalR_Data);
                connection.off('ReceiveNewCustomerFeedbackRecord', handleNewSignalR_Data);
                connection.off('ReceiveNewVideoTestimonialRecord', handleNewSignalR_Data);
                // connection.stop();
                connection.off('ReceiveNewAIChatBotMessageRecord', handleNewSignalR_Data_Ai_Chat_App);
                connection.off('ReceiveNewWhatsappMessageRecord', handleNewSignalR_DataWhatsapp);
                connection.off('ReceiveWhatsappTemplateUpdatedRecord', handleNewSignalR_DataWhatsapp_Template);
            };
    
            //  connection.on('ReceiveTypingStatus', (typingUser) => {
            //    console.log(`${typingUser} is typing`);
            //    setIsTyping(true);
            //    setTimeout(() => {
            //      setIsTyping(false);
            //    }, 2000);
            //  });

            
    
    
        } else {
          console.log('SignalR connection is not in Disconnected state, cannot start.');
        }
    }, [connection?.state, path]);

    return (
        <Fragment>
            <div className="layout-wrapper">
                {/* <LogoutOnWindowClose /> */}
                {/* When replying on and if we want to change current page this dialog will be come out */}
                {
                    pageRoute && isReplyingOn ?
                    <Dialog
                        open={pageRoute ? true : false}
                        parentCallback={(flag?: any) => handlePageChange(flag)}
                        classes='dialog-container-content'
                        title='Continue?'
                        warning_dialog_content={"If you confirm, all your edits will be lost. Locked message will be unlocked and editor will be accessible to others."}
                        footer={true}
                        left_button_text='No, Cancel'
                        right_button_text='Yes, Continue'
                        // hover={true}
                    />
                    : null
                    
                }

                {
                    path?.includes('gallery')
                    ?   <div className="special-lg-md-device">
                            <Navigation />
                        </div>
                    :   <div className="lg-navigation"><Navigation /></div>
                }
                
                
                <div className="w-100 main-body">
                    
                    {
                        path?.includes('gallery')
                        ? 
                            <div
                                className={`lg-header ${query_string?.includes('=contents') ? "d-block" : "special-mobile-header"} position-sticky top-0 bg-white`}
                                style={{ zIndex: 100 }}
                            >
                                <Header btnRequest={btnRequest} onClick={click} />
                            </div>
                        : 
                        <div
                            className={`
                                position-sticky
                                top-0 bg-white 
                                ${
                                    path?.includes('whatsapp')  || 
                                    path?.includes('messages')  || 
                                    // (path?.includes('gallery') && !query_string?.includes("=contents")) ||
                                    path?.includes('unified')  || path?.includes('video') ||
                                    path?.includes('customer') || path?.includes("google") ||
                                    path?.includes("facebook") || path?.includes("twitter")
                                    ? "d-none"
                                    : "mobile-header"
                                }
                            `}
                            style={{ zIndex: 100 }}
                        >
                            <Header btnRequest={btnRequest} onClick={click} />
                        </div>
                    }

                    <div
                        className={`position-sticky top-0 bg-white lg-header ${query_string?.includes('=contents') ? "d-none" : ""}`}
                        // style={{ zIndex: 100 }}
                    >
                        <Header btnRequest={btnRequest} onClick={click} />
                    </div>

                    <div
                        className={'dashboard-layout-sm ' + (pad ? ' padZero' : '')}
                        style={{
                            overflow: path?.includes('/gallery')|| path?.includes('/promotions')
                                || path?.includes('/workspace') || path?.includes("/user")
                                || path?.includes('/profile') || path?.includes("/account")
                                || path?.includes("/biopage") || path?.includes("/bot-management")
                                ? 'visible' : 'hidden',
                            height: path?.includes('/gallery') || path?.includes('/promotions')
                                ? query_string?.includes("=contents") ? "90%" : '94%'
                                : '100vh',
                                // ? '90.4vh' : '100vh',
                        }}
                    >
                        {children}
                    </div>
                    
                    <div
                        className={`dashboard-layout-lg ${pad ? 'padZero' : ''}`}
                        style={{
                            overflow:
                                // path?.includes('/promotions') || path?.includes('/gallery') ||
                                path?.includes('/workspace') || path?.includes("/user") 
                                || path?.includes("/account") || path?.includes("/biopage")
                                || path?.includes("/bot-management") || path?.includes('/profile')
                                ? 'hidden' : 'hidden',
                                // ? 'visible' : 'hidden',
                            height: (path?.includes('/workspace') || path?.includes("/user") 
                                || path?.includes("/account") || path?.includes("/biopage")
                                || path?.includes("/bot-management") || path?.includes('/profile')
                                || path?.includes('/gallery') || path?.includes('/promotions'))
                                ? '91.7%' : '90.5%',
                            // height: (path?.includes('/gallery') || path?.includes('/promotions')) ? '92vh' : '90vh',
                        }}
                    >
                        {children}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Layout;