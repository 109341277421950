import React, { useState } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector} from 'react-redux';
// import moment from 'moment';
// import { SearchOutlined } from '@ant-design/icons';
import { getImage, getTemplateImage } from '../../../../dashboard/image_service';
import './Templates.scss';
import { setErrorMessage } from '../../../../dashboard/reducer';
import { RootState } from '../../../../store';

// type CreateTemplateFormProps = {
//     onCancel?: () => void;
//     onParentCallback?: (data: any, file?: any) => void;
// };

const CreateTemplateForm: React.FC<any> = ({onCancel, onParentCallback }) => {
    const dispatch = useDispatch();
    // const {toggle_template_edition, }: any = useSelector((state: RootState) => state?.promotionsState);
    const [templateDisplayName, setTemplateDisplayName] = useState("");
    const [templateName, setTemplateName] = useState("");
    const [language, setLanguage] = useState("en_US");
    const [templateCategory, setTemplateCategory] = useState("");
    const handleSubmit = () => {
        const data = {
            clapupUserId: sessionStorage.getItem('cuid'),
            workspaceId: sessionStorage.getItem('wuid'),
            // roleId: sessionStorage.getItem('roleId'),
            templateName,
            templateDisplayName,
            language,
            templateCategory,
            templateHeaderType: "", // header
            templateHeader: "", // header
            templateBody: "", // body
            templateFooter: "", // footer
            isPromotionOptOut: false, // opt out
            headerSampleData: "",
            bodySampleData: "",
        };

        // console.log({ data });
        if (!templateName || !templateDisplayName || !language || !templateCategory) { 
            dispatch(setErrorMessage("Required all fields!"));
            return;
        }
        onParentCallback && onParentCallback(data);
    };


    const handleChange = (value: any, setState: any) => {
        setState(value);
    }

    return (
        <React.Fragment>
            <div className='form-template-container'>
                <p className='header-title'>Create a New Template</p>

                <form className='form'>
                    <div className='input-group'>
                        <input
                            type="text" name={"templateDisplayName"}
                            autoFocus={true}
                            autoComplete='off'
                            autoSave='off'
                            onChange={(e: any) => {
                                const inputValue = e.target.value;
                                // Remove characters other than English letters, numbers, space, and underscore
                                const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9\s_]/g, '');
                                // Replace multiple spaces with a single space
                                const formattedValue = sanitizedValue.replace(/\s+/g, ' ');
                                // Replace spaces with underscore
                                // const templateName = formattedValue.replace(/\s/g, '_');
                                // Removing Underscore
                                const templateName = formattedValue.replace(/\s/g, '');
                                handleChange(formattedValue, setTemplateDisplayName);
                                handleChange(templateName.toLowerCase(), setTemplateName);
                                // old
                                // handleChange(e.target.value, setTemplateDisplayName);
                                // handleChange(e.target.value.toLowerCase().replace(/\s/g, "_"), setTemplateName);
                            }}
                            value={templateDisplayName || ""}
                            placeholder={"Add Display Name"}
                        />
                    </div>
                    
                    <div className='input-group'>
                        <input
                            type="text" name={"templateName"}
                            autoFocus={true}
                            readOnly={true}
                            autoComplete='off'
                            autoSave='off'
                            // onChange={(e: any) => handleChange(e.target.value, setTemplateName)}
                            value={templateName || ""}
                            placeholder={"template_name (Internal)"}
                        />
                    </div>
                </form>

                <div className='language-category-container'>
                    <div className='language-zone'>
                        <p className='text-start'>Select Language</p>
                        <Select
                            className='time-picker w-100 text-start'
                            placeholder='Language'
                            value={language || null}
                            showSearch
                            allowClear
                            listHeight={100}
                            onChange={(value) => handleChange(value, setLanguage)}
                        >
                            {
                                [
                                    "en_US",
                                    "es_ES",
                                    "fr_FR",
                                    "de_DE",
                                    "it_IT",
                                    "ja_JP",
                                    "ko_KR",
                                    "pt_BR",
                                    "zh_CN",
                                    "zh_TW",
                                ]?.map((item: any, index: number) => (
                                    <Select.Option key={index} value={item}>
                                        <div style={{display: 'flex', gap: '0.5rem'}}>
                                            <img src={getImage("global-language")} alt="language" />
                                            <span>{item}</span>
                                        </div>
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div className='category-zone'>
                        <p className='text-start'>Select Category</p>
                        <Select
                            className='time-picker w-100 text-start'
                            placeholder='Category'
                            allowClear
                            onChange={(value) => handleChange(value, setTemplateCategory)}
                        >
                            {
                                ["MARKETING", "UTILITY"]?.map((item: any, index: number) => (
                                    <Select.Option key={index} value={item}>{item}</Select.Option>
                                ))
                            }
                        </Select>
                    </div>
                </div>

                <div className='form-button-container'>
                    <button
                        type='button'
                        className='cancel-button'
                        onClick={onCancel}
                    >
                        { "Cancel"}
                    </button>
                    <button
                        // disabled={}
                        className='create-button'
                        onClick={() => handleSubmit()}
                        type='button'
                    >
                        {"Yes, Create" }
                    </button>
                </div>
            </div>
        </React.Fragment>
    )
};

export default CreateTemplateForm;
