import React, { useState } from 'react';
import { Button, DatePicker, Form, Input, Select, TimePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getImage, testCollaboratorImage } from '../../../../../../dashboard/image_service';
import { getLanguageData } from '../../../../../../dashboard/my_data_service';
import { RootState } from '../../../../../../store';
import { toggleTemplateEdition } from '../../../../reducer';
// import { getImage, getTemplateImage } from '../../../../dashboard/image_service';
// import './Templates.scss';

// type GeneralEditionProps = {
//     onCancel?: () => void;
//     onParentCallback?: (data: any, file?: any) => void;
// };

const GeneralEdition: React.FC<any> = () => {
    const {
        toggle_template_edition, isEditableTemplate
    }: any = useSelector((state: RootState) => state?.promotionsState);
    const dispatch = useDispatch();
    const handleChange = (key: any, value: any) => {
        const data = {
            ...toggle_template_edition,
            [key]: value
        };
        console.log({ data });
        
        dispatch(toggleTemplateEdition(data));
    }

    const [isGeneralNonPreviewEdition, setIsGeneralNonPreviewEdition] = useState(true);

    const getCustomState = (state: any) => {
        switch (state) {
          case 'active':
            return 'Active';
          case 'inactive':
            return 'Inactive';
          case 'draft':
            return <div style={{padding: 10, borderRadius: '50%', background: '#F79009'}}></div>;
        case 'approved':
            return <div style={{padding: 10, borderRadius: '50%', background: '#5CCE99'}}></div>;
        case 'in-progress':
            return <div style={{padding: 10, borderRadius: '50%', background: '#F79009'}}></div>;
          default:
            return state;
        }
    };

    // const all_languages = getLanguageData();
    // console.log({all_languages});
    
    return (
        <React.Fragment>
            <div className='general-container'
                style={{ pointerEvents: toggle_template_edition?.templateId && !isEditableTemplate ? "none" : "auto" }}
            >
                <div className="info-body">
                    <p className='body-content'>Item Name: {"Sreekanth"}</p>
                    <p className='body-content'>Content Type: {"WhatsApp Template"}</p>
                    <p className='body-content'>Created by: {"Sreekanth PM"}</p>
                    <p className='body-content'>Created on: {moment('2023-06-16T14:23:00').format('DD MMMM YYYY, hh:mm A')}</p>
                    <p className='body-content'>Last Updated by: {"Kuber Mannur"}</p>
                    <p className='body-content'>Last Updated on: {moment('2023-06-16T14:23:00').format('DD MMMM YYYY, hh:mm A')}</p>
                </div>
                <div className="general-form">
                    <div className='input-group'>
                        <label>Category</label>
                        <Select
                            placeholder="Select Category"
                            value={toggle_template_edition?.templateCategory || null}
                            disabled={toggle_template_edition?.status?.toLowerCase() === "approved" ? true : false}
                            onChange={(value) => handleChange("templateCategory", value)}
                            allowClear
                        >
                            {
                                ["MARKETING", "UTILITY"]?.map((item: any, index: number) => (
                                    <Select.Option key={index} value={item}>{item}</Select.Option>
                                ))
                            }
                        </Select>
                    </div>

                    <div className='input-group'>
                        <label>Language</label>
                        <Select
                            placeholder='Select Language'
                            value={toggle_template_edition?.language || null}
                            showSearch
                            allowClear
                            listHeight={100}
                            onChange={(value) => handleChange("language", value)}
                        >
                            {
                                [
                                    "en_US",
                                    "es_ES",
                                    "fr_FR",
                                    "de_DE",
                                    "it_IT",
                                    "ja_JP",
                                    "ko_KR",
                                    "pt_BR",
                                    "zh_CN",
                                    "zh_TW",
                                ]?.map((item: any, index: number) => (
                                    <Select.Option key={index} value={item}>
                                        <div style={{display: 'flex', gap: '0.5rem'}}>
                                            <img src={getImage("global-language")} alt="language" />
                                            <span>{item}</span>
                                        </div>
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </div>
                </div>
                <div className="state-collaborators">
                    <div className='state-container'>
                        <p className='title'>State</p>
                        <div className='state-body'>
                            {getCustomState("draft")}
                            <span>{"Draft"}</span>
                        </div>
                    </div>
                    
                    <div className='collaborator-container'>
                        <p className='title'>Collaborators</p>
                        <div className='collaborator-body'>
                            <img src={testCollaboratorImage("man-collaborator")} alt="collaborator" />
                            <img src={testCollaboratorImage("boy-collaborator")} alt="collaborator" />
                            <img src={testCollaboratorImage("woman-collaborator")} alt="collaborator" />
                            <img src={testCollaboratorImage("boy-collaborator")} alt="collaborator" />
                            <img src={testCollaboratorImage("man-collaborator")} alt="collaborator" />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default GeneralEdition;
