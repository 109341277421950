import { useDispatch } from 'react-redux';
import refresh from '../../assets/icons/refresh-icon.svg';
import './content-wrap.scss';
import { setSuccessMessage } from '../../dashboard/reducer';
interface LayoutProps {
    title: any;
    link?: any;
    children?: React.ReactNode;
    pagination?: any;
    style?: any;
}
const ContentWrap: React.FC<LayoutProps> = ({title, link, children, style, pagination}: any) => {
    const dispatch = useDispatch();
    return(
        <div style={style} className="content-wrapper">
            <div className="head-wrapper">
                <h3>{title}</h3>
                <span>{
                    link || pagination || 
                    <img
                        onClick={() => dispatch(setSuccessMessage('Refreshed'))}
                        style={{ marginRight: 2, cursor: 'pointer' }}
                        src={refresh} alt="refresh"
                    />
                }</span>
            </div>
            <div style={{height: '82%', padding: '0'}}>
                {children}
            </div>
        </div>
    )
}

export default ContentWrap;