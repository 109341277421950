import React, { useEffect } from 'react';
import { Form, Input, Select, Col, Row } from 'antd';
import { useSelector } from 'react-redux';

const InviteForm = ({form_name, handleSubmitForm, form, error}: any) => {
    const {roles} = useSelector((state: any) => state?.headerState);
    const [errorMessage, setErrorMessage] = React.useState('');
    useEffect(() => {
        if(error && errorMessage !== error) {
            setErrorMessage(error);
        }
    } , [error]);

    return (
        <React.Fragment>
            <Form
                form={form}
                style={{padding: "5% 5% 0 5%"}}
                labelCol={{ span: 20 }}
                wrapperCol={{ span: 32 }}
                layout="vertical"
                name={form_name || ""}
                initialValues={{ remember: true }}
                onFinish={(values: any) => {
                    // console.log('Success:', values);
                    handleSubmitForm(values, form);
                }}
                onChange={() => {
                    // console.log({ e });
                    setErrorMessage('');
                    
                }}
                id={form_name || "myForm"}
                autoComplete='off'
                autoSave='off'
            >
                <Row 
                    gutter={32}
                    // style={{marginBottom: '10px'}}

                >
                    <Col span={12}>
                        <Form.Item
                            label="First Name"
                            name="firstName"
                            rules={[{ required: true, message: 'Please input your first name!' }]}
                        >
                            <Input type="text" className="form-input-field w-100 m-0" placeholder="First Name" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Last Name"
                            name="lastName"
                            rules={[{ required: true, message: 'Please input your last name!' }]}
                        >
                            <Input type="text" className="form-input-field w-100 m-0" placeholder="Last Name" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ 
                                required: true, 
                                message: 'Please input your valid email!',
                                type: 'email',
                                // pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                // only gmail patterns are allowed
                                // pattern: /^[A-Z0-9._%+-]+@gmail]+\.[com]{3,4}$/i,
                                // length: {
                                //     min: 13,
                                //     max: 20
                                // }
                                validator(rule, value, callback) {
                                    if (value) {
                                        if (value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) {
                                        // if (!value.includes('@microsoft.com') && value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) {
                                        // if (value.includes('@gmail.com') && value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{3,4}$/i)) {
                                            // console.log({value});
                                            
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('Please enter valid gmail(Ex: example@gmail.com)!');
                                    }
                                    return Promise.resolve();
                                },
                            }]}
                        >
                            <Input type="email" className="form-input-field w-100 m-0" placeholder="Email" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Role"
                            name="roleId"
                            rules={[{ required: true, message: 'Please select your role!' }]}
                        >
                            <Select
                                showSearch
                                className="form-input-field w-75 m-0"
                                placeholder="Select Role"
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                allowClear
                            >
                                {
                                    roles?.map((role: any) => (
                                        <Select.Option key={role?.id} value={role?.id}>
                                            {role?.roleName}
                                        </Select.Option>
                                    ))
                                }
                                
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="Message"
                            name="message"
                            rules={[{ required: true, message: 'Please input your message!' }]}
                        >
                            <Input.TextArea
                                className="form-input-field w-100 m-0"
                                placeholder="Message"
                                autoSize={{ minRows: 4, maxRows: 7 }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <p className='text-danger text-center'>{errorMessage || null}</p>
            </Form>
        </React.Fragment>
    );
};

export default InviteForm;