import React, { useEffect } from 'react';
import prev_icon from '../../../../../assets/icons/previous-icon.svg';
import next_icon from '../../../../../assets/icons/next-icon.svg';
import refresh_icon from '../../../../../assets/icons/refresh-icon-unified.svg';
import user_avatar from '../../../../../assets/icons/user-avatar.svg';
import user_avatar_2 from '../../../../../assets/icons/user-avatar-2.svg';
import lock_icon from '../../../../../assets/icons/lock-icon.svg';
import unlock_icon from '../../../../../assets/icons/unlock-icon.svg';
import back_btn from '../../../../../assets/icons/back.svg';
import reply_icon from '../../../../../assets/icons/reply-icon.svg';
import edit_icon from '../../../../../assets/icons/edit-icon.svg';
import send_icon from '../../../../../assets/icons/send-icon.svg';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import ConversationDetails from '../../AllDetails/ConversationsDetails';
import { useDispatch, useSelector } from 'react-redux';
import { setSuccessMessage, toggleReplyingOn, toggleIsInReplyingMode, likeUnlikeFacebookPost } from '../../../../../dashboard/reducer';
import ConversationHeaderSD from '../ConversationHeaderSD';
import ConversationHeader from '../ConversationHeader';
import { RootState } from '../../../../../store';
import ShowFiles from './ShowFiles/ShowFiles';
import { getGenerateApiImageByName } from '../../../../../dashboard/image_service';
export default function FacebookMentions(props: any) {
  const dispatch = useDispatch();
  const { workspaces } = useSelector((state: any) => state?.headerState);
    const workSpaceName: any = workspaces?.find(
        (w: any) => w?.workSpaceId === localStorage.getItem("wuid")
    )?.workSpaceName || "";
  const {conversationDetailsData, isReplyingOn, isInReplyingMode}: any = useSelector((state: RootState) => state?.unified);
  // const { comments: replyData }: any = conversationDetailsData || {};
  const {
    mention: post_item,
    comments: commentData
  }: any = conversationDetailsData || {};
  const post: any = post_item?.length ? post_item[0] : (post_item || null);
  const {sender, conversation, isReply, setIsReply, textRef, setReplyText, handleReply, handleReplaceConversation, workspaceSettings, getImage, handlePrevPath, conversationId, isGoForDetails, handleDetailChange} = props;
  const { reviewedbyUserName, reviewerPhoteUrl, recieverName, mentionedbyUserName, mentionedbyUserImageUrl, reviewtype, createdOn, reviewReplyComment, reviewReplyUpdatedon, reviewComment, provider, starRating, ratingStars, likeCount: likes, retweetCount, locationName, pagename, recieverCompany, mentionedbyUserEmailId, reviewComment2 } = conversation || {};
  
  const [comments, setComments] = React.useState(commentData?.length ? commentData : []);
  React.useEffect(() => {
    console.log({ commentData });
    setComments(commentData?.length ? commentData : []);
  }, [commentData?.length]);
  const [replyText, setCurrentReplyText] = React.useState<any>("");
  const [likeCount, setLikeCount] = React.useState({ isLike: false, likes: likes || 0 });
  const [currentRecord, setCurrentRecord] = React.useState<any>({});

  const handleReplyRecord = (replyText: string, currentRecord: any) => { 
    const replyRecord = {
      // repliedId: currentRecord?.commentId,
      replyMessage: replyText,
      repliedByUserName: workspaceSettings?.workspaceName,
      repliedUserImageURL: workspaceSettings?.logo,
      repliedOn: new Date(),
      isLike: false,
      likeCount: 0,
    };
    if (replyText?.trim()) {
      handleReply(replyText, currentRecord);
      const newReplies = [replyRecord, ...comments];
      setCurrentReplyText("");
      setComments(newReplies);
      // Scroll to bottom after sending a message
      setTimeout(() => {
          // scrollToBottom();
      }, 100); // Timeout to ensure the message is rendered before scrolling
    }
    
  };

  const handleMainLikeClick = (likeObj: any) => {
    console.log({ likeObj });
    const { reviewId, reviewPageId, tag, companyId } = conversation;
    const likePayload = {
        replyId: reviewId,
        pageId: reviewPageId || companyId || null,
        isLiked: likeObj?.isLike,
        pageModule: tag,
    }
    console.log({likePayload, conversation});
    setLikeCount(likeObj);
    dispatch(likeUnlikeFacebookPost(likePayload));
  };
  
  const handleLike = (curData: any, isLike?: any) => {
    // console.log({curData});
    
    const { reviewPageId, tag, companyId } = conversation;
    const { likeCount, replyId } = curData;
    const likeFlag = isLike ? false : true;
    const likeObj = {
        ...curData,
        likeCount: isLike ? (likeCount - 1) : (likeCount + 1),
        isLike: likeFlag,
    };
    const likePayload = {
        replyId: replyId,
        pageId: reviewPageId || companyId || null,
        isLiked: likeFlag,
        pageModule: tag,
    }
    dispatch(likeUnlikeFacebookPost(likePayload));
    const index = comments.findIndex((item: any) => item?.replyId === curData?.replyId);
    const newReplies = [...comments];
    newReplies[index] = likeObj;
    setComments(newReplies);
  };

  useEffect(() => {
    console.log({ sender });
    console.log({ conversationDetailsData: conversation });
  }, [sender]);
    
  const debounce = <F extends (...args: any[]) => void>(func: F, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: Parameters<F>) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleTextChange = (value = "") => {
    setReplyText(value);
  };

  const debouncedTextChange = debounce(handleTextChange, 500);
  return (
    <React.Fragment>
      <div className="conversation-section-container-lg h-100">
        {/* <div className="conversation-header">
          <div className="person-container">
            <div className="person-1">
              {
                reviewerPhoteUrl
                ? <img
                    className="user-avatar"
                    src={reviewerPhoteUrl || user_avatar}
                    alt="avatar"
                  />
                : <span className='user-avatar'>
                  {
                    conversation?.pagename?.split(" ")?.slice(0, 2)?.length === 2
                    ? conversation?.pagename?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                    : conversation?.pagename?.substr(0, 2).toUpperCase()
                  }
                </span>
              }
              <span className="description">
                {conversation?.pagename?.substr(0, 10) ||
                  "User Name"}{" "}
                <span className="review me-1">
                  mentioned
                </span>
                {workspaceSettings?.workspaceName}
              </span>
            </div>
            <div className="person-2">
              <img
                src={workspaceSettings?.logo || user_avatar_2}
                alt="user-2"
              />
              <span className="user-2">
                {workspaceSettings?.workspaceName || "WS Name"}
              </span>
            </div>
          </div>
          <div className="conversation-features-media">
            <div className="features">
              <img
                onClick={() =>
                  handleReplaceConversation("prev", conversation?.idx)
                }
                src={prev_icon}
                alt="prev"
                title='Previous'
              />
              <img
                onClick={() =>
                  handleReplaceConversation("next", conversation?.idx)
                }
                src={next_icon}
                alt="next"
                title='Next'
              />
              <img
                onClick={() => dispatch(setSuccessMessage("Refreshed"))}
                src={refresh_icon}
                alt="refresh"
                title='Refresh'
              />
            </div>
            <div className="media">
              <img src={getImage(provider)} alt="google" />
            </div>
          </div>
        </div> */}
        <ConversationHeader
          header_title={" mentioned "}
          workSpaceName={workSpaceName}
          workspaceSettings={workspaceSettings}
          conversation={conversation}
          handleReplaceConversation={handleReplaceConversation}
          prev_icon={prev_icon}
          next_icon={next_icon}
          refresh_icon={refresh_icon}
          user_avatar_2={user_avatar_2}
          user_avatar={user_avatar}
        />
        <div className="conversation-section">
          <Scrollbars
            style={{
              paddingRight: 10,
              display: "flex",
              flexDirection: "column-reverse",
            }}
            // style={{ paddingRight: 10 }}
            autoHide
            autoHideTimeout={500}
            autoHideDuration={500}
            autoHeight
            // autoHeightMin={250}
            // autoHeightMax={250}
            // autoHeightMin={480}
            autoHeightMax={"100%"}
            thumbMinSize={10}
            universal={true}
          >
            <div className="post">
              {
                post?.message
                ?   <div className='post-container' style={{margin: '10px 0'}}>
                        <div className="post-header">
                            {
                                post?.userImageUrl
                                ? <img
                                    className="user-avatar"
                                    style={{
                                        borderRadius: '50%',
                                        height: 22,
                                        width: "auto"
                                    }}
                                    src={post?.userImageUrl || user_avatar}
                                    alt="avatar"
                                />
                                : 
                                <div className='user-avatar' 
                                    style={{
                                        fontSize: "0.75rem", 
                                        background: '#14B0BF',
                                        height: 22,
                                        width: 26
                                    }}
                                >
                                {
                                    post?.userName?.split(" ")?.slice(0, 2)?.length === 2
                                    ? post?.userName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                                    : post?.userName?.substr(0, 2).toUpperCase()
                                }
                                </div>
                            }
                            <div className='content'>
                                <h2 className='user-name'>{post?.userName}</h2>
                                <p>
                                    {
                                        moment(post?.postCreatedDate).format("DD MMM YYYY, hh:mm:ss A")
                                        // moment(createdOn).format("MMMM Do YYYY, hh:mm:ss A")
                                    }
                                </p>
                            </div>
                        </div>
                        <div className="post-body">
                            {/* <ShowFiles data={post?.attachment} /> */}
                            {
                                post?.attachment
                                ? <ShowFiles data={post?.attachment} />
                                : null
                            }
                            
                            <p>
                                {post?.message}
                            </p>
                        </div>                 
                        <div className="post-footer">
                            <div className="post-footer-left w-50">
                                <div className="like-edit-reply">
                                    <div className='image-likes'>
                                    <img
                                        className="image-like"
                                        src={getImage("like")}
                                        alt="like"
                                        onClick={() => {
                                            handleMainLikeClick({
                                                isLike: likeCount?.isLike ? false : true,
                                                likes: likeCount?.isLike ? (likeCount?.likes - 1) : (likeCount?.likes + 1)
                                            });
                                        }}
                                    />
                                    <div
                                        className="likes"
                                        style={{color: "inherit"}}
                                    >
                                        {
                                            (likeCount?.likes === 0 || likeCount?.likes === 1)
                                            ? likeCount?.likes + " Like"
                                            : likeCount?.likes + " Likes"
                                        }
                                    </div>
                                    </div>
                                    {/* <div className='reply-edit'
                                        onClick={() => {
                                            setCurrentRecord(conversation);
                                            setIsReply(true);
                                            dispatch(toggleReplyingOn(true));
                                            dispatch(toggleIsInReplyingMode(true));
                                        }}
                                    >Reply</div> */}
                                </div>
                            </div>
                            <div className="post-footer-right w-50">
                                {
                                    comments?.length > 0
                                    ? comments?.length === 1
                                        ? <p className='text-end'>1 Comment</p>
                                        : <p className='text-end'>{comments?.length} Comments</p>
                                    : <p className='text-end'>0 Comment</p>
                                }
                            </div>
                        </div>                 
                    </div>
                : null
              }
              <p>Comment</p>
            </div>
            <div className="conversation-body" id="scrollableDiv">
                {/* This will implement it feeds.tsx file */}
                {/* <InfiniteScroll
                    dataLength={2}
                    className="scrolling"
                    next={() => console.log("next")}
                    inverse={true}
                    hasMore={false}
                    loader={<h4 className="text-center">Loading...</h4>}
                    scrollableTarget="scrollableDiv"
                    height={320}
                    
                > */}
              
              {
                comments?.map((comment: any, index: number) => (
                  workspaceSettings?.workspaceName !== comment?.repliedByUserName
                  ?
                  <div key={index} className="conversation-person-1">
                    <div className="details">
                      {
                          comment?.repliedByUserImageUrl
                          ? <img
                              className="user-avatar"
                              src={comment?.repliedByUserImageUrl}
                              alt="avatar"
                          />
                          : <div className='user-avatar'>
                              {
                                comment?.repliedByUserName?.split(" ")?.slice(0, 2)?.length === 2
                                ? comment?.repliedByUserName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                                : comment?.repliedByUserName?.substr(0, 2).toUpperCase()
                              }
                          </div>
                      }
                      <div className="description">
                        <div className={`d-flex align-items-center user-ratings-container`}>
                          <div className="user_name me-2 ">
                              {comment?.repliedByUserName || "User Name"}
                          </div>
                        </div>
                        {
                          comment?.attachment
                          ? <ShowFiles
                              data={ comment?.attachment
                                // [
                                //   {  
                                //     attachmentUrl: comment?.attachmentUrl,
                                //     attachmentType: comment?.attachmentType,
                                //     id: comment?.attachmentId
                                //   }
                                // ]
                              }
                            />
                          : null
                        }
                        <p>{comment?.mentionReplyMessage}</p>
                        <div className="like-edit-reply">
                          <div className='image-likes'>
                            <img
                              className={`image-like ${!comment?.replyId ? "disable-hover" : ""}`}
                              src={getImage("like")}
                              alt="like"
                              onClick={() => {
                                if (!comment?.replyId) {
                                    return;
                                } else {
                                    handleLike(comment, comment?.isLike);
                                }
                              }}
                            />
                            <div
                              className="likes"
                              style={{ color: "inherit" }}
                              
                            >
                              {
                                (comment?.likeCount === 0 || comment?.likeCount === 1)
                                ? comment?.likeCount + " Like"
                                : comment?.likeCount + " Likes"
                              }
                            </div>
                          </div>
                          <div className={`reply-edit ${!comment?.replyId ? "disable-hover" : ""}`}
                              onClick={() => {
                                  if (!comment?.replyId) {
                                      return;
                                  } else {
                                      setCurrentRecord(comment);
                                      setIsReply(true);
                                      dispatch(toggleReplyingOn(true));
                                      dispatch(toggleIsInReplyingMode(true));
                                  }
                              }}
                          >Reply</div>
                        </div>
                      </div>
                    </div>
                    <p className="text-start mt-2 date"> Mention  -  {" "} <span>{moment(comment?.repliedOn).format("DD MMM YYYY, hh:mm:ss A")}</span></p>
                  </div>
                  : 
                  <div className="conversation-person-2">
                    <div className="details">
                      {
                        workspaceSettings?.logo
                        ?   <img
                                className="user-avatar"
                                src={workspaceSettings?.logo || user_avatar_2}
                                alt="user_avatar"
                            />
                        : <div className='user-avatar'>
                            {
                            comment?.repliedByUserName?.split(" ")?.slice(0, 2)?.length === 2
                            ? comment?.repliedByUserName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                            : comment?.repliedByUserName?.substr(0, 2).toUpperCase()
                            }
                        </div>
                      }
                      <div className="description">
                        <p>{comment?.replyMessage}</p>
                      </div>
                    </div>
                    <p className="text-end mt-2 date">
                        {"Replied"} -{" "}
                        <span>
                        {
                            moment(comment?.repliedOn).format("DD MMM YYYY, hh:mm:ss A") +
                            " by " +
                            (workspaceSettings?.workspaceName || "WS Name")
                            // moment(details?.requested_time).format('Y-m-d')
                        }
                        </span>
                    </p>
                  </div>
                ))
              }

              {/* </InfiniteScroll> */}
            </div>
          </Scrollbars>
        </div>
        <div className="conversation-footer">
          <p className="text-start my-2 px-3 sender">
            {
              sender
              ? `${sender} is typing...`
              : `Sending as ${workspaceSettings?.workspaceName || "WS Name"}`
            }
          </p>
          <div className="message-box">
            <div className="message">
              <div className="text-container">
                <textarea
                  ref={textRef}
                  className=""
                  value={replyText}
                  placeholder="Write here..."
                  onChange={(event: any) => {
                    setCurrentReplyText(event.target.value);
                    debouncedTextChange(event.target.value);
                  }}
                  style={{ resize: "none" }}
                  autoFocus={isReply}
                />
              </div>
              <div className="reply-btn">
                <button
                  style={{
                    background: `${isReply ? "#E4E7EC" : "#E4E7EC"}`,
                  }}
                  onClick={() => {
                    if (isReply) {
                        handleReplyRecord(replyText, currentRecord);
                    } else {
                        !currentRecord && setCurrentRecord(conversation);
                        setIsReply(true);
                        dispatch(toggleReplyingOn(true));
                        dispatch(toggleIsInReplyingMode(true));
                    }
                  }}
                  type="button"
                  disabled={sender ? true : false}
                >
                  {
                    // isReply && replyText
                    // ? "Save" : 
                    isReply
                    ? "Send"
                    : replyText
                    ? "Edit"
                    : "Reply"}
                </button>
                <img
                  onClick={() => {
                    setIsReply(false);
                    dispatch(toggleReplyingOn(false));
                    dispatch(toggleIsInReplyingMode(false));
                  }}
                  src={(sender || isReplyingOn || isInReplyingMode) ? lock_icon : unlock_icon}
                  alt="lock-unlock"
                />
              </div>
              <p className={`click-reply-des ${(isReply && !sender) ? "d-none" : ""}`}>
                {
                  sender
                  ? "You can not reply now. Editor is locked"
                  : "Click reply and start adding your reply."
                }
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="conversation-container-mobile">
        <div className="conversation-header">
          <div className="header-top">
            <img
              onClick={handlePrevPath}
              className="back"
              src={back_btn}
              alt="back"
            />
            <img
              className="provider"
              src={getImage(provider)}
              alt="provider"
            />
          </div>
          {/* <div className="header-body">
            <div className="body-left">
              <div className="first-ws">
                {
                  reviewerPhoteUrl
                  ? <img
                      className="user-avatar"
                      src={reviewerPhoteUrl || user_avatar}
                      alt="avatar"
                    />
                  : <span className='border text-light image-logo rounded-circle d-flex align-items-center justify-content-center'>
                      {
                        conversation?.pagename?.split(" ")?.slice(0, 2)?.length === 2
                        ? conversation?.pagename?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                        : conversation?.pagename?.substr(0, 2).toUpperCase()
                      }
                    </span>
                }
                
                <div className="user-info">
                  <p className="user-name">
                    {conversation?.pagename?.substr(0, 10) || "Client"}
                  </p>{" "}
                  <span className="review-type">
                    mentioned  
                  </span>
                  <p className="ws-name">
                    {workspaceSettings?.workspaceName?.substr(0, 10) ||
                      "WS Name"}
                  </p>
                </div>
              </div>
              <div className="second-ws">
                <img
                  className="image-logo"
                  src={workspaceSettings?.logo || user_avatar_2}
                  alt="user_avatar"
                />
                <p className="ws-name">
                  {workspaceSettings?.workspaceName?.substr(0, 7) ||
                    "WS Name"}
                </p>
              </div>
            </div>
            <div className="body-right">
              <img
                onClick={() =>
                  handleReplaceConversation("prev", conversation?.idx)
                }
                src={prev_icon}
                alt="prev"
                title='Previous'
              />
              <img
                onClick={() =>
                  handleReplaceConversation("next", conversation?.idx)
                }
                src={next_icon}
                alt="next"
                title='Next'
              />
              <img
                onClick={() => dispatch(setSuccessMessage("Refreshed"))}
                src={refresh_icon}
                alt="refresh"
                title='Refresh'
              />
            </div>
          </div> */}
          <ConversationHeaderSD
            header_title={" mentioned "}
            workSpaceName={workSpaceName}
            workspaceSettings={workspaceSettings}
            conversation={conversation}
            handleReplaceConversation={handleReplaceConversation}
            prev_icon={prev_icon}
            next_icon={next_icon}
            refresh_icon={refresh_icon}
            user_avatar_2={user_avatar_2}
            user_avatar={user_avatar}
          />
          <div className="header-bottom">
            <span
              onClick={() => isGoForDetails && handleDetailChange()}
              style={{ color: !isGoForDetails ? "#1570EF" : "" }}
            >
              Messages
            </span>
            <span
              onClick={() => !isGoForDetails && handleDetailChange()}
              style={{ color: isGoForDetails ? "#1570EF" : "" }}
            >
              Details
            </span>
          </div>
        </div>
        {isGoForDetails ? (
          <div className="conversation-details-mobile-container">
            <ConversationDetails conversationId={conversationId} />
          </div>
        ) : (
          <>
            <div className="conversation-body">
              <Scrollbars
                style={{
                  paddingRight: 10,
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
                // style={{ paddingRight: 10 }}
                autoHide
                autoHideTimeout={500}
                autoHideDuration={500}
                autoHeight
                // autoHeightMin={250}
                // autoHeightMax={250}
                // autoHeightMin={480}
                autoHeightMax={"100%"}
                thumbMinSize={10}
                universal={true}
              >
                <div className="post">
                  {
                    post?.message
                    ?   <div className='post-container mb-3' style={{width: '90%'}}>
                            <div className="post-header">
                                {
                                    post?.userImageUrl
                                    ?   <img
                                            className="user-avatar"
                                            style={{
                                                fontSize: "0.75rem", 
                                                background: '#14B0BF',
                                                borderRadius: '50%',
                                                height: 22,
                                                width: "auto"
                                            }}
                                            src={post?.userImageUrl || user_avatar}
                                            alt="avatar"
                                        />
                                    :   <div className='user-avatar' 
                                           
                                        >
                                        {
                                            post?.userName?.split(" ")?.slice(0, 2)?.length === 2
                                            ? post?.userName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                                            : post?.userName?.substr(0, 2).toUpperCase()
                                        }
                                        </div>
                                }
                                <div className='content'>
                                    <h2 className='user-name'>{post?.userName}</h2>
                                    <p>
                                        {
                                            moment(post?.postCreatedDate).format("DD MMM YYYY, hh:mm:ss A")
                                            // moment(createdOn).format("MMMM Do YYYY, hh:mm:ss A")
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className="post-body">
                                {
                                    post?.attachment
                                    ? <ShowFiles data={post?.attachment} />
                                    : null
                                }
                                <p>
                                    {post?.message}
                                </p>
                            </div>                 
                            <div className="post-footer">
                                <div className="post-footer-left w-50">
                                    <div className="like-edit-reply">
                                        <div className='image-likes'>
                                        <img
                                            className="image-like"
                                            src={getImage("like")}
                                            alt="like"
                                            onClick={() => {
                                                handleMainLikeClick({
                                                    isLike: likeCount?.isLike ? false : true,
                                                    likes: likeCount?.isLike ? (likeCount?.likes - 1) : (likeCount?.likes + 1)
                                                });
                                            }}
                                        />
                                        <div
                                            className="likes"
                                            style={{color: "inherit"}}
                                        >
                                            {
                                                (likeCount?.likes === 0 || likeCount?.likes === 1)
                                                ? likeCount?.likes + " Like"
                                                : likeCount?.likes + " Likes"
                                            }
                                        </div>
                                        </div>
                                        {/* <div className='reply-edit'
                                            onClick={() => {
                                                setCurrentRecord(conversation);
                                                setIsReply(true);
                                                dispatch(toggleReplyingOn(true));
                                                dispatch(toggleIsInReplyingMode(true));
                                            }}
                                        >Reply</div> */}
                                    </div>
                                </div>
                                <div className="post-footer-right w-50">
                                    {
                                        comments?.length > 0
                                        ? comments?.length === 1
                                            ? <p className='text-end'>1 Comment</p>
                                            : <p className='text-end'>{comments?.length} Comments</p>
                                        : <p className='text-end'>0 Comment</p>
                                    }
                                </div>
                            </div>                 
                        </div>
                    : null
                  }
                  <p>Comment</p>
                </div>
                <div className="conversation-body" id="scrollableDiv">
                  {
                    comments?.map((comment: any, index: number) => (
                      workspaceSettings?.workspaceName !== comment?.repliedByUserName
                      ?
                      <div key={index} className="conversation-person-1">
                        <div className="details">
                          {
                            comment?.repliedByUserImageUrl
                            ? <img
                              className="user-avatar"
                              src={comment?.repliedByUserImageUrl}
                              alt="avatar"
                            />
                            : <img
                                  className="user-avatar"
                                  src={getGenerateApiImageByName(comment?.repliedByUserName)}
                                  alt="avatar"
                              />
                          }
                          <div className="description">
                            <div className={`d-flex align-items-center user-ratings-container`}>
                              <div className="user_name me-2">
                                {comment?.repliedByUserName || "User Name"}
                              </div>
                            </div>

                            <div className="d-flex align-items-center py-1">
                              <div className="mx-1 client-container">
                                mentioned <span className="client-name">{workspaceSettings?.workspaceName}</span>
                              </div>
                            </div>
                            {
                              comment?.attachment
                              ? <ShowFiles
                                  data={ comment?.attachment
                                    // [
                                    //   {  
                                    //     attachmentUrl: comment?.attachmentUrl,
                                    //     attachmentType: comment?.attachmentType,
                                    //     id: comment?.attachmentId
                                    //   }
                                    // ]
                                  }
                                />
                              : null
                            }
                            <p>{comment?.mentionReplyMessage}</p>
                            <div className="like-edit-reply">
                              <div className='image-likes'>
                                <img
                                  className={`image-like ${!comment?.replyId ? "disable-hover" : ""}`}
                                  src={getImage("like")}
                                  alt="like"
                                  onClick={() => {
                                    if (!comment?.replyId) {
                                        return;
                                    } else {
                                        handleLike(comment, comment?.isLike);
                                    }
                                  }}
                                />
                                <div
                                  className="likes"
                                  style={{color: "inherit"}}
                                >
                                  {
                                    (comment?.likeCount === 0 || comment?.likeCount === 1)
                                    ? comment?.likeCount + " Like"
                                    : comment?.likeCount + " Likes"
                                  }
                                </div>
                              </div>
                              <div className={`reply-edit ${!comment?.replyId ? "disable-hover" : ""}`}
                                onClick={() => {
                                    if (!comment?.replyId) {
                                        return;
                                    } else {
                                        setCurrentRecord(comment);
                                        setIsReply(true);
                                        dispatch(toggleReplyingOn(true));
                                        dispatch(toggleIsInReplyingMode(true));
                                    }
                                }}
                              >Reply</div>
                            </div>
                          </div>
                        </div>
                        <p className="text-start mt-2 date">
                          Mention - {" "}
                          <span>
                            {
                              // new Date(createdOn).toDateString() +
                              moment(comment?.repliedOn).format("DD MMM YYYY, hh:mm:ss A")
                              // moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + ("Tezla Inc")
                              // moment(details?.requested_time).format('Y-m-d')
                            }
                          </span>
                        </p>
                      </div>
                      :
                      <div className="conversation-person-2">
                        <div className="details">
                          {
                            workspaceSettings?.logo
                            ?   <img
                                    className="user-avatar"
                                    src={workspaceSettings?.logo || user_avatar_2}
                                    alt="user_avatar"
                                />
                            : <img
                                  className="user-avatar"
                                  src={getGenerateApiImageByName(comment?.repliedByUserName)}
                                  alt="avatar"
                              />
                          }
                          <div className="description">
                            <p>{comment?.replyMessage}</p>
                          </div>
                        </div>
                        <p className="text-end mt-2 date">
                            {"Replied"} -{" "}
                            <span>
                            {
                                moment(comment?.repliedOn).format("DD MMM YYYY, hh:mm:ss A") +
                                " by " +
                                (workspaceSettings?.workspaceName || "WS Name")
                                // moment(details?.requested_time).format('Y-m-d')
                            }
                            </span>
                        </p>
                      </div>
                    ))
                  }
                </div>
              </Scrollbars>
            </div>
            <div className="conversation-footer">
              <div
                style={{ padding: "0 10px" }}
                className="d-flex align-items-center justify-content-between my-2"
              >
                <p className="text-start sender">
                  {
                    sender
                    ? `${sender} is typing...`
                    : `Sending as ${workspaceSettings?.workspaceName || "WS Name"}`
                  }
                </p>
                <img
                  onClick={() => {
                    setIsReply(false);
                    dispatch(toggleReplyingOn(false));
                    dispatch(toggleIsInReplyingMode(false));
                  }}
                  src={(sender || isReplyingOn || isInReplyingMode) ? lock_icon : unlock_icon}
                  alt="lock-unlock"
                />
              </div>
              <div className="message-box">
                <div className="message">
                  <div className="text-container">
                    <textarea
                      ref={textRef}
                      className=""
                      value={replyText}
                      placeholder="Write here..."
                      onChange={(event: any) => {
                        setCurrentReplyText(event.target.value);
                        debouncedTextChange(event.target.value);
                      }}
                      style={{ resize: "none" }}
                      autoFocus={isReply}
                    />
                  </div>
                  <div className="send-reply-action">
                    <div
                      onClick={() => {
                        if (isReply) {
                            handleReplyRecord(replyText, currentRecord);
                        } else {
                            !currentRecord && setCurrentRecord(conversation);
                            setIsReply(true);
                            dispatch(toggleReplyingOn(true));
                            dispatch(toggleIsInReplyingMode(true));
                        }
                      }}
                      // type="button"
                    >
                      {isReply ? (
                        <img src={send_icon} alt="save-account" />
                      ) : replyText ? (
                        <img src={edit_icon} alt="edit" />
                      ) : (
                        <img src={reply_icon} alt="reply" />
                      )}
                    </div>
                  </div>
                  <p
                    className={`click-reply-des ${(isReply && !sender) ? "d-none" : ""}`}
                  >
                    {
                      sender
                      ? "You can not reply now. Editor is locked"
                      : "Click reply and start adding your reply."
                    }
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  )
}
