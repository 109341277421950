
// import dashboard_icon from "../../../assets/icons/blur-dashboard.svg";
import mobile_dashboard_icon from "../../../assets/mobile-icons/dashboard-2-line.svg";
import mobile_inbox_icon from "../../../assets/mobile-icons/mobile-inbox-icon.svg";
import mobile_message_inbox_icon from "../../../assets/mobile-icons/message-inbox.svg";
import mobile_promotion_icon from "../../../assets/mobile-icons/promotion-icon.svg";
import mobile_gallery_icon from "../../../assets/mobile-icons/gallery-icon.svg";
import mobile_feedback_icon from "../../../assets/mobile-icons/feedback-line.svg";
import mobile_video_icon from "../../../assets/mobile-icons/video-line-icon.svg";
import mobile_google_icon from "../../../assets/mobile-icons/google-line-icon.svg";
import mobile_facebook_icon from "../../../assets/mobile-icons/facebook-line-icon.svg";
// import mobile_twitter_icon from "../../../assets/mobile-icons/twitter-line-icon.svg";
import dashboard_icon from "../../../assets/icons/dashboard-2-line.svg";
import inbox_icon from "../../../assets/icons/inbox-line.svg";
import message_inbox_icon from "../../../assets/icons/bot-inbox-line.svg";
import promotion_icon from "../../../assets/icons/commerce-mike.svg";
import feedback_icon from "../../../assets/icons/feedback-line.svg";
import video_icon from "../../../assets/icons/video-line-icon.svg";
import google_icon from "../../../assets/icons/google-line-icon.svg";
import facebook_icon from "../../../assets/icons/facebook-line-icon.svg";
// import twitter_icon from "../../../assets/icons/blur-twitter.svg";
import gallery_icon from "../../../assets/icons/gallery.svg";
// import dashboard_icon from "../../../assets/img/dashboard-normal.svg"; // old
// import inbox_icon from "../../../assets/img/inbox-normal.svg";
// import feedback_icon from "../../../assets/img/feedback-normal.svg";
// import video_icon from "../../../assets//img/video-normal.svg";
// import google_icon from "../../../assets/img/google-normal.svg"; // old
// import facebook_icon from "../../../assets/img/facebook-normal.svg"; // old
// import twitter_icon from "../../../assets/icons/blur-twitter.svg";


const menu_routes = [
    {
        // This will be in menu_routes on the top first
        id: 1,
        isWall: true,
        title: "Dashboard",
        icon: dashboard_icon,
        mobile_icon: mobile_dashboard_icon,
        key: "dashboard",
        root: true,
        permissionName: "dashboard",
        url: "/dashboard",
    },
    {
        id: 2,
        isWall: true,
        title: "Review Inbox",
        icon: inbox_icon,
        // mobile_icon: inbox_icon,
        mobile_icon: mobile_inbox_icon, // mobile icon is not available
        key: "unified",
        permissionName: "unifiedInbox",
        url: "/unified",
    },
    {
        id: 9,
        mobile_title: "Message Inbox",
        title: "Messages",
        icon: message_inbox_icon,
        mobile_icon: mobile_message_inbox_icon,
        key: "whatsapp",
        sub_key: "messages",
        permissionName: "dashboard",
        // url: "/messages",
        url: "/whatsapp",
    },
    {
        id: 10,
        title: "Promotions",
        icon: promotion_icon,
        mobile_icon: mobile_promotion_icon,
        key: "promotions",
        permissionName: "dashboard",
        url: "/promotions",
    },
    // New requirement
    {
        id: 8,
        title: "Gallery",
        icon: gallery_icon,
        mobile_icon: mobile_gallery_icon,
        key: "gallery",
        permissionName: "gallery",
        url: "/gallery",
    },

];

const wall_menu_routes = [
    {
        // This will be in menu_routes on the top first
        id: 1,
        isWall: true,
        title: "Dashboard",
        icon: dashboard_icon,
        mobile_icon: mobile_dashboard_icon,
        key: "dashboard",
        root: true,
        permissionName: "dashboard",
        url: "/dashboard",
    },
    {
        id: 2,
        isWall: true,
        title: "Review Inbox",
        icon: inbox_icon,
        mobile_icon: inbox_icon,
        // mobile_icon: mobile_inbox_icon, // mobile icon is not available
        key: "unified",
        permissionName: "unifiedInbox",
        url: "/unified",
    },
    // New requirement
    {
        id: 3,
        isWall: true,
        title: "Feedback",
        icon: feedback_icon,
        mobile_icon: mobile_feedback_icon,
        key: "feedback",
        permissionName: "customerFeedback",
        url: "/customer-feedback",
    },
    {
        id: 4,
        isWall: true,
        title: "Video Testimonial",
        icon: video_icon,
        mobile_icon: mobile_video_icon,
        key: "video",
        permissionName: "videoTestimonial",
        url: "/video-testimonial",
    },
    {
        id: 5,
        isWall: true,
        title: "Google",
        icon: google_icon,
        mobile_icon: mobile_google_icon,
        key: "google",
        permissionName: "google",
        url: "/google",
    },
    {
        id: 6,
        isWall: true,
        title: "Facebook",
        icon: facebook_icon,
        mobile_icon: mobile_facebook_icon,
        key: "facebook",
        permissionName: "facebook",
        url: "/facebook",
    },

    // {
    //     id: 7,
    //     title: "Twitter",
    //     icon: twitter_icon,
    //     key: "twitter",
    //     permissionName: "twitter",
    //     url: "/twitter",
    // },

];

export { menu_routes, wall_menu_routes };