import React, { useEffect, useState, useRef } from 'react';
import { Button, DatePicker, Form, Input, TimePicker } from 'antd';
import { convertImageToBase64 } from '../../../../../utils/convertingFunction';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorMessage } from '../../../../../dashboard/reducer';
import { extractImageNameFromUrl } from '../../../../../pages/page-routes';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import { getCampaignImage } from '../../../../../dashboard/image_service';
import { extract_campaign_data_from_xlsx_file, extract_title_variables_from_file } from '../../../../../utils/serviceXLSX';
import { setCampaignDetailsPayload } from '../../../reducer';
import { RootState } from '../../../../../store';
import { getSelectedTemplate } from '../../../saga';

type CreateFormProps = {
    onCancel?: () => void;
    onParentCallback?: (data: any, file?: any) => void;
};

const CreateForm: React.FC<any> = ({onCancel, onParentCallback }: any) => {
    const dispatch = useDispatch();
    const {campaign_details_payload, template_list,}: any = useSelector((state: RootState) => state?.promotionsState);
    const [Templates, setTemplates] = useState<any[]>([]);
    useEffect(() => {
        if(!Templates?.length) {
            setTemplates(template_list?.length ? template_list : []);
        }
    }, [template_list?.length])
    const handleSubmit = () => {
        onParentCallback && onParentCallback();
    };

    const handleFileChange = async (e: any) => {
        // file local path
        try {
            const file = e.target.files[0];
            if (file) {
                // const path = e.target.value;
                const { name } = file;
                const { result: extracted_xlsx_data, campaign_contact_table_data, error }: any = await extract_campaign_data_from_xlsx_file(file);
                if (error) {
                    dispatch(setErrorMessage(error || "Something went wrong"));
                    return;
                };
                const res_excel_sheet_variables: any[] = await extract_title_variables_from_file({ file });
                const excel_sheet_title_variables = new Set([...res_excel_sheet_variables]);
                const excel_sheet_variables: any[] = [...excel_sheet_title_variables];
                console.log({ name, excel_sheet_variables, extracted_xlsx_data, campaign_contact_table_data });
                dispatch(setCampaignDetailsPayload({
                    ...campaign_details_payload,
                    excel_sheet_variables,
                    extracted_xlsx_data,
                    campaign_contact_table_data,
                    file,
                }));
                e.target.value = null;
            }
        } catch (error) {
            console.log({ error });
            dispatch(setErrorMessage("Something went wrong while reading the file"));
        }
        
    };

    const handleChange = async(key: any, value: any) => {
        if (key === "selected_template") {
            const selected_template = await getSelectedTemplate(value?.templateId);
            dispatch(setCampaignDetailsPayload({
                ...campaign_details_payload,
                [key]: selected_template || value,
            }));
        } else {
            dispatch(setCampaignDetailsPayload({
                ...campaign_details_payload,
                [key]: value,
            }));
        }
    };

    const handleSearchTemplate = (search_string = "") => { 
        const filtered_template = template_list?.filter((template: any) => {
            return template?.templateDisplayName?.toLowerCase().includes(search_string?.trim()?.toLowerCase());
        });
        setTemplates(search_string ? filtered_template : template_list);
    };

    const fileInputRef = useRef(null);
    return (
        <React.Fragment>
            <div className="form-input-container">
                <p className='header-title'>Create a Campaign</p>

                <form className='form'>
                    <div className='input-group'>
                        <input
                            type="text"
                            name={"campaignName"}
                            // name={"broadcastName"}
                            autoFocus={true}
                            autoComplete='off'
                            autoSave='off'
                            // onChange={(e: any) => handleChange("broadcastName", e.target.value)}
                            onChange={(e: any) => handleChange("campaignName", e.target.value)}
                            value={campaign_details_payload?.campaignName || ""}
                            placeholder={"Enter the campaign name"}
                        />
                    </div>

                    <div className='input-group'>
                        <input
                            type="text" name={"excel_file"}
                            autoComplete='off'
                            autoSave='off'
                            readOnly={true}
                            placeholder={"Contacts.xlsx"}
                            value={campaign_details_payload?.file?.name?.replaceAll(" ", "_") || ""}
                            // value={campaign_details_payload?.file?.name || extractImageNameFromUrl(fileName) || ""}
                        />
                        <input
                            // disabled={image ? false : true}
                            id="file-data"
                            type="file"
                            autoSave='off'
                            accept=".xlsx"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            className="form-input-field d-none w-100 my-1"
                            placeholder="xlsx sheet"
                        />
                        <label className='image-label' htmlFor="file-data">Upload</label>
                    </div>
                </form>
                
                <Input
                    style={{ width: '70%', margin: '1rem auto' }}
                    placeholder='Search for Templates'
                    addonBefore={<SearchOutlined />}
                    addonAfter={null}
                    size='middle'
                    onChange={(e: any) => {
                        handleSearchTemplate(e?.target?.value);                    // handleChange(e.target.value, setCurCampaignText)
                    }}
                />

                <div className='create-campaign-table'>
                    <table className="table table-borderless m-0">
                        <thead className='table-thead sticky-top' style={{top: 0, zIndex: 999}}>
                            <tr>
                                <th>Template Name</th>
                                <th>Category</th>
                                <th>Select</th>
                            </tr>
                            <tr>
                                <th style={{ padding: '0.25rem 0'}}></th>
                                <th style={{ padding: '0.25rem 0'}}></th>
                                <th style={{ padding: '0.25rem 0'}}></th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            Templates?.map((template: any, index: number) => (
                            <tr key={index}>
                                <td>
                                    <div className='user-template'>
                                        <img
                                            src={getCampaignImage("table-template")}
                                            alt="template-icon"
                                        />
                                        <p>{template?.templateDisplayName || "templateDisplayName"}</p>
                                    </div>
                                </td>
                                <td>{template?.templateCategory || "templateCategory"}</td>
                                <td>
                                    <img 
                                        src={template?.templateId === campaign_details_payload?.selected_template?.templateId ? getCampaignImage("template-active-select") : getCampaignImage("template-inactive-select")}
                                        style={{cursor: 'pointer'}}
                                        onClick={() => handleChange("selected_template", template)}
                                        alt="select-icon"
                                    />
                                </td>
                            </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>


                <div className='form-button-container'>
                    <button
                        type='button'
                        className='cancel-button'
                        onClick={onCancel}
                    >
                        Cancel
                    </button>
                    <button
                        // disabled={}
                        className='create-button'
                        onClick={() => {
                            const { campaignName, selected_template, file} = campaign_details_payload;
                            if (campaignName && selected_template?.templateId && file?.name) {
                                handleSubmit();
                                return;
                            }
                            if (!campaignName?.trim()) {
                                dispatch(setErrorMessage("Please enter the campaign name"));
                            } else if (!selected_template?.templateId) {
                                dispatch(setErrorMessage("Please select a template"));
                            } else if (!file?.name) {
                                dispatch(setErrorMessage("Please upload a .xlsx file"));
                            } else {
                                dispatch(setErrorMessage("Please fill all the fields"));
                            }
                        }}
                        type='button'
                    >
                        Next
                    </button>
                </div>
            </div>
        </React.Fragment>
    )
};

export default CreateForm;
