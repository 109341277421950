import './button.scss';
interface Props {
    children: any;
    classes: any;
    disabled?: boolean;
    onClick?: () => void;
    color?: any;
    style?: any;
    type?: string;
}

const Button: React.FC<Props> = ({ classes, children, disabled, onClick, color, style, type }) => {

    return (
        <button type={'button'} style={{
            ...style, cursor: disabled ? 'not-allowed' : 'pointer',
        }} onClick={onClick} disabled={disabled} className={classes}>{children}</button>
    )
}

export default Button;