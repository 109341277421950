import Routing from './Routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-quill/dist/quill.snow.css'; // Import Quill Snow theme
import 'react-quill/dist/quill.bubble.css'; // Import Quill Bubble theme
import Success from './components/notifications/Success';
import Error from './components/notifications/Error';
import InfoMessage from './components/notifications/Info';
import './App.scss';

function App() {
  return (
    <div className="App">
      <Routing/>
      <Success />
      <InfoMessage/>
      <Error/>
    </div>
  );
}

export default App;
