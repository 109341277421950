import Constants from "../../constants";

const initialState = {
    workspaceSettings: null,
    loading: false,
    error: null,
    newWorkspaceResult: null,
    google_locations: [],
    google_connection_location_data: null,
    google_location_payload: null,
    google_connected_mapped_locations: [],
    facebook_connection_page_data: null,
    facebook_connected_mapped_pages: [],
    show_fb_page_map_modal: false,
    isUpdatedWorkspace: false,
    locationList: [],
    google_modal_visible: false,

}


export const toggleGoggleModalVisible = (data: any = null) => {
    return {
        type: "TOGGLE_GOOGLE_MODAL_VISIBLE",
        payload: data
    };
}

export const createNewWorkspace = (data: any = null) => {
    return {
        type: Constants.CREATE_NEW_WORKSPACE,
        payload: data
    };
}

export const setNewWorkspaceData = (data: any) => {
    return {
        type: Constants.SET_NEW_WORKSPACE_DATA,
        payload: data
    };
}

// get workspace settings
export const getWorkspaceSettings = (data: any = null) => {
    return {
        type: Constants.GET_WORKSPACE_SETTING,
        payload: data,
    };
}

export const setWorkSpaceData = (data: any) => {
    return {
        type: Constants.SET_WORKSPACE_SETTING_TRIGGER,
        payload: {
            workspaceSettings: data?.table?.length ? data?.table[0] : {},
        }
    };
}

// get workspace locations
export const getWorkspaceLocations = (data: any = null) => {
    return {
        type: Constants.GET_WORKSPACE_LOCATIONS,
        payload: data || sessionStorage.getItem('wuid')
    };
}

export const setWorkspaceLocations = (data: any) => {
    return {
        type: Constants.SET_WORKSPACE_LOCATIONS,
        payload: data
    };
}

export const updateWorkspaceLocation = (data: any) => {
    return {
        type: Constants.UPDATE_WORKSPACE_LOCATION,
        payload: data
    }
};

// update workspace settings
export const updateWorkSettings = (data: any = null) => {
    return {
        type: Constants.UPDATE_WORKSPACE_SETTING,
        payload: data
    };
}

export const setUpdatedWorkspaceSettingsData = (data: any) => {
    return {
        type: Constants.SET_UPDATED_WORKSPACE_SETTING_DATA,
        payload: data
    };
}

// google locations
export const setGoogleConnectionLocations = (data: any) => {
    return {
        type: Constants.SET_GOOGLE_CONNECTION_LOCATIONS,
        payload: data
    };
}

export const setGoogleLocationPayload = (data: any) => {
    return {
        type: "SET_GOOGLE_LOCATION_PAYLOAD",
        payload: data
    }
}

export const getGoogleConnectionLocations = (data: any = null) => {
    return {
        type: Constants.GET_GOOGLE_CONNECTION_LOCATIONS,
        payload: data
    };
}

// google connection location ids map
export const triggerMapGoogleConnectionLocations = (data: any) => {
    return {
        type: Constants.TRIGGER_MAP_GOOGLE_CONNECTION_LOCATIONS,
        payload: data
    }
}

export const setMapGoogleConnectionLocations = (data: any) => {
    return {
        type: Constants.SET_MAP_GOOGLE_CONNECTION_LOCATIONS,
        payload: data
    }
}

export const getConnectedMapGoogleLocations = (data: any = sessionStorage.getItem('wuid')) => {
    return {
        type: Constants.GET_CONNECTED_MAPPED_GOOGLE_LOCATIONS,
        payload: data
    }
}

export const setConnectedMappedGoogleLocations = (data: any) => {
    return {
        type: Constants.SET_CONNECTED_MAPPED_GOOGLE_LOCATIONS,
        payload: data
    }
}

// Facebook connection pages - not using
export const triggerFacebookConnectionPages = (data?: any) => {
    return {
        type: Constants.TRIGGER_FACEBOOK_CONNECTION_PAGES,
        payload: data
        // payload: data || sessionStorage.getItem('wuid')
    };
}

export const getFacebookConnectionPages = (data: any = null) => {
    return {
        type: Constants.GET_FACEBOOK_CONNECTION_PAGES,
        payload: data || sessionStorage.getItem('wuid')
        // payload: data || sessionStorage.getItem('wuid')
    };
}

export const setFacebookConnectionPages = (data: any) => {
    return {
        type: Constants.SET_FACEBOOK_CONNECTION_PAGES,
        payload: data
    };
}

export const triggerMapFacebookConnectionPages = (data: any) => {
    return {
        type: Constants.TRIGGER_MAP_FACEBOOK_CONNECTION_PAGES,
        payload: data
    }
}

export const toggleShowFbPageMapModal = (data: any) => {
    return {
        type: Constants.TOGGLE_SHOW_FB_PAGE_MAP_MODAL,
        payload: data
    }
}

export const toggleWorkspaceUpdate = (data: any) => {
    return {
        type: "UPDATE_WORKSPACE",
        payload: data
    }
}

const workspaceManagementSettingReducer = (state: any = initialState, action: any) => {
    // console.log("Workspace Setting Data ===> ",{workspace_setting_data: action});

    switch (action.type) {

        case Constants.TOGGLE_SHOW_FB_PAGE_MAP_MODAL:
            // console.log({ TOGGLE_SHOW_FB_PAGE_MAP_MODAL: action.payload });

            return {
                ...state,
                show_fb_page_map_modal: action.payload,
            }

        case Constants.SET_FACEBOOK_CONNECTION_PAGES:
            return {
                ...state,
                facebook_connected_mapped_pages: action.payload
            }

        case Constants.SET_CONNECTED_MAPPED_GOOGLE_LOCATIONS:
            return {
                ...state,
                google_connected_mapped_locations: action.payload,
                google_connection_location_data: null
            }
        case "SET_GOOGLE_LOCATION_PAYLOAD":
            return {
                ...state,
                google_location_payload: action.payload,
            }
        case Constants.SET_MAP_GOOGLE_CONNECTION_LOCATIONS:
            return {
                ...state,
                google_connection_location_data: action.payload
            }
        case Constants.SET_WORKSPACE_SETTING_TRIGGER:
            return {
                ...state,
                workspaceSettings: {
                    ...state?.workspaceSettings,
                    ...action?.payload?.workspaceSettings
                }
            };
        case Constants.SET_UPDATED_WORKSPACE_SETTING_DATA:
            return {
                ...state,
                workspaceSettings: {
                    ...state?.workspaceSettings,
                    ...action?.payload
                },
            };
        case Constants.SET_NEW_WORKSPACE_DATA:
            return {
                ...state,
                newWorkspaceResult: action.payload
            };
        case Constants.SET_GOOGLE_CONNECTION_LOCATIONS:
            return {
                ...state,
                google_locations: action.payload
            };
        case "UPDATE_WORKSPACE":
            return {
                ...state,
                isUpdatedWorkspace: action.payload
            }
        case Constants.SET_WORKSPACE_LOCATIONS:
            return {
                ...state,
                locationList: action.payload
            };
        case "TOGGLE_GOOGLE_MODAL_VISIBLE":
            return {
                ...state,
                google_modal_visible: action.payload
            };
        case Constants.LOGOUT:
            return {
                ...state,
                isUpdatedWorkspace: false,
                workspaceSettings: null,
                loading: false,
                newWorkspaceResult: null,
                google_connection_location_data: null,
                google_location_payload: null,
                google_locations: [],
                google_connected_mapped_locations: [],
                facebook_connection_page_data: null,
                facebook_connected_mapped_pages: [],
                error: null,
                locationList: [],
                google_modal_visible: false,
            };
        default:
            return state;
    }
}

export default workspaceManagementSettingReducer;