import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from '../../../../store';
import { useDispatch } from 'react-redux';
import './Integrations.scss';
import WhatsAppIntegration from './IntegrationComponents/WhatsAppIntegration';
import GoogleIntegration from './IntegrationComponents/GoogleIntegration';
import FacebookIntegration from './IntegrationComponents/FacebookIntegration';
// import { setGoogleLocationPayload, triggerMapGoogleConnectionLocations, toggleGoggleModalVisible } from '../../workSpaceReducer';

// const Google = "https://images.clapup.io/clapupapp/btn_google_light_normal_ios.svg";
const Integrations: React.FC = () => {
  const history = useNavigate();
  const { userPermissions }: any = useSelector((state: RootState) => state?.login);
  // permission
  useEffect(() => {
    const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "integration") : null;
    // const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "generalSettings-Account") : null;
    if(permission){
        permission?.value === 0 && history('/not-found');
    }
  }, [userPermissions?.length]);
  
  return (
    <React.Fragment>
      {/* Google */}
      <GoogleIntegration />
      
      {/* Facebook */}
      <FacebookIntegration/>
      
      {/* What's App */}
      <WhatsAppIntegration/>
    </React.Fragment>
  );
};

export default Integrations;