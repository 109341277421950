import { all, fork } from "redux-saga/effects";
import dashboardSaga from "../dashboard/saga";
import loginSaga from "../signup-login/saga";
import profileSaga from "../profile/saga";
// mmhk-region
import headerSaga from "../components/layout/header/saga";
import profileManagementSettingSaga from "../pages/profile-management/saga";
import workspaceManagementSettingSaga from "../pages/workspace-management/saga";
import bioPageSettingSaga from "../pages/biopage-management/saga";
import userManagementSaga from "../pages/user-management/saga";
import botSaga from "../pages/bot-management/saga";
import mediaManagementSaga from "../components/MediaContents/saga";
import promotionsSaga from "../components/Promotions/saga";

export function* rootSaga() {
    yield all([
        fork(loginSaga),
        fork(dashboardSaga),
        fork(profileSaga),
        // mmhk-region
        fork(headerSaga),
        fork(profileManagementSettingSaga),
        fork(userManagementSaga),
        fork(workspaceManagementSettingSaga),
        fork(bioPageSettingSaga),
        fork(mediaManagementSaga),
        fork(botSaga),
        fork(promotionsSaga),
    ]);
}