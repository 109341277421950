import './ratings.scss';
interface Props {
    value?: any;
    children?: React.ReactNode;
}
const Ratings: React.FC<Props> = ({value}) => { 
    
    return (
        <div className="star-ratings-css">
            <div className="star-ratings-css-top" style={{width: 10*value*2+'%'}}>
                <span>★</span>
                <span>★</span>
                <span>★</span>
                <span>★</span>
                <span>★</span>
            </div>
            <div className="star-ratings-css-bottom">
                <span>★</span>
                <span>★</span>
                <span>★</span>
                <span>★</span>
                <span>★</span>
            </div>
        </div>
    )
}

export default Ratings;