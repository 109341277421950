import React, { useState } from 'react';
import { setErrorMessage } from '../../../../dashboard/reducer';
import { extractImageNameFromUrl } from '../../../../pages/page-routes';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import CreateForm from './FormSteps/CreateForm';
import ContactForm from './FormSteps/ContactForm';
import VariablesForm from './FormSteps/VariablesForm';
import './Campaign.scss';

type CampaignProps = {
    onCancel?: () => void;
    onParentCallback?: (data?: any, file?: any) => void;
};

const CampaignForm: React.FC<CampaignProps> = ({ onCancel, onParentCallback }) => {
    const dispatch = useDispatch();

    const handleSubmit = () => {
        const data = {
            clapupUserId: sessionStorage.getItem('cuid'),
            workspaceId: sessionStorage.getItem('wuid'),
            // roleId: sessionStorage.getItem('roleId'),
            
        }
        // console.log({data});
        onParentCallback && onParentCallback(data);
    };

    const [step, setStep] = useState<any>(1);
    const handleSep_1_data = () => {
        setStep(2);
    }
    
    const handleSep_2_data = () => {
        setStep(3);
    }
    
    const handleSep_3_data = () => {
        // setStep(null);
        handleSubmit();
    }

    return (
        <div className='form-campaign-container'>
            {
                step === 1 
                ? <CreateForm onCancel={onCancel} onParentCallback={handleSep_1_data} />
                : step === 2 
                ? <ContactForm onCancel={() => setStep(1)} onParentCallback={handleSep_2_data} />
                : step === 3 
                ? <VariablesForm onCancel={() => setStep(2)} onParentCallback={handleSep_3_data} />
                : null
            }
        </div>
    )
};

export default CampaignForm;
