import { takeEvery, put, call, all, delay } from 'redux-saga/effects';
import axios from "axios";
import Constants from '../../constants';
import {
    getWorkspaceLocations, getWorkspaceSettings,
    setConnectedMappedGoogleLocations, setFacebookConnectionPages,
    setGoogleConnectionLocations, getFacebookConnectionPages,
    toggleShowFbPageMapModal,
    setMapGoogleConnectionLocations, setNewWorkspaceData,
    // setUpdatedWorkspaceSettingsData,
    setWorkSpaceData, setWorkspaceLocations, toggleWorkspaceUpdate
} from './workSpaceReducer';
// import { findWorkspaceList } from '../../components/layout/header/headerReducer';
import { generateAccessToken } from '../../profile/reducer';
import { api_url } from '../../utils/server';
import { setSuccessMessage } from '../../dashboard/reducer';
import { findWorkspaceList } from '../../components/layout/header/headerReducer';
import { setFacebookConnection, triggerGetIntegrationConnections } from '../../signup-login/loginReducer';

// Worker Saga:
function* getWorkSpaceSetting(action: any = {}): Generator<any> {
    const wuid = localStorage.getItem('workspace_id') || sessionStorage.getItem('wuid');
    const cuid = localStorage.getItem('cuid') || sessionStorage.getItem('cuid');
    if (!wuid || !cuid) {
        return;
    }
    try {
        const url = api_url + `/api/v1.2/Clapup/GetWorkspaceDetails?workspaceId=${action?.payload || wuid}&clapupUserId=${cuid}`;
        const { data, status }: any = yield call(axios.get, url, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {   
            yield put(setWorkSpaceData(data));
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* updateWorkspaceSettings(action: any): Generator<any> {
    // console.log({workspaceSettingPayload: action?.payload});
    
    try {
        const url = action?.payload?.logo 
                    ? api_url + "/api/v1.2/Clapup/UpdateWorkspaceLogo" 
                    : api_url + "/api/v1.2/Clapup/UpdateWorkspaceElements";
        const {data, status}: any = yield call(axios.post, url, action?.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {   
            console.log("Updated workspace settings => ", data);
            if(data?.length && data[0]?.success === "success"){
                yield put(getWorkspaceSettings());
                
                if (action?.payload?.columnName === "WorkspaceName") {
                    yield put(toggleWorkspaceUpdate(true));
                    yield put(findWorkspaceList());
                }

                // if(action?.payload?.columnName === "WorkspaceType"){
                //     yield put(setUpdatedWorkspaceSettingsData({ workspaceType: action?.payload?.columnValue }));
                // }else if(action?.payload?.logo){
                //     console.log({imageData: data});
                    
                //     // yield put(setUpdatedWorkspaceSettingsData({workspaceName: action?.payload?.columnValue}));
                // }
            }
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* createNewWorkSpace(action: any): Generator<any> {
    try {
        const url = api_url + "/api/v1.2/Clapup/CreateWorkSpace";
        const { data, status }: any = yield call(axios.post, url, action?.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {   
            console.log("Created workspace => ", data);
            const {CreatedBy} = data[0] || {};
            if(CreatedBy){
                sessionStorage.setItem('cuid', CreatedBy);
            }
            yield put(setNewWorkspaceData(data));
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// get workspace locations
function* getWorkspaceLocationData(action: any): Generator<any> {
    try {
        const url = api_url + `/api/v1.2/google/GetGoogleLocationList?workspaceId=${action.payload}`;
        const { data, status }: any = yield call(axios.get, url, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });

        if (status === 200) {   
            // const {Table} = data || {};
            // // console.log("Connection locations => ", Table);
            yield put(setWorkspaceLocations(data));
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* updateWorkspaceLocation(action: any): Generator<any> { 
    try {
        const url = api_url + "/api/v1.2/google/UpdateLocationDisplayName";
        const { status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });

        if (status === 200) { 
            yield put(getWorkspaceLocations());
            yield put(setSuccessMessage("Saved Successfully"));
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
};

// google connection locations
function* getGoogleConnectionLocations(action: any): Generator<any> {
    try {
        const url = api_url + "/api/v1.2/google/locationlist";
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {   
            const {Table} = data || {};
            // console.log("Connection locations => ", Table);
            yield put(setGoogleConnectionLocations(Table));
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}



function* triggerMapGoogleLocationConnection(action: any): Generator<any> {
    try {
        const url = api_url + "/api/v1.2/google/MapGoogleLocationAndWorkspace";
        const {data, status}: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });if (status === 200) {   
            yield put(setMapGoogleConnectionLocations(data));
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* getConnectedMappedGoogleLocations(action: any): Generator<any> {
    try {
        const url = api_url + `/api/v1.2/google/MappedGoogleLocationList?workspaceId=${action.payload}`; ;
        const {data, status}: any = yield call(axios.get, url, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {   
            yield put(setConnectedMappedGoogleLocations(data));
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// facebook connection pages
// function* triggerFacebookConnection(action: any): Generator<any> {
//     try {
//         const url = api_url + "/api/v1.2/facebook/MappedFacebookPageList";
//         const { data, status }: any = yield call(axios.post,
//             url,
//             action.payload,
//             {
//             headers: {
//               'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
//               'Content-Type': 'application/json'
//             }
//         });
//         if (status === 200) {
//             console.log({ data });
//             // yield put(setFacebookConnectionPages(data));
//         } 
//     } catch (error) {
//         const { response }: any = error;
//         console.log('error', response);
//         const { status } = response || {};
//         (status === 403 || status === 401) && (yield put(generateAccessToken()));
//     }
// }
// old is get method --- haha
function* triggerGetFacebookConnectionPages(action: any): Generator<any> {
    try {
        const url = api_url + "/api/v1.2/facebook/MappedFacebookPageList?workspaceId=" + action.payload;
        const { data, status }: any = yield call(axios.get, url, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {   
            yield put(setFacebookConnectionPages(data));
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* triggerMapFacebookConnectionPages(action: any): Generator<any> {
    try {
        const url = api_url + "/api/v1.2/facebook/MapFacebookPagesAndWorkspace";
        const {data, status}: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {   
            // yield put(setMapFacebookConnectionPages(data));
            // delay(200);
            const workspaceId = sessionStorage.getItem('wuid');
            yield put(triggerGetIntegrationConnections(workspaceId));
            yield put(getFacebookConnectionPages());
            yield put(toggleShowFbPageMapModal(false));
            yield put(setFacebookConnection(null));
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// Watcher Saga:
function* workspaceManagementSettingSaga() {
    
    yield all([
        takeEvery(Constants.TRIGGER_MAP_FACEBOOK_CONNECTION_PAGES, triggerMapFacebookConnectionPages),
        takeEvery(Constants.GET_FACEBOOK_CONNECTION_PAGES, triggerGetFacebookConnectionPages),
        // takeEvery(Constants.TRIGGER_FACEBOOK_CONNECTION_PAGES, triggerFacebookConnection),
        takeEvery(Constants.GET_CONNECTED_MAPPED_GOOGLE_LOCATIONS, getConnectedMappedGoogleLocations),
        takeEvery(Constants.TRIGGER_MAP_GOOGLE_CONNECTION_LOCATIONS, triggerMapGoogleLocationConnection),
        takeEvery(Constants.GET_GOOGLE_CONNECTION_LOCATIONS, getGoogleConnectionLocations),
        takeEvery(Constants.CREATE_NEW_WORKSPACE, createNewWorkSpace),
        takeEvery(Constants.GET_WORKSPACE_SETTING, getWorkSpaceSetting),
        takeEvery(Constants.UPDATE_WORKSPACE_SETTING, updateWorkspaceSettings),
        takeEvery(Constants.GET_WORKSPACE_LOCATIONS, getWorkspaceLocationData),
        takeEvery(Constants.UPDATE_WORKSPACE_LOCATION, updateWorkspaceLocation),

    ]);
}

export default workspaceManagementSettingSaga;