const Constants = {
    // generate token
    GENERATE_ACCESS_TOKEN: "GENERATE_ACCESS_TOKEN",
    SET_ACCESS_TOKEN: "SET_ACCESS_TOKEN",
    // trigger notifications
    MODAL_STATE: "MODAL_STATE",
    Toggle_CREATE_WORKSPACE_MODAL: "Toggle_CREATE_WORKSPACE_MODAL",
    TWITTER_LOGIN_TRIGGER: "TWITTER_LOGIN_TRIGGER",
    GET_TWITTER_REQUEST_TOKEN: "GET_TWITTER_REQUEST_TOKEN",
    TWITTER_ACCESS_TOKEN: "TWITTER_ACCESS_TOKEN",
    TWITTER_AUTH: "TWITTER_AUTH",
    TWITTER_MENTIONS: "TWITTER_MENTIONS",
    LOGOUT: "LOGOUT",
    // reset for changing workspace
    RESET_FOR_CHANGE_WORKSPACE: "RESET_FOR_CHANGE_WORKSPACE",
    TRIGGER_DASHBOARD_DATA: "TRIGGER_DASHBOARD_DATA",
    SET_DASHBOARD_DATA: "SET_DASHBOARD_DATA",
    // checking to remove
    TRIGGER_NOTIFICATIONS: "TRIGGER_NOTIFICATION",
    SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
    TRIGGER_SET_NOTIFICATION: "TRIGGER_SET_NOTIFICATION",
    SET_NOTIFICATION: "SET_NOTIFICATION",
    UPDATE_NOTIFICATION: "UPDATE_NOTIFICATION",
    // mmhk-region
    GET_CHART_DATA: "GET_CHART_DATA",
    SET_CHART_DATA: "SET_CHART_DATA",
    SET_CHART_PAGE: "SET_CHART_PAGE",
    RESET_INTEGRATION_CONNECTIONS: "RESET_INTEGRATION_CONNECTIONS",
    RESET_TWITTER_REQUEST_TOKEN: "RESET_TWITTER_REQUEST_TOKEN",
    TRIGGER_TWITTER_AUTH_DATA: "TRIGGER_TWITTER_AUTH_DATA",
    SET_TWITTER_AUTH_DATA: "SET_TWITTER_AUTH_DATA",
    TRIGGER_DISCONNECT_INTEGRATION_CONNECTION: "TRIGGER_DISCONNECT_INTEGRATION_CONNECTION",
    TRIGGER_TWITTER_INTEGRATION: "TRIGGER_TWITTER_INTEGRATION",
    TRIGGER_FACEBOOK_INTEGRATION: "TRIGGER_FACEBOOK_INTEGRATION",
    TRIGGER_GET_CONNECTIONs: "TRIGGER_GET_CONNECTIONs",
    SET_INTEGRATION_CONNECTION_DATA: "SET_INTEGRATION_CONNECTION_DATA",
    SET_TWITTER_INTEGRATION_CONNECTION: "SET_TWITTER_INTEGRATION_CONNECTION",
    SET_FACEBOOK_INTEGRATION_CONNECTION: "SET_FACEBOOK_INTEGRATION_CONNECTION",
    SET_GOOGLE_INTEGRATION_CONNECTION: "SET_GOOGLE_INTEGRATION_CONNECTION",
    TRIGGER_GOOGLE_SIGN_UP: "TRIGGER_GOOGLE_SIGNUP",
    TRIGGER_GOOGLE_LOGIN: "TRIGGER_GOOGLE_LOGIN",
    SET_GOOGLE_SIGN_UP_DATA: "SET_GOOGLE_SIGN_UP_DATA",
    SET_GOOGLE_ACCESS_DATA: "SET_GOOGLE_ACCESS_DATA",
    SET_APP_ACCESS_TYPE_MESSAGE: "SET_APP_ACCESS_TYPE_MESSAGE",
    // user permission list
    GET_USER_PERMISSION_LIST: "GET_USER_PERMISSION_LIST",
    SET_USER_PERMISSION_LIST: "SET_USER_PERMISSION_LIST",

    // success message
    SET_SUCCESS_MESSAGE: "SET_SUCCESS_MESSAGE",
    // info message
    SET_INFO_MESSAGE: "SET_INFO_MESSAGE",
    // error message
    SET_ERROR: "SET_ERROR",
    SET_ERROR_MESSAGE: "SET_ERROR_MESSAGE",
    // workspace
    TOGGLE_CHANGE_WORKSPACE: "TOGGLE_CHANGE_WORKSPACE",
    GET_WORKSPACES: "GET_WORKSPACES",
    SET_WORKSPACES: "SET_WORKSPACES",
    SET_CURRENT_WORKSPACE: "SET_CURRENT_WORKSPACE",
    FIND_WORKSPACE_LIST: "FIND_WORKSPACE_LIST",

    // roles
    GET_ROLES: "GET_ROLES",
    FIND_ROLE_LIST: "FIND_ROLE_LIST",
    UPDATE_USER_ROLE: "UPDATE_USER_ROLE",

    // profile - notification
    UPDATE_NOTIFICATION_PREFERENCE: "UPDATE_NOTIFICATION_PREFERENCE",
    GET_NOTIFICATION_SETTINGS: "GET_NOTIFICATION_SETTINGS",
    GET_NOTIFICATION_SETTING_LIST: "GET_NOTIFICATION_SETTING_LIST",

    GET_PROFILE_INFO: "GET_PROFILE_INFO",
    UPDATE_PROFILE_INFO_SETTING: "UPDATE_PROFILE_INFO_SETTING",
    UPLOAD_PROFILE_IMAGE: "UPLOAD_PROFILE_IMAGE",
    SET_PROFILE_INFO: "SET_PROFILE_INFO",

    // user management
    CONVERT_TO_CLAPUP_USER: "CONVERT_TO_CLAPUP_USER",
    SET_CONVERTED_CLAPUP_USER: "SET_CONVERTED_CLAPUP_USER",
    GET_USER_REQUEST: "GET_USER_REQUEST",
    FIND_USER_LIST: "FIND_USER_LIST",
    UPDATE_STATUS: "UPDATE_STATUS",
    SET_UPDATE_STATUS: "SET_UPDATE_STATUS",
    INVITE_USER: "INVITE_USER",
    AFTER_INVITE_USER: "AFTER_INVITE_USER",
    DELETE_USER: "DELETE_USER",

    // workspace setting
    GET_WORKSPACE_SETTING: "GET_WORKSPACE_SETTING",
    SET_WORKSPACE_SETTING_TRIGGER: "SET_WORKSPACE_SETTING_TRIGGER",
    GET_WORKSPACE_LOCATIONS: "GET_WORKSPACE_LOCATIONS",
    SET_WORKSPACE_LOCATIONS: "SET_WORKSPACE_LOCATIONS",
    UPDATE_WORKSPACE_LOCATION: "UPDATE_WORKSPACE_LOCATION",
    UPDATE_WORKSPACE_SETTING: "UPDATE_WORKSPACE_SETTING",
    SET_UPDATED_WORKSPACE_SETTING_DATA: "SET_UPDATED_WORKSPACE_SETTING_DATA",
    CREATE_NEW_WORKSPACE: "CREATE_NEW_WORKSPACE",
    SET_NEW_WORKSPACE_DATA: "SET_NEW_WORKSPACE_DATA",
    // google connection
    SET_GOOGLE_CONNECTION_LOCATIONS: "SET_CONNECTION_LOCATIONS",
    GET_GOOGLE_CONNECTION_LOCATIONS: "GET_CONNECTION_LOCATIONS",
    TRIGGER_MAP_GOOGLE_CONNECTION_LOCATIONS: "TRIGGER_MAP_GOOGLE_CONNECTION_LOCATIONS",
    SET_MAP_GOOGLE_CONNECTION_LOCATIONS: "SET_MAP_GOOGLE_CONNECTION_LOCATIONS",
    GET_CONNECTED_MAPPED_GOOGLE_LOCATIONS: "GET_CONNECTED_MAPPED_GOOGLE_LOCATIONS",
    SET_CONNECTED_MAPPED_GOOGLE_LOCATIONS: "SET_CONNECTED_MAPPED_GOOGLE_LOCATIONS",

    // facebook connection
    TRIGGER_FACEBOOK_CONNECTION_PAGES: "TRIGGER_FACEBOOK_CONNECTION_PAGES",
    GET_FACEBOOK_CONNECTION_PAGES: "GET_FACEBOOK_CONNECTION_PAGES",
    SET_FACEBOOK_CONNECTION_PAGES: "SET_FACEBOOK_CONNECTION_PAGES",
    TRIGGER_MAP_FACEBOOK_CONNECTION_PAGES: "TRIGGER_MAP_FACEBOOK_CONNECTION_PAGES",
    TOGGLE_SHOW_FB_PAGE_MAP_MODAL: "TOGGLE_SHOW_FB_PAGE_MAP_MODAL",

    // whatsapp connection
    GET_WHATS_APP_CONNECTED_USERS: "GET_WHATS_APP_CONNECTED_USERS",
    SET_WHATS_APP_CONNECTED_USERS: "SET_WHATS_APP_CONNECTED_USERS",
    DISCONNECT_WHATS_APP: "DISCONNECT_WHATS_APP",
    //Promotion management (campaign vs template)
    // template
    GET_TEMPLATE_LIST: "GET_TEMPLATE_LIST",
    GET_FIRST_TIME_TEMPLATE_LIST: "GET_FIRST_TIME_TEMPLATE_LIST",
    GET_SEARCH_TEMPLATE_LIST: "GET_SEARCH_TEMPLATE_LIST",
    SET_SEARCH_TEMPLATE_DATA: "SET_SEARCH_TEMPLATE_DATA",
    SET_TEMPLATE_LIST: "SET_TEMPLATE_LIST",
    SET_FIRST_TIME_TEMPLATE_LIST: "SET_FIRST_TIME_TEMPLATE_LIST",
    TOGGLE_MODAL_TEMPLATES: "TOGGLE_MODAL_TEMPLATES",
    GET_TEMPLATE_DETAILS_BY_ID: "GET_TEMPLATE_DETAILS_BY_ID",
    SET_TEMPLATE_DETAILS_BY_ID: "SET_TEMPLATE_DETAILS_BY_ID",
    UPDATE_TEMPLATE_DETAILS_BEFORE_SUBMIT: "UPDATE_TEMPLATE_DETAILS_BEFORE_SUBMIT",
    REMOVE_TEMPLATE_DATA: "REMOVE_TEMPLATE_DATA",
    REMOVE_CALL_ACTION_TEMPLATE: "REMOVE_CALL_ACTION_TEMPLATE",
    REMOVE_QUICK_REPLY_TEMPLATE: "REMOVE_QUICK_REPLY_TEMPLATE",
    TOGGLE_TEMPLATE_EDITION: "TOGGLE_TEMPLATE_EDITION",
    INJECT_TEMPLATE_ID_AFTER_CREATING_TEMPLATE: "INJECT_TEMPLATE_ID_AFTER_CREATING_TEMPLATE",
    SET_CALL_TO_ACTION_TEMPLATE: "SET_CALL_TO_ACTION_TEMPLATE",
    SET_QUICK_REPLY_BUTTONS_TEMPLATE: "SET_QUICK_REPLY_BUTTONS_TEMPLATE",
    SAVE_TEMPLATE_DETAILS: "SAVE_TEMPLATE_DETAILS",
    TOGGLE_UPDATE_TEMPLATE_SIGNAL_R_REQUEST: "TOGGLE_UPDATE_TEMPLATE_SIGNAL_R_REQUEST",
    APPEND_SIGNAL_R_DATA_FOR_WHATSAPP_TEMPLATE: "APPEND_SIGNAL_R_DATA_FOR_WHATSAPP_TEMPLATE",
    TOGGLE_IS_ALREADY_SAVED: "TOGGLE_IS_ALREADY_SAVED",
    TOGGLE_EDITABLE_TEMPLATE: "TOGGLE_EDITABLE_TEMPLATE",
    TOGGLE_EDITING_MASTER_NON_GENERAL_PREVIEW: "TOGGLE_EDITING_MASTER_NON_GENERAL_PREVIEW",
    TOGGLE_FOR_EDITING_TEMPLATE: "TOGGLE_FOR_EDITING_TEMPLATE",
    TOGGLE_MODAL_TEMPLATE_MASTER_VARIABLES: "TOGGLE_MODAL_TEMPLATE_MASTER_VARIABLES",
    // campaign
    SET_CAMPAIGN_DETAILS_PAYLOAD: "SET_CAMPAIGN_DETAILS_PAYLOAD",
    GET_FIRST_TIME_CAMPAIGN_LIST: "GET_FIRST_TIME_CAMPAIGN_LIST",
    SET_FIRST_TIME_CAMPAIGN_LIST: "SET_FIRST_TIME_CAMPAIGN_LIST",
    SET_SEARCHED_CAMPAIGN_LIST: "SET_SEARCHED_CAMPAIGN_LIST",
    INSERT_CAMPAIGN_DATA: "INSERT_CAMPAIGN_DATA",
    GET_TIME_ZONES: "GET_TIME_ZONES",
    SET_TIME_ZONES: "SET_TIME_ZONES",
    // bot management
    GET_BOT_DETAILS_SETTINGS: "GET_BOT_DETAILS_SETTINGS",
    SET_BOT_DETAILS_SETTINGS: "SET_BOT_DETAILS_SETTINGS",
    UPDATE_BOT_DETAILS_SETTINGS: "UPDATE_BOT_DETAILS_SETTINGS",
    SET_UPDATED_BOT_DETAILS_SETTINGS: "SET_UPDATED_BOT_DETAILS_SETTINGS",
    // bio page settings
    GET_BIO_PAGE_SETTINGS: "GET_BIO_PAGE_SETTINGS",
    GET_BIO_PAGE_TEMPLATES: "GET_BIO_PAGE_TEMPLATES",
    SET_BIO_PAGE_TEMPLATES: "SET_BIO_PAGE_TEMPLATES",
    SELECT_BIO_PAGE_TEMPLATE: "SELECT_BIO_PAGE_TEMPLATE",
    SET_BIO_PAGE_SETTINGS: "SET_BIO_PAGE_SETTINGS",
    UPDATE_BIO_PAGE_SETTINGS: "UPDATE_BIO_PAGE_SETTINGS",
    GET_BIO_PAGE_LINKS: "GET_BIO_PAGE_LINKS",
    SET_BIO_PAGE_LINKS: "SET_BIO_PAGE_LINKS",
    ADD_EDIT_DELETE_SOCIAL_LINKS: "ADD_EDIT_DELETE_SOCIAL_LINKS",
    ADD_EDIT_DELETE_SOCIAL_CUSTOM_LINKS: "ADD_EDIT_DELETE_SOCIAL_CUSTOM_LINKS",
    UPDATE_SOCIAL_LINKS: "UPDATE_SOCIAL_LINKS",
    SET_SOCIAL_LINKS: "SET_SOCIAL_LINKS",
    REMOVE_SOCIAL_LINKS: "REMOVE_SOCIAL_LINKS",
    ADD_CUSTOM_LINKS: "ADD_CUSTOM_LINKS",
    UPDATE_CUSTOM_LINKS: "UPDATE_CUSTOM_LINKS",
    REMOVE_CUSTOM_LINKS: "REMOVE_CUSTOM_LINKS",
    ADD_EDIT_DELETE_ANNOUNCEMENT: "ADD_EDIT_DELETE_ANNOUNCEMENT",
    GET_BIO_PAGE_ANNOUNCEMENTS: "GET_BIO_PAGE_ANNOUNCEMENTS",
    SET_BIO_PAGE_ANNOUNCEMENTS: "SET_BIO_PAGE_ANNOUNCEMENTS",
    GET_FIRST_TIME_EMBED_VIDEO_RECORDS: "GET_FIRST_TIME_EMBED_VIDEO_RECORDS",
    SET_FIRST_TIME_EMBED_VIDEO_RECORDS: "SET_FIRST_TIME_EMBED_VIDEO_RECORDS",
    GET_EMBED_VIDEO_RECORDS: "GET_EMBED_VIDEO_RECORDS",
    SET_EMBED_VIDEO_RECORDS: "SET_EMBED_VIDEO_RECORDS",
    GET_FIRST_TIME_EMBED_IMAGE_RECORDS: "GET_FIRST_TIME_EMBED_IMAGE_RECORDS",
    SET_FIRST_TIME_EMBED_IMAGE_RECORDS: "SET_FIRST_TIME_EMBED_IMAGE_RECORDS",
    GET_EMBED_IMAGE_RECORDS: "GET_EMBED_IMAGE_RECORDS",
    SET_EMBED_IMAGE_RECORDS: "SET_EMBED_IMAGE_RECORDS",

    // Media Content
    // folder
    CREATE_FOLDER: "CREATE_FOLDER",
    ADD_NEW_FOLDER: "ADD_NEW_FOLDER",
    DELETE_FOLDERS: "DELETE_FOLDERS",
    REMOVE_FOLDERS: "REMOVE_FOLDERS",
    GET_FIRST_TIME_FOLDERS: "GET_FIRST_TIME_FOLDERS",
    SET_FIRST_TIME_FOLDERS: "SET_FIRST_TIME_FOLDERS",
    SET_SEARCH_FOLDER_TEXT: "SET_SEARCH_FOLDER_TEXT",
    GET_FOLDERS: "GET_FOLDERS",
    SET_SELECTED_DELETABLE_CONTENTS: "SET_SELECTED_DELETABLE_CONTENTS",
    GET_ONE_TIME_FOLDER_CONTENTS: "GET_ONE_TIME_FOLDER_CONTENTS",
    SET_ONE_TIME_FOLDER_CONTENTS: "SET_ONE_TIME_FOLDER_CONTENTS",
    GET_FOLDER_CONTENTS: "GET_FOLDER_CONTENTS",
    SET_FOLDER_CONTENTS: "SET_FOLDER_CONTENTS",
    // content
    SET_SEARCH_CONTENT_TEXT: "SET_SEARCH_CONTENT_TEXT",
    SAVE_CONTENT_DETAILS: "SAVE_CONTENT_DETAILS",
    SET_CONTENT_DETAILS: "SET_CONTENT_DETAILS",
    SET_DEFAULT_CONTENT_INDEX: "SET_DEFAULT_CONTENT_INDEX",
    CREATE_FOLDER_CONTENT: "CREATE_FOLDER_CONTENT",
    UPDATE_FOLDER_CONTENT_NEW_VERSION: "UPDATE_FOLDER_CONTENT_NEW_VERSION",
    AWS_PRE_ASSIGN_LINK: "AWS_PRE_ASSIGN_LINK",
    SET_FOLDER_CONTENT: "SET_FOLDER_CONTENT",
    UPDATE_FOLDER_CONTENT: "UPDATE_FOLDER_CONTENT",
    SET_UPDATE_FOLDER_CONTENT: "SET_UPDATE_FOLDER_CONTENT",
    DELETE_FOLDER_CONTENTS: "DELETE_FOLDER_CONTENTS",
    REMOVE_CONTENTS_FROM_FOLDER: "REMOVE_CONTENTS_FROM_FOLDER",
    SET_ALL_FOLDERS: "SET_ALL_FOLDERS",
    GET_CONTENT_VERSIONS: "GET_CONTENT_VERSIONS",
    SET_CONTENT_VERSIONS: "SET_CONTENT_VERSIONS",
    REQUEST_TO_REVERT_VERSION: "REQUEST_TO_REVERT_VERSION",
    SET_BIO_PAGE_FOLDERS: "SET_BIO_PAGE_FOLDERS",
    GET_BIO_PAGE_FOLDERS: "GET_BIO_PAGE_FOLDERS",

    // unified inbox
    TOGGLE_REPLYING_ON: "TOGGLE_REPLYING_ON",
    TOGGLE_NEW_CONVERSATION_DETAILS: "TOGGLE_NEW_CONVERSATION_DETAILS",
    LIKE_UNLIKE_FACEBOOK_POST: "LIKE_UNLIKE_FACEBOOK_POST",
    REPLYING_FACEBOOK_POST: "REPLYING_FACEBOOK_POST",
    TOGGLE_PAGE_ROUTE: "TOGGLE_PAGE_ROUTE",
    TOGGLE_IS_IN_REPLYING_MODE: "TOGGLE_IS_IN_REPLYING_MODE",
    TRIGGER_ADD_TO_WALL: "TRIGGER_ADD_TO_WALL",
    ADD_TO_WALL: "ADD_TO_WALL",
    TRIGGER_DELETE_FROM_WALL: "TRIGGER_DELETE_FROM_WALL",
    REMOVE_FROM_WALL: "REMOVE_FROM_WALL",
    TRIGGER_REQUEST_REVIEW: "TRIGGER_REQUEST_REVIEW",
    SET_REQUEST_REVIEW_DATA: "SET_REQUEST_REVIEW_DATA",
    RESET_REQUEST_REVIEW_DATA: "RESET_REQUEST_REVIEW_DATA",
    GET_LOCATIONS: "GET_COUNTRIES",
    SET_LOCATIONS: "SET_COUNTRIES",
    TRIGGER_WALL_UNIFIED_INBOX: "TRIGGER_WALL_UNIFIED_INBOX",
    RESET_UNIFIED_INBOX: "RESET_UNIFIED_INBOX",
    RESET_WALL_UNIFIED_INBOX: "RESET_WALL_UNIFIED_INBOX",
    // messages
    GET_FIRST_TIME_WHATSAPP_DATA_INBOX: "GET_FIRST_TIME_WHATSAPP_DATA_INBOX",
    SET_FIRST_TIME_WHATSAPP_DATA_INBOX: "SET_FIRST_TIME_WHATSAPP_DATA_INBOX",
    TRIGGER_CLOSED_WHATSAPP: "TRIGGER_CLOSED_WHATSAPP",
    TOGGLE_COUNT_DOWN: "TOGGLE_COUNT_DOWN",
    SET_CLOSED_WHATSAPP: "SET_CLOSED_WHATSAPP",
    TRIGGER_UPDATE_WHATSAPP_PHONE_OR_EMAIL: "TRIGGER_UPDATE_WHATSAPP_PHONE_OR_EMAIL",
    SET_UPDATED_WHATSAPP_PHONE_OR_EMAIL: "SET_UPDATED_WHATSAPP_PHONE_OR_EMAIL",
    // bot
    GET_FIRST_TIME_BOT_DATA_INBOX: "GET_FIRST_TIME_BOT_DATA_INBOX",
    SET_FIRST_TIME_BOT_DATA_INBOX: "SET_FIRST_TIME_BOT_DATA_INBOX",
    GET_BOT_DATA_INBOX: "GET_BOT_DATA_INBOX",
    SET_BOT_DATA_INBOX: "SET_BOT_DATA_INBOX",
    // filtering unified inbox
    TRIGGER_INBOX_AND_WALL: "TRIGGER_INBOX_AND_WALL",
    TRIGGER_SEARCH_WALL_DATA: "TRIGGER_SEARCH_WALL_DATA",

    SET_UNIFIED_INBOX: "SET_UNIFIED_INBOX",
    APPEND_SIGNAL_R_DATA: "APPEND_SIGNAL_R_DATA",
    APPEND_SIGNAL_R_DATA_FOR_AI_CHAT_INBOX: "APPEND_SIGNAL_R_DATA_FOR_AI_CHAT_INBOX",
    APPEND_SIGNAL_R_DATA_FOR_WHATSAPP_INBOX: "APPEND_SIGNAL_R_DATA_FOR_WHATSAPP_INBOX",
    SET_WALL_UNIFIED_INBOX: "SET_WALL_UNIFIED_INBOX",
    GET_CURRENT_UNIFIED_DATA: "GET_CURRENT_UNIFIED_DATA",

    // data according to unified inbox conversation & details
    SET_CONVERSATION_DETAILS_PAYLOAD: "SET_CONVERSATION_DETAILS_PAYLOAD",
    TRIGGER_CONVERSATION_DETAILS_DATA: "TRIGGER_CONVERSATION_DETAILS_DATA",
    SET_CONVERSATION_DETAILS_DATA: "SET_CONVERSATION_DETAILS_DATA",
    SET_BOX_ID: "SET_BOX_ID",
    TRIGGER_GOOGLE_DATA: "TRIGGER_GOOGLE_DATA",
    TRIGGER_FACEBOOK_REVIEW_DATA: "TRIGGER_FACEBOOK_REVIEW_DATA",
    TRIGGER_FACEBOOK_MENTION_DATA: "TRIGGER_FACEBOOK_MENTION_DATA",
    TRIGGER_FACEBOOK_COMMENT_DATA: "TRIGGER_FACEBOOK_COMMENT_DATA",
    TRIGGER_CUSTOMER_FEEDBACK_DATA: "TRIGGER_CUSTOMER_FEEDBACK_DATA",
    TRIGGER_VIDEO_TESTIMONIAL_DATA: "TRIGGER_VIDEO_TESTIMONIAL_DATA",
    TRIGGER_TWITTER_DATA: "TRIGGER_TWITTER_DATA",
    SET_UNIFIED_DETAILS_DATA: "SET_UNIFIED_DETAILS_DATA",
    // mmhk-region
}

export default Constants;