import React from "react";
import './WorkspaceManagement.scss';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { workspace_routes } from "../page-routes";
import { ChildPageTabs, MobilePageTabs, ParentPageTabs } from "../Page.Tabs";
import TopTabs from "../../components/layout/TopTabs";

const WorkspaceManagement: React.FC = () => {
    const parent_routes = workspace_routes;
    const { personalInfo } = useSelector((state: any) => state?.profileManageSettingState);
    const path_name = window.location.pathname;

    const path_item: any = parent_routes?.find((item: any) => path_name.includes(item?.path));
    const tab_items: any[] = [];
    path_item?.route_children?.map((item: any) => (
        // const id = item?.name;
        // console.log({id});
        
        tab_items.push({
            name: path_item.path + item.path,
            label: item.name,
            permission: item.permission,
            component: item.component,
        })
    ));
   
    const {
        email, firstname, lastname,photo, 
        // accountType,
    }: any = personalInfo || {};
    
    const {userPermissions} = useSelector((state: any) => state?.login);
    const history = useNavigate();
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "workspaceSettings") : null;
        // const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "generalSettings-Account") : null;
        if(permission){
            permission?.value === 0 && history('/not-found');
        }
    }, [userPermissions, history]);
    return (
        <React.Fragment>
            <div className="main-container">
                <div className="lg-sub-menu">
                    <TopTabs />
                    <div className="parent-tabs">
                        <div className="logo-container">
                            <img className="workspace-logo" src={photo || "https://www.w3schools.com/howto/img_avatar.png"} alt="Avatar" />
                            <p className="workspace-name">{((firstname ? (firstname + " ") : "")  + (lastname || "")) || email || "Profile Email / Workspace name"}</p>
                        </div>

                        <div className="parent-tabs-container">
                            <ParentPageTabs parent_routes={parent_routes} path_name={path_name}/>
                        </div>
                    </div>
                    <ChildPageTabs parent_routes={parent_routes} path_name={path_name}/>
                </div>
                <div className="mobile-sub-menu">
                    <MobilePageTabs tab_items={tab_items} path_name={path_name}/>
                </div>
            </div>
        </React.Fragment>
    );
};

export default WorkspaceManagement;