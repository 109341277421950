import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from '../../store';
import { changePage } from '../../dashboard/reducer';
import { useDispatch } from 'react-redux';

const TopTabs = () => {
    const route_path = window.location.pathname;
    const query_string = window.location.search;
    const {isReplyingOn, pageRoute}: any = useSelector((state: RootState) => state?.unified);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // console.log({ isReplyingOn });
    return (
        <React.Fragment>
            <div className="top-tab-wall">
                <button
                    onClick={() => {
                        // navigate("/unified?all=conversations");
                        if (!query_string?.includes("conversation")) {
                            isReplyingOn
                            ? dispatch(changePage("/unified?all=conversations"))
                            : navigate("/unified?all=conversations");
                        }
                    }}
                    className={`inbox-button${
                        (query_string?.includes("conversation") || route_path?.includes("promotions")) ? "-active" : ""
                    }`}
                    type="button"
                >
                    Conversation
                </button>
            
                {/* <button
                    onClick={() => (isReplyingOn ? dispatch(changePage("/gallery?all=content")) : navigate("/gallery?all=content"))}
                    className={`inbox-button${
                        (route_path?.includes("gallery")) ? "-active" : ""
                    }`}
                    type="button"
                >
                    Content
                </button> */}

                <button
                    onClick={() => {
                        // navigate("/unified?all=wall");
                        isReplyingOn
                        ? dispatch(changePage("/unified?all=wall")) 
                        : navigate("/unified?all=wall")
                    }}
                    className={`inbox-button${
                        (query_string?.includes("wall")) ? "-active" : ""
                    }`}
                    type="button"
                >
                    Wall
                </button>
            

                
                {/* <button
                    onClick={() => (isReplyingOn ? dispatch(changePage("/promotions")) : navigate("/promotions"))}
                    className={`inbox-button${
                        (route_path?.includes("promotions")) ? "-active" : ""
                    }`}
                    type="button"
                >
                    Promotions
                </button> */}

            </div>
        </React.Fragment>
    );
};

export default TopTabs;