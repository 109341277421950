import React, { useRef } from "react";
import './FolderForm.scss';
import { setErrorMessage } from "../../../dashboard/reducer";
import { useDispatch } from "react-redux";
interface FolderFormProps {
    parentCallback?: any;
}

const FolderForm: React.FC<FolderFormProps> = ({ parentCallback }) => {
    const dispatch = useDispatch();
    const formRef: any = useRef(null);
    const handleFolderNameChange = (e?: any) => {
        const inputValue = e?.target?.value;
        const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9-_]|[-_]{2,}/g, ''); // Remove anything that is not a letter or number
        formRef.current.folderName.value = sanitizedValue;
    };
    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (formRef.current.folderName.value && formRef.current.displayName.value) {
            parentCallback({
                folderName: formRef.current.folderName.value,
                displayName: formRef.current.displayName.value
            })
        } else {
            dispatch(setErrorMessage("Please fill in all required fields"))
        }
    };
    return (
        <React.Fragment>
            <div className="form-container">
                <div className="form-header">
                    <h2>Create Folder</h2>
                </div>
                <div className="form-body">
                    <form ref={formRef} onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input
                                // className="form-control"
                                name="folderName" type="text"
                                autoComplete="off"
                                autoSave="off"
                                autoFocus={true}
                                placeholder="Add folder name here (internal, no space or special character)"
                                onChange={handleFolderNameChange}
                            />
                        </div>
                        
                        <div className="form-group">
                            <input
                                // className="form-control"
                                name="displayName" type="text"
                                autoComplete="off"
                                autoSave="off"
                                // autoFocus={true}
                                placeholder="Add Display name (What you see )"
                            />
                        </div>
                    </form>
                </div>
                <div className="form-footer">
                    <button type="button" className="btn" onClick={() => parentCallback(false)}>No, Cancel</button>
                    <button type="submit" onClick={handleSubmit} className="btn">Yes, Create</button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default FolderForm;