import google_logo from '../assets/icons/google-logo.svg';
import bot_message_icon from '../assets/icons/message-bot.svg';
import un_permissible_cross_icon from '../assets/img/un-permissible-cross-icon.svg';
import empty_box from '../assets/img/Empty.svg';
import form_close_icon from '../assets/icons/form-close-icon.svg';
import square_active_check from '../assets/icons/square-active-check.svg';
import square_inactive_check from '../assets/icons/square-inactive-check.svg';
import back_path_line from '../assets/icons/back-path-line.svg';
import edit_line from '../assets/icons/edit-line.svg';
import mini_carousel_left_button from '../assets/icons/mini-carousel-left-button.svg';
import mini_carousel_right_button from '../assets/icons/mini-carousel-right-button.svg';
import mini_carousel_preview_button from '../assets/icons/mini-carousel-preview-button.svg';
import preview_left_button from '../assets/icons/preview-left-button.svg';
import preview_right_button from '../assets/icons/preview-right-button.svg';
import preview_cross_button from '../assets/icons/preview-cross-icon.svg';
// commerce campaign & template == start
import campaign_icon from '../assets/icons/campaign-icon.svg';
import template_icon from '../assets/icons/table-template-icon.svg';
import select_active_template_icon from '../assets/icons/template-active-select.svg';
import select_inactive_template_icon from '../assets/icons/template-inactive-select.svg';

import right_bottom_tab_campaign_icon from '../assets/icons/campaign-icon.svg';
import right_bottom_tab_template_icon from '../assets/icons/template-icon.svg';
// commerce campaign & template == end
// messages inbox & details == start
import save_tik_icon from '../assets/icons/save-tik.svg';
import save_tik_campaign_icon from '../assets/icons/save-tik-pro.svg';
import schedular_icon from '../assets/icons/schedular-icon.svg';
import right_bottom_tab_message_icon from '../assets/icons/right-side-message.svg';
import right_bottom_tab_bot_icon from '../assets/icons/right-side-bot.svg';
import whats_app_message_icon from '../assets/icons/whatsapp-icon.svg';
import inbox_whats_app_message_icon from '../assets/icons/inbox-whatsapp.svg';
import whatsapp_circle_download_icon from '../assets/icons/whatsapp-circle-download.svg';
import whatsapp_pdf_icon from '../assets/icons/whatsapp-file-pdf.svg';
import whatsapp_mp4_icon from '../assets/icons/whatsapp-file-mp4.svg';
import whatsapp_png_icon from '../assets/icons/whatsapp-file-png.svg';
import whatsapp_jpg_icon from '../assets/icons/whatsapp-file-jpg.svg';
// import whatsapp_jpeg_icon from '../assets/icons/whatsapp-file-jpg.svg';
// messages inbox & details == end
import clapup_bot_icon from '../assets/icons/bot-icons/clapup-bot-icon.svg';
import bot_exclamation_icon from '../assets/icons/exclamation-circle.svg';
import bot_smile_icon from '../assets/icons/smile-circle.svg';
import bot_dropdown_active from '../assets/icons/drop-down-active.svg';
import bot_dropdown_inactive from '../assets/icons/drop-down-inactive.svg';
import bot_tik_right_icon from '../assets/icons/tik-right-icon.svg';
import twitter_logo from '../assets/icons/twitter-icon.svg';
import facebook_logo from '../assets/icons/facebook-reviews.svg';
import feedback_logo from '../assets/icons/feedback-line-1.2.svg';
import video_logo from '../assets/icons/video-chat-line-1.2.svg';
// import video_link from '../assets/icons/video-link.svg';
import lock_icon from '../assets/icons/lock-icon.svg';
import special_lock_icon from '../assets/icons/lock-special.svg';
import fb_recommendation from '../assets/icons/fb-recommendation-star.svg';
import fb_dont_recommendation from '../assets/icons/facebook-dont-recommendation-star.svg';
import like_icon from '../assets/icons/feather-thumbs-up.svg';
import heart_icon from '../assets/icons/heart-icon.svg';
import retweet_icon from '../assets/icons/retweet-icon.svg';
import copy_icon_line from '../assets/icons/copy-icon-line.svg';
import delete_icon_line from '../assets/icons/delete-icon-line.svg';
import global_language_icon from '../assets/icons/global-language.svg';
import global_line from '../assets/icons/global-line.svg';
import save_icon from '../assets/icons/save-icon.svg';
import man_collaborator from '../assets/icons/man-collaborator.svg';
import woman_collaborator from '../assets/icons/woman-collaborator.svg';
import boy_collaborator from '../assets/icons/boy-collaborator.svg';
import add_line_icon from '../assets/icons/add-line.svg';
// rating icons
import face_grin_star_icon from '../assets/icons/smile-grin-start.svg';
import face_smile_icon from '../assets/icons/face-smile.svg';
import face_meh_icon from '../assets/icons/face-meh.svg';
import face_frown_light_icon from '../assets/icons/face-frown-light.svg';
import face_frown_icon from '../assets/icons/face-frown.svg';

const getImage = (type: any) => {
  switch (type) {
    case 'preview_left_button':
      return preview_left_button;
    case 'preview_right_button':
      return preview_right_button;
    case 'preview_cross_button':
      return preview_cross_button;
    case 'mini_carousel_left_button':
      return mini_carousel_left_button;
    case 'mini_carousel_right_button':
      return mini_carousel_right_button;
    case 'mini_carousel_preview_button':
      return mini_carousel_preview_button;
    case 'whatsapp-circle-download':
      return whatsapp_circle_download_icon;
    case 'PDF':
      return whatsapp_pdf_icon;
    case 'MP4':
      return whatsapp_mp4_icon;
    case 'PNG':
      return whatsapp_png_icon;
    case 'JPG':
      return whatsapp_jpg_icon;
    case 'JPEG':
      return whatsapp_jpg_icon;
    case 'edit-icon':
      return edit_line;
    case 'save-icon':
      return save_icon;
    case 'global-line':
      return global_line;
    case 'back-path-line':
      return back_path_line;
    case 'global-language':
      return global_language_icon;
    case 'campaign':
      return campaign_icon;
    case 'table-template':
      return template_icon;
    case 'square-active-check':
      return square_active_check;
    case 'square-inactive-check':
      return square_inactive_check;
    case 'form-close-icon':
      return form_close_icon;
    case 'template-active-select':
      return select_active_template_icon;
    case 'template-inactive-select':
      return select_inactive_template_icon;
    case 'save-tik':
      return save_tik_icon;
    case 'right-side-campaign':
      return right_bottom_tab_campaign_icon;
    case 'right-side-template':
      return right_bottom_tab_template_icon;
    case 'right-side-message':
      return right_bottom_tab_message_icon;
    case 'right-side-bot':
      return right_bottom_tab_bot_icon;
    case 'inbox-whats-app-messages':
      return inbox_whats_app_message_icon;
    case 'whats-app-messages':
      return whats_app_message_icon;
    case 'clapup-bot-icon':
      return clapup_bot_icon;
    case 'bot_exclamation_icon':
      return bot_exclamation_icon;
    case 'bot_smile_icon':
      return bot_smile_icon;
    case 'bot_dropdown_active':
      return bot_dropdown_active;
    case 'bot_dropdown_inactive':
      return bot_dropdown_inactive;
    case 'tik_right_icon':
      return bot_tik_right_icon;
    case 'bot-messages':
      return bot_message_icon;
    case 'google':
      return google_logo;
    case 'lock_icon':
      return lock_icon;
    case 'thread_lock_icon':
      return special_lock_icon;
    case 'facebook':
      return facebook_logo;
    case 'facebookComments':
      return facebook_logo;
    case 'twitter':
      return twitter_logo;
    case 'videoTestimonial':
      return video_logo;
    case 'customerFeedBack':
      return feedback_logo;
    case 'heart':
      return heart_icon;
    case 'retweet':
      return retweet_icon;
    case 'fb-recommend':
      return fb_recommendation;
    case 'fb-dont-recommend':
      return fb_dont_recommendation;
    case 'like':
      return like_icon;
    case 'empty':
      return empty_box;
    case 'un-permissible-cross-icon':
      return un_permissible_cross_icon;
    // rating icons
    case 'rating-5':
      return face_grin_star_icon;
    case 'rating-4':
      return face_smile_icon;
    case 'rating-3':
      return face_meh_icon;
    case 'rating-2':
      return face_frown_light_icon;
    case 'rating-1':
      return face_frown_icon;
    default:
      return face_frown_icon;
  }
};

const getCampaignImage = (type: any) => {
  switch (type) {
    case 'campaign':
      return campaign_icon;
    case 'table-template':
      return template_icon;
    case 'square-active-check':
      return square_active_check;
    case 'square-inactive-check':
      return square_inactive_check;
    case 'form-close-icon':
      return form_close_icon;
    case 'template-active-select':
      return select_active_template_icon;
    case 'template-inactive-select':
      return select_inactive_template_icon;
    case 'save-tik':
      return save_tik_icon;
    case 'save-tik-campaign':
      return save_tik_campaign_icon;
    case 'schedular-icon':
      return schedular_icon;
    case 'right-side-campaign':
      return right_bottom_tab_campaign_icon;
    case 'right-side-template':
      return right_bottom_tab_template_icon;
    case 'empty':
      return empty_box;
    default:
      return empty_box;
  }
};

const getTemplateImage = (type: any) => {
  switch (type) {
    case 'campaign':
      return campaign_icon;
    case 'table-template':
      return template_icon;
    case 'square-active-check':
      return square_active_check;
    case 'square-inactive-check':
      return square_inactive_check;
    case 'form-close-icon':
      return form_close_icon;
    case 'template-active-select':
      return select_active_template_icon;
    case 'template-inactive-select':
      return select_inactive_template_icon;
    case 'copy-icon-line':
      return copy_icon_line;
    case 'delete-icon-line':
      return delete_icon_line;
    case 'save-tik':
      return save_tik_icon;
    case 'right-side-campaign':
      return right_bottom_tab_campaign_icon;
    case 'right-side-template':
      return right_bottom_tab_template_icon;
    case 'add-line':
      return add_line_icon;
    case 'empty':
      return empty_box;
    default:
      return empty_box;
  }
};

const getConversationImage = (type: any) => {
  switch (type) {
    case 'google':
      return google_logo;
    case 'lock_icon':
      return lock_icon;
    case 'facebook':
      return facebook_logo;
    case 'facebookComments':
      return facebook_logo;
    case 'twitter':
      return twitter_logo;
    case 'videoTestimonial':
      return video_logo;
    case 'customerFeedBack':
      return feedback_logo;
    case 'heart':
      return heart_icon;
    case 'retweet':
      return retweet_icon;
    case 'fb-recommend':
      return fb_recommendation;
    case 'fb-dont-recommend':
      return fb_dont_recommendation;
    case 'like':
      return like_icon;
    default:
      return google_logo;
  }
};

const testCollaboratorImage = (type: any) => {
  switch (type) {
    case "man-collaborator":
      return man_collaborator;
    case "woman-collaborator":
      return woman_collaborator;
    case "boy-collaborator":
      return boy_collaborator;
    default:
      return google_logo;
  }
};

const capitalize = (word: string) => word[0].toUpperCase() + word.slice(1).toLowerCase();

const getGenerateApiImageByName = (name?: string) => {
  return `https://ui-avatars.com/api/?name=${name || "Unknown User"}&background=0F43BD&color=fff&rounded=true&size=32`
};

export { getImage, getGenerateApiImageByName, getCampaignImage, getTemplateImage, testCollaboratorImage, getConversationImage, capitalize };
