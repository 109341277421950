import React from 'react';
import prev_icon from '../../../../../assets/icons/previous-icon.svg';
import next_icon from '../../../../../assets/icons/next-icon.svg';
import refresh_icon from '../../../../../assets/icons/refresh-icon-unified.svg';
import user_avatar from '../../../../../assets/icons/user-avatar.svg';
import user_avatar_2 from '../../../../../assets/icons/user-avatar-2.svg';
// import start from '../../../../../assets/icons/star-icon.svg';
// import star_half from '../../../../../assets/icons/star-half-icon.svg';
import lock_icon from '../../../../../assets/icons/lock-icon.svg';
import unlock_icon from '../../../../../assets/icons/unlock-icon.svg';
import back_btn from '../../../../../assets/icons/back.svg';
import reply_icon from '../../../../../assets/icons/reply-icon.svg';
import edit_icon from '../../../../../assets/icons/edit-icon.svg';
import send_icon from '../../../../../assets/icons/send-icon.svg';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import ConversationDetails from '../../AllDetails/ConversationsDetails';
import { useDispatch, useSelector } from 'react-redux';
import { setSuccessMessage, toggleReplyingOn, toggleIsInReplyingMode, likeUnlikeFacebookPost } from '../../../../../dashboard/reducer';
import ConversationHeaderSD from '../ConversationHeaderSD';
import ConversationHeader from '../ConversationHeader';
import { RootState } from '../../../../../store';
import ShowFiles from './ShowFiles/ShowFiles';
export default function FacebookReviews(props: any) {
  const dispatch = useDispatch();
  const { workspaces } = useSelector((state: any) => state?.headerState);
    const workSpaceName: any = workspaces?.find(
        (w: any) => w?.workSpaceId === localStorage.getItem("wuid")
    )?.workSpaceName || "";
  const {conversationDetailsData, isReplyingOn, isInReplyingMode}: any = useSelector((state: RootState) => state?.unified);
  // const { replies: replyData }: any = conversationDetailsData || {};
  const {
    review: post_item,
    comments: commentData
  }: any = conversationDetailsData || {};
  // }: any = {
  //   review: {
  //     "reviewId": "4457768454284935",
  //     "message": "A great hardworking result oriented team.. All the best guys",
  //     "reviewCreatedDate": "06-09-2021 08:52:45",
  //     "pageId": "251094609003725",
  //     "userName": "UnKnown User",
  //     "userImageUrl": "",
  //     "likeCount": 0,
  //     "attachment": [
  //       {
  //         "id": 18,
  //         "attachmentMsg": "A nice Video",
  //         "attachmentType": "video",
  //         "attachmentUrl": "https://scontent.fccj3-1.fna.fbcdn.net/o1/v/t2/f2/m69/An95Rb3sZLaEOwRhWUjknwWKOLOjLe2ilRC8E7njZvJ4uZJgm3ZQQ234qSuIxjQVt4cvsipIMiuAAenmqwL_ZDOJ.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6Im9lcF9oZCJ9&_nc_ht=scontent.fccj3-1.fna.fbcdn.net&_nc_cat=101&strext=1&vs=2ca91f9d0e43fee2&_nc_vs=HBkcFQIYOnBhc3N0aHJvdWdoX2V2ZXJzdG9yZS9HUEpYMWhwMTMzYng5am9EQUdBYldPellVb05EYm1kakFBQUYVAALIAQBLB4gScHJvZ3Jlc3NpdmVfcmVjaXBlATENc3Vic2FtcGxlX2ZwcwAQdm1hZl9lbmFibGVfbnN1YgAgbWVhc3VyZV9vcmlnaW5hbF9yZXNvbHV0aW9uX3NzaW0AKGNvbXB1dGVfc3NpbV9vbmx5X2F0X29yaWdpbmFsX3Jlc29sdXRpb24AHXVzZV9sYW5jem9zX2Zvcl92cW1fdXBzY2FsaW5nABFkaXNhYmxlX3Bvc3RfcHZxcwAVACUAHIwXQAAAAAAAAAAREQAAACbMzt%2FW3ojZAhUCKAJDMxgLdnRzX3ByZXZpZXccF0AjzMzMzMzNGBlkYXNoX2gyNjQtYmFzaWMtZ2VuMl83MjBwEgAYGHZpZGVvcy52dHMuY2FsbGJhY2sucHJvZDgSVklERU9fVklFV19SRVFVRVNUGwqIFW9lbV90YXJnZXRfZW5jb2RlX3RhZwZvZXBfaGQTb2VtX3JlcXVlc3RfdGltZV9tcwEwDG9lbV9jZmdfcnVsZQd1bm11dGVkE29lbV9yb2lfcmVhY2hfY291bnQDMTE4EW9lbV9pc19leHBlcmltZW50AAxvZW1fdmlkZW9faWQQMTAyMDg2MzM3NTY5NDcxNBJvZW1fdmlkZW9fYXNzZXRfaWQQODI2MDYzMTU2NzMyMTQ2MBVvZW1fdmlkZW9fcmVzb3VyY2VfaWQPNzU4ODEzMTY5NTQ2MTUwHG9lbV9zb3VyY2VfdmlkZW9fZW5jb2RpbmdfaWQQMTE2NTM1NDEwODExNDUyMA52dHNfcmVxdWVzdF9pZAAlAhwAJcQBGweIAXMEMjMyNgJjZAoyMDI0LTA3LTEyA3JjYgMxMDADYXBwBVZpZGVvAmN0DVZJREVPX0NPTU1FTlQTb3JpZ2luYWxfZHVyYXRpb25fcwM5LjkCdHMVcHJvZ3Jlc3NpdmVfZW5jb2RpbmdzAA%3D%3D&ccb=9-4&oh=00_AYAC8cRlES3CuVqmnb5BSDU-qZmS9B7EJ4KzUNmRqi-z8A&oe=66954EC8&_nc_sid=1d576d&_nc_rid=657898541814137&_nc_store_type=1",
  //         "objectId": "4457768454284935_1021245859356211"
  //       }
  //     ]
  //   },
  //   comments: [
  //     {
  //       "reviewReplyId": "4457768454284935_1021245859356211",
  //       "reviewReplyMessage": "A nice Video",
  //       "repliedByUserName": "3Potters",
  //       "repliedOn": "12-07-2024 18:23:19",
  //       "reviewId": "4457768454284935",
  //       "likeCount": 0,
  //       "repliedByUserImageUrl": "https://scontent.fccj3-1.fna.fbcdn.net/v/t39.30808-1/301000193_496015222529491_8748252647151693977_n.jpg?stp=c1.0.50.50a_cp0_dst-jpg_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=6738e8&_nc_ohc=aP_SOwqvy3UQ7kNvgE87Rbz&_nc_ht=scontent.fccj3-1.fna&edm=ACHimx4EAAAA&oh=00_AYBdGCUG_FbndosOUYuGUn8K_iiisriDPuwWrL3S7tsX6w&oe=66994290",
  //       "attachment": [
  //         {
  //           "id": 18,
  //           "attachmentMsg": "A nice Video",
  //           "attachmentType": "video",
  //           "attachmentUrl": "https://scontent.fccj3-1.fna.fbcdn.net/o1/v/t2/f2/m69/An95Rb3sZLaEOwRhWUjknwWKOLOjLe2ilRC8E7njZvJ4uZJgm3ZQQ234qSuIxjQVt4cvsipIMiuAAenmqwL_ZDOJ.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6Im9lcF9oZCJ9&_nc_ht=scontent.fccj3-1.fna.fbcdn.net&_nc_cat=101&strext=1&vs=2ca91f9d0e43fee2&_nc_vs=HBkcFQIYOnBhc3N0aHJvdWdoX2V2ZXJzdG9yZS9HUEpYMWhwMTMzYng5am9EQUdBYldPellVb05EYm1kakFBQUYVAALIAQBLB4gScHJvZ3Jlc3NpdmVfcmVjaXBlATENc3Vic2FtcGxlX2ZwcwAQdm1hZl9lbmFibGVfbnN1YgAgbWVhc3VyZV9vcmlnaW5hbF9yZXNvbHV0aW9uX3NzaW0AKGNvbXB1dGVfc3NpbV9vbmx5X2F0X29yaWdpbmFsX3Jlc29sdXRpb24AHXVzZV9sYW5jem9zX2Zvcl92cW1fdXBzY2FsaW5nABFkaXNhYmxlX3Bvc3RfcHZxcwAVACUAHIwXQAAAAAAAAAAREQAAACbMzt%2FW3ojZAhUCKAJDMxgLdnRzX3ByZXZpZXccF0AjzMzMzMzNGBlkYXNoX2gyNjQtYmFzaWMtZ2VuMl83MjBwEgAYGHZpZGVvcy52dHMuY2FsbGJhY2sucHJvZDgSVklERU9fVklFV19SRVFVRVNUGwqIFW9lbV90YXJnZXRfZW5jb2RlX3RhZwZvZXBfaGQTb2VtX3JlcXVlc3RfdGltZV9tcwEwDG9lbV9jZmdfcnVsZQd1bm11dGVkE29lbV9yb2lfcmVhY2hfY291bnQDMTE4EW9lbV9pc19leHBlcmltZW50AAxvZW1fdmlkZW9faWQQMTAyMDg2MzM3NTY5NDcxNBJvZW1fdmlkZW9fYXNzZXRfaWQQODI2MDYzMTU2NzMyMTQ2MBVvZW1fdmlkZW9fcmVzb3VyY2VfaWQPNzU4ODEzMTY5NTQ2MTUwHG9lbV9zb3VyY2VfdmlkZW9fZW5jb2RpbmdfaWQQMTE2NTM1NDEwODExNDUyMA52dHNfcmVxdWVzdF9pZAAlAhwAJcQBGweIAXMEMjMyNgJjZAoyMDI0LTA3LTEyA3JjYgMxMDADYXBwBVZpZGVvAmN0DVZJREVPX0NPTU1FTlQTb3JpZ2luYWxfZHVyYXRpb25fcwM5LjkCdHMVcHJvZ3Jlc3NpdmVfZW5jb2RpbmdzAA%3D%3D&ccb=9-4&oh=00_AYAC8cRlES3CuVqmnb5BSDU-qZmS9B7EJ4KzUNmRqi-z8A&oe=66954EC8&_nc_sid=1d576d&_nc_rid=657898541814137&_nc_store_type=1",
  //           "objectId": "4457768454284935_1021245859356211"
  //         }
  //       ]
  //     },
  //     {
  //       "reviewReplyId": "4457768454284935_1547258659531233",
  //       "reviewReplyMessage": "",
  //       "repliedByUserName": "Kuber Mannur",
  //       "repliedOn": "12-07-2024 16:23:57",
  //       "reviewId": "4457768454284935",
  //       "likeCount": 0,
  //       "repliedByUserImageUrl": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXE_6sASR-aq_3q4Y9EpExtleZpBq6xCfGpzcKISatX-Rqv8JGo8QjozAcFI7ikI4tIjF_L-GEhh&psid=5565908443481676&height=50&width=50&ext=1723535500&hash=AbaiidFIebaKOdnp7YZd8_uF",
  //       "attachment": [
  //         {
  //           "id": 17,
  //           "attachmentMsg": "click to view details",
  //           "attachmentType": "photo",
  //           "attachmentUrl": "https://scontent.fccj3-1.fna.fbcdn.net/v/t39.30808-6/450963694_10232260889922810_7781519876327929732_n.jpg?stp=dst-jpg_p720x720&_nc_cat=110&ccb=1-7&_nc_sid=bd9a62&_nc_ohc=ntrJjSQuCzkQ7kNvgHIWyv9&_nc_ht=scontent.fccj3-1.fna&edm=ACHimx4EAAAA&oh=00_AYBKcEjCKsCUWx7-AomHx3Pw359tpkTX2cDXBXz6c6cQjg&oe=66995617",
  //           "objectId": "4457768454284935_1547258659531233"
  //         }
  //       ]
  //     },
  //     {
  //       "reviewReplyId": "4457768454284935_260697206560620",
  //       "reviewReplyMessage": "Thank you so much again",
  //       "repliedByUserName": "3Potters",
  //       "repliedOn": "04-11-2023 12:06:42",
  //       "reviewId": "4457768454284935",
  //       "likeCount": 0,
  //       "repliedByUserImageUrl": "https://scontent.fccj3-1.fna.fbcdn.net/v/t39.30808-1/301000193_496015222529491_8748252647151693977_n.jpg?stp=c1.0.50.50a_cp0_dst-jpg_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=6738e8&_nc_ohc=aP_SOwqvy3UQ7kNvgE87Rbz&_nc_ht=scontent.fccj3-1.fna&edm=ACHimx4EAAAA&oh=00_AYBdGCUG_FbndosOUYuGUn8K_iiisriDPuwWrL3S7tsX6w&oe=66994290",
  //       "attachment": []
  //     },
  //     {
  //       "reviewReplyId": "4457768454284935_1568624057008590",
  //       "reviewReplyMessage": "Great",
  //       "repliedByUserName": "3Potters",
  //       "repliedOn": "06-10-2023 20:17:27",
  //       "reviewId": "4457768454284935",
  //       "likeCount": 0,
  //       "repliedByUserImageUrl": "https://scontent.fccj3-1.fna.fbcdn.net/v/t39.30808-1/301000193_496015222529491_8748252647151693977_n.jpg?stp=c1.0.50.50a_cp0_dst-jpg_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=6738e8&_nc_ohc=aP_SOwqvy3UQ7kNvgE87Rbz&_nc_ht=scontent.fccj3-1.fna&edm=ACHimx4EAAAA&oh=00_AYBdGCUG_FbndosOUYuGUn8K_iiisriDPuwWrL3S7tsX6w&oe=66994290",
  //       "attachment": []
  //     },
  //     {
  //       "reviewReplyId": "4457768454284935_4457775747617539",
  //       "reviewReplyMessage": "Thank you. ",
  //       "repliedByUserName": "3Potters",
  //       "repliedOn": "06-09-2021 08:55:44",
  //       "reviewId": "4457768454284935",
  //       "likeCount": 0,
  //       "repliedByUserImageUrl": "https://scontent.fccj3-1.fna.fbcdn.net/v/t39.30808-1/301000193_496015222529491_8748252647151693977_n.jpg?stp=c1.0.50.50a_cp0_dst-jpg_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=6738e8&_nc_ohc=aP_SOwqvy3UQ7kNvgE87Rbz&_nc_ht=scontent.fccj3-1.fna&edm=ACHimx4EAAAA&oh=00_AYBdGCUG_FbndosOUYuGUn8K_iiisriDPuwWrL3S7tsX6w&oe=66994290",
  //       "attachment": []
  //     }
  //   ]
  // };
  const post: any = post_item?.length ? post_item[0] : (post_item || null);
  const {sender, conversation, handleReplaceConversation, workspaceSettings, getImage, handlePrevPath, isReply, setIsReply, textRef, setReplyText, handleReply, conversationId, isGoForDetails, handleDetailChange} = props;
  // const { tag} = conversation || {};
  const { reviewedbyUserName, reviewerPhoteUrl, recieverName, reviewtype, reviewType, createdOn, reviewReplyComment, reviewReplyUpdatedon, reviewComment, provider, starRating, ratingStars, likeCount: likes, pagename } = conversation || {};
  // console.log({ replies });
  const [comments, setComments] = React.useState(commentData?.length ? commentData : []);
  
  React.useEffect(() => {
    console.log({commentData});
    
    setComments(commentData?.length ? commentData : []);
  }, [commentData?.length]);
  const [replyText, setCurrentReplyText] = React.useState<any>("");
  const [likeCount, setLikeCount] = React.useState({ isLike: false, likes: likes || 0 });
  const [currentRecord, setCurrentRecord] = React.useState<any>({});

  const handleReplyRecord = (replyText: string, currentRecord: any) => { 
    const replyRecord = {
      // repliedId: currentRecord?.commentId,
      reviewReplyMessage: replyText,
      repliedByUserName: workspaceSettings?.workspaceName,
      repliedUserImageURL: workspaceSettings?.logo,
      repliedOn: new Date(),
      isLike: false,
      likeCount: 0,
    };
    if (replyText?.trim()) {
      handleReply(replyText, currentRecord);
      const newReplies = [replyRecord, ...comments];
      setCurrentReplyText("");
      setComments(newReplies);
      // Scroll to bottom after sending a message
      setTimeout(() => {
          // scrollToBottom();
      }, 100); // Timeout to ensure the message is rendered before scrolling
    }
    
  };

  const handleMainLikeClick = (likeObj: any) => {
    console.log({ likeObj });
    const { reviewId, reviewPageId, tag, companyId } = conversation;
    const likePayload = {
        replyId: reviewId,
        pageId: reviewPageId || companyId || null,
        isLiked: likeObj?.isLike,
        pageModule: tag,
    }
    console.log({likePayload, conversation});
    setLikeCount(likeObj);
    dispatch(likeUnlikeFacebookPost(likePayload));
  };

  // const handleMainLikeClick = (likeObj: any) => {
  //   console.log({ likeObj });
  //   const { reviewId, reviewPageId, tag } = conversation;
  //   const likePayload = {
  //       commentId: reviewId,
  //       pageId: reviewPageId,
  //       isLiked: likeObj?.isLike,
  //       pageModule: tag,
  //   }
  //   console.log({likePayload, conversation});
  //   setLikeCount(likeObj);
  //   dispatch(likeUnlikeFacebookPost(likePayload));
  // };

  const handleLike = (curData: any, isLike?: any) => {
    // console.log({curData});
    
    const { reviewPageId, tag, companyId } = conversation;
    const { likeCount, reviewReplyId: commentId } = curData;
    const likeFlag = isLike ? false : true;
    const likeObj = {
        ...curData,
        likeCount: isLike ? (likeCount - 1) : (likeCount + 1),
        isLike: likeFlag,
    };
    const likePayload = {
        commentId: commentId || null,
        pageId: reviewPageId ||companyId || null,
        isLiked: likeFlag,
        pageModule: tag,
    }
    dispatch(likeUnlikeFacebookPost(likePayload));
    const index = comments?.findIndex((item: any) => item?.reviewReplyId === curData?.reviewReplyId);
    const newReplies = [...comments];
    newReplies[index] = likeObj;
    setComments(newReplies);
  };

  const debounce = <F extends (...args: any[]) => void>(func: F, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: Parameters<F>) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleTextChange = (value = "") => {
      setReplyText(value);
  };

  const debouncedTextChange = debounce(handleTextChange, 500);
  return (
    <React.Fragment>
      <div className="conversation-section-container-lg h-100">
        {/* <div className="conversation-header">
          <div className="person-container">
            <div className="person-1">
              {
                reviewerPhoteUrl
                ? <img
                    className="user-avatar"
                    src={reviewerPhoteUrl || user_avatar}
                    alt="avatar"
                  />
                : <span className='user-avatar'>
                  {
                    reviewedbyUserName?.split(" ")?.slice(0, 2)?.length === 2
                    ? reviewedbyUserName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                    : reviewedbyUserName?.substr(0, 2).toUpperCase()
                  }
                </span>
              }
              <span className="description">
                {reviewedbyUserName?.substr(0, 6) || "User Name"}{" "}
                <span className="review me-1">
                  {reviewtype?.toUpperCase() === "POSITIVE"
                    ? " recommends "
                    : reviewtype?.toUpperCase() === "NEGATIVE"
                    ? "doesn't recommend "
                    : null}
                </span>
                {workspaceSettings?.workspaceName}
              </span>
            </div>
            <div className="person-2">
              <img
                src={workspaceSettings?.logo || user_avatar_2}
                alt="user-2"
              />
              <span className="user-2">
                {workspaceSettings?.workspaceName || "WS Name"}
              </span>
            </div>
          </div>
          <div className="conversation-features-media">
            <div className="features">
              <img
                onClick={() =>
                  handleReplaceConversation("prev", conversation?.idx)
                }
                src={prev_icon}
                alt="prev"
                title='Previous'
              />
              <img
                onClick={() =>
                  handleReplaceConversation("next", conversation?.idx)
                }
                src={next_icon}
                alt="next"
                title='Next'
              />
              <img
                onClick={() => dispatch(setSuccessMessage("Refreshed"))}
                src={refresh_icon}
                alt="refresh"
                title='Refresh'
              />
            </div>
            <div className="media">
              <img src={getImage(provider)} alt="google" />
            </div>
          </div>
        </div> */}
        <ConversationHeader
          header_title={
            (reviewtype || reviewType)?.toUpperCase() === "POSITIVE"
            ? " recommends "
            : (reviewtype || reviewType)?.toUpperCase() === "NEGATIVE"
            ? " doesn't recommend " : ""
          }
          workSpaceName={workSpaceName}
          workspaceSettings={workspaceSettings}
          conversation={conversation}
          handleReplaceConversation={handleReplaceConversation}
          prev_icon={prev_icon}
          next_icon={next_icon}
          refresh_icon={refresh_icon}
          user_avatar_2={user_avatar_2}
          user_avatar={user_avatar}
        />
        <div className="conversation-section">
          <Scrollbars
            style={{
              paddingRight: 10,
              display: "flex",
              flexDirection: "column-reverse",
            }}
            // style={{ paddingRight: 10 }}
            autoHide
            autoHideTimeout={500}
            autoHideDuration={500}
            autoHeight
            // autoHeightMin={250}
            // autoHeightMax={250}
            // autoHeightMin={480}
            autoHeightMax={"100%"}
            thumbMinSize={10}
            universal={true}
          >
            <div className="post">
              {
                post?.message
                ? <div className='post-container' style={{margin: '10px 0'}}>
                    <div className="post-header">
                      {
                        post?.userImageUrl
                        ? <img
                            className="user-avatar"
                            style={{
                                borderRadius: '50%',
                                height: 22,
                                width: "auto"
                            }}
                            src={post?.userImageUrl || user_avatar}
                            alt="avatar"
                        />
                        : 
                        <div className='user-avatar' 
                            style={{
                                fontSize: "0.75rem", 
                                background: '#14B0BF',
                                height: 22,
                                width: 26
                            }}
                        >
                        {
                            post?.userName?.split(" ")?.slice(0, 2)?.length === 2
                            ? post?.userName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                            : post?.userName?.substr(0, 2).toUpperCase()
                        }
                        </div>
                      }
                      <div className='content'>
                        <h2 className='user-name'>{post?.userName}</h2>
                        <p>
                            {
                                moment(post?.postCreatedDate).format("DD MMM YYYY, hh:mm:ss A")
                                // moment(createdOn).format("MMMM Do YYYY, hh:mm:ss A")
                            }
                        </p>
                      </div>
                    </div>
                    <div className="post-body">
                      {
                        post?.attachment
                        ? <ShowFiles data={post?.attachment} />
                        : null
                      }
                      
                      <p>{post?.message}</p>
                    </div>                 
                    <div className="post-footer">
                        <div className="post-footer-left w-50">
                          <div className="like-edit-reply">
                            <div className='image-likes'>
                            <img
                                className="image-like"
                                src={getImage("like")}
                                alt="like"
                                onClick={() => {
                                    handleMainLikeClick({
                                        isLike: likeCount?.isLike ? false : true,
                                        likes: likeCount?.isLike ? (likeCount?.likes - 1) : (likeCount?.likes + 1)
                                    });
                                }}
                            />
                            <div
                                className="likes"
                                style={{color: "inherit"}}
                            >
                                {
                                    (likeCount?.likes === 0 || likeCount?.likes === 1)
                                    ? likeCount?.likes + " Like"
                                    : likeCount?.likes + " Likes"
                                }
                            </div>
                            </div>
                            {/* <div className='reply-edit'
                                onClick={() => {
                                    setCurrentRecord(conversation);
                                    setIsReply(true);
                                    dispatch(toggleReplyingOn(true));
                                    dispatch(toggleIsInReplyingMode(true));
                                }}
                            >Reply</div> */}
                          </div>
                        </div>
                        <div className="post-footer-right w-50">
                            {
                                comments?.length > 0
                                ? comments?.length === 1
                                    ? <p className='text-end'>1 Comment</p>
                                    : <p className='text-end'>{comments?.length} Comments</p>
                                : <p className='text-end'>0 Comment</p>
                            }
                        </div>
                    </div>                 
                  </div>
                : null
              }
              <p>Comment</p>
            </div>
            <div className="conversation-body" id="scrollableDiv">
              {/* This will implement it feeds.tsx file */}
              {/* <InfiniteScroll
                dataLength={2}
                className="scrolling"
                next={() => console.log("next")}
                inverse={true}
                hasMore={false}
                loader={<h4 className="text-center">Loading...</h4>}
                scrollableTarget="scrollableDiv"
                height={320}
                
              > */}
              {
                comments?.map((comment: any, index: number) => (
                  workspaceSettings?.workspaceName !== comment?.repliedByUserName
                  ?
                  <div key={index} className="conversation-person-1">
                    <div className="details">
                      {
                        comment?.repliedByUserImageUrl
                        ? <img
                            className="user-avatar"
                            src={comment.repliedByUserImageUrl}
                            alt="avatar"
                          />
                        : <div className='user-avatar'>
                            {
                              comment?.repliedByUserName?.split(" ")?.slice(0, 2)?.length === 2
                              ?  comment?.repliedByUserName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                              :  comment?.repliedByUserName?.substr(0, 2).toUpperCase()
                            }
                          </div>
                      }
                      <div className="description">
                        <div className={`d-flex align-items-center user-ratings-container`}>
                          <div className="user_name me-2 ">
                            { comment?.repliedByUserName || "User Name"}
                          </div>
                        </div>

                        <div className="d-flex align-items-center recommend-not-recommend-container">
                          {(reviewtype || reviewType)?.toUpperCase() === "POSITIVE" ? (
                            <img
                              className='recommend-not-recommend-image'
                              src={getImage("fb-recommend")}
                              alt="fb-recommend"
                            />
                          ) : (reviewtype || reviewType)?.toUpperCase() === "NEGATIVE" ? (
                            <img
                              className='recommend-not-recommend-image'
                              src={getImage("fb-dont-recommend")}
                              alt="fb-recommend"
                            />
                          ) : null}
                          <span className="mx-1 client-container block">
                            {(reviewtype || reviewType)?.toUpperCase() === "POSITIVE"
                            ? " Recommendation"
                            : (reviewtype || reviewType)?.toUpperCase() === "NEGATIVE"
                            ? "Non Recommendation"
                            : null}
                          </span>
                        </div>
                        
                        {
                          comment?.attachment
                          ? <ShowFiles
                              data={ comment?.attachment
                                // [
                                //   {  
                                //     attachmentUrl: comment?.attachmentUrl,
                                //     attachmentType: comment?.attachmentType,
                                //     id: comment?.attachmentId
                                //   }
                                // ]
                              }
                            />
                          : null
                        }
                        <p>{comment?.reviewReplyMessage}</p>
                        <div className="like-edit-reply">
                          <div className='image-likes'>
                            <img
                              className={`image-like ${!comment?.reviewReplyId ? "disable-hover" : ""}`}
                              src={getImage("like")}
                              alt="like"
                              onClick={() => {
                                if (!comment?.reviewReplyId) {
                                  return;
                                } else {
                                  handleLike(comment, comment?.isLike);
                                }
                              }}
                            />
                            <div
                              className="likes"
                              style={{color: "inherit"}}
                            >
                              {
                                (comment?.likeCount === 0 || comment?.likeCount === 1)
                                ? comment?.likeCount + " Like"
                                : comment?.likeCount + " Likes"
                              }
                              {/* {likeCount ? "Liked" : "n/a"} */}
                            </div>
                          </div>
                          <div className={`reply-edit ${!comment?.reviewReplyId ? "disable-hover" : ""}`}
                              onClick={() => {
                                if (!comment?.reviewReplyId) {
                                  return;
                                } else {
                                  setCurrentRecord(comment);
                                  setIsReply(true);
                                  dispatch(toggleReplyingOn(true));
                                  dispatch(toggleIsInReplyingMode(true));
                                }
                              }}
                          >Reply</div>
                        </div>
                      </div>
                    </div>
                    <p className="text-start mt-2 date">
                      Review - {" "}
                      <span>
                        {
                          
                          moment(comment?.repliedOn).format("DD MMM YYYY, hh:mm:ss A")
                          // moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + ("Tezla Inc")
                          // moment(details?.requested_time).format('Y-m-d')
                        }
                      </span>
                    </p>
                  </div>
                  :
                  <div className="conversation-person-2">
                    <div className="details">
                      {
                        workspaceSettings?.logo
                        ?   <img
                                className="user-avatar"
                                src={workspaceSettings?.logo || user_avatar_2}
                                alt="user_avatar"
                            />
                        : <div className='user-avatar'>
                            {
                            comment?.repliedByUserName?.split(" ")?.slice(0, 2)?.length === 2
                            ? comment?.repliedByUserName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                            : comment?.repliedByUserName?.substr(0, 2).toUpperCase()
                            }
                        </div>
                      }
                      <div className="description">
                        <p>{comment?.reviewReplyMessage}</p>
                        <div className="like-edit-reply">
                          <div className='image-likes'>
                            <img
                              className="image-like"
                              src={getImage("like")}
                              alt="like"
                              // onClick={() => setLikeCount(likeCount ? 0 : 1)}
                            />
                            <div
                              className="likes"
                              style={{color: "inherit"}}
                            >
                              {/* {(likeCount === 0 || likeCount === 1)
                                ? likeCount + " Like"
                                : likeCount + " Likes"} */}
                              {likeCount ? "Liked" : "n/a"}
                            </div>
                          </div>
                          <div className='reply-edit'
                            onClick={() => {
                              setIsReply(true);
                              dispatch(toggleReplyingOn(true));
                              dispatch(toggleIsInReplyingMode(true));
                            }}
                          >Edit</div>
                        </div>
                      </div>
                    </div>
                    <p className="text-end mt-2 date">
                      {"Replied"} - {" "}
                      <span>
                        {
                          moment(comment?.repliedOn).format("DD MMM YYYY, hh:mm:ss A") +
                          " by " +
                          (workspaceSettings?.workspaceName || "WS Name")
                          // moment(details?.requested_time).format('Y-m-d')
                        }
                      </span>
                    </p>
                  </div>
                ))
              }
              

              {/* </InfiniteScroll> */}
            </div>
          </Scrollbars>
        </div>
        <div className="conversation-footer">
          <p className="text-start my-2 px-3 sender">
            {
              sender
              ? `${sender} is typing...`
              : `Sending as ${workspaceSettings?.workspaceName || "WS Name"}`
            }
          </p>
          <div className="message-box">
            <div className="message">
              <div className="text-container">
                <textarea
                  ref={textRef}
                  className=""
                  value={replyText || ""}
                  placeholder="Write here..."
                  onChange={(event: any) => {
                    setCurrentReplyText(event.target.value);
                    debouncedTextChange(event.target.value);
                  }}
                  style={{ resize: "none" }}
                  autoFocus={isReply}
                />
              </div>
              <div className="reply-btn">
                <button
                  style={{
                    background: `${isReply ? "#E4E7EC" : "#E4E7EC"}`,
                  }}
                  onClick={() => {
                    if (isReply) {
                        handleReplyRecord(replyText, currentRecord);
                    } else {
                        !currentRecord && setCurrentRecord(conversation);
                        setIsReply(true);
                        dispatch(toggleReplyingOn(true));
                        dispatch(toggleIsInReplyingMode(true));
                    }
                  }}
                  type="button"
                  disabled={sender ? true : false}
                >
                  {
                    // isReply && replyText
                    // ? "Save" : 
                    isReply
                    ? "Send"
                    : replyText
                    ? "Edit"
                    : "Reply"}
                </button>
                <img
                  onClick={() => {
                    setIsReply(false);
                    dispatch(toggleReplyingOn(false));
                    dispatch(toggleIsInReplyingMode(false));
                  }}
                  src={(sender || isReplyingOn || isInReplyingMode) ? lock_icon : unlock_icon}
                  alt="lock-unlock"
                />
              </div>
              <p className={`click-reply-des ${(isReply && !sender) ? "d-none" : ""}`}>
                {
                  sender
                  ? "You can not reply now. Editor is locked"
                  : "Click reply and start adding your comment."
                }
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="conversation-container-mobile">
        <div className="conversation-header">
          <div className="header-top">
            <img
              onClick={handlePrevPath}
              className="back"
              src={back_btn}
              alt="back"
            />
            <img
              className="provider"
              src={getImage(provider)}
              alt="provider"
            />
          </div>
          {/* <div className="header-body">
            <div className="body-left">
              <div className="first-ws">
                {
                  reviewerPhoteUrl
                  ? <img
                      className="user-avatar"
                      src={reviewerPhoteUrl || user_avatar}
                      alt="avatar"
                    />
                  : <span className='border text-light image-logo rounded-circle d-flex align-items-center justify-content-center'>
                      {
                        reviewedbyUserName?.split(" ")?.slice(0, 2)?.length === 2
                        ? reviewedbyUserName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                        : reviewedbyUserName?.substr(0, 2).toUpperCase()
                      }
                    </span>
                }
                
                <div className="user-info">
                  <p className="user-name">
                    {reviewedbyUserName?.substr(0, 6) || "Client"}
                  </p>
                  <span className="review-type">
                    {reviewtype?.toUpperCase() === "POSITIVE"
                      ? "recommends"
                      : reviewtype?.toUpperCase() === "NEGATIVE"
                      ? "doesn't recommend"
                      : null}
                  </span>
                  <p className="ws-name">
                    {workspaceSettings?.workspaceName?.substr(0, 6) ||
                      "WS Name"}
                  </p>
                </div>
              </div>
              <div className="second-ws">
                <img
                  className="image-logo"
                  src={workspaceSettings?.logo || user_avatar_2}
                  alt="user_avatar"
                />
                <p className="ws-name">
                  {workspaceSettings?.workspaceName?.substr(0, 7) ||
                    "WS Name"}
                </p>
              </div>
            </div>
            <div className="body-right">
              <img
                onClick={() =>
                  handleReplaceConversation("prev", conversation?.idx)
                }
                src={prev_icon}
                alt="prev"
                title='Previous'
              />
              <img
                onClick={() =>
                  handleReplaceConversation("next", conversation?.idx)
                }
                src={next_icon}
                alt="next"
                title='Next'
              />
              <img
                onClick={() => dispatch(setSuccessMessage("Refreshed"))}
                src={refresh_icon}
                alt="refresh"
                title='Refresh'
              />
            </div>
          </div> */}
          <ConversationHeaderSD
            header_title={
              (reviewtype || reviewType)?.toUpperCase() === "POSITIVE"
              ? " recommends "
              : (reviewtype || reviewType)?.toUpperCase() === "NEGATIVE"
              ? " doesn't recommend " : ""
            }
            workSpaceName={workSpaceName}
            workspaceSettings={workspaceSettings}
            conversation={conversation}
            handleReplaceConversation={handleReplaceConversation}
            prev_icon={prev_icon}
            next_icon={next_icon}
            refresh_icon={refresh_icon}
            user_avatar_2={user_avatar_2}
            user_avatar={user_avatar}
          />
          <div className="header-bottom">
            <span
              onClick={() => isGoForDetails && handleDetailChange()}
              style={{ color: !isGoForDetails ? "#1570EF" : "" }}
            >
              Messages
            </span>
            <span
              onClick={() => !isGoForDetails && handleDetailChange()}
              style={{ color: isGoForDetails ? "#1570EF" : "" }}
            >
              Details
            </span>
          </div>
        </div>
        {isGoForDetails ? (
          <div className="conversation-details-mobile-container">
            <ConversationDetails conversationId={conversationId} />
          </div>
        ) : (
          <>
            <div className="conversation-body">
              <Scrollbars
                style={{
                  paddingRight: 10,
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
                // style={{ paddingRight: 10 }}
                autoHide
                autoHideTimeout={500}
                autoHideDuration={500}
                autoHeight
                // autoHeightMin={250}
                // autoHeightMax={250}
                // autoHeightMin={480}
                autoHeightMax={"100%"}
                thumbMinSize={10}
                universal={true}
                >
                  <div className="post">
                  {
                    post?.message
                    ?   <div className='post-container mb-3' style={{width: '90%'}}>
                            <div className="post-header">
                                {
                                    post?.userImageUrl
                                    ?   <img
                                            className="user-avatar"
                                            style={{
                                                fontSize: "0.75rem", 
                                                background: '#14B0BF',
                                                borderRadius: '50%',
                                                height: 22,
                                                width: "auto"
                                            }}
                                            src={post?.userImageUrl || user_avatar}
                                            alt="avatar"
                                        />
                                    :   <div className='user-avatar' 
                                           
                                        >
                                        {
                                            post?.userName?.split(" ")?.slice(0, 2)?.length === 2
                                            ? post?.userName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                                            : post?.userName?.substr(0, 2).toUpperCase()
                                        }
                                        </div>
                                }
                                <div className='content'>
                                    <h2 className='user-name'>{post?.userName}</h2>
                                    <p>
                                        {
                                            moment(post?.postCreatedDate).format("DD MMM YYYY, hh:mm:ss A")
                                            // moment(createdOn).format("MMMM Do YYYY, hh:mm:ss A")
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className="post-body">
                                {
                                    post?.attachment
                                    ? <ShowFiles data={post?.attachment} />
                                    : null
                                }
                                <p>
                                    {post?.message}
                                </p>
                            </div>                 
                            <div className="post-footer">
                                <div className="post-footer-left w-50">
                                    <div className="like-edit-reply">
                                        <div className='image-likes'>
                                        <img
                                            className="image-like"
                                            src={getImage("like")}
                                            alt="like"
                                            onClick={() => {
                                                handleMainLikeClick({
                                                    isLike: likeCount?.isLike ? false : true,
                                                    likes: likeCount?.isLike ? (likeCount?.likes - 1) : (likeCount?.likes + 1)
                                                });
                                            }}
                                        />
                                        <div
                                            className="likes"
                                            style={{color: "inherit"}}
                                        >
                                            {
                                                (likeCount?.likes === 0 || likeCount?.likes === 1)
                                                ? likeCount?.likes + " Like"
                                                : likeCount?.likes + " Likes"
                                            }
                                        </div>
                                        </div>
                                        {/* <div className='reply-edit'
                                            onClick={() => {
                                                setCurrentRecord(conversation);
                                                setIsReply(true);
                                                dispatch(toggleReplyingOn(true));
                                                dispatch(toggleIsInReplyingMode(true));
                                            }}
                                        >Reply</div> */}
                                    </div>
                                </div>
                                <div className="post-footer-right w-50">
                                    {
                                        comments?.length > 0
                                        ? comments?.length === 1
                                            ? <p className='text-end'>1 Comment</p>
                                            : <p className='text-end'>{comments?.length} Comments</p>
                                        : <p className='text-end'>0 Comment</p>
                                    }
                                </div>
                            </div>                 
                        </div>
                    : null
                  }
                  <p>Comment</p>
                </div>
                <div className="conversation-body" id="scrollableDiv">
                  {/* This will implement it feeds.tsx file */}
                  {
                    comments?.map((comment: any, index: number) => (
                      workspaceSettings?.workspaceName !== comment?.repliedByUserName
                      ?
                      <div key={index} className="conversation-person-1">
                        <div className="details">
                          {
                            comment?.repliedByUserImageUrl
                            ? <img
                                className="user-avatar"
                                src={comment.repliedByUserImageUrl}
                                alt="avatar"
                              />
                            : <div className='user-avatar'>
                                {
                                  comment?.repliedByUserName?.split(" ")?.slice(0, 2)?.length === 2
                                  ?  comment?.repliedByUserName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                                  :  comment?.repliedByUserName?.substr(0, 2).toUpperCase()
                                }
                              </div>
                          }
                          <div className="description">
                            <div className={`d-flex align-items-center user-ratings-container`}>
                              <div className="user_name me-2 ">
                                { comment?.repliedByUserName || "User Name"}
                              </div>
                            </div>

                            <div className="d-flex align-items-center recommend-not-recommend-container">
                              {(reviewtype || reviewType)?.toUpperCase() === "POSITIVE" ? (
                                <img
                                  className='recommend-not-recommend-image'
                                  src={getImage("fb-recommend")}
                                  alt="fb-recommend"
                                />
                              ) : (reviewtype || reviewType)?.toUpperCase() === "NEGATIVE" ? (
                                <img
                                  className='recommend-not-recommend-image'
                                  src={getImage("fb-dont-recommend")}
                                  alt="fb-recommend"
                                />
                              ) : null}
                              <span className="mx-1 client-container block">
                                {(reviewtype || reviewType)?.toUpperCase() === "POSITIVE"
                                ? " Recommendation"
                                : (reviewtype || reviewType)?.toUpperCase() === "NEGATIVE"
                                ? "Non Recommendation"
                                : null}
                              </span>
                            </div>
                            {
                              comment?.attachment
                              ? <ShowFiles
                                  data={ comment?.attachment
                                    // [
                                    //   {  
                                    //     attachmentUrl: comment?.attachmentUrl,
                                    //     attachmentType: comment?.attachmentType,
                                    //     id: comment?.attachmentId
                                    //   }
                                    // ]
                                  }
                                />
                              : null
                            }
                            <p>{comment?.reviewReplyMessage}</p>
                            <div className="like-edit-reply">
                              <div className='image-likes'>
                                <img
                                  className={`image-like ${!comment?.reviewReplyId ? "disable-hover" : ""}`}
                                  src={getImage("like")}
                                  alt="like"
                                  onClick={() => {
                                    if (!comment?.reviewReplyId) {
                                      return;
                                    } else {
                                      handleLike(comment, comment?.isLike);
                                    }
                                  }}
                                />
                                <div
                                  className="likes"
                                  style={{color: "inherit"}}
                                >
                                  {
                                    (comment?.likeCount === 0 || comment?.likeCount === 1)
                                    ? comment?.likeCount + " Like"
                                    : comment?.likeCount + " Likes"
                                  }
                                  {/* {likeCount ? "Liked" : "n/a"} */}
                                </div>
                              </div>
                              <div className={`reply-edit ${!comment?.reviewReplyId ? "disable-hover" : ""}`}
                                  onClick={() => {
                                    if (!comment?.reviewReplyId) {
                                      return;
                                    } else {
                                      setCurrentRecord(comment);
                                      setIsReply(true);
                                      dispatch(toggleReplyingOn(true));
                                      dispatch(toggleIsInReplyingMode(true));
                                    }
                                  }}
                              >Reply</div>
                            </div>
                          </div>
                        </div>
                        <p className="text-start mt-2 date">
                          Review - {" "}
                          <span>
                            {
                              
                              moment(comment?.repliedOn).format("DD MMM YYYY, hh:mm:ss A")
                              // moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + ("Tezla Inc")
                              // moment(details?.requested_time).format('Y-m-d')
                            }
                          </span>
                        </p>
                      </div>
                      :
                      <div className="conversation-person-2">
                        <div className="details">
                        `{
                            workspaceSettings?.logo
                            ?   <img
                                    className="user-avatar"
                                    src={workspaceSettings?.logo || user_avatar_2}
                                    alt="user_avatar"
                                />
                            : <div className='user-avatar'>
                                {
                                comment?.repliedByUserName?.split(" ")?.slice(0, 2)?.length === 2
                                ? comment?.repliedByUserName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                                : comment?.repliedByUserName?.substr(0, 2).toUpperCase()
                                }
                            </div>
                          }
                          <div className="description">
                            <p>{comment?.reviewReplyMessage}</p>
                            <div className="like-edit-reply">
                              <div className='image-likes'>
                                <img
                                  className="image-like"
                                  src={getImage("like")}
                                  alt="like"
                                  // onClick={() => setLikeCount(likeCount ? 0 : 1)}
                                />
                                <div
                                  className="likes"
                                  style={{color: "inherit"}}
                                >
                                  {/* {(likeCount === 0 || likeCount === 1)
                                    ? likeCount + " Like"
                                    : likeCount + " Likes"} */}
                                  {likeCount ? "Liked" : "n/a"}
                                </div>
                              </div>
                              <div className='reply-edit'
                                onClick={() => {
                                  setIsReply(true);
                                  dispatch(toggleReplyingOn(true));
                                  dispatch(toggleIsInReplyingMode(true));
                                }}
                              >Edit</div>
                            </div>
                          </div>
                        </div>
                        <p className="text-end mt-2 date">
                          {"Replied"} - {" "}
                          <span>
                            {
                              moment(comment?.repliedOn).format("DD MMM YYYY, hh:mm:ss A") +
                              " by " +
                              (workspaceSettings?.workspaceName || "WS Name")
                              // moment(details?.requested_time).format('Y-m-d')
                            }
                          </span>
                        </p>
                      </div>
                    ))
                  }
                </div>
              </Scrollbars>
            </div>
            <div className="conversation-footer">
              <div
                style={{ padding: "0 10px" }}
                className="d-flex align-items-center justify-content-between my-2"
              >
                <p className="text-start sender">
                  {
                    sender
                    ? `${sender} is typing...`
                    : `Sending as ${workspaceSettings?.workspaceName || "WS Name"}`
                  }
                </p>
                <img
                  onClick={() => {
                    setIsReply(false);
                    dispatch(toggleReplyingOn(false));
                    dispatch(toggleIsInReplyingMode(false));
                  }}
                  src={(sender || isReplyingOn || isInReplyingMode) ? lock_icon : unlock_icon}
                  alt="lock-unlock"
                />
              </div>
              <div className="message-box">
                <div className="message">
                  <div className="text-container">
                    <textarea
                      ref={textRef}
                      className=""
                      value={replyText || ""}
                      placeholder="Write here..."
                      onChange={(event: any) => {
                        setCurrentReplyText(event.target.value);
                        debouncedTextChange(event.target.value);
                      }}
                      style={{ resize: "none" }}
                      autoFocus={isReply}
                    />
                  </div>
                  <div className="send-reply-action">
                    <div
                      onClick={() => {
                        if (isReply) {
                            handleReplyRecord(replyText, currentRecord);
                        } else {
                            !currentRecord && setCurrentRecord(conversation);
                            setIsReply(true);
                            dispatch(toggleReplyingOn(true));
                            dispatch(toggleIsInReplyingMode(true));
                        }
                      }}
                      // type="button"
                    >
                      {isReply ? (
                        <img src={send_icon} alt="save-account" />
                      ) : replyText ? (
                        <img src={edit_icon} alt="edit" />
                      ) : (
                        <img src={reply_icon} alt="reply" />
                      )}
                    </div>
                  </div>
                  <p
                    className={`click-reply-des ${(isReply && !sender) ? "d-none" : ""}`}
                  >
                    {
                      sender
                      ? "You can not reply now. Editor is locked"
                      : "Click reply and start adding your comment."
                    }
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  )
}
