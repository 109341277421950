import React from 'react';
import { Button, Dropdown, Space, Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import { setWorkspaces, toggleChangeWorkspace, getRoles, setCurrentWorkspace } from '../headerReducer';
// import { getUserPermissions, resetForChangeWorkspace } from '../../../../signup-login/loginReducer';
// import clapup_logo from "../../../../assets/icons/ClapUp-Icon.svg";
// import { getWorkspaceSettings } from '../../../../pages/workspace-management/workSpaceReducer';
// import { convertToClapUpUser } from '../../../../pages/user-management/userManagementReducer';
import PermissionCheck from '../../../PermissionCheck';
import Constants from '../../../../constants';
import { changePage } from '../../../../dashboard/reducer';
import {
  SettingOutlined, UserOutlined, LogoutOutlined,
  // CopyOutlined
} from '@ant-design/icons';
import { getGenerateApiImageByName } from '../../../../dashboard/image_service';


const HeaderDropdown: React.FC<any> = () => {
//   const { workspaceSettings } = useSelector(
//     (state: any) => state?.workspaceManagementState
//   );
//   const {workspaces} = useSelector((state: any) => state?.headerState);
  // const { isReplyingOn } = useSelector((state: any) => state?.unified);
  const [path, setPath] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const query_string = window.location.search;
  const path_name = window.location.pathname;
  const user_info = JSON.parse(
    sessionStorage.getItem("accessed_user_details") || "{}"
  );
  React.useEffect(() => {
      // const query = query_string.replace('?', '');
      // console.log({ path_name, query, user_info });
      setPath(path_name);
      
  }, [query_string, path_name]);
  
  const history = useNavigate();
  const dispatch = useDispatch()


  const handleLogout = () => {
      console.log("Logout");
      dispatch({type: Constants.LOGOUT});
      // dispatch(triggerLogout())
      return history("/login");
  }

  // let user_name = "Mehedi"?.split(" ")?.slice(0, 2)?.length === 2
  const user_name = user_info?.name?.split(" ")?.slice(0, 2)?.length === 2
      ? user_info?.name?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("")
      : user_info?.name?.substr(0, 2).toUpperCase();
  return (
    <React.Fragment>
      <Space direction="vertical">
          <Space wrap>
              <Dropdown 
                  className=""
                  trigger={['click']} 
                  overlay={<div className="mt-3 py-2 px-1 bg-white rounded shadow">
                      <PermissionCheck permissionName="profile">
                          <div
                              className="border-bottom p-1"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                  if(path !== "/user/personal-info") {
                                      setOpen(!open);
                                      dispatch(changePage("/user/personal-info"));
                                      // (isReplyingOn ? dispatch(changePage("/user/personal-info")) : history("/user/personal-info", { replace: true }));
                                  }
                              }}
                          >
                              <UserOutlined className="mx-1" /> Profile
                          </div>
                      </PermissionCheck>
                      
                      <PermissionCheck permissionName="accountSettings">
                          <div 
                              style={{ cursor: 'pointer' }}
                              className="border-bottom p-1"
                              onClick={() => {
                                  if(path !== "/account/general-settings"){
                                      setOpen(!open);
                                      dispatch(changePage("/account/general-settings"))
                                      // (isReplyingOn ? dispatch(changePage("/account/general-settings")) : history("/account/general-settings", { replace: true }));
                                      // history("/account/general-settings");
                                  }
                              }}
                              >
                              <SettingOutlined className="mx-1"/> Account Settings
                          </div>
                      </PermissionCheck>

                      <div 
                          className="p-1"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                              setOpen(!open);
                              handleLogout();
                          }}
                      >
                          <LogoutOutlined className="mx-1" /> Logout
                      </div>
                  </div>} 
                  placement="bottomLeft"
                  open={open}
                  onOpenChange={() => {
                      setOpen(!open);
                  }}
              >
                  <Button
                      style={{
                          padding: "0 0 1% 0",
                          width: 32,
                          // width: "fit-content",
                          height: 30,
                          // borderRadius: 50
                          background: "#1570EF"
                      }}
                      className="rounded-circle"
                      type={"default"}
                  >
                      {/* <img src={user_info?.picture || user} style={{height: "100%", width: "100%" }} className='logo-icon rounded-circle' /> */}
                          {/* <span style={{ fontSize: 12, fontWeight: 600, color: 'white' }} className="text-uppercase">{user_name}</span> */}
                        <img
                            className="main-user-profile-avatar"
                            src={getGenerateApiImageByName(user_name)}
                            alt="avatar"
                        />
                  </Button>
              </Dropdown>
          </Space>
      </Space>
    </React.Fragment>
  );
};

export default HeaderDropdown;