import React, { useEffect } from 'react';
import { DateRangePicker } from "rsuite";
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import './search.scss';

const MobileCalendar = ({handleSelectField, isOpenCalender}: any) => {
    const [date, setDate] = React.useState(null);

    const predefinedBottomRanges: any = [
      // {
      //   label: "Today",
      //   value: [new Date(), new Date()],
      //   placement: "left",
      // },
      // {
      //   label: "Yesterday",
      //   value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      //   placement: "left",
      // },
      // {
      //   label: "This week",
      //   value: [startOfWeek(new Date()), endOfWeek(new Date())],
      //   placement: "left",
      // },
      {
        label: <small>Last 7 days</small>,
        value: [subDays(new Date(), 6), new Date()],
        // placement: "left",
      },
      {
        label: <small>Last 30 days</small>,
        value: [subDays(new Date(), 29), new Date()],
        // placement: "left",
      },
      // {
      //   label: "This month",
      //   value: [startOfMonth(new Date()), new Date()],
      //   placement: "left",
      // },
      // {
      //   label: "Last month",
      //   value: [
      //     startOfMonth(addMonths(new Date(), -1)),
      //     endOfMonth(addMonths(new Date(), -1)),
      //   ],
      //   placement: "left",
      // },
      {
        label: <small>Last 90 days</small>,
        value: [subDays(new Date(), 89), new Date()],
        // placement: "left",
      },
      {
        label: new Date().getFullYear(),
        value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      },
      {
        label: new Date().getFullYear() - 1,
        value: [
          new Date(new Date().getFullYear() - 1, 0, 1),
          new Date(new Date().getFullYear(), 0, 0),
        ],
      },
      {
        label: new Date().getFullYear() - 2,
        value: [
          new Date(new Date().getFullYear() - 2, 0, 1),
          new Date(new Date().getFullYear(), 0, 0),
        ],
      },
      // {
      //   label: new Date().getFullYear() - 3,
      //   value: [
      //     new Date(new Date().getFullYear() - 3, 0, 1),
      //     new Date(new Date().getFullYear(), 0, 0),
      //   ],
      // },
    ];

    return (
        <React.Fragment>
            <DateRangePicker
                block
                value={date}
                ranges={predefinedBottomRanges}
                // defaultOpen={true}
                open={isOpenCalender}
                size={"sm"}
                placeholder={"Select a date"}
                onOk={(value: any) => {
                    // console.log(value);
                    // setIsOpenCalender(!isOpenCalender);
                    handleSelectField(value, "date");
                    setDate(value);
                }}
                // renderTitle={(date: any) => {
                //     return (
                //         <div className="text-center">
                //             <h5>Choose a date</h5>
                //         </div>
                //     );
                // }}
                style={{ width: 250, display: "none" }}
                // renderExtraFooter={undefined}
                
                showMeridian={true}
                showOneCalendar={true}
                placement="autoVerticalEnd"
            />
            {/* <div className='border bg-white shadow mt-3 p-2 rounded filter' style={{width: 100}}>
                <p onClick={() => handleSelectField("All", "date")} style={{margin: 2, cursor: 'pointer'}} className='text-start'>All</p>
                <p onClick={() => handleSelectField("Unread", "date")} style={{margin: 2, cursor: 'pointer'}} className='text-start'>Unread</p>
                <p onClick={() => handleSelectField("Not Replied", "date")} style={{margin: 2, cursor: 'pointer'}} className='text-start'>Not Replied</p>
                <p onClick={() => handleSelectField("Replied", "date")} style={{margin: 2, cursor: 'pointer'}} className='text-start'>Replied</p>
            </div> */}
        </React.Fragment>
    );

}

export default MobileCalendar;
