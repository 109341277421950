import { takeEvery, put, call, all } from 'redux-saga/effects';
import axios from "axios";

import { 
    findUserList, 
    setIsInvitationSent, 
    setUpdateStatus,
    deleteUser,
    setErrorMessage, 
    setConvertedClapUpUser
} from './userManagementReducer';
import { api_url } from '../../utils/server';
import { generateAccessToken } from '../../profile/reducer';
import Constants from '../../constants';

// Worker Saga:
function* findUsers(action: any): Generator<any> {
    // console.log("header -> Worker saga => findUsersSaga action===>", action);
    const {clapupUserId, roleId, workspaceId} = action?.payload;
    try {
        let url = api_url + `/api/v1.2/ClapupIdentity/GetWorkspaceUsers?clapupUserId=${clapupUserId}&roleId=${roleId}&workspaceId=${workspaceId}`;
        const { data, status }: any = yield call(axios.get, url, {
            headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
            }
        });
        // console.log('findUsers===user manager saga==>', data);
        if (status === 200) {   
            yield put(findUserList(data?.map((user: any, idx: number) => {
                return {
                    ...user,
                    idx: (idx + 1)
                }
            })));
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* updateUserRole(action: any): Generator<any> {
    // console.log("header -> Worker saga => updateUserRole action===>");
    let url = api_url + `/api/v1.2/ClapupIdentity/AddUsersToRoles`;
    try {
        const {data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {   
            // console.log('updateUserRole===user manager saga==>', data)
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* inviteUser(action: any): Generator<any> {
    // console.log("header -> Worker saga => inviteUser action===>", action);
    let url = api_url + `/api/v1.2/ClapupIdentity/InviteUser `;
    try {
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {   
            // console.log('inviteUser===user manager saga==>', data)
            yield put(setIsInvitationSent(data ? true : false));
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* updateUserStatus(action: any): Generator<any> {
    let url = api_url + `/api/v1.2/ClapupIdentity/UpdateUserStatus`;
    try {
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        // console.log('updateUserStatus===user manager saga==>', data);
        if (status === 200) {   
            if(action.payload.status === "Delete"){
                yield put(setUpdateStatus(data));
            }else{
                yield put(deleteUser(data));
            }
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// convert to clapup user
function* convertToClapupUser(action: any): Generator<any> {
    let url = api_url + `/api/v1.2/ClapupIdentity/ConvertToClapupUser`;
    try {
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {   
            yield put(setConvertedClapUpUser(data));
        } 
    } catch (error) {
        const { response, message }: any = error;
        yield put(setErrorMessage(message));
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// Watcher Saga:
function* userManagementSaga() {
    
    yield all([
      takeEvery(Constants.CONVERT_TO_CLAPUP_USER, convertToClapupUser),
      takeEvery(Constants.GET_USER_REQUEST, findUsers),
      takeEvery(Constants.UPDATE_USER_ROLE, updateUserRole),
      takeEvery(Constants.UPDATE_STATUS, updateUserStatus),
      takeEvery(Constants.INVITE_USER, inviteUser),
    ]);
}

export default userManagementSaga;

