import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorMessage, setSuccessMessage } from '../../../../dashboard/reducer';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getImage, getTemplateImage } from '../../../../dashboard/image_service';
import { Spinner } from 'react-bootstrap';
import Dialog from '../../../dialogs/dialog';
import CreateTemplateForm from './CreateTemplateForm';
import { deleteTemplateData, getFirstTimeTemplateList, toggleUpdatedTemplateBySignalR, getTemplateDetailsById, setFirstTimeTemplateList, setSearchTemplateData, toggleEditableTemplate, toggleModalTemplates, toggleTemplateEdition } from '../../reducer';
import './Templates.scss';
const Templates: React.FC = () => {
    const { template_list, is_updated_template_by_signal_r, toggle_modal_template, search_template_text, search_template_list } = useSelector((state: any) => state?.promotionsState);
    // const [all_templates, setAllTemplates] = useState<any>([]);
    const [searchText, setSearchText] = React.useState<string>('');
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [hasMore, setHasMore] = React.useState<boolean>(true);
    const dispatch = useDispatch();
    // useEffect(() => {
    //     console.info("Templates ", { Templates });
    //     if (!Templates?.length) {
    //         dispatch(getFirstTimeTemplateList());
    //         setIsLoading(true);
    //     } else if (search_template_text) {
    //         // console.info("Template ", { Templates });
    //         setIsLoading(false);
        
    //     } else {
    //         // console.info("Template ", { Templates });
    //         setIsLoading(false);
    //     }

    //     setTimeout(() => {
    //         setIsLoading(false);
    //     }, 1500);

    // }, [dispatch, template_list?.length]);
    
    useEffect(() => {
        if (search_template_text) {
            dispatch(setSearchTemplateData([]));
        }
    }, [dispatch, search_template_text]);
    
    const [Templates, setTemplates] = useState<any[]>([]);
    useEffect(() => {
        console.info("Templates ", { template_list, is_updated_template_by_signal_r });
        if (template_list?.length) {
            setTemplates(template_list);
            dispatch(toggleUpdatedTemplateBySignalR(false));
        } else {
            setTemplates([]);
        }

    }, [dispatch, template_list?.length, is_updated_template_by_signal_r]);

    const fetchMoreData = () => {
        setIsLoading(true);
        // if (!campaignNextDate) {
        //     setHasMore(false);
        //     setIsLoading(false);
        //     return;
        // }
        setTimeout(() => {
          // // setFolders(campaigns.concat(campaigns));
        //   const campaignPayload: any = {
        //       nextDate: campaignNextDate,
        //   };
  
        //   if (searchText) {
        //     campaignPayload.searchText = searchText;
        //     campaignPayload.settingsId = "";
        //   }
        //   dispatch(getFolders(campaignPayload));
          setIsLoading(false);
        }, 1500);
    };

    const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
    const handleAllTemplateCheck = (check: any) => {
        setIsAllChecked(check);
        // const modifiedTemplates = all_templates;
        const modifiedTemplates = Templates;
        modifiedTemplates.forEach((template: any) => {
            template.isChecked = check;
        });
        // setAllTemplates(modifiedTemplates);
        dispatch(setFirstTimeTemplateList(modifiedTemplates));
    };

    const handleTemplateCheck = (check: any, templateId: any) => {
        // const modifiedTemplates = all_templates;
        const modifiedTemplates = Templates;
        modifiedTemplates.map((template: any) => {
            if (template.templateId === templateId) {
                template.isChecked = check;
            }
            return template;
        });
        console.log({ check, templateId, modifiedTemplates });
        
        // setAllTemplates(modifiedTemplates);
        dispatch(setFirstTimeTemplateList(modifiedTemplates));
    };
    // console.log({ all_templates });
    

    const [showDeleteTemplate, setShowDeleteTemplate] = useState<any>(false);
    const handleGoToEditingTemplate = (template: any) => { 
        // dispatch(toggleTemplateEdition(template));
        console.log("Template Editing: ", {template});
        const { status } = template || {};
        if (status?.toLowerCase() === "draft" ||
            status?.toLowerCase() === "paused" ||
            status?.toLowerCase() === "approved" ||
            status?.toLowerCase() === "rejected") {
            dispatch(getTemplateDetailsById({templateId: template?.templateId}));
            return;
        }
        dispatch(setErrorMessage("This template is not editable!"));
    };

    return (
        <React.Fragment>
            {/* Create Template Modal */}
            <Dialog
                open={toggle_modal_template}
                onCancel={() => setShowDeleteTemplate(false)}
                parentCallback={(flag?: any) => dispatch(toggleModalTemplates(flag))}
                classes='dialog-container-commerce-form'
                // header='Add Campaign'
                closeIcon={getImage("form-close-icon")}
                footer={false}
            >
                <CreateTemplateForm 
                    onCancel={() => dispatch(toggleModalTemplates(false))}
                    onParentCallback={(data: any, file?: any) => {
                        // handleCreateNewTemplate(data, file);
                        console.log({ data });
                        dispatch(toggleModalTemplates(false));
                        dispatch(toggleTemplateEdition(data));
                        dispatch(toggleEditableTemplate(true));
                        // dispatch(setSuccessMessage("Created Successfully!"));
                        
                    }}      
                />
            </Dialog>

            {/* Delete Template Modal */}
            <Dialog
                open={showDeleteTemplate}
                onCancel={() => setShowDeleteTemplate(false)}
                parentCallback={(flag?: any) => {
                    const deletableTemplate = (search_template_text ? search_template_list : Templates)?.find((template: any) => template?.templateId === flag);
                    // console.log({ deletableTemplate });
                    // return;
                    if (deletableTemplate) {
                        const {templateId, language, templateName} = deletableTemplate;
                        setShowDeleteTemplate(false);
                        dispatch(deleteTemplateData({
                            templateId,
                            language,
                            templateName,
                        }));
                        // dispatch(setErrorMessage(flag + " - Deleted Successfully!"));
                    }
                }}
                classes='dialog-container-content'
                header='Delete Template?'
                warning_dialog_content={"If you delete the template, the same template name cannot be used   while creating a template  for the next 30 days"}
                // warning_dialog_content={"If you continue with the action, the same template name cannot be used for while creating a template  for the next 30 days"}
                // warning_dialog_content={"If you continue with the action, your template will be deleted for ever."}
                footer={true}
                left_button_text='No, Cancel'
                right_button_text='Yes, Continue'
                // hover={true}
            />

            <div className={`content-wall`}>
                <div className="table-responsive">
                    {/* bootstrap responsive table */}
                    <div className='infinity-scroll'>
                        <InfiniteScroll
                            dataLength={Templates?.length}
                            // className="border"
                            scrollThreshold={0.9}
                            style={{ overflow: "scroll", paddingRight: 5 }}
                            next={() => {
                                // console.log(
                                //     `prev -> ${campaigns?.length}, next -> ${campaigns?.length + 20}`
                                // );
                                // fetchMoreData();
                            }}
                            inverse={false}
                            hasMore={hasMore}
                            loader={
                                null
                                // isLoading && Templates?.length ? (
                                //     <div className="d-flex justify-content-center align-items-center mt-1">
                                //         <Spinner
                                //             size="sm"
                                //             animation="border"
                                //             variant="primary"
                                //         />
                                //     </div>
                                // ) : null
                            }
                            scrollableTarget="scrollableDiv"
                            // endMessage={
                            //   <p style={{ textAlign: "center" }}>
                            //     <b>Yay! You have seen it all</b>
                            //   </p>
                            // }
                            height={"70vh"} // 43.5vh
                        >
                            <table className="table table-borderless template-table m-0">
                                <thead className='template-thead sticky-top' style={{top: -1}}>
                                    <tr>
                                        <th>
                                            <img 
                                                style={{ marginRight: '0.8rem', cursor: 'pointer' }}
                                                onClick={() => handleAllTemplateCheck(!isAllChecked)}
                                                src={isAllChecked ? getTemplateImage("square-active-check") : getTemplateImage("square-inactive-check")}
                                                alt="select-icon"
                                            />
                                        </th>
                                        <th style={{textAlign: 'start', paddingLeft: 25}}>Name</th>
                                        <th>Category</th>
                                        <th>Language</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    // [1,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2]?.map((template: any, index: number) => (
                                    // all_templates?.map((template: any, index: number) => (
                                    (search_template_text ? search_template_list : Templates)?.map((template: any, index: number) => (
                                        <tr key={index}>
                                            <td>
                                                <img 
                                                    style={{marginRight: '0.8rem', cursor: 'pointer'}}
                                                    onClick={() => handleTemplateCheck(!template?.isChecked, template?.templateId)}
                                                    src={template?.isChecked ? getTemplateImage("square-active-check") : getTemplateImage("square-inactive-check")}
                                                    alt="select-icon"
                                                />
                                            </td>
                                            <td style={{cursor: 'pointer'}} onClick={() => handleGoToEditingTemplate(template)}>
                                                <div className='user-template'>
                                                    <img
                                                        src={getTemplateImage("table-template")}
                                                        alt="template-icon"
                                                    />
                                                    <p
                                                        style={{ cursor: 'pointer' }}
                                                        // onClick={() => handleGoToEditingTemplate(template)}
                                                    >
                                                        {template?.templateDisplayName || "templateDisplayName"}
                                                    </p>
                                                </div>    
                                            </td>
                                            <td style={{ cursor: 'pointer' }} onClick={() => handleGoToEditingTemplate(template)}>
                                                {template?.templateCategory || "templateCategory"}
                                            </td>
                                            <td style={{cursor: 'pointer'}} onClick={() => handleGoToEditingTemplate(template)}>
                                                {template?.language || "language"}
                                            </td>
                                            <td style={{cursor: 'pointer'}} onClick={() => handleGoToEditingTemplate(template)}>
                                                {template?.status || "status"}
                                            </td>
                                            <td>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20}}>
                                                    <img
                                                        onClick={() => setShowDeleteTemplate(template?.templateId)}
                                                        style={{ cursor: 'pointer' }}
                                                        src={getTemplateImage("delete-icon-line")}
                                                        alt="delete-icon"
                                                    />
                                                    <img
                                                        onClick={() => dispatch(setErrorMessage("Copying Not allowed Now, Coming Soon!"))}
                                                        style={{ cursor: 'pointer' }}
                                                        // style={{ marginLeft: '1.5rem', cursor: 'not-allowed' }}
                                                        src={getTemplateImage("copy-icon-line")}
                                                        alt="copy-icon"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </InfiniteScroll>
                    </div>
                </div>
            </div>

            {/* <div className={`content-wall`}>
            <div className='h-75 d-flex flex-column justify-content-center align-items-center w-100'>
              <img src={getImage('empty')} alt="empty" />
              <h3 className='mt-3'>No Templates Available</h3>
            </div>
          </div> */}
        </React.Fragment>
    );
};

export default Templates;