import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, TimePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import { RootState } from '../../../../../../store';
import { updateTemplateDataBeforeSubmit, toggleIsSubmittedTemplate } from '../../../../reducer';
import { setErrorMessage } from '../../../../../../dashboard/reducer';
import { capitalizeSentence } from '../../../../../../utils/convertingFunction';

type TemplateVariablesFormProps = {
    onCancel?: () => void;
    onParentCallback?: (data: any, file?: any) => void;
};

const TemplateVariablesForm: React.FC<any> = ({onCancel, onParentCallback }) => {
    const dispatch = useDispatch();
    const {
        isSubmittedTemplate, toggle_template_edition, call_to_action_templates, 
    }: any = useSelector((state: RootState) => state?.promotionsState);
    const [headerVariables, setHeaderVariables] = useState<any>(null);
    const [bodyVariables, setBodyVariables] = useState<any>(null);
    const [callToActionExampleUrlVariables, setCallToActionExampleUrlVariables] = useState<any>(null);
    const generateVariablesObject = (string: string, sample_string?: string ) => { 
        const regex = /{{(.*?)}}/g;
        // Set to store unique variable names
        const variableNamesSet = new Set();

        // Match variables and add their names to the set
        let match;
        while ((match = regex.exec(string)) !== null) {
            const variableName = match[1]?.trim()?.replaceAll(" ", "_");
            variableNamesSet.add(variableName); // Add variable name to the set
        }

        // Set to store unique variables with their corresponding objects
        const variablesSet = new Set();

        // Iterate over the set of variable names and construct corresponding objects
        variableNamesSet.forEach(variableName => {
            const variableObject = { variableName, variableValue: "" };
            variablesSet.add(variableObject); // Add object to the set
        });

        // Set variable values based on the variable names in the sample_string
        sample_string?.split(', ').forEach((pair: any) => {
            const [name, value] = pair?.split('=');
            variablesSet.forEach((variableObject: any) => {
                if (variableObject?.variableName === name) {
                    variableObject.variableValue = value;
                }
            });
        });
        return Array.from(variablesSet);
    };

    const { templateHeader, templateHeaderType, headerSampleData, templateBody, bodySampleData } = toggle_template_edition;
    useEffect(() => {
        // console.log({ templateHeader, templateHeaderType, headerSampleData, templateBody, bodySampleData });
        if(toggle_template_edition?.templateId) {
            const body_string = templateBody || "";
            const header_string = templateHeader || "";
            let headerVariablesArray: any = [];
            if (templateHeaderType?.toLowerCase() === "text") {
                headerVariablesArray = generateVariablesObject(header_string, headerSampleData || "");
            } else {
                if (templateHeaderType) {
                    headerVariablesArray = [{
                        variableName: "header_url",
                        variableValue: "",
                    }];
                }
            }
            const bodyVariablesArray: any = generateVariablesObject(body_string, bodySampleData || "");

            if (headerVariablesArray?.length) {
                setHeaderVariables(headerVariablesArray);
            };
            
            if (bodyVariablesArray?.length) {
                setBodyVariables(bodyVariablesArray);
            }

            const dynamic_call_to_action_website_url_object = call_to_action_templates?.find((cta: any) => cta?.urlType?.toLowerCase() === "dynamic");
            if (dynamic_call_to_action_website_url_object) {
                const urlExampleVariables = [{
                    variableName: "sample_url",
                    // variableValue: "",
                    variableValue: dynamic_call_to_action_website_url_object?.url,
                }];
                setCallToActionExampleUrlVariables(urlExampleVariables);
                console.log({ bodyVariablesArray, headerVariablesArray, urlExampleVariables });
            }
        }
    }, [toggle_template_edition?.templateId]);

    const handleSaveTemplateData = () => {
        // const { templateHeader, templateHeaderType } = toggle_template_edition;
        
        // ======== Header =========
        // checking variableValue is available or not using trim()
        let not_value_check = false;
        headerVariables?.forEach((item: any) => {
            if (!item?.variableValue?.trim()) {
                not_value_check = true;
            }
        });

        if (not_value_check) {
            dispatch(setErrorMessage("Please fill all the values!"));
            return;
        }

        if (templateHeaderType?.toLowerCase() !== "text") {
            const header_url_value = headerVariables?.length ? headerVariables[0]?.variableValue : "";
            const isUrl = new RegExp('^(http|https)://').test(header_url_value);
            if (!isUrl) {
                dispatch(setErrorMessage("Invalid Header URL!"));
                return;
            }
            if (header_url_value) { 
                if (templateHeaderType?.toLowerCase() === "image") { 
                    // checking .jpg and .png and .jpeg check in the templateHeader string for last time
                    const isJPG = header_url_value?.endsWith(".jpg");
                    const isJPEG = header_url_value?.endsWith(".jpeg");
                    const isPNG = header_url_value?.endsWith(".png");
                    console.log({ isJPG, isJPEG, isPNG });
                    if (!isJPG && !isJPEG && !isPNG) {
                        dispatch(setErrorMessage("Invalid Image URL!"));
                        return;
                    }
                }
                
                if (templateHeaderType?.toLowerCase() === "document") { 
                    // checking .pdf check in the header_url_value string for last time
                    const isPDF = header_url_value?.endsWith(".pdf");
                    console.log({ isPDF });
                    if (!isPDF) {
                        dispatch(setErrorMessage("Invalid Document URL!"));
                        return;
                    }
                }
                
                if (templateHeaderType?.toLowerCase() === "video") { 
                    // checking .mp4 check in the header_url_value string for last time
                    const isMP4 = header_url_value?.endsWith(".mp4");
                    console.log({ isMP4 });
                    if (!isMP4) {
                        dispatch(setErrorMessage("Invalid Video URL!"));
                        return;
                    }
                }
            }
        } else {
            // checking it is url or not
            const is_text_variable_value_as_url = headerVariables?.find((item: any) => {
                const isUrl = new RegExp('^(http|https)://').test(item?.variableValue);
                return isUrl;
            });
            // const isUrl = new RegExp('^(http|https)://').test(templateHeader);
            if (is_text_variable_value_as_url) {
                dispatch(setErrorMessage("Invalid variable!"));
                return;
            }
        }

        // return;
        // ========= X ===========
        
        // ========= Body ========
        // checking body variables value is available or not

        bodyVariables?.forEach((item: any) => {
            if (!item?.variableValue?.trim()) {
                not_value_check = true;
            }
        });

        if (not_value_check) {
            dispatch(setErrorMessage("Please fill all the values!"));
            return;
        }

        //  // checking body variables value is url or not
        const is_body_text_variable_value_as_url = bodyVariables?.find((item: any) => {
            const isUrl = new RegExp('^(http|https)://').test(item?.variableValue);
            return isUrl;
        
        })
        // const isUrl = new RegExp('^(http|https)://').test(templateHeader);
        if (is_body_text_variable_value_as_url) {
            dispatch(setErrorMessage("Invalid variable!"));
            return;
        }

        // ============= X ==========

        callToActionExampleUrlVariables?.forEach((item: any) => {
            if (!item?.variableValue?.trim()) {
                not_value_check = true;
            }
        });

        if (not_value_check) {
            dispatch(setErrorMessage("Please fill all the values!"));
            return;
        }

        if (callToActionExampleUrlVariables?.length) {
            const is_text_variable_value_as_url = callToActionExampleUrlVariables?.find((item: any) => {
                const isUrl = new RegExp('^(http|https)://').test(item?.variableValue);
                return isUrl;
            
            });
            
            if (!is_text_variable_value_as_url) {
                dispatch(setErrorMessage("Invalid sample url!"));
                return;
            }
        }

        
        const headerSampleData: any[] = headerVariables?.map((item: any) => item?.variableName + "=" + item?.variableValue);
        const bodySampleData: any[] = bodyVariables?.map((item: any) => item?.variableName + "=" + item?.variableValue);
        const callToActionUrlSampleData: any[] = callToActionExampleUrlVariables?.map((item: any) => item?.variableValue);
        const variable_payload: any = {
            templateId: toggle_template_edition?.templateId,
            workspaceId: toggle_template_edition?.workspaceId || sessionStorage.getItem('wuid'), 
            headerSampleData: headerSampleData?.join(",") || "",
            bodySampleData: bodySampleData?.join(",") || "",
            urlExample: callToActionUrlSampleData?.join(",") || "", // dynamic website url {{slug}}
            submitType: toggle_template_edition?.submitType === "insert" ? "insert" : "edit", // "edit" or "insert" and it will be taken by toggle_template_edition
        };
        console.log({ variable_payload, callToActionExampleUrlVariables });
        // return;
        dispatch(updateTemplateDataBeforeSubmit({toggle_template_edition, variable_payload}));
    };

    useEffect(() => { 
        if (isSubmittedTemplate) {
            handleBack();
        }
    }, [isSubmittedTemplate])

    const handleBack = () => {
        // const data = {
        //     clapupUserId: sessionStorage.getItem('cuid'),
        //     workspaceId: sessionStorage.getItem('wuid'),
        //     // roleId: sessionStorage.getItem('roleId'),

        // }
        // console.log({ headerVariable, bodyVariables});
        dispatch(toggleIsSubmittedTemplate(false));
        onParentCallback && onParentCallback(null);
    };

    const handleChangeCallToActionDynamicWebsiteVariable = (value: any, cur_idx: any) => {
        // console.log({ value, cur_idx });
        if (value?.length > 2000) {
            dispatch(setErrorMessage("URL should be max 2000 characters!"));
            return;
        }
        if (value?.length > 8) {
            const isUrl = new RegExp('^(http|https)://').test(value);
            if (!isUrl) {
                dispatch(setErrorMessage("Invalid URL!"));
                return;
            }
        }
        const callToActionVariables = callToActionExampleUrlVariables?.map((item: any, idx: number) => {
            if (cur_idx === idx) {
                return {
                    ...item,
                    variableValue: value
                }
            }
            return item;
        });
        setCallToActionExampleUrlVariables(callToActionVariables);
    }

    const handleChangeHeaderVariable = (value: any, cur_idx: any) => {
        // console.log({ value, cur_idx });

        if (templateHeaderType?.toLowerCase() === "text") { 
            const isUrl = new RegExp('^(http|https)://').test(value);
            if (isUrl) {
                dispatch(setErrorMessage("Invalid variable!"));
                return;
            }

            const regex = /\{\{[^}]+\}\}/g;
            const matches = templateHeader?.match(regex);
            // checking proper variable or not
            if (matches?.length) {
                const variable = matches[0];
                const without_variable_header_value = templateHeader?.replace(variable, "")?.replaceAll("  ", " ") || "";
                // console.log({without_variable_header_value, templateHeader, value});
                if ((without_variable_header_value?.trim() + value?.trim())?.length > 60) {
                    // dispatch(setErrorMessage("Value should be maximum 60 characters!"));
                    return;
                }
            }
        } else {
            const isUrl = new RegExp('^(http|https)://').test(value);
            if (value?.length > 8) {
                if (!isUrl) {
                    dispatch(setErrorMessage(`Invalid Header ${capitalizeSentence(templateHeaderType)}  URL!`));
                    return;
                }
                if (value?.length > 100) {
                    dispatch(setErrorMessage("URL should be maximum 100 characters!"));
                    return;
                }
            }
            
        }

        const newHeaderVariables = headerVariables?.map((item: any, idx: number) => {
            if (cur_idx === idx) {
                return {
                    ...item,
                    variableValue: value
                }
            }
            return item;
        });
        setHeaderVariables(newHeaderVariables);
    }
    
    const handleChangeBodyVariable = (value: any, cur_idx: any) => {
        // console.log({ value, cur_idx });
        if (value?.length > 60) {
            // dispatch(setErrorMessage("Value should be maximum 60 characters!"));
            return;
        }
        const isUrl = new RegExp('^(http|https)://').test(value);
        if (isUrl) {
            dispatch(setErrorMessage("Invalid variable!"));
            return;
        }
        const newBodyVariables = bodyVariables?.map((item: any, idx: number) => {
            if (cur_idx === idx) {
                return {
                    ...item,
                    variableValue: value
                }
            }
            return item;
        });
        setBodyVariables(newBodyVariables);
    }

    // const handleUploadFile = async (file: any, duration?: any, message?: string) => {
    //     console.log({file});
        
    //     // setVideoFile(null);
    //     // if (file) {
    //     //   const { name: fileName, size, type } = file;
    //     //   const sizeInBytes = size;
    //     //   // Convert bytes to kilobytes (KB)
    //     //   const sizeInKB = sizeInBytes / 1024;
    //     //   // Convert bytes to megabytes (MB)
    //     //   const sizeInMB = sizeInKB / 1024;
    //     //   let fileSize = "";
    //     //   // if (sizeInMB > 15) {
    //     //   //   dispatch(setShowingUploadingProgress(false));
    //     //   //   dispatch(setIsContentUploadedError(true));
    //     //   //   dispatch(setErrorMessage(`Error. Maximum file size is 15 MB`));
    //     //   //   return;
    //     //   // }
    //     //   if (sizeInMB > 1) {
    //     //     fileSize = sizeInMB.toFixed(2) + " MB";
    //     //   } else {
    //     //     fileSize = sizeInKB.toFixed(2) + " KB";
    //     //   }
    
    //     //   const contentPayload: any = {
    //     //     clapupUserId: sessionStorage.getItem('cuid'),
    //     //     roleId: sessionStorage.getItem('roleId'),
    //     //     workspaceId: sessionStorage.getItem('wuid'),
    //     //     contentType: type?.includes("image") ? "GalleryImages" : "GalleryVideos",
    //     //     contentText: "",
    //     //     isFolder: "0",
    //     //     folderPath: path_array[0]?.replaceAll("%20", ""),
    //     //     contentFileName: fileName.replaceAll(" ", ""),
    //     //     fileSize,
    //     //     isAvailableinGallery: true,
    //     //     subCategory: type?.includes("image") ? "GalleryImages" : "GalleryVideos",
    //     //     isCreationCompleted: true
    //     //   };
    //     //   console.log({ contentPayload, file });
    //     //   try { 
    //     //     dispatch(createFolderContent(contentPayload, file));
    
    //     //     // const s3_bucket = {
    //     //     //   endpoint: `https://${aws_account_id}.r2.cloudflarestorage.com`,
    //     //     //   accessKeyId: `${aws_access_key}`,
    //     //     //   secretAccessKey: `${aws_secret_key}`,
    //     //     //   signatureVersion: 'v4',
    //     //     // };
            
    //     //     // const s3 = new S3(s3_bucket);
    //     //     // await navigator.clipboard.writeText(JSON.stringify(s3_bucket));
    //     //     // const aws_link = await s3.getSignedUrlPromise('putObject', s3_bucket);
    //     //     // if (aws_link) {
    //     //     //   dispatch(createFolderContent(contentPayload, file, aws_link));
    //     //     //   // setTimeout(() => {
    //     //     //     //   setTimeout(() => {
    //     //     //       //     dispatch(setShowingUploadingProgress(false));
    //     //     //       //   }, 2000);
    //     //     //       //   dispatch(setUploadProgressCount(0));
    //     //     //   // }, 3000);
    //     //     //   // dispatch(setUploadProgressCount(100));
    //     //     //   // dispatch(setShowingUploadingProgress(false));
    //     //     // } else {
    //     //     //   setTimeout(() => {
    //     //     //     dispatch(setShowingUploadingProgress(false));
    //     //     //     dispatch(setIsContentUploadedError(true));
    //     //     //     dispatch(setErrorMessage(`Your content is not uploaded`));
    //     //     //   }, 3000);
    //     //     // }
    
    //     //   } catch (err: any) {
    //     //     console.log({ message: err?.message });
            
    //     //     setTimeout(() => {
    //     //       dispatch(setShowingUploadingProgress(false));
    //     //       dispatch(setIsContentUploadedError(true));
    //     //       dispatch(setErrorMessage(err?.message || `Your content is not uploaded`));
    //     //     }, 3000);
            
    //     //   }
    //     // } else {
    //     //   dispatch(setShowingUploadingProgress(false));
    //     //   dispatch(setIsContentUploadedError(true));
    //     //   dispatch(setErrorMessage(message || `Your content is not uploaded`));
    //     // }
    // };

    return (
        <React.Fragment>
            <p className='header-title'>Add Sample Variables</p>
            
            {
                headerVariables?.map((item: any, index: number) => (
                    <div className='variable-input-group' key={index}>
                        <label>
                            <span>
                                {
                                    templateHeaderType?.toLowerCase() === "text"
                                    ? `Header ${item?.variableName}`
                                    : `Header ${capitalizeSentence(templateHeaderType)} URL (Max Size ${templateHeaderType?.toLowerCase() === "image" ? "5 Mb" : templateHeaderType?.toLowerCase() === "video" ? "16 Mb" : "100 Mb"})`
                                }
                            </span>
                        </label>
                        <Input
                            className='variable-input text-start'
                            placeholder={`Enter ${
                                    templateHeaderType?.toLowerCase() === "text"
                                    ? `Sample Value for ${item?.variableName}`
                                    : `the URL for Header ${capitalizeSentence(templateHeaderType)}`
                                }
                            `}
                            value={item?.variableValue || ""}
                            onChange={(e: any) => {
                                const { value } = e.target;
                                if (templateHeaderType?.toLowerCase() === "text") { 
                                    const inputValue = value?.replace(/\s+/g, ' ');
                                    handleChangeHeaderVariable(inputValue, index);
                                    return;
                                }
                                const inputValue = value?.replace(/[^a-zA-Z0-9\s_/:.-]/g, '')?.replace(/\s+/g, '');
                                handleChangeHeaderVariable(inputValue, index);
                            }}
                        />
                    </div>
                ))
            }

            {
                bodyVariables?.map((item: any, index: number) => (
                    <div className='variable-input-group' key={index}>
                        <label>
                            {`Body ${item?.variableName} (Max 60 Characters)`}
                        </label>
                        <Input
                            className='variable-input'
                            placeholder={`Enter Sample Value for ${item?.variableName}`}
                            value={item?.variableValue || ""}
                            onChange={(e: any) => {
                                const { value } = e.target;
                                const inputValue = value?.replace(/\s+/g, ' ');
                                handleChangeBodyVariable(inputValue, index);
                            }}
                        />
                    </div>
                ))
            }

            {
                callToActionExampleUrlVariables?.map((item: any, index: number) => (
                    <div className='variable-input-group' key={index}>
                        <label>
                            <span>
                                Call To Action {item?.variableName}
                                <span>
                                    {
                                        ` (Max 2000 Characters)`
                                    }
                                </span>
                            </span>
                        </label>
                        <Input
                            className='variable-input'
                            placeholder={`Enter Sample URL`}
                            value={item?.variableValue || ""}
                            onChange={(e: any) => {
                                const { value } = e.target;
                                const inputValue = value?.replace(/[^a-zA-Z0-9\s_/:.-]/g, '')?.replace(/\s+/g, '');
                                handleChangeCallToActionDynamicWebsiteVariable(inputValue, index);
                            }}
                        />
                    </div>
                ))
            }

            {
                !headerVariables?.length && !bodyVariables?.length && !callToActionExampleUrlVariables?.length
                ?   (
                        <div className='d-flex justify-content-center align-items-center' style={{height: 200}} >
                            <h5>No Variables Found</h5>
                        </div>
                ) 
                : null
            }
            
            {/* <div className='variable-input-group'>
                <label>Body - Name</label>
                <Input
                    className='variable-input'
                    placeholder='Body Name'
                    defaultValue={'IN - India'}
                    onChange={(e: any) => {
                        console.log(e.target.value);
                        // handleChange(e.target.value, setCurCampaignText)
                    }}
                />
            </div> */}

            {/* <div className='variable-input-group'>
                <label>CTA 1 Url</label>
                <Input
                    className='variable-input'
                    placeholder='URL'
                    defaultValue={"Kerala"}
                    onChange={(e: any) => {
                        console.log(e.target.value);
                        // handleChange(e.target.value, setCurCampaignText)
                    }}
                />
            </div> */}
            
            {/* <div className='variable-input-group'>
                <label>CTA 2 Url</label>
                <Input
                    className='variable-input'
                    placeholder='URL'
                    defaultValue={"670650"}
                    onChange={(e: any) => {
                        console.log(e.target.value);
                        // handleChange(e.target.value, setCurCampaignText)
                    }}
                />
            </div> */}

            <div className='form-button-container'>
                <button
                    type='button'
                    className='cancel-button'
                    onClick={onCancel}
                >
                    Cancel
                </button>
                
                <button
                    // disabled={}
                    className='create-button'
                    onClick={() => (headerVariables?.length || bodyVariables?.length || callToActionExampleUrlVariables?.length) ? handleSaveTemplateData() : onCancel()}
                    type='button'
                >
                    Finish
                </button>
            </div>
        </React.Fragment>
    )
};

export default TemplateVariablesForm;
