import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import collapse from '../../assets/icons/collapse-btn.svg';
import SignUpModal from './SignUpModal';
import './SignUpModal.scss';
import { createNewWorkspace } from '../../pages/workspace-management/workSpaceReducer';
interface FormProps {
    open?: boolean;
    parentCallback?: any;
};

const SignUpModalForm: React.FC<FormProps> = ({ open, parentCallback }: any) => {
  const dispatch = useDispatch();
  const [current, setCurrent] = React.useState<number>(0);
  const [curCollapse, setCurrentCollapse] = React.useState<number>(1);
  const [host, setHost] = React.useState<string>("");
  const [workspace, setWorkspace] = React.useState<string>("");

  const handleNext = () => {
    setCurrent((current + 1));
  };

  const handleFinish = () => {
    // console.log({ host, workspace });
    const user_details = sessionStorage.getItem("accessed_user_details");
    const user = JSON.parse(user_details || "{}");
    const { email } = user;
    const dataObj = {
      workSpaceName: workspace,
      agencyId: "0",
      workspaceType: "Basic",
      businessName: workspace,
      // superAdminEmail: "mmhk.itsforyou@gmail.com", // "Email Id of the signup user",
      superAdminEmail: email, // "Email Id of the signup user",
      createdBy: "",
      subdomainName: host, // "The name entered in the hostname column in the modal popup"
    };
    console.log("dataObj ====> ", dataObj);
    // return;
    sessionStorage.removeItem("client_details");
    dispatch(createNewWorkspace(dataObj));
  };
      
  return (
    <div>
      <SignUpModal open={open} parentCallback={parentCallback}>
        {
          current == 0 && <div className='modal-container p-2'>
            <div className='header'>
              <p className='title'>Subdomain for Biopage</p>
            </div>
            <div className='sub-title'>
              <p className='number'>1</p>
              <span className=''>Hostname</span>
            </div>
            <p className='description'>Your biopage will be available at our subdomain hostname.clapup.co. Use a name that represent your brand. Word limit is 20 and no special character or spaces is allowed.</p>
            <div className='input-container'>
              <input 
                type='text' placeholder='eg: your company (microsoft)' 
                autoFocus
                onChange={(e) => setHost(e.target.value)} 
              />
            </div>
            <button style={{cursor: `${!host ? "not-allowed" : "pointer"}`}} disabled={!host} className='next' onClick={() => handleNext()}>Next</button>
          </div>
        }
        {current == 1 && <div className='modal-container p-2'>
            <div className='header'>
              <p className='title'>Workspace</p>
            </div>
            <div className='sub-title'>
              <p className='number'>2</p>
              <span className=''>Workspace Name</span>
            </div>
            <p className='description'>Provide the name for your default workspace. You can add the logo later. This information can be edited later. No special character or spaces is allowed. </p>
            <div className='input-container'>
              <input 
                type='text' placeholder='eg: Microsoft' 
                autoFocus
                onChange={(e) => setWorkspace(e.target.value)} 
              />
            </div>
            <button style={{cursor: `${!workspace ? "not-allowed" : "pointer"}`}} disabled={!workspace} className='next' onClick={() => handleNext()}>Next</button>
          </div>}
        {current == 2 && <div className='modal-container p-2'>
            <div className='header'>
              <p className='title'>Getting started checklist</p>
            </div>
            <div className='body border-bottom'>
              <div onClick={() => setCurrentCollapse(1)} className='sub-title justify-content-between align-items-center'
                style={{cursor: 'pointer'}}
              >
                <div className='d-flex align-items-center' style={{gap: 10}}>
                  <p className='number'>1</p>
                  <span className=''>Add Channels</span>
                </div>
                {
                  curCollapse === 2
                  ? <img className='d-block' src={collapse} alt="collapse" />
                  : null
                }
              </div>
              {
                curCollapse === 1
                ? <p className='description'>{"For getting data into our inbox, you need to integrate relevant account, we call them as channels. Go to Settings -> Workspace Settings -> Integrations and start connecting."}</p>
                : null
              }
            </div>
            <div className='body border-bottom mb-1'>
              <div onClick={() => setCurrentCollapse(2)} className='sub-title justify-content-between align-items-center'
                style={{cursor: 'pointer'}}
              >
                <div className='d-flex align-items-center' style={{gap: 10}}>
                  <p className='number'>2</p>
                  <span className=''>Invite Users</span>
                </div>
                {
                  curCollapse === 1 
                  ? <img className='d-block' src={collapse} alt="collapse" />
                  : null
                }
              </div>
              {
                curCollapse === 2 
                ? <p className='description'>{"You can invite and collaborate with your team members. Go to Workspace Settings -> User Management Tab ->  and then click on Invite User and start adding your Team members."}</p>
                : null
              }
            </div>
            <button className='finish' onClick={() => handleFinish()}>Finish</button>
          </div>}
      </SignUpModal>
    </div>
  )
}

export default SignUpModalForm;