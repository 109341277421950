import React from 'react';
// import save_icon from '../../../../../../assets/icons/settings/save-icon.svg';
// import nested_folder_icon from '../../../../../../assets/icons/settings/nested-folder.svg';
// import arrow_down_icon from '../../../../../../assets/icons/settings/arrow-down.svg';
// import upload_icon from '../../../../../../assets/icons/settings/upload-icon.svg';
import edit_icon from '../../../../../../assets/icons/settings/edit-icon.svg';
import delete_icon from '../../../../../../assets/icons/settings/biopage/delete-icon.svg';
import './../../CustomLinks.scss';
import { convertImageToBase64 } from '../../../../../../utils/convertingFunction';
import { useDispatch } from 'react-redux';
import { setErrorMessage } from '../../../../../../dashboard/reducer';
import { api_url } from '../../../../../../utils/server';
import axios from 'axios';
import { uploadIconFileData, updateIconFileData } from '../../../../saga';
import { modifyString } from '../../../../../../utils/mediaService';

type LinkProps = {
    link_item?: any;
    id?: number;
    children?: [];
    onCancel?: () => void;
    onParentCallback?: (data: any) => void;
};

const NestedChildLinkForm: React.FC<LinkProps> = ({ link_item, id, children, onCancel, onParentCallback }) => {
    const dispatch = useDispatch();
    const [curChild, setCurChild] = React.useState<any>(null);
    const [curDisplayText, setCurDisplayText] = React.useState("");
    const [curLabel, setCurLabel] = React.useState("");
    const [curYouTubeLink, setCurYouTubeLink] = React.useState("");
    const [curChildren, setCurChildren] = React.useState<any>([]);
    const [image, setImage] = React.useState<any>(null);
    
    const handleSubmit = async() => {
        // const { label, url } = form;
        // // console.log({label, url, image});
        // const data = {
        //     id,
        //     children: curChildren,
            
        // }
        const data = curChildren?.reduce((prev: any, curChild: any) => { 
            if ((curChild?.isDeletedStatus && curChild.mode === "insert") || (!curChild?.mode)) {
                return prev;
            }
            const file = curChild?.file;
            delete curChild?.file;
            delete curChild.idx;
            delete curChild.isDeletedStatus;
            delete curChild.isActive;
            delete curChild.linkDisplayText;
            delete curChild.linkLabel;
            
            if (curChild?.mode === 'delete') {
                const data = {
                    clapupUserId: sessionStorage.getItem('cuid'),
                    workspaceId: sessionStorage.getItem('wuid'),
                    roleId: sessionStorage.getItem('roleId'),
                    id: curChild?.id,
                    displayText: '',
                    label: '',
                    iconUrl: '',
                    iconName: '',
                    linkUrl: '',
                    parentId: curChild?.parentId,
                    mode: "delete",
                    linkType: 101,
                    videoLink: '',
                    mediaType: '',
                    contentIds: '',
                    linkCategory: "customlinks",
                    contentId: curChild?.contentId || "",
                    uniqueNo: curChild?.uniqueNo || null,
                }
                prev.push({ data });
                return prev;
            }


            curChild.clapupUserId = sessionStorage.getItem('cuid');
            curChild.workspaceId = sessionStorage.getItem('wuid');
            curChild.roleId = sessionStorage.getItem('roleId');
            prev.push({data: curChild, file});
            return prev;
        }, []);

        console.log({ children_data: data });
        
        let successCount = 0;
        const promises = data?.map(async (item: any) => {
            // console.log({item});
            
            let payload: any = {
                ...item?.data
            };
            const common_payload: any = {
                clapupId: sessionStorage.getItem('cuid'),
                workspaceId: sessionStorage.getItem('wuid'),
                roleId: sessionStorage.getItem('roleId'),
            };
            let apiUrl = api_url + "/api/v1.2/Clapup/UpdateWorkspaceBioPageLinkSettingsDetails";
            if (item?.data?.mode === "delete") { 
                apiUrl = api_url + "/api/v1.2/gallery/DeleteContents";
                // console.log({contentId});
                
                payload = {
                    contentId: item?.data?.contentId,
                    isFolderDelete: false,
                    ...common_payload
                }
            }
            
            const axiosConfig = {
                method: 'post',
                url: apiUrl,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                },
            };
            console.log({ payload: payload});
            
            try {
                const { success, iconUrl, data: content_data, message }: any
                        = payload?.mode === "update"
                        ? await updateIconFileData({ contentFile: item?.file, content: {contentId: payload?.contentId, uniqueNo: payload?.uniqueNo},  contentType: "NestedChildLink" })
                        : payload?.mode === "insert" 
                        ? await uploadIconFileData({ contentFile: item?.file, contentType: "NestedChildLink" })
                        : { success: true, iconUrl: null, message: null };
                
                console.log({success, content_data});
                if (!success) {
                    console.log('message', message);
                    dispatch(setErrorMessage(message || "Something went wrong"));
                    return;
                }
                
                if (iconUrl) {
                    payload.iconUrl = iconUrl;
                    delete payload.contentId;
                    delete payload.uniqueNo;
                }
                const { data, status } = await axios({ ...axiosConfig, data: payload });
                const result = { data, status };
                // createdContentId && (payload.contentIds = createdContentId);
                console.log({ result, content_data });
                
                successCount++;
            } catch (error) {
                console.error('API request error:', error);
            }
        });

        await Promise.all(promises);

        console.log(`All items processed. Successful API calls: ${successCount}/${data.length}`);

        
        onParentCallback && (successCount === data?.length) && onParentCallback("NestedLinks");
    };
    
    const handleAdd = () => {
        const data = {
            idx: curChildren?.length ? curChildren?.length : 0,
            id: 0,
            displayText: curDisplayText,
            label: curLabel,
            // iconUrl: image?.base64Url || logo, // we have to work in future
            iconUrl: image?.file ? URL.createObjectURL(image?.file) : "",
            file: image?.file, 
            iconName: image?.name,
            // youtubeLink: curYouTubeLink,
            linkUrl: curYouTubeLink,
            parentId: link_item?.id,
            linkType: 101,
            videoLink: '',
            mediaType: '',
            contentIds: '',
            mode: "insert",
            linkCategory: "customlinks",
            // logo: image?.base64Url,
        }

        // console.log({curChildren});
        
        setCurChildren([...curChildren, data]);
        setCurDisplayText("");
        setCurLabel("");
        setCurYouTubeLink("");
        setImage(null);
    };
    
    const handleUpdate = () => {
        const data = {
            ...curChild,
            displayText: curDisplayText,
            label: curLabel,
            // iconUrl: image?.base64Url || logo, // we have to work in future
            iconUrl: image?.file ? URL.createObjectURL(image?.file) : curChild?.iconUrl || "",
            file: image?.file,
            iconName: image?.name,
            // youtubeLink: curYouTubeLink,
            linkUrl: curYouTubeLink,
            parentId: link_item?.id,
            linkType: 101,
            videoLink: '',
            mediaType: '',
            contentIds: '',
            mode: curChild?.id ? "update" : "insert",
            linkCategory: "customlinks",
        }

        console.log({data});
        
        // return;
        const filteredChildren = curChildren.filter((child: any) => child.idx !== curChild?.idx);
            
        setCurChildren([...filteredChildren, data].sort((a: any, b: any) => a.idx - b.idx));        
        setCurDisplayText("");
        setCurLabel("");
        setCurYouTubeLink("");
        setImage(null);
        setCurChild(null);
    };

    const handleImageChange = (e: any) => {
        // file local path
        // const image_path
        const file: any = e?.target?.files[0];
        // const path = e.target.value;
        const { name } = file || {};
        // console.log({path});
        // setImageFile(file);

        setImage({file, name: name?.replaceAll(" ", "")});

        // ======== 1st way to convert image to base64 ========
        // convertImageToBase64(file).then((base64Url: any) => {
        //     // console.log({base64Url});
        //     // console.log({base64Url, name});
        //     setImage({base64Url, name});
            
        // }).catch((error: any) => {
        //     console.log({ error });
        //     dispatch(setErrorMessage("Something went wrong while converting image to base64"))
        // });
        
    }
    
    const handleDeleteChild = (idx: any) => {
        const children_data = curChildren?.reduce((result: any, child: any) => {
            if (child.idx === idx) {
                child.isDeletedStatus = true;
                child?.id && (child.mode = 'delete');
            }
            result.push(child);
            return result;
        }, []);
        // const filteredChildren = children_data?.filter((child: any) => child.idx !== idx && child?.id !== 0);
        console.log({children_data, curChildren});
        setCurChildren(children_data);
    };
    
    const handleChange = (e: any, setState: any) => {
        setState(e.target.value);
    };

    React.useEffect(() => {
        console.log({children})
        const data = children?.reduce((res: any, item: any) => {
            // make index
            item.idx = res?.length ? res?.length : 0;
            res.push(item);
            return res;
        }, []);
        setCurChildren(data?.length ? data : []);
    }, [""]);
    // console.log({curChildren});
    

    return (
        <div>
            <div className='form-link-container form-component' 
                style={{
                    // height: '60vh',
                    height: curChildren?.length > 3 ? '60vh': '100%',
                }}
            >
                <div className='scroll-hover'>
                    <form
                        id='link-form'
                        className='form'
                        style={{
                            padding: curChildren?.length > 2 ? '1.5% 3% 10% 3%': '3.5% 3% 6% 3%',
                        }}
                    >
                        <div
                            className='input-group'
                        >
                            <input
                                type="text" name={"displayText"}
                                autoFocus={true}
                                autoComplete='off'
                                autoSave='off'
                                value={curDisplayText || ""}
                                onChange={(e: any) => handleChange(e, setCurDisplayText)}
                                placeholder={"Enter the Display Text"}
                                style={{margin: '0 0 3.5% 0'}}
                            />
                        </div>

                        <div
                            className='input-group'
                        >
                            <input
                                type="text" name={"label"}
                                autoComplete='off'
                                autoSave='off'
                                onChange={(e: any) => handleChange(e, setCurLabel)}
                                value={curLabel || ""}
                                placeholder={"Enter the label"}
                                style={{margin: '0 0 3.5% 0'}}
                            />
                        </div>
                        
                        <div
                            className='input-group'
                        >
                            <input
                                type="text" name={"logo"}
                                autoComplete='off'
                                autoSave='off'
                                readOnly={true}
                                placeholder={"Upload the link icon"}
                                style={{margin: '0 0 3.5% 0'}}
                                value={image?.name || ''}
                            />
                            <input
                                // disabled={image ? false : true}
                                id="image"
                                type="file"
                                accept="image/svg+xml, image/png, image/jpeg, image/jpg"
                                onChange={(e: any) => {
                                    handleImageChange(e); 
                                }}
                                className="form-input-field d-none w-100 my-1" placeholder="Biopage Image"
                            />
                            <label className='image-label' htmlFor="image">Upload</label>
                        </div>
                        <div
                            className='input-group'
                        >
                            <input
                                type="text" name={"logo"}
                                autoComplete='off'
                                autoSave='off'
                                placeholder={"Add YouTube Link"}
                                onChange={(e: any) => handleChange(e, setCurYouTubeLink)}
                                value={curYouTubeLink || ""}
                            />
                            
                            <span
                                className='input-label'
                                style={{
                                    cursor: curDisplayText && curLabel && image?.name && curYouTubeLink ? 'pointer' : 'not-allowed',
                                }}
                                onClick={() => {
                                    if(curDisplayText && curLabel && image?.name && curYouTubeLink) {
                                        curChild !== null ? handleUpdate() : handleAdd();
                                    }
                                }}
                            >
                                {
                                    curChild !== null ? 'Update' : 'Add'
                                }
                            </span>
                        </div>
                    </form>
                    
                    {
                        curChildren?.length
                        ?   <div className='child-list-container'>
                                {
                                    curChildren?.map((child: any, index: number) => (
                                        child?.isDeletedStatus == true
                                        ? null
                                        : <div key={index} className='child-item'>
                                            <div className='input-container'>
                                                <div className='logo-text-label-container'>
                                                    <div className='logo-text'>
                                                        <img
                                                            src={child?.iconUrl?.replaceAll("@", "/")}
                                                            alt="item-icon"
                                                            className={``}
                                                        />
                                                        <p className='input-value'>{modifyString((child?.displayText || child?.linkDisplayText), 23)}</p>
                                                        {/* <p className='input-value'>{(child?.displayText || child?.linkDisplayText)?.length < 15 ? (child?.displayText || child?.linkDisplayText) : (child?.displayText || child?.linkDisplayText)?.substring(0, 20) + "..."}</p> */}
                                                    </div>
                                                    <p>{modifyString((child?.label || child?.linkLabel), 5)}</p>
                                                    {/* <p>{(child?.label || child?.linkLabel)?.length < 10 ? (child?.label || child?.linkLabel) : (child?.label || child?.linkLabel)?.substring(0, 10) + "..."}</p> */}
                                                </div>
                                                <div className='edit-delete-container'>
                                                    <img
                                                        src={edit_icon}
                                                        alt="edit-icon"
                                                        className={``}
                                                        onClick={() => {
                                                            // console.log({child});
                                                            
                                                            setCurChild(child);
                                                            setCurDisplayText(child?.linkDisplayText || child.displayText);
                                                            setCurLabel(child?.linkLabel || child?.label);
                                                            setCurYouTubeLink(child?.linkUrl);
                                                            if (child?.file) {
                                                                // console.log({ file: child.file });
                                                                const { name }: any = child.file || {};

                                                                setImage({file: child.file, name: name?.replaceAll(" ", "")});
                                                            } else {
                                                                setImage({ base64Url: child?.iconUrl, name: child.iconName });
                                                            }
                                                        }}
                                                    />
                                                    <img
                                                        src={delete_icon}
                                                        alt="delete-icon"
                                                        className={``}
                                                        onClick={() => {
                                                            handleDeleteChild(child.idx);
                                                        }}

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        : null
                    }
                </div>
            </div>

            <div className='form-button-container'
                style={{
                    paddingRight: curChildren?.length > 2 ? '1%': '0',
                }}
            >
                <button
                    type='button'
                    className='cancel-button'
                    onClick={onCancel}
                >Cancel</button>
                <button
                    disabled={(curChildren?.length) ? false : true}
                    className='create-button'
                    onClick={() => handleSubmit()}
                    type='button'
                >Save</button>
            </div>
        </div>
    )
};

export default NestedChildLinkForm;
