import React, {useState, useEffect, useRef } from 'react';
import { Carousel, Spinner } from 'react-bootstrap';
import "./ShowFiles.scss";
import { getImage } from '../../../../../../dashboard/image_service';
const ShowFilePreview = ({post_content_index, contents, handleParentCallBack}: any) => {
    const slider: any = useRef<any>(null);
    const videoRef = useRef<any>(null);
    const handleVideoLoaded = () => {
        // modifyString
        // for seeing the video duration change
        // console.log({video_default_duration: videoRef?.current?.duration});
    };
    return (
        <React.Fragment>
            <div className='preview-files-carousel-container'>
                <img className='preview-cross' onClick={() => handleParentCallBack()} src={getImage("preview_cross_button")} alt="cross"/>
                
                {
                    contents?.length > 1
                    ?
                        <button
                            className='bg-transparent'
                            type='button'
                            // disabled={!contents?.length}
                            onClick={() => { 
                                // playVideo();
                                // dispatch(setShowingSaveButton(true));
                                console.log("click prev");
                                slider.current.prev();
                            }}
                        >
                            <img src={getImage("preview_left_button")} alt="prev" />
                        </button>
                    : null
                }

                <Carousel
                    className='w-100'
                    ref={slider}
                    interval={null}
                    indicators={false}
                    nextIcon={false}
                    prevIcon={false}
                    // activeIndex={post_content_index}
                    defaultActiveIndex={post_content_index}
                    onSlide={(e: any) => {
                        console.log({ index: e });
                        // dispatch(setDefaultContentIdx(e));
                        
                    }}
                >
                  {
                    contents?.length
                    ? contents?.map((item: any, index: number) => {
                      return (
                        <Carousel.Item key={index} className='w-100'>
                              <div className='file-container d-flex justify-content-center align-items-center'>
                                  {
                                    item?.attachmentType?.toUpperCase() === "VIDEO"
                                    ?
                                    <video
                                        ref={videoRef}
                                        className=""
                                        // style={{width: "auto"}}
                                        // src={item?.videoUrl}
                                        // src={currentContentUrl?.replaceAll("@", "/") || "https://dc474190-f363-4e8e-9949-8cdac65b4d44.7e93201b14c845ca2c0a69a67557e543.r2.cloudflarestorage.com/kuber-test/MehediClapup3_12.jpg?X-Amz-Expires=35997&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=bd755bf91cf43678d86ec58a1059cdb9%2F20231129%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20231129T094129Z&X-Amz-SignedHeaders=content-type%3Bhost&X-Amz-Signature=077bd19e3eb91675a8d639ee0a0a39beedd3e288611f0522109ecfa6ca7f1a46"}
                                        src={item?.url?.replaceAll("@", "/")} // one by one
                                        // src={item?.videoUrl || 'https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm'}
                                        onLoadedMetadata={handleVideoLoaded}
                                        controls
                                    />
                                    :
                                    <img src={item?.url} alt="item-image" />
                                  }
                            </div>
                        </Carousel.Item>
                      );
                    })
                    : null
                  }
                </Carousel>

                {
                    contents?.length > 1
                    ?
                        <button
                            className='bg-transparent'
                            type='button'
                            // disabled={!contents?.length}
                            onClick={() => {
                                // playVideo();
                                // dispatch(setShowingSaveButton(true));
                                console.log("click next");
                                slider?.current?.next();
                            }}
                        >
                            <img src={getImage("preview_right_button")} alt="next" />
                        </button>
                    : null
                }
            </div>
        </React.Fragment>
    );
}

export default ShowFilePreview;