import React, { useEffect } from 'react';
import {Table, Switch, Divider } from 'antd';
import './Notifications.scss';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getNotificationSettings, updateNotification } from '../../profileManageReducer';
import { useNavigate } from 'react-router';
import { setSuccessMessage } from '../../../../dashboard/reducer';
const Notifications: React.FC = () => {
    const [loading, setLoading] = React.useState(true);
    const {notifications} = useSelector((state: any) => state?.profileManageSettingState);
    const dispatch = useDispatch();
    const [table_data, setTableData]: any = React.useState([]);
    const [table_columns, setTableColumns]: any = React.useState([]);
    useEffect(() => {
        const notification_columns = [
            {
                title: <p>Notification Items</p>,
                dataIndex: 'title',
                key: 'title',
                width: 240,
                render: (text: any, record: any) => {
                    return <p>{text}</p>
                },
            },
            {
                title: <p>In App</p>,
                dataIndex: 'IsSystemNotificationRequired',
                key: 'IsSystemNotificationRequired',
                render: (text: any, record: any) => {
                    // console.log({text, record});
                    
                    return <div className="d-flex justify-content-center align-items-center">
                        {/* <div className='sm-device'> */}
                            <Switch
                                defaultChecked={text === 'false' ? false : true}
                                disabled={true}
                                size='small'
                                onChange={(value: any) => {
                                    // console.log({id: record?.Id, needSystemNotification: value, needEmailNotification: record?.IsEmailNotificationRequired === 'false' ? false : true});
                                    updateNotificationPreference({
                                        id: record?.Id,
                                        needSystemNotification: value,
                                        needEmailNotification: record?.IsEmailNotificationRequired === 'false' ? false : true
                                    });
                                }}
                            />
                        {/* </div> */}

                        {/* <div className='lg-device'>
                            <Switch
                                defaultChecked={text === 'false' ? false : true}
                                disabled={true}
                                onChange={(value: any) => {
                                    // console.log({id: record?.Id, needSystemNotification: value, needEmailNotification: record?.IsEmailNotificationRequired === 'false' ? false : true});
                                    updateNotificationPreference({
                                        id: record?.Id,
                                        needSystemNotification: value,
                                        needEmailNotification: record?.IsEmailNotificationRequired === 'false' ? false : true
                                    });
                                }}
                            />
                        </div> */}
                    </div>
                },
                align: 'center',
            },
            {
                title: <p>Email</p>,
                dataIndex: 'IsEmailNotificationRequired',
                key: 'IsEmailNotificationRequired',
                render: (text: any, record: any) => (
                    <div className="d-flex justify-content-center align-items-center">
                        {/* <div className='sm-device'> */}
                            <Switch defaultChecked={text === 'false' ? false : true}
                                size='small'
                                onChange={(value) => {
                                    console.log({id: record?.Id, needEmailNotification: value, needSystemNotification: record?.IsSystemNotificationRequired});
                                    updateNotificationPreference({id: record?.Id, needEmailNotification: value, needSystemNotification: record?.IsSystemNotificationRequired === 'false' ? false : true });
                                }}
                            />
                        {/* </div> */}
                        
                        {/* <div className='lg-device'>
                            <Switch defaultChecked={text === 'false' ? false : true}
                                onChange={(value) => {
                                    console.log({id: record?.Id, needEmailNotification: value, needSystemNotification: record?.IsSystemNotificationRequired});
                                    updateNotificationPreference({id: record?.Id, needEmailNotification: value, needSystemNotification: record?.IsSystemNotificationRequired === 'false' ? false : true });
                                }}
                            />
                        </div> */}
                    </div>
                ),
                align: 'center',
            },
        ];
        setTableColumns(notification_columns);
        if(!notifications?.Table?.length){
            console.log("Notifications ==== ", notifications?.Table, ", cuid => ",sessionStorage.getItem('cuid'));
            dispatch(getNotificationSettings(sessionStorage.getItem('cuid')));
            setTimeout(() => setLoading(false), 5000);
        }else{
            // setTableData(notification_data);
            setTableData(notifications?.Table);
    
            setTableColumns(notification_columns);
            setLoading(false);
        }
        
    }, [notifications?.Table?.length]);
    // }, [notifications?.Table?.length]);

    const updateNotificationPreference = (dataObject: any) => {
        console.log("updateNotificationPreference ==== ", dataObject);
        dispatch(updateNotification({
            ...dataObject,
            // clapupUserId: sessionStorage.getItem('cuid'),
            clapupId: sessionStorage.getItem('cuid'),
            workspaceId: sessionStorage.getItem('wuid'),
        }));
        setTimeout(() => dispatch(setSuccessMessage("Settings saved.")), 1000);
    }

    const {userPermissions} = useSelector((state: any) => state?.login);
    const history = useNavigate();
    
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "notifications") : null;
        if(permission){
            permission?.value === 0 && history('/not-found');
        }
    }, [userPermissions?.length]);

    return (
        <React.Fragment>
            <div className='mobile-sub-menu' style={{padding: "0 2%"}}>
                <Table
                    loading={loading}
                    // title={() => <h4 className='header-title border-bottom'>Notification Preferences</h4>}
                    className="table-style"
                    columns={table_columns}
                    dataSource={table_data}
                    pagination={false}
                    rowKey={(record: any) => record?.Id}
                    scroll={{ x: 450, }}
                />
            </div>
            
            <div className='lg-device-menu' style={{padding: "0 5% 0 2%"}}>
                <Table
                    loading={loading}
                    // title={() => <h4 className='header-title border-bottom'>Notification Preferences</h4>}
                    className="table-style"
                    columns={table_columns}
                    dataSource={table_data}
                    pagination={false}
                    rowKey={(record: any) => record?.Id}
                    scroll={{ x: 450, y: "55vh" }}
                    // scroll={{ x: 450, y: 350 }}
                />
            </div>
            
        </React.Fragment>
    );
};

export default Notifications;