import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { toggleEditingMasterNonGeneralPreview, getFirstTimeTemplateList, toggleTemplateEdition, setCallToActionTemplate, setQuickReplyButtonsTemplate, toggleIsAlreadySaved, toggleEditableTemplate } from '../../../reducer';
import { getImage } from '../../../../../dashboard/image_service';
// import Button from '../../../../button/button-container';
import ActionButtons from './ActionButtons';

const EditingTabs: React.FC<any> = () => {
    const { editing_for_master_non_general_preview, toggle_template_edition }: any = useSelector((state: RootState) => state?.promotionsState);
    const dispatch = useDispatch();
    // console.log({ toggle_template_edition });
    return (
        <React.Fragment>
            <div className='lg-device lg-edition-tabs'>
                <div className='back-path'>
                    <img
                        src={getImage("back-path-line")}
                        onClick={() => {
                            dispatch(getFirstTimeTemplateList());
                            dispatch(toggleTemplateEdition(null));
                            dispatch(setCallToActionTemplate(null));
                            dispatch(setQuickReplyButtonsTemplate(null));
                            dispatch(toggleIsAlreadySaved(null));
                            dispatch(toggleEditableTemplate(false));
                        }}
                        alt='back-path'
                    />
                </div>
                <div className='save-edition-container'>
                    <p className='user-name'>{toggle_template_edition?.templateDisplayName || "Sreekanth PM"}</p>
                    <ActionButtons/>
                </div>
            </div>
            
            <div className='sm-edition-tabs'>
                <div className='back-path'>
                    <img
                        src={getImage("back-path-line")}
                        onClick={() => {
                            dispatch(getFirstTimeTemplateList());
                            dispatch(toggleTemplateEdition(null))
                        }}
                        alt='back-path'
                    />
                </div>
                <p onClick={() => dispatch(toggleEditingMasterNonGeneralPreview(true))}
                    className={`tab${editing_for_master_non_general_preview ? '-active' : ''}`}
                >
                    Master Edition
                </p>
                <p onClick={() => dispatch(toggleEditingMasterNonGeneralPreview(false))}
                    className={`tab${!editing_for_master_non_general_preview ? '-active' : ''}`}
                >
                    General Preview
                </p>
            </div>
        </React.Fragment>
    )
};

export default EditingTabs;
