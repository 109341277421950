import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { toggleTemplateEdition } from '../../../../../reducer';
import { setErrorMessage } from '../../../../../../../dashboard/reducer';
import { RootState } from '../../../../../../../store';

const FooterForm: React.FC<any> = () => {
    const dispatch = useDispatch();
    const { toggle_template_edition }: any = useSelector((state: RootState) => state?.promotionsState);
    const [value, setValue] = useState<any>('');

    useEffect(() => {
        if ((!value && toggle_template_edition?.templateFooter)) {
            setValue(
                toggle_template_edition?.isPromotionOptOut
                ? "Not Interested? Tap Stop Promotions"
                : (toggle_template_edition?.templateFooter || "")
            );
        }
    }, [toggle_template_edition]);

    useEffect(() => {
        if ((value)) {
            setValue(
                toggle_template_edition?.isPromotionOptOut
                ? "Not Interested? Tap Stop Promotions"
                : (toggle_template_edition?.templateFooter || "")
            );
        }
    }, [toggle_template_edition?.isPromotionOptOut]);

    const handleChange = (new_value: string) => {
        if (new_value?.length > 60) {
            // dispatch(setErrorMessage("Footer text should not more than 60 characters!"));
            return;
        }
        const data = {
            ...toggle_template_edition,
            templateFooter: new_value
        };
        // console.log({ data });
        setValue(new_value);
        dispatch(toggleTemplateEdition(data));
    }

    return (
        <React.Fragment>
            <div className='footer-form-container'>
                <div className='title-content'>
                    <div className='title'>Footer</div>
                    <div className='optional'>{"(Optional)"}</div>
                </div>
                <div className="footer-form">
                    <div className="form">
                        <label className="form-item">
                            <span>Footer Text <span>{"(Max 60 Characters)"} </span></span>
                        </label>
                        <div className="form-item">
                            <Input
                                placeholder="Enter your footer text here"
                                style={{ background: toggle_template_edition?.isPromotionOptOut ? "#D4DBE7" : "white" }}
                                readOnly={toggle_template_edition?.isPromotionOptOut}
                                value={value || ""}
                                onChange={(e: any) => {
                                    const { value } = e.target;
                                    const inputValue = value?.replace(/\s+/g, ' ');
                                    handleChange(inputValue);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default FooterForm;
