import React, { useEffect, useState } from 'react';
// import { Row, Col, Card } from 'react-bootstrap';
import {Button, Descriptions, Input, Modal, Table} from 'antd';
import AntTable from '../../../../../components/AntTable';
import cross_icon from "../../../../../assets/icons/cross-svgrepo.svg";
import CommonModal from '../../../../../components/CommonModal/CommonModal';
import CommonModalCustomWidth from '../../../../../components/CommonModal/CommonModalCustomWidth';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
// import queryString from 'query-string';
import axios from 'axios';
import { RootState } from '../../../../../store';
import { useDispatch } from 'react-redux';
import {
  // getAccessToken, resetTwitterRequestToken, triggerTwitterConnection, triggerGetIntegrationConnections,
  triggerDisconnectIntegrationConnection, triggerTwitterLogin
} from '../../../../../signup-login/loginReducer';
import { api_url, auth_url } from '../../../../../utils/server';
import { getConnectedMapGoogleLocations, setGoogleLocationPayload, toggleGoggleModalVisible, toggleShowFbPageMapModal, triggerMapGoogleConnectionLocations } from '../../../workSpaceReducer';
import Dialog from '../../../../../components/dialogs/dialog';
import { generateAccessToken } from '../../../../../profile/reducer';
// import queryString from 'query-string';
// import { Spinner } from 'react-bootstrap';
// import { getProfileInfo } from '../../../../profile-management/profileManageReducer';

const Google = "https://images.clapup.io/clapupapp/btn_google_light_normal_ios.svg";
declare global {
  interface Window {
    FB: any; // Assuming 'FB' has any type
  }
}

const GoogleIntegration: React.FC = () => {
  const dispatch = useDispatch();
  // const [loading, setLoading]: any = React.useState(false);
  // const { personalInfo } = useSelector((state: any) => state?.profileManageSettingState);
  // const { email }: any = personalInfo || {};
  // useEffect(() => {
  //   const { access_token }: any = queryString.parse(
  //     window.location.href.split("?")[1]
  //   );
  //   if (email) {
  //     // setLoading(true);
  //     if (!access_token) {
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 1500);
  //     }
  //   } else {
  //     setLoading(true);
  //     dispatch(getProfileInfo());
  //   }
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 5000);
  // }, [dispatch, email]);
  const {
    integration_type,
    google_connection_data,
    integrationConnections,
  }: any = useSelector((state: RootState) => state?.login);

  const {
    google_locations,
    google_modal_visible,
    google_connection_location_data,
    google_connected_mapped_locations
  } = useSelector((state: any) => state?.workspaceManagementState);
  
  // ======= After Connection Showing Google Location List => Start ======
  const [google_table_data, setGoogleTableData]: any = React.useState([]);
  const [google_table_columns, setGoogleTableColumns]: any = React.useState([]);
  const user = JSON.parse(localStorage.getItem("integrated_user_details") || "{}");

  const [selectedGoogleLocationIds, setSelectedGoogleLocationIds] = React.useState([]);
  const handleGoogleSelectedLocation = (data: any) => {
    console.log("handleGoogleSelectedLocation ====> ", data);
    setSelectedGoogleLocationIds(data);
  };

  const handleGoogleLocationsContinue = () => {
    console.log("handleGoogleLocationsContinue ====> ", selectedGoogleLocationIds);
    if(selectedGoogleLocationIds?.length){
      const locationIds = selectedGoogleLocationIds?.join(",");
      const workspaceId = sessionStorage.getItem("wuid");
      const googleLocationsMapWithWorkspaceObject = {
        locationIds,
        workspaceId,
      }
      console.log({ googleLocationsMapWithWorkspaceObject });
      dispatch(triggerMapGoogleConnectionLocations(googleLocationsMapWithWorkspaceObject));
      // setGoogleModalVisible(false);
      handleToggleModalVisible();
    }
  }

  useEffect(() => {
    if (integration_type === "google" && google_connection_data?.data === "success") {
      const social_location_obj = {
        gmbAccountId: user?.id,
        workSpaceId: sessionStorage.getItem("wuid"),
      };
      // sessionStorage.removeItem("app_connect");
      dispatch(setGoogleLocationPayload(social_location_obj));
      // google_connection_data?.data === "success" && setGoogleModalVisible(true);
      dispatch({
        type: "SET_INTEGRATION_TYPE",
        payload: "google-locations-search",
      });
    } else if (
      integration_type === "google-locations-search" &&
      google_locations?.length
    ) {
      setGoogleTableColumns([
        {
          title: "#",
          dataIndex: "idx",
          key: "idx",
        },
        {
          title: "Locations",
          dataIndex: "locationName",
          key: "locationName",
          align: "start",
          responsive: ['md'],
          //   render: (text: string, data: any) => {text},
        },
      ]);

      const table_data = google_locations?.map((item: any, idx: number) => {
        return {
          ...item,
          id: item?.locationId,
          idx: idx + 1,
        };
      }) || [];

      const selectedRowKeys = table_data?.map(
        (item: any) => item?.isActive && item?.id
      );

      selectedRowKeys && setSelectedGoogleLocationIds(selectedRowKeys);
      setGoogleTableData(table_data);
      google_connection_data?.data === "success" && dispatch(toggleGoggleModalVisible(true));
      dispatch({ type: "SET_INTEGRATION_TYPE", payload: "" });
    }
  }, [integration_type, google_locations?.length]);


  const handleToggleModalVisible = () => {
    dispatch(toggleGoggleModalVisible(false));
  }

  // ======= After Connection Showing Google Location List => End ======


  // ======== Connect with Google => start =====
  const [connectionData, setConnection] = React.useState();

  const redirect = auth_url;
  const google_connected_data = integrationConnections?.length &&
    integrationConnections[0]?.UserId;
    // && google_connected_mapped_locations?.length;
  const [isGoogleConnected, setIsGoogleConnected] = useState<any>(false);
  useEffect(() => { 
    console.log({ google_connected_data });
    if (google_connected_data) {
      setIsGoogleConnected(true);
    } else {
      setIsGoogleConnected(false);
    }
  }, [google_connected_data]);
  
  const handleGoogleConnect = () => {
    sessionStorage.setItem('isLoading', 'true');
    sessionStorage.setItem('google_connect', 'google-connection');
    localStorage.setItem('appAccessType', 'google-connection');
    axios.get(
        `${api_url}/api/v1.2/clapup/getKeys?provider=Google`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
          }
        }
    ).then(response => {
      // console.log({ response });
      const {
        clientId,
        // clientSecret
      } = response?.data;
      // sessionStorage.setItem("client_details", JSON.stringify({ clientId, clientSecret }));
      sessionStorage.setItem("client_details", JSON.stringify({ clientId }));
      axios.post(
        `${redirect}/auth/google/url?code=${window.location.host}/profile`,
        {
          clientId,
          // clientSecret
        }
      )
      .then((res) => {
          console.log('res data  =====47====>', res);
          setConnection(res?.data);
      })
      .catch((err) => {
        console.log('err: ', err);
      });
      
    }).catch((error: any) => {
      console.log('error: ', error);
      const { response } = error;
      const { status }: any = response;
      if((status === 403 || status === 401)) {
        dispatch(generateAccessToken());
      }
    });
    
  }
  

  useEffect(() => {
      // "https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https%3A%2F%2Flocalhost%3A4000%2Fprofile&client_id=651003914717-81c4av16ki35tcfmv89ig7ku204j57q2.apps.googleusercontent.com&include_granted_scopes=true&access_type=offline&response_type=code&prompt=consent&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fplus.business.manage"
      if (connectionData) {
        let googleURL: any = connectionData;
        console.log('connection-url ===== ===== ==> ', {googleURL});
        window.location.href = googleURL;
      }

  }, [ dispatch, connectionData]);


  // const get_integration_connections = () => {
  //   const workspaceId = sessionStorage.getItem('wuid');
  //   dispatch(triggerGetIntegrationConnections(workspaceId));
  // }

  // ======== Connect with Google => end =====


  // ==== Disconnect Function ====
  const handleDisconnect = (Provider?: any, ProviderUserId?: String) => {
    console.log('ConnectWarning ==>', {Provider, ProviderUserId});
    dispatch(triggerDisconnectIntegrationConnection({ Provider, ProviderUserId, WorkspaceId: sessionStorage.getItem('wuid') }));
    setTimeout(() => {
      dispatch(getConnectedMapGoogleLocations());
      // dispatch(getFacebookConnectionPages());
      setIsDialogShow(false);
      // dispatch(getTwitterConnectionData());
    }, 1000);
    // get_integration_connections();
      
  }

  // ======== Connected Location Management => start =====
  // use callback function for google location services
  const [connectedMappedGoogleLocations, setConnectedMappedGoogleLocations] = useState<any>([]);
  const [connectedMappedGoogleLocationsColumns, setConnectedMappedGoogleLocationsColumns] = useState<any>([]);
  const [googleLocaitonDetails, setGoogleLocaitonDetails] = useState<any>(null);
  useEffect(() => {
    console.log("google_locations ====> ", google_locations);

    if (google_connected_mapped_locations?.length) {
      const table_data = google_connected_mapped_locations?.map(
        (item: any, idx: number) => {
          return {
            ...item,
            id: item?.locationId,
            idx: idx + 1,
          };
        }
      );
      // setSelectedGoogleLocationIds(selectedRowKeys);
      setConnectedMappedGoogleLocations(table_data);
    }
  }, [google_connected_mapped_locations?.length, google_connection_location_data]);

  const showGoogleLocationDetails = (googleObject?: any) => {
    console.log({ connectedMappedGoogleLocations });
    console.log("showGoogleLocationDetails ====> ", googleObject);
    setConnectedMappedGoogleLocationsColumns([
      {
        title: "#",
        dataIndex: "idx",
        key: "idx",
        align: "left",
      },
      {
        title: "Locations",
        dataIndex: "locationName",
        key: "locationName",
        align: "right",
        responsive: ['md'],
        //   render: (text: string, data: any) => {text},
      },
    ]);
    setGoogleLocaitonDetails(googleObject);
  };

  // ======== Connected Location Management => End =====


  // use callback function for google location services
  

  // // continue button for google location services
  // useEffect(() => {
  //   console.log("Google connection established ==== 317 =====>", google_connection_location_data);
  //   // google_connection_location_data => success => true
  //   if(google_connection_location_data === "success"){
  //     setGoogleModalVisible(false);
  //   }
  //   // setGoogleModalVisible(false);
  // }, [google_connection_location_data]);


  const [isDialogShow, setIsDialogShow] = useState<any>(null);
  const handleDialogCallback = (flag?: any) => {
    console.log({ flag });
    if (!flag) {
      setIsDialogShow(null);
    } else {
      if (isDialogShow === "Google" && integrationConnections?.length) {
        handleDisconnect("Google", integrationConnections[0].UserId);
      }
      // else {
      //   handleDisconnect("Twitter", integrationConnections[1].UserId);
      // }
      setTimeout(() => {
        setIsDialogShow(null);
      }, 1500);
    }
  };

  return (
    <React.Fragment>
      {/* Showing ==== Modal ======= Start */}
      {
        isDialogShow ?
          <Dialog
            open={isDialogShow}
            parentCallback={(flag?: any) => handleDialogCallback(flag)}
            classes='dialog-container-content'
            title='Disconnect?'
            warning_dialog_content={
              isDialogShow === "Google"
              ? "If you continue, all your data related to Google Business Profile will be deleted from our system"
              : null
            }
            footer={true}
            right_button_text='Yes, Continue'
            left_button_text='No, Cancel'
            // hover={true}
          />
        : null
        
      }

      {googleLocaitonDetails?.UserId ? (
        <CommonModal
          padding={"10px 5px"}
          title={
            <div className="d-flex flex-row justify-content-between align-items-center py-2">
              <p
                style={{ fontSize: 20, fontWeight: 700 }}
                className="text-capitaliz ps-3"
              >
                Google Account Info.
              </p>
              <button
                onClick={() => setGoogleLocaitonDetails(false)}
                className={
                  "m-2 rounded rounded-circle btn btn-outline-light px-2 py-0"
                }
                style={{ color: "#FAFBFC", backgroundColor: "#ABB9CC" }}
              >
                {/* font awesome icon fa-xmark in react */}
                {/* <FontAwesomeIcon icon={faTimes} /> */}
                <img
                  className="mb-1 p-0"
                  height={10}
                  src={cross_icon}
                  alt=""
                />
              </button>
            </div>
          }
          // closeIcon={true}
          width={900}
          modal_visible={googleLocaitonDetails?.UserId ? true : false}
          centered={true}
          setModalVisible={setGoogleLocaitonDetails}
          footer={null}
        >
          {/* <div style={{ padding: "0 24px" }}> */}
          <div style={{ padding: "0 3%" }}>
            <Descriptions
            // title="User Info"
            >
              <Descriptions.Item label="User Name">
                {googleLocaitonDetails?.UserName}
              </Descriptions.Item>
              <Descriptions.Item label="User Id">
                {googleLocaitonDetails?.UserId}
              </Descriptions.Item>
              <Descriptions.Item label="Display Name">
                {googleLocaitonDetails?.DisplayName}
              </Descriptions.Item>
              <Descriptions.Item label="Provider">
                {googleLocaitonDetails?.Provider}
              </Descriptions.Item>
              <Descriptions.Item label="Email Id">
                {googleLocaitonDetails?.EmailId}
              </Descriptions.Item>
            </Descriptions>
            <Table
              columns={connectedMappedGoogleLocationsColumns}
              dataSource={connectedMappedGoogleLocations}
              rowKey="id"
              pagination={false}
            />
          </div>
        </CommonModal>
      ) 
      :  null}
      {/* Showing ==== Modal ======= End */}


      <div className='integration-container'>
        {/* Google */}
        <div className="card-container">
          <div className='logo-container'>
            <img
              className="logo-icon"
              src={Google}
              alt="google"
            />
          </div>
          <div className='card-content'>
            <h4 className='card-title'>Google Business Profile</h4>
            <div className='description-container'>
              <div className='card-description'>
                <p >A simple way to connect to your Google business profile and manage reviews from multiple locations</p>
                
              </div>
              <div className='button-container'>
                <button
                  type="button"
                  className="card-button"
                  onClick={() => {
                    // integrationConnections?.length &&
                    // integrationConnections[0].UserId &&
                    // connectedMappedGoogleLocations?.length

                    // google_connected_mapped_locations?.length
                    // ? handleDisconnect(
                    //     "Google",
                    //     integrationConnections[0].UserId
                    //   )
                    // : handleGoogleConnect();
                    // if (google_connected_mapped_locations?.length && isGoogleConnected) {
                    if (isGoogleConnected) {
                      setIsDialogShow("Google")
                    } else {
                      handleGoogleConnect();
                    }
                  }}
                >
                  {
                    // integrationConnections?.length &&
                    // // integrationConnections[0].UserId &&
                    // // connectedMappedGoogleLocations?.length
                    // integrationConnections[0]?.UserId
                    // && google_connected_mapped_locations?.length
                    isGoogleConnected
                    ? "Disconnect"
                    : "Connect"
                  }
                </button>
              </div>
            </div>
            {
              // integrationConnections?.length && integrationConnections[0]?.UserId
              isGoogleConnected
              ? <div className='card-footer'>
                    <button
                      type='button' className='card-footer-button'
                      onClick={() => showGoogleLocationDetails(integrationConnections[0])}>
                      Configure Locations</button>
                    <p>{integrationConnections?.length ? integrationConnections[0]?.DisplayName : ""}</p>
                </div>
              : null
            }
            {/* {integration_type === "google" &&
            google_connection_data?.data !== "success" ? (
              <p className="text-danger">
                <small>Some wrong to connect!</small>
              </p>
            ) : null} */}
          </div>
        </div>
      </div>

      {/* Showing ==== Google Location Modal ======= Start */}
      <CommonModal
        title={
          <h4 style={{ padding: "0 24px" }} className="header-title pt-3">
            {google_table_data?.length
              ? "Select locations"
              : "No locations found"}
          </h4>
        }
        modal_visible={google_modal_visible}
        centered={true}
        setModalVisible={handleToggleModalVisible}
        okText="Continue"
        cancelText="Cancel"
        footer={[
          <div style={{ padding: "20px 24px" }} key="1">
            <Button
              className="common-btn"
              onClick={() => handleToggleModalVisible()}
            >
              Cancel
            </Button>
            <Button
              className="common-btn"
              onClick={() => handleGoogleLocationsContinue()}
              disabled={!selectedGoogleLocationIds?.length}
            >
              Continue
            </Button>
          </div>,
        ]}
      >
        <div style={{ padding: "0 24px" }}>
          <AntTable
            handleSelectedRows={handleGoogleSelectedLocation}
            table_columns={google_table_columns}
            table_data={google_table_data}
            selectedRowKeys={selectedGoogleLocationIds}
          />
        </div>
      </CommonModal>
      {/* {google_modal_visible && google_table_data?.length ? (
      ) : null} */}
      
      {/* Showing ==== Google Location Modal ======= End */}
      
    </React.Fragment>
  );
};

export default GoogleIntegration;