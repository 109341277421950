import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSuccessMessage } from "../../dashboard/reducer";
import "./notifications.scss";

// type Props = {
//   message?: string;
//   isShow?: boolean;
//   removeDialog?: any;
// };

export default function Success() {
  const dispatch = useDispatch();
  const { success_message } = useSelector(
      (state: any) => state?.unified
  );
  //   const [show, setShow] = useState<any>(true);
  useEffect(() => {
    if (success_message) {
      setTimeout(() => {
        dispatch(setSuccessMessage(null));
      }, 3000);
    }
  }, [success_message]);

  return success_message ? (
    <div
      //   onClick={() => {
      //     setShow(!show);
      //     removeDialog && removeDialog();
      //   }}
      className={"dialog bg-transparent"}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "end",
          justifyContent: "end",
        }}
        // className={"h-full flex items-end justify-end"}
      >
        <div
          style={{
            height: "8.81vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bottom: "6%",
            background: "#D8E3FF",
            borderLeft: "4px solid #1570EF",
            cursor: "pointer",
            position: "relative",
            padding: "0 1.5rem",
          }}
          className={`dialog_container cursor-pointer relative mx-auto `}
        >
          <p
            style={{ color: "#667085" }}
            // className={`success text-[#667085]`}
          >
            {
              success_message || "Success message here!"
            }
          </p>
        </div>
      </div>
    </div>
  ) : null;
}
