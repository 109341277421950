import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import CanvasJSReact from '../components/CanvasChart/canvasjs.react';
import { RootState } from '../store';
import './dashboard.scss';
// const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const getMonthNumber = (month: any) => {
    switch (month) {
        case 'January':
            return 0;
        case 'February':
            return 1;
        case 'March':
            return 2;
        case 'April':
            return 3;
        case 'May':
            return 4;
        case 'June':
            return 5;
        case 'July':
            return 6;
        case 'August':
            return 7;
        case 'September':
            return 8;
        case 'October':
            return 9;
        case 'November':
            return 10;
        case 'December':
            return 11;
        default:
            return 0;
    }
}

const getMonthName = (month: any) => {
    switch (month) {
        case 0:
            return 'January';
        case 1:
            return 'February';
        case 2:
            return 'March';
        case 3:
            return 'April';
        case 4:
            return 'May';
        case 5:
            return 'June';
        case 6:
            return 'July';
        case 7:
            return 'August';
        case 8:
            return 'September';
        case 9:
            return 'October';
        case 10:
            return 'November';
        case 11:
            return 'December';
        default:
            return "December";
    }
}

const getAvg = (arr: any) => {
    let sum = 0;
    for (let i = 0; i < arr.length; i++) {
        sum += arr[i];
    }
    return Math.round(sum / arr.length);
}

const DashboardChart: React.FC = () => {
    const { chart_data: data, chart_page } = useSelector(
      (state: RootState) => state?.unified
    );
    const [chart_data, setChartData] = React.useState<any>([]);
    const [options, setOptions] = React.useState<any>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    useEffect(() => {
      if (data?.length) {
        // console.log("filter_chart_data === No ===> ", chart_page);
        // console.log("filter_chart_data === Month ===> ", getMonthName(chart_page - 1));

        const today = new Date();

        // Calculate cutoff date
        const cutoffDate = new Date(
          today.getFullYear(),
          today.getMonth() - chart_page,
        //   (chart_page -1),
          1
        );
        // console.log('filter_chart_data == 0 ===> ', { cutoffDate });
        const filter_chart_data =
        chart_page === "All"
        ? data
        // filter by last x months data from today
        : data?.filter((item: any) => {
            // return item?.Month === getMonthName(chart_page);
            // return item?.Month === (chart_page);
            const dateObj = new Date(item.Year, item.Month - 1, 1);
            // console.log('filter_chart_data == 0 ===> ', { dateObj, cutoffDate });
            
            return dateObj >= cutoffDate;
            // return dateObj <= cutoffDate;
        });
        // console.log('filter_chart_data == 1 ===> ', { data });
        // console.log('filter_chart_data == 2 ===> ', { filter_chart_data });
        setTimeout(() => {
            setChartData(filter_chart_data?.length ? filter_chart_data : []);
            setIsLoading(false);
        }, 500);
        setIsLoading(true);
        setChartData([]);

      }
    }, [data?.length, chart_page]);
            
    
    useEffect(() => {
        
        if (chart_data?.length) {
            console.log("Graph data ===> ", chart_data?.length);
            
            const options: any = {
                animationEnabled: true,
                // exportEnabled: true,
                // theme: "light1", // "light1", "dark1", "dark2"
                title:{
                    text: '.',
                    fontFamily: 'Graphik',
                    fontSize: 5,
                    fontWeight: 'lighter',
                    fontColor: '#fff',
                },
                axisX: {
                    // value year month day
                    // valueFormatString: "YYYY MMM",
                    valueFormatString: "MMM",
                    innerHeight: 10,
                    // interval: 5,
                    interval: chart_data?.length % 12 < 2 ? 1 : (chart_data?.length % 5 > 3 ? 3 : 5),
                    intervalType: "month",
                    labelAngle: 180,
                    labelFontColor: "#707070",
                    labelFontSize: 10,
                    labelFontFamily: "Graphik",
                    labelFontStyle: "normal",
                    labelFontWeight: 400,
                    labelLineHeight: 0,
                    labelLetterSpacing: 0.14,
                    // labelWrap: true,
                    // labelMaxWidth: 100,
                    // labelAutoFit: true,
                    // labelMinWidth: 100,
                    title: "Month",
                    titleFontColor: "#fff",
                },
                axisY: {
                    // title: "Sales (in USD)",
                    titleFontColor: "#fff",
                    prefix: "",
                    // interval: 10,
                    interval: getAvg(chart_data?.map((item: any) => Number(item?.Count))),
                    includeZero: false,
                    labelFontColor: "#707070",
                    labelFontSize: 10,
                    labelFontFamily: "Graphik",
                    labelFontStyle: "normal",
                    labelFontWeight: 400,
                    labelLineHeight: 0,
                    labelLetterSpacing: 0.14,
                },
                data: [{
                    yValueFormatString: "#,##",
                    xValueFormatString: "YYYY MMMM",
                    // type: "spline",
                    type: "spline",
                    dataPoints: [
                        // { x: new Date(2017, 0), y: 25 },
                        // { x: new Date(2017, 1), y: 27 },
                        // { x: new Date(2017, 2), y: 42 },
                        // { x: new Date(2017, 3), y: 32 },
                        // { x: new Date(2017, 4), y: 35 },
                        // { x: new Date(2017, 5), y: 33 },
                        // { x: new Date(2017, 6), y: 40 },
                        // { x: new Date(2017, 7), y: 25 },
                        // { x: new Date(2017, 8), y: 32 },
                        // { x: new Date(2017, 9), y: 42 },
                        // { x: new Date(2017, 10), y: 37 },
                        // { x: new Date(2017, 11), y: 38 },
                        // { x: new Date(2018, 6), y: 68 }
                    ]
                }]
            };
            const dataPoints = chart_data?.map((item: any) => {
                return {
                //   x: new Date(item?.Year, getMonthNumber(item?.Month)),
                  x: new Date(item?.Year, (item?.Month - 1)),
                  y: Number(item.Count),
                };
            });
            // if dataPoints contains only one data point, then add a dummy data point
            if (dataPoints?.length === 1) {
                dataPoints.push({
                    x: new Date(dataPoints[0].x.getFullYear(), dataPoints[0].x.getMonth()),
                    y: 0,
                });
                dataPoints.push({
                    x: new Date(dataPoints[0].x.getFullYear(), dataPoints[0].x.getMonth() + 1),
                    y: 0,
                });
                dataPoints.push({
                    x: new Date(dataPoints[0].x.getFullYear(), dataPoints[0].x.getMonth() - 1),
                    y: 0,
                });
            }
            options.data[0].dataPoints = dataPoints;
            // console.log("options ===> ", options);
            setOptions(options);
        }
    }, [chart_data?.length]);
    return (
        <React.Fragment>
            <div className='position-relative chart-board'>
                {/* <p style={{top: '1.5%'}} className='chart-word-off bg-white position-absolute p-2'></p> */}
                <p style={{ bottom: '3%', right: "0%"}} className='chart-word-off bg-white position-absolute p-2'></p>
                {
                    chart_data?.length && options 
                    ? <CanvasJSChart options = {options}/> 
                    : <div className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center'>
                        {
                            isLoading
                            ? <Spinner size="sm" animation="border" variant="primary" />
                            : <p className='text-center'>No data found</p>
                        }
                        
                    </div>
                }
                {/* <CanvasJSChart options = {options}/> */}
                {/* onRef={ref => this.chart = ref} */}

            </div>
        </React.Fragment>
    );
};

export default DashboardChart;