import React, { Fragment, useEffect, useState } from "react";
// import { Tooltip } from 'antd';
import { Col, Row, Spinner } from "react-bootstrap";
// import Button from "../../button/button-container";
import './../all_feeds.scss';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTimes } from "@fortawesome/free-solid-svg-icons";
// import search from '../../../assets/icons/search-icon.svg';
import emptyBox from '../../../assets/img/Empty.svg';
// import user_avatar from '../../assets/icons/user-avatar.svg';
// import google_logo from '../../assets/icons/google-logo.svg';
// import cross_icon from '../../../assets/icons/cross-svgrepo.svg';
import Search from "../../search/search-component";
import { useDispatch, useSelector } from "react-redux";
import { changePage, toggleIsWhatsappInboxEmpty, resetWallUnifiedInbox, toggleWhatsappInboxUpdated, toggleReplyingOn, triggerDeleteWallUnifiedData, triggerSearchWallData, triggerWallUnified, twitterMentions, toggleWhatsAppSenderState } from "../../../dashboard/reducer";
import { RootState } from "../../../store";
import connection from '../../../utils/signalRconnectionConversation';
// import moment from "moment";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
// import CommonModal from "../../CommonModal/CommonModal";
// import RequestReview from "../../RequestReview/RequestReview";
// import { bio_url } from "../../../utils/server";
// import EmbedCode from "../../EmbedCode/EmbedCode";
// import { DebounceInput } from "react-debounce-input";
import Dialog from "../../dialogs/dialog";
// import BotMemberConversations from "../ConversationDetails/AllConversations/BotMemberConversations";
import MessagesDetails from "../ConversationDetails/AllDetails/MessagesDetails";
import MessagesInboxCard from "../InboxCard/MessagesInboxCard";
import WhatsAppMemberConversations from "../ConversationDetails/AllConversations/WhatsAppMemberConversations";
// const capitalize = (word: string) => word[0].toUpperCase() + word.slice(1).toLowerCase();

interface Props {
  feedsEmpty?: boolean;
  feedsEmptyContent?: string;
  buttonContent?: string;
  imagePath?: any;
  handleTriggerData?: any;
  triggerData?: any;
  feedsContent?: any;
  type?: any;
  isTagChanged?: any;
  handleSearchData?: any;
}

const WhatsAppMessageFeeds: React.FC<Props> = ({ feedsContent, type, handleSearchData, handleTriggerData, triggerData }) => {
  const route_path = window.location.pathname;
  // const path = window.location.pathname;
  const query_string = window.location.search;
  const tag_type = query_string?.includes("open") ? "open"
  : query_string?.includes("in-progress") ? "inProgress"
  : query_string?.includes("closed") ? "closed" 
  : query_string?.includes("spam") ? "spam" 
  : "all";
  const navigate = useNavigate();
  const [sText, setSearchText] = useState('');
  
  // const [isLoading, setIsLoading] = useState<boolean>(true);
  // const {integrationConnections}: any = useSelector((state: RootState) => state?.login);
  const {
    whatsAppInboxData: whatsApp_inbox_data, is_main_whatsapp_inbox_updated,
    searchText, searchPayload, searchStartDate, searchEndDate, searchFilterStatus,
    pageRoute, is_empty_whatsapp_inbox,
  }: any = useSelector((state: RootState) => state?.unified);
  const dispatch = useDispatch();
  
  const {
    main, statDetails, msgDetails: chatDetails,
  }: any = whatsApp_inbox_data || {};
    
    
  const whatsAppInboxData:
    any[] = tag_type === "all"
      ? main || []
      // : tag_type === "reviews"
      // ? CFReviews_CFReviews
      : main || [];
  
  useEffect(() => {
    if (main?.length && tag_type !== "all") {
      const main_is_not_empty_data_by_tag_type = main?.map((item: any) => {
        const userWiseMessageDetailsSubs = item?.userWiseMessageDetailsSubs?.filter((sub: any) => sub.chatStatus === tag_type);
        return userWiseMessageDetailsSubs?.length ? { notEmpty: true } : { notEmpty: false };
      }).find((item: any) => item.notEmpty === true);
      
      // console.log({ main_is_not_empty_data_by_tag_type });
      if (main_is_not_empty_data_by_tag_type) {
        dispatch(toggleIsWhatsappInboxEmpty(false));
      } else {
        dispatch(toggleIsWhatsappInboxEmpty(true));
      }
    } else {
      dispatch(toggleIsWhatsappInboxEmpty(false));
    }
  }, [main?.length, query_string]);
    

  const nextDate: any[] = [new Date().toISOString().replace('Z', '')];
    // tag_type === "all"
    // ?
    // [new Date().toISOString().replace('Z', '')]
    // : tag_type === "reviews"
    // ? CFReviews_CFReviewsNextDate
    // : [];
      
            
  const [stat_details, setStatDetails] = useState<any>(null);
  const [conversation, setConversation] = useState<any>(null);
  const [conversationId, setConversationId] = useState<any>(null);
  const [wallData, setWallData] = useState<any[]>([]);
  const [activePage, setActivePage] = useState<any>("conversations");
  const [activeTab, setActiveTab] = useState<any>("all");
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);

  // ===== SignalR start ====
  const { isReplyingOn, whatsapp_replying_text, whats_app_sender_state: whats_app_sender } = useSelector((state: RootState) => state?.unified);
  // Join a workspace (replace with actual workspace ID)
  const joinWorkspace = async (workspaceId: any) => {
    try {
        await connection.invoke("JoinWorkspace", workspaceId);
        console.log(`SignalR_Whatsapp_Conversation: Joined workspace: ${workspaceId}`);
    } catch (error) {
        console.error("SignalR_Whatsapp_Conversation: Error joining workspace:", error);
    }
  };

  // // Receiving hook
  useEffect(() => {
    const user_info = JSON.parse(
      sessionStorage.getItem("accessed_user_details") || "{}"
    );
    console.log('SignalR_Whatsapp_Conversation => ', {state: connection?.state});

    if (connection?.state === 'Connected') {
      // console.log("SignalR_Whatsapp_Conversation: => ", {state: connection?.state, email: user_info?.email, name: user_info?.name, ticketNo: conversation?.ticketNo, provider: conversation?.provider });


      if (sessionStorage.getItem('wuid') || localStorage.getItem('wuid')) {
          joinWorkspace(sessionStorage.getItem('wuid') || localStorage.getItem('wuid'));
      }

      const handleLockWhenTyping = (details: any) => {
        const sender_details = JSON.parse(details);
        const { isFromPrevious, sender_name, ticketNo, provider, sender_email, whatsapp_replying_text } = sender_details || {};
        // console.log("SignalR_Whatsapp_Conversation: received data:", {isFromPrevious, sender_details: sender_details});
        
        if (isFromPrevious) {
          dispatch(toggleWhatsAppSenderState(null));
        } else if (sender_email !== user_info?.email && ticketNo && provider === "whatsApp") {
          // console.log(`SignalR_Whatsapp_Received_Conversation User with ID ${sender_name} is typing`);
          if(whatsapp_replying_text){
            dispatch(toggleWhatsAppSenderState(sender_details));
          } else {
            dispatch(toggleWhatsAppSenderState(null));
          }

          if (!whats_app_sender) {
            setTimeout(() => {
              dispatch(toggleWhatsAppSenderState(null));
            }, 600000); // 10 mins = 600 sec
          }
        }
      }

      // receives methods with route
      connection.on('TicketLocked', handleLockWhenTyping);
      // connection.on('TicketUnlocked', handleLockWhenTyping);

      // Clean up SignalR connection on component unmount
      return () => {
        connection.off('TicketLocked', handleLockWhenTyping);
        // connection.off('TicketUnlocked', handleLockWhenTyping);
        // leave workspace Id
        if (sessionStorage.getItem('wuid') || localStorage.getItem('wuid')) {
          connection.invoke("LeaveWorkspace", (sessionStorage.getItem('wuid') || localStorage.getItem('wuid'))).catch(err => console.error('Failed to leave workspace: ', err));
        }
      }

    }
  }, [connection?.state]);

  // invoking hook
  useEffect(() => {
    const user_info = JSON.parse(
      sessionStorage.getItem("accessed_user_details") || "{}"
    );
    // console.log({conversation, whatsapp_replying_text, state: connection?.state});
    if (conversation && connection?.state === "Connected" && whatsapp_replying_text !== null) {
      const path = query_string.replace('?', '')?.replaceAll('&', "=");
      const path_array = path.split("=");
      const [_0, _1, _2, ticketNo, _4, _user] = path_array;
      const sender_info = {
        isFromPrevious: true,
        whatsapp_replying_text,
        whats_app_sender_state: true,
        sender_email: user_info?.email,
        sender_name: user_info?.name,
        ticketNo,
        sender_inbox_userName: conversation?.userName || _user,
        provider: "whatsApp"
      };
      // console.log("SignalR_Whatsapp_Conversation invoked by data: => ", {state: connection?.state, sender_info, email: user_info?.email, name: user_info?.name });
      if (whatsapp_replying_text?.trim()) { 
        sessionStorage.setItem("prev_sender_info", JSON.stringify({ whatsapp_replying_text: '', sender_email: user_info?.email, name: user_info?.name, ticketNo, provider: "whatsApp" }));
        sender_info.isFromPrevious = false;
      }

      // invoking methods with route
      const ticketId = ticketNo;
      const providerExample = "whatsApp";
      const workspaceId = sessionStorage.getItem('wuid') || localStorage.getItem('wuid');
      // "TicketLocked", ticketId,workspaceId,userName, provider)

      connection.invoke('LockTicket', true, JSON.stringify(sender_info), ticketId, providerExample, workspaceId)
      .catch((error: any) => {
        console.error('SignalR_Whatsapp_Conversation invoked: Failed to send typing status:', error);
      }); 
    }
  }, [whatsapp_replying_text]);

  // ===== SignalR End ====


  useEffect(() => {
    // console.log( "Check type ======> ",{tag_type, type, whatsAppInboxData, whatsApp_inbox_data});
    // console.log("Embed Object ======> ", embedObject);
    setData(whatsAppInboxData || []);
    dispatch(toggleWhatsappInboxUpdated(false));
    if(whatsAppInboxData?.length && nextDate?.length){
      setHasMore(true);
      // setIsLoading(true);
      // setTimeout(() => {
      //   setIsLoading(false);
      // }, 2000);
    } else {
      setHasMore(false);
    }
    
    
    const flag = nextDate?.filter((nd: any) => nd?.createdOn);
    // console.log({flag, nextDate, whatsAppInboxData});
    
    if(whatsAppInboxData?.length && (nextDate === null || !flag?.length)){
      // setIsLoading(true);
      setHasMore(false);
    }
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 5000);
    
    // setData(dummyData);
  }, [
    query_string, whatsAppInboxData?.length, nextDate?.length,
    searchText, searchStartDate, searchEndDate, searchFilterStatus,
    // if signalR updated whatsapp inbox data
    is_main_whatsapp_inbox_updated
  ]);
  
  useEffect(() => {
    const path = query_string.replace('?', '')?.replaceAll('&', "=");
    const path_array = path.split("=");
    if (path_array?.length > 5) {
      const [_0, _1, _2, conversation_id, _4, user] = path_array;
      if (whatsAppInboxData?.length && conversation_id) {
        const conversation = whatsAppInboxData?.find((conversation: any) => conversation?.userName === user);
        console.log({ path_array, conversation, botInboxData: whatsAppInboxData?.length });
        // console.log({conversation});
        if (!conversation) {
          navigate(`${route_path}?all=conversations`);
        }
      }
    }
  }, [whatsAppInboxData?.length]);

  const fetchMoreData = () => {
    // if (nextDate === null || !nextDate?.length) {
    //   setHasMore(false);
    //   return;
    // }
    setHasMore(true);
    // a fake async api call like which sends
    console.log({type_more: true});
    
    setTimeout(() => {
      setHasMore(false);
      handleTriggerData(triggerData, false);
      // setData(data.concat(whatsAppInboxData.slice(data?.length, data?.length + 7)));
    }, 500);
  };
  
  const filterBySearchData = (value?: any, key?: any) => {
    // dispatch
    console.log("key, value ======> ", {key, value});

    handleSearchData(key, value);
    // setIsLoading(true);
    // if(key === "readStatus"){
    //   dispatch({type: "FILTER_BY_READ_STATUS", payload: value});
    // } else if(key === "searchText"){
    //   dispatch({type: "FILTER_BY_SEARCH_TEXT", payload: value});
    // } else{
    //   dispatch({type: "FILTER_BY_SEARCH_FROM_TO_DATE", payload: value});
    // }

  };

  
  useEffect(() => {
    // if (!integrationConnections?.length) {
    //   const workspaceId = sessionStorage.getItem('wuid');
    //   dispatch(triggerGetIntegrationConnections(workspaceId));
    // }
    if ( query_string?.includes("all=conversations") || query_string?.includes("open=conversations") || query_string?.includes("closed=conversations") || query_string?.includes("in-progress=conversations") || query_string?.includes("spam=conversations")) {
      setHasMore(true);
      // setTimeout(() => {
      //   setIsLoading(false);
      // }, 3000);
      const path = query_string.replace('?', '')?.replaceAll('&', "=");
      const path_array = path.split("=");
      // console.log({ path_array });
      // setActiveTab("all");
      // setActivePage("conversations");
      // console.log({ path_array });
      // console.log({ query_string });
      setActiveTab(path_array[0]);
      setActivePage(path_array[1]);
      if (path_array?.length > 5) {
        const [_0, _1, _2, conversation_id, _4, path_user] = path_array;
        const user = path_user?.replaceAll("%20", " ");
        // console.log("Conversation Id ======> ", { conversation_id, user });
        setConversationId(conversation_id);
        const main_item = whatsAppInboxData?.find((wid: any) => wid?.userName?.toLowerCase() === user?.toLowerCase());
        // console.log("conversation", conversation);
        let stat_details = statDetails?.find((sd: any) => sd?.userName?.toLowerCase() === user?.toLowerCase());
        const ticketWisedData = main_item?.userWiseMessageDetailsSubs?.find((chat: any) => chat?.ticketNo === conversation_id);
        const current_conversations = chatDetails?.filter((conversation: any) => conversation?.ticketNo === conversation_id); //?.reverse();
        
        // const route = `${route_path}?${activeTab}=${activePage}&cov=${conversation_id}&user=${user}`;
        // console.log("Conversation Id ======> ", { route, stat_details });
        // if (main_item && current_conversations?.length && stat_details) {
        
        // console.log("whats_stat_details", {
        //   whatsAppInboxData, statDetails, chatDetails,
        //   stat_details,
        //   current_conversations,
        //   main_item, 
        //   conversation_id,
        //   user
        // });
        
        if (current_conversations) {
        // if (main_item && stat_details) {
          const stat_details_data: any = {
            ...stat_details,
            ...ticketWisedData,
            ...main_item,
          };
          delete stat_details_data.userWiseMessageDetailsSubs;
          setStatDetails(stat_details_data);
          const other_user = current_conversations?.find((chat: any) => chat?.userName !== main_item?.userName);
          const conversation_data: any = {
            other_user,
            ...ticketWisedData,
            messages: current_conversations, //?.reverse(),
            ...stat_details_data,
            ...main_item,
          };
          delete conversation_data.userWiseMessageDetailsSubs;
          
          console.log("whats_stat_details", {main_item, stat_details, ticketWisedData});
          setConversation(conversation_data);
        } else {
          setStatDetails(null);
          setConversation(null);
          // navigate(route_path+`?all=conversations`);
        }
        
      } else {
        // setIsLoading(true);
        // setTimeout(() => {
          //   setIsLoading(false);
          // }, 2000);
        setStatDetails(null);
        setConversation(null);
        setConversationId(null);
      }
    } else {
      setStatDetails(null);
      setConversation(null);
      setConversationId(null);
      // console.log({ path_array });
      // console.log({ query_string });
      // navigate(route_path+`?all=conversations`);
    }
  }, [query_string, whatsAppInboxData?.length]);

  const [currentPage, setCurrentPage] = useState<any>(null);
  useEffect(() => {
    dispatch(toggleReplyingOn(false));
    setCurrentPage(null);
  }, [query_string]);

  useEffect(() => {
    if (!pageRoute && !isReplyingOn) {
      setSearchText("");
      dispatch({ type: "REMOVE_SEARCH_TEXT" });
      dispatch({ type: "REMOVE_FILTER_BY_READ_STATUS" });
      dispatch({ type: "REMOVE_SEARCH_FROM_TO_DATE" });
      // dispatch(resetUnifiedInbox());
      // dispatch(resetWallUnifiedInbox());
      setCurrentPage(null);
    }
  }, [pageRoute, isReplyingOn]);
  
  const handlePageChange = (page?: any) => {
    console.log("Page change", page);
    if (!page) {
      // dispatch(toggleReplyingOn(false));
      setCurrentPage(null);
      return;
    }
    if(page?.isFromReply){
      navigate(page?.value);
      setCurrentPage(null);
    } else {
      if (isReplyingOn) {
        dispatch(changePage(`${route_path}?${page}`));
      } else {
        setSearchText("");
        dispatch({ type: "REMOVE_SEARCH_TEXT" });
        dispatch({ type: "REMOVE_FILTER_BY_READ_STATUS" });
        dispatch({ type: "REMOVE_SEARCH_FROM_TO_DATE" });
        // dispatch(resetUnifiedInbox());
        // dispatch(resetWallUnifiedInbox());  
        // dispatch(resetUnifiedInbox());
        navigate(`${route_path}?${page}`);
        setCurrentPage(null);
      }
    }
  };
  
  const left_top_tabs: any[] = [
    // { title: "Q & A", key: "qa" },
    // { title: "Mentions", key: "mentions" },
    // { title: "Comments", key: "comments" },
    // { title: "Reviews", key: "reviews" },
    { title: "Spam", key: "spam" },
    { title: "Closed", key: "closed" },
    { title: "In Progress", key: "in-progress" },
    { title: "Open", key: "open" },
    { title: "All", key: "all" },
  ];

  const handleSeeOtherConversation = (item?: any) => {
    // let stat_details = statDetails?.find((sd: any) => sd?.userName === item?.userName);
    // console.log({ seeing_stat: stat_details, statDetails, item });
    const route = `${route_path}?${activeTab}=${activePage}&cov=${item?.ticketNo}&user=${item?.userName}`;
    // console.log({ route });
    
    // stat_details = {
    //   ...stat_details,
    //   ...item
    // };
    // console.log("bot_", stat_details);
    // setStatDetails(stat_details);
    // navigate(route);
    dispatch(changePage(route));
    // if (query_string?.includes("conversations") && !query_string?.includes(item?.ticketNo)) {
    //   // dispatch({type: "REMOVE_SEARCH_TEXT"});
    //   // dispatch({type: "REMOVE_FILTER_BY_READ_STATUS"});
    //   // dispatch({type: "REMOVE_SEARCH_FROM_TO_DATE"});
      
    //   // mmhk-working
    //   if (isReplyingOn) {
    //     setCurrentPage({ isFromReply: true, value: `${route_path}?${activeTab}=${activePage}&cov=${item?.ticketNo}` });
    //   } else {
    //     navigate(`${route_path}?${activeTab}=${activePage}&cov=${item?.ticketNo}`);
    //   }
    // }
  }

  // console.log("con-length => ", {conversation});
  
  return (
    <React.Fragment>
      {
        currentPage?.value ?
          <Dialog
            open={currentPage}
            parentCallback={(flag?: any) => handlePageChange(flag)}
            classes='dialog-container-content'
            title='Continue?'
            warning_dialog_content={"If you confirm, all your edits will be lost. Locked message will be unlocked and editor will be accessible to others."}
            footer={true}
            left_button_text='No, Cancel'
            right_button_text='Yes, Continue'
            // hover={true}
          />
        : null
        
      }
      <div
        className={`
          feeds-container w-100 position-relative
          ${
            data?.length && conversationId
              ? "feeds-conversations-details"
              : "feeds-row-mobile"
          }
        `}
        style={{
          padding: data?.length && conversationId ? "0" : "2.1% 4% 0% 2%",
        }}
      >
        <Row className={`mobile-conversations mobile-feed`}>
          <Col
            md={3}
            className={data?.length && conversationId ? "" : "feeds-section"}
          >
            {
              data?.length && conversation?.userName ? (
              <div className="conversations h-100">
                <WhatsAppMemberConversations
                  type={type}
                  activePage={activePage}
                  activeTab={activeTab}
                  routePath={route_path}
                  conversationId={conversationId}
                  conversation={conversation}
                  statDetails={stat_details}
                />
              </div>
              ) : null
            }

            {/* Search Inbox Data */}
            {!conversationId ? (
              <div>
                <Search
                  parentCallback={(value: any, key: any) =>
                    filterBySearchData(value, key)
                  }
                  is_whats_app_filter_data={true}
                  searchText={sText}
                  setSearchText={setSearchText}
                  setConversationId={setConversationId}
                  activeTab={activeTab}
                  activePage={activePage}
                />

                <div className="unified-btn mt-2 d-flex justify-content-between align-items-center">
                  {
                    left_top_tabs?.map((tab: any, idx: number) => (
                      <button
                        key={idx}
                        className={`${activeTab === tab.key ? "active" : ""}`}
                        onClick={() => {
                          // console.log({activeTab, tab_key: tab.key});
                          activeTab !== tab.key &&
                          handlePageChange(`${tab.key}=${activePage}`)
                        }}
                        type="button"
                      >
                        {tab.title}
                      </button>
                    ))
                  }
                </div>
              </div>
            ) : null}
  
            {
              // inbox feeds
              !conversationId ? (
                <div className="feeds-section-hove mt-2">
                  <InfiniteScroll
                    dataLength={data?.length || 0}
                    className="feed-scrollin"
                    scrollThreshold={0.9}
                    style={{ overflow: "scroll" }}
                    next={() => {
                      console.log(
                        `prev -> ${data?.length}, next -> ${data?.length + 20}`
                      );
                      fetchMoreData();
                    }}
                    inverse={false}
                    hasMore={hasMore}
                    loader={null
                      // !isLoading && hasMore && data?.length ? (
                      //   <div className="d-flex justify-content-center align-items-center mt-1">
                      //     <Spinner
                      //       size="sm"
                      //       animation="border"
                      //       variant="primary"
                      //     />
                      //   </div>
                      // ) : null
                    }
                    scrollableTarget="scrollableDiv"
                    // endMessage={
                    //   <p style={{ textAlign: "center" }}>
                    //     <b>Yay! You have seen it all</b>
                    //   </p>
                    // }

                    // height={"80vh"}
                    // height={"86vh"}
                    height={"76vh"}
                  >

                    {!data?.length || is_empty_whatsapp_inbox ? (
                      <div className="empty-feeds-section">
                        <div
                          className="empty-wall-section position-absolute"
                          style={{ top: "35%" }}
                        >
                          {/* {isLoading ? (
                            <div className="d-flex justify-content-center align-items-center mt-1">
                              <Spinner
                                size="sm"
                                animation="border"
                                variant="primary"
                              />
                            </div>
                          ) : (
                            <div>
                              <img src={emptyBox} alt="" />
                              <h4>Empty Feeds</h4>
                            </div>
                          )} */}
                          <div>
                            <img src={emptyBox} alt="" />
                            <h4>Empty Feeds</h4>
                          </div>
                        </div>
                      </div>
                    ) : (
                      data.map((item: any, index: number) => {
                        return (
                          <div key={index}>
                            <MessagesInboxCard
                              type={type}
                              handleSeeOtherConversation={handleSeeOtherConversation}
                              inbox={item}
                              activePage={activePage}
                              activeTab={activeTab}
                              routePath={route_path}
                              wallData={wallData}
                              setWallData={setWallData}
                            />
                          </div>
                        );
                      })
                    )}

                  </InfiniteScroll>
                </div>
              ) : null
            }

            {/* mobile bottom tab */}
            {/* {data?.length && conversationId ? null
            : !conversationId ? (
              <div className="conversation-wall-mobile ms-0 flex-row justify-content-around align-items-center mt-2">
                <div>
                  <button
                    // onClick={() =>
                    //   `${activePage}` === "wall" &&
                    //   handlePageChange(
                    //     conversationId
                    //       ? `all=conversations`
                    //       : `all=conversations`
                    //   )
                    // }
                    className={`inbox-btn${
                      query_string?.includes("conversations") ? "-active" : ""
                    }`}
                    type="button"
                  ></button>
                  <p
                    className={`mobile-inbox-wall ${
                      query_string?.includes("conversations") && "inbox-wall-active"
                    } p-0 m-0`}
                  >
                    Inbox
                  </p>
                </div>
                <div>
                  <button
                    // onClick={() =>
                    //   `${activePage}` !== "wall" && handleFindWallUnifiedData()
                    // }
                    className={`coder-wall-btn${
                      activePage === "wall" ? "-active" : ""
                    }`}
                    type="button"
                  ></button>
                  <p
                    className={`mobile-inbox-wall ${
                      activePage === "wall" && "inbox-wall-active"
                    } p-0 m-0`}
                  >
                    Wall
                  </p>
                </div>
              </div>
            ) : null} */}
          </Col>
        </Row>
      </div>

      <div className="feeds-row-lg feeds-container w-100 position-relative">
        <div
          style={{
            // width: `${query_string?.includes("conversations") ? "99%" : "98%"}`,
            height: "92vh",
            overflow: "hidden",
            // display: "flex",
            // gap: '0.4%',
          }}
          className="row"
        >
          <div className="feeds-section col col-xl-3 col-xxl-3"
            style={{ width: '25%'}}
          >
            <div className="unified-btn mt-2 d-flex justify-content-between align-items-center pb-1">
              {
                left_top_tabs?.map((tab: any, idx: number) => (
                  <button
                    key={idx}
                    className={`${activeTab === tab.key ? "active" : ""}`}
                    onClick={() =>
                      activeTab !== tab.key &&
                      handlePageChange(`${tab.key}=${activePage}`)
                    }
                    type="button"
                  >
                    {tab.title}
                  </button>
                ))
              }
            </div>
            <div className="feeds-section-hover">
              <InfiniteScroll
                dataLength={data?.length || 0}
                className="scrollin"
                scrollThreshold={0.9}
                style={{ overflow: "scroll", paddingRight: 2 }}
                next={() => {
                  console.log(
                    `prev -> ${data?.length}, next -> ${data?.length + 20}`
                  );
                  fetchMoreData();
                }}
                inverse={false}
                hasMore={hasMore}
                loader={null
                  // !isLoading && hasMore && data?.length ? (
                  //   <div className="d-flex justify-content-center align-items-center mt-1">
                  //     <Spinner
                  //       size="sm"
                  //       animation="border"
                  //       variant="primary"
                  //     />
                  //   </div>
                  // ) : null
                }
                scrollableTarget="scrollableDiv"
                // endMessage={
                //   <p style={{ textAlign: "center" }}>
                //     <b>Yay! You have seen it all</b>
                //   </p>
                // }
                // height={"85vh"}
                height={"86vh"}
              >
                <Search
                  parentCallback={(value: any, key: any) =>
                    filterBySearchData(value, key)
                  }
                  is_whats_app_filter_data={true}
                  searchText={sText}
                  setSearchText={setSearchText}
                  setConversationId={setConversationId}
                  activeTab={activeTab}
                  activePage={activePage}
                />

                {/* Infinity Scrolling */}

                {(!data?.length || is_empty_whatsapp_inbox) ? (
                // {(data?.length && !isLoading) || conversationId ? (
                  <div className="empty-feeds-section">
                    <div
                      className="empty-wall-section position-absolute"
                      style={{ top: "35%" }}
                    >
                      {/* {isLoading ? (
                        <div className="d-flex justify-content-center align-items-center mt-1">
                          <Spinner
                            size="sm"
                            animation="border"
                            variant="primary"
                          />
                        </div>
                      ) : (
                        <div>
                          <img src={emptyBox} alt="" />
                          <h4>Empty Feeds</h4>
                        </div>
                      )} */}
                      <div>
                        <img src={emptyBox} alt="" />
                        <h4>Empty Feeds</h4>
                      </div>
                    </div>

                    {/* <img src={imagePath} alt="" />
                      <h4>{feedsEmptyContent}</h4>
                      <Button classes="secondary" children={buttonContent} /> */}
                  </div>
                ) : (
                  data?.map((item: any, index: number) => {
                    return (
                      <div key={index}>
                        <MessagesInboxCard
                          type={type}
                          inbox={item}
                          handleSeeOtherConversation={handleSeeOtherConversation}
                          activePage={activePage}
                          activeTab={activeTab}
                          routePath={route_path}
                          wallData={wallData}
                          setWallData={setWallData}
                        />
                      </div>
                    );
                  })
                )}
              </InfiniteScroll>
            </div>
          </div>
          <div
            className={"wall-section col-xl-6 col-xxl-6"}
            // className={query_string?.includes("conversations") ? "wall-section col-xl-6 col-xxl-6" : "wall-section col-xl-9 col-xxl-9"}
            // md={query_string?.includes("conversations") ? 6 : 9}
            // style={{width: query_string?.includes("conversations") ? '47.3%' : "71.9%"}}
            style={{width: '47.3%'}}
          >
            {/* {data?.length && conversationId ? ( */}
            {data?.length && conversation?.userName ? (
              <div className="conversations h-100">
                <WhatsAppMemberConversations
                  type={type}
                  activePage={activePage}
                  activeTab={activeTab}
                  routePath={route_path}
                  conversationId={conversationId}
                  conversation={conversation}
                  statDetails={stat_details}
                />
              </div>
            ) : (
              <div className="empty-wall-section">
                <div>
                  <img src={emptyBox} alt="" />
                  <h4>Your Wall is Empty.</h4>
                  <span>Select an item from inbox to display!</span>
                </div>
              </div>
            )}
          </div>
          <div className="conversation-right-side col-xl-3 col-xxl-3"
            style={{ width: '25%'}}
          >
            <div className="whats-app-details-container">
              {
              // !isLoading &&
                // conversation && conversationId ? (
                data?.length && stat_details ? (
                  <MessagesDetails type={type} statDetails={stat_details} />
                ) : <div style={{height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                      <img src={emptyBox} alt="" />
                  </div>
              }
            </div>
          </div>
        </div>


        {/* <div className="conversation-wall">
          <button
            onClick={() => {
              `${activePage}` !== "conversations"
                && dispatch(changePage("/unified?all=conversations"));
                // && handlePageChange(conversationId ? `all=conversations` : `all=conversations`)
            }}
            className={`inbox-button${
              (query_string?.includes("conversations")) ? "-active" : ""
            }`}
            type="button"
          >Conversation</button>
          
          <button
            onClick={() => {
              `${activePage}` !== "wall" && handleFindWallUnifiedData()
            }}
            className={`inbox-button${
              (query_string?.includes("wall")) ? "-active" : ""
            }`}
              type="button"
          >Wall</button>

        </div> */}

        {/* old version */}
        {/* <button
          onClick={() => handlePageChange(conversationId ? `${activeTab}=conversations&conv=${conversationId}` : `${activeTab}=conversations`)} 
          className={`inbox-btn${activePage === 'conversations' ? '-active' : ""} mt-2`} type="button"></button>
        <button
          onClick={() => (`${activePage}` === "wall") && handlePageChange(conversationId ? `${activeTab}=conversations` : `${activeTab}=conversations`)} 
          className={`inbox-btn${activePage === 'conversations' ? '-active' : ""} mt-2`} type="button">
        </button> */}
        {/* <div className="conversation-wall ms-0 d-flex flex-column justify-content-start align-items-start mt-1">
          <Tooltip title="Inbox" color="white" style={{ color: 'black !important' }} placement="leftBottom">
            <button
              onClick={() =>
                `${activePage}` === "wall" &&
                handlePageChange(
                  conversationId ? `all=conversations` : `all=conversations`
                )
              }
              className={`inbox-btn${
                query_string?.includes("conversations") ? "-active" : ""
              }`}
              type="button"
            ></button>
          </Tooltip>
          <Tooltip title="Wall" color="white" style={{color: 'black !important'}} placement="leftBottom">
            <button
              onClick={() =>
                `${activePage}` !== "wall" && handleFindWallUnifiedData()
              }
              className={`coder-wall-btn${
                activePage === "wall" ? "-active" : ""
              }`}
              type="button"
              // title="Wall"
            ></button>
          </Tooltip>
        </div> */}
      </div>
    </React.Fragment>
  );
}

export default WhatsAppMessageFeeds;
