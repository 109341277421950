import React, { useEffect, useState } from 'react';
import save_icon from '../../../../assets/icons/settings/save-icon.svg';
import edit_icon from '../../../../assets/icons/settings/edit-icon.svg';
import upload_icon from '../../../../assets/icons/settings/upload-icon.svg';
import './BusinessIdentities.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { convertImageToBase64 } from '../../../../utils/convertingFunction';
import { updateWorkSettings } from '../../workSpaceReducer';
import { getProfileInfo } from '../../../profile-management/profileManageReducer';
import { Spinner } from 'react-bootstrap';
import { setSuccessMessage } from '../../../../dashboard/reducer';
const BusinessIdentities: React.FC = () => {
    const dispatch = useDispatch();
    
    // Business Name
    const [businessName, setBusinessName] = useState<any>(null);
    const [isBusinessNameActive, setIsBusinessNameActive] = useState<boolean>(false);
    const [isBusinessNameError, setIsBusinessNameError] = useState<boolean>(false);

    // Subdomain for customer app
    const [subDomain, setSubDomain] = useState<any>(null);
    const [isSubDomainActive, setIsSubDomainActive] = useState<boolean>(false);
    const [isSubDomainError, setIsSubDomainError] = useState<boolean>(false);

    // Redirect URL for Feedback (Leave blank for default)
    const [feedBackRedirectURL, setFeedBackRedirectURL] = useState<any>(null);
    const [isFeedBackRedirectURLActive, setIsFeedBackRedirectURLActive] = useState<boolean>(false);
    const [isFeedBackRedirectURLError, setIsFeedBackRedirectURLError] = useState<boolean>(false);

    // Redirect URL for Video Testimonial (Leave blank for default)
    const [videoTestimonialRedirectURL, setVideoTestimonialRedirectURL] = useState<any>(null);
    const [isVideoTestimonialRedirectURLActive, setIsVideoTestimonialRedirectURLActive] = useState<boolean>(false);
    const [isVideoTestimonialRedirectURLError, setIsVideoTestimonialRedirectURLError] = useState<boolean>(false);
    const [image, setImage] = useState<any>("");
    const [logo_base_url, setLogoBaseUrl] = useState<any>("");
    const [imageURL, setImageURL] = useState<any>("");
    const { workspaceSettings } = useSelector((state: any) => state?.workspaceManagementState);
    const [loading, setLoading]: any = React.useState(false);
    const { personalInfo } = useSelector((state: any) => state?.profileManageSettingState);
    
    useEffect(() => {
        const {businessName, logo, subDomainName, customerFeedBackRedirectURL, videoTestimonialRedirectURL}: any = workspaceSettings || {};
        setBusinessName(businessName);
        setSubDomain(subDomainName);
        setFeedBackRedirectURL(customerFeedBackRedirectURL);
        setVideoTestimonialRedirectURL(videoTestimonialRedirectURL);
        setImageURL(logo);
    }, [dispatch, workspaceSettings?.businessName, workspaceSettings?.subDomain, workspaceSettings?.customerFeedBackRedirectURL, workspaceSettings?.videoTestimonialRedirectURL, workspaceSettings?.logo]);

    // const {email}: any = personalInfo || {};
    // useEffect(() => {
    //     if (email) {
    //         setTimeout(() => {
    //             setLoading(false);
    //         }, 2000);
    //     } else {
    //         setLoading(true);
    //         dispatch(getProfileInfo());
    //     }
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 5000);
    // }, [dispatch, email]);
    
    const handleUpdateBusinessSettingField = (columnName: any, columnValue: any, setBusinessState: any) => {
        // console.log({columnValue});
        if(!columnValue){
            setBusinessState("error");
        }else {
            const dataObject = {
                columnName,
                columnValue,
                clapupId: sessionStorage.getItem('cuid'),
                workSpaceId: sessionStorage.getItem('wuid'),
            }
            console.log({dataObject});
            dispatch(updateWorkSettings(dataObject));
            // setBusinessState(null);
            dispatch(setSuccessMessage("Settings saved."));
        }
    }

    const handleLogoSubmit = (imageBase64 = logo_base_url) => {
        console.log({imageBase64});
        
        if(imageBase64){
            const dataObject = {
                logo: imageBase64,
                clapupId: sessionStorage.getItem('cuid'),
                workSpaceId: sessionStorage.getItem('wuid'),
            }
            dispatch(updateWorkSettings(dataObject));
            setImage(false);
            setTimeout(() => {
                dispatch(setSuccessMessage("Settings saved."));
            }, 2000);
        }
    }

    const handleImageChange = (e: any) => {
        // file local path
        // const image_path
        const file = e.target.files[0];
        const path = e.target.value;
        console.log({path});
        // setImageFile(file);

        // ======== 1st way to convert image to base64 ========
        convertImageToBase64(file).then((base64Url: any) => {
            // console.log({base64Url});
            setLogoBaseUrl(base64Url);
            // handleLogoSubmit(base64Url);

        })
        .catch((error: any) => {
            console.log({error});
        });

        // ======== 2nd way to convert image to base64 ========
        // const formData = new FormData();
        // const body = {
        //     "code": Number(new Date().getTime() + (1000*1000)),
        //     "AccessKey": "746def78-473d-4b9a-b230976d1e62-6dd3-46de",
        //     "workspaceId": sessionStorage.getItem('wuid'),
        // }
        // formData.append('file', file);
        // formData.append('body', btoa(JSON.stringify(body)));
        // console.log({ formBody: body });
        // axios((auth_url) + "/api/v1/upload-workspace-image", {
        //     method: 'POST',
        //     data: formData,
        // }).then(response => {
        //     console.log({response});
        //     // setImageURL(response?.data?.data?.url);
        // }).catch(error => {
        //     console.log({error});
        // });
        
    }

    const {userPermissions} = useSelector((state: any) => state?.login);

    const history = useNavigate();

    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "businessIdentities") : null;
        if(permission){
            permission?.value === 0 && history('/not-found');
        }
    }, [userPermissions?.length]);

    // console.log({ imageURL });
    
    return (
        <React.Fragment>
            <div className='lg-sub-menu h-100 p-0' style={{alignItems: 'flex-start'}}>
                {
                    loading
                    ? <div className='loading-container w-100 h-100 d-flex justify-content-center align-items-center'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                    : <div className='workspace-menu-container'>
                        <div className="form-container h-100 business-identities-settings-gap">

                            {/* Business Name */}
                            <div className="form-group">
                                <label>Business Name</label>
                                <div className='input-container'>
                                    {
                                        isBusinessNameActive
                                        ? <input  
                                            defaultValue={businessName}
                                            readOnly={!isBusinessNameActive}
                                            autoFocus={true}
                                            onChange={(e: any) => {
                                                const { value } = e.target;
                                                if (value) {
                                                    setBusinessName(value);
                                                    setIsBusinessNameError(false);
                                                } else {
                                                    setBusinessName("");
                                                    setIsBusinessNameError(true);
                                                }
                                            }}
                                            type="text" 
                                            placeholder="Business Name"
                                            className={`${isBusinessNameError ? "input-error" : ""}`}
                                        />
                                        : <span className='input-value'>{businessName}</span>
                                    }
                                    
                                    <img
                                        src={isBusinessNameActive ? save_icon : edit_icon}
                                        className={`add-edit-save-icon ${isBusinessNameError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isBusinessNameError) {
                                                if (isBusinessNameActive && businessName) {
                                                    setIsBusinessNameActive(false);
                                                    handleUpdateBusinessSettingField("BusinessName", businessName, setBusinessName)
                                                } else {
                                                    setIsBusinessNameActive(true);
                                                }
                                            }
                                        }}
                                        alt='save-edit'
                                    />
                                </div>
                            </div>
                            
                            {/* Business Logo {"( < 25KB)"} */}
                            <div className="form-group">
                                <label>Business Logo {"( < 25KB)"}</label>
                                <div className='input-container'>
                                    <div className='image-label'>
                                        {
                                            imageURL
                                            ? <img src={imageURL} 
                                                alt={"logo"} 
                                                className='rounded-circle' 
                                            /> 
                                            : image 
                                            ? <span>Logo.png</span>
                                            : <img className='rounded-circle' src={workspaceSettings?.logo} alt='logo' /> 
                                        }                                        
                                    </div> 
                                    {
                                        image
                                        ? <input
                                            // disabled={image ? false : true}
                                            id="image" type="file"
                                            accept='*'
                                            // accept='image/png, image/jpg, image/jpeg, image/svg'
                                        
                                            onChange={(e: any) => {
                                                // console.log({ img: e?.target?.files[0] });
                                                setImageURL(URL.createObjectURL(e?.target?.files[0]));
                                                setImage(e?.target?.files[0]?.name);
                                                handleImageChange(e); 
                                                // if (e?.target?.files[0]?.size < 25000) {
                                                // }

                                            }}
                                            className="form-input-field d-none w-100 my-1"
                                            placeholder="Business Image"
                                        />
                                        : null
                                    }
                                    
                                    {
                                        image
                                        ? <label className='mx-2' htmlFor="image">
                                            <img
                                                src={imageURL ? save_icon : upload_icon}
                                                className={`add-edit-save-icon`}
                                                onClick={() => {
                                                    // setImage(false);
                                                    // imageURL && setImageURL('');
                                                    if (imageURL) {
                                                        handleLogoSubmit();
                                                    }
                                                }}
                                                alt='save-edit'
                                            />
                                        </label>
                                        : <img
                                            src={edit_icon}
                                            className={`add-edit-save-icon`}
                                            onClick={() => {
                                                setImage(true);
                                                setImageURL('');
                                                if (image && imageURL) {
                                                    handleLogoSubmit();
                                                }
                                            }}
                                            alt='save-edit'
                                        />
                                    }
                                </div>
                            </div>
                            
                            {/* Subdomain for customer app */}
                            <div className="form-group">
                                <label>Subdomain for customer app</label>
                                <div className='input-container'>
                                    {
                                        isSubDomainActive
                                        ? <input  
                                            defaultValue={subDomain}
                                            readOnly={!isSubDomainActive}
                                            autoFocus={true}
                                            onChange={(e: any) => {
                                                const { value } = e.target;
                                                if (value) {
                                                    setSubDomain(value);
                                                    setIsSubDomainError(false);
                                                } else {
                                                    setSubDomain("");
                                                    setIsSubDomainError(true);
                                                }
                                            }}
                                            type="text" 
                                            placeholder="Subdomain"
                                            className={`${isSubDomainError ? "input-error" : ""}`}
                                        />
                                        : <span className='input-value'>{subDomain || "biopage.clapup.io"}</span>
                                    }
                                    
                                    {/* <img
                                        src={isSubDomainActive ? save_icon : edit_icon}
                                        className={`add-edit-save-icon ${isSubDomainError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isSubDomainError) {
                                                if (isSubDomainActive && subDomain) {
                                                    setIsSubDomainActive(false);
                                                    handleUpdateBusinessSettingField("SubDomainName", subDomain, setSubDomain)
                                                } else {
                                                    setIsSubDomainActive(true);
                                                }
                                            }
                                        }}
                                        alt='save-edit'
                                    /> */}
                                </div>
                            </div>
                            
                            {/* Redirect URL for Feedback */}
                            <div className="form-group">
                                <label>Redirect URL for Feedback</label>
                                <div className='input-container'>
                                    {
                                        isFeedBackRedirectURLActive
                                            ? <input
                                                defaultValue={feedBackRedirectURL || workspaceSettings?.customerFeedBackRedirectURL || ""}
                                                readOnly={!isFeedBackRedirectURLActive}
                                                autoFocus={true}
                                                onChange={(e: any) => {
                                                    const { value } = e.target;
                                                    if (value) {
                                                        setFeedBackRedirectURL(value);
                                                        setIsFeedBackRedirectURLError(false);
                                                    } else {
                                                        setFeedBackRedirectURL("");
                                                        setIsFeedBackRedirectURLError(true);
                                                    }
                                                }}
                                                type="text"
                                                placeholder="Redirect URL for Feedback"
                                                className={`${isFeedBackRedirectURLError ? "input-error" : ""}`}
                                            />
                                            : <span className='input-value'>{feedBackRedirectURL || workspaceSettings?.customerFeedBackRedirectURL || ""}</span>
                                    }
                                    <img
                                        src={isFeedBackRedirectURLActive ? save_icon : edit_icon}
                                        className={`add-edit-save-icon ${isFeedBackRedirectURLError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isFeedBackRedirectURLError) {
                                                if (isFeedBackRedirectURLActive && feedBackRedirectURL) {
                                                    setIsFeedBackRedirectURLActive(false);
                                                    handleUpdateBusinessSettingField("FeedBackRedirectURL", feedBackRedirectURL === true ? workspaceSettings?.customerFeedBackRedirectURL : feedBackRedirectURL, setFeedBackRedirectURL);
                                                } else {
                                                    setIsFeedBackRedirectURLActive(true);
                                                }
                                            }
                                        }}
                                        alt='save-edit'
                                    />
                                </div>
                            </div>          

                            {/* Redirect URL for Video Testimonial */}
                            <div className="form-group">
                                <label>Redirect URL for Video Testimonial</label>
                                <div className='input-container'>
                                    {
                                        isVideoTestimonialRedirectURLActive
                                        ? <input
                                            defaultValue={videoTestimonialRedirectURL || workspaceSettings?.videoTestimonialRedirectURL || ""}
                                            readOnly={!isVideoTestimonialRedirectURLActive}
                                            autoFocus={true}
                                            onChange={(e: any) => {
                                                const { value } = e.target;
                                                if (value) {
                                                    setVideoTestimonialRedirectURL(value);
                                                    setIsVideoTestimonialRedirectURLError(false);
                                                } else {
                                                    setVideoTestimonialRedirectURL("");
                                                    setIsVideoTestimonialRedirectURLError(true);
                                                }
                                            }}
                                            type="text"
                                            placeholder="Redirect URL for Video Testimonial"
                                            className={`${isVideoTestimonialRedirectURLError ? "input-error" : ""}`}
                                        />
                                        : <span className='input-value'>{videoTestimonialRedirectURL || workspaceSettings?.videoTestimonialRedirectURL || ""}</span>
                                    }
                                    <img
                                        src={isVideoTestimonialRedirectURLActive ? save_icon : edit_icon}
                                        className={`add-edit-save-icon ${isVideoTestimonialRedirectURLError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isVideoTestimonialRedirectURLError) {
                                                if (isVideoTestimonialRedirectURLActive && videoTestimonialRedirectURL) {
                                                    setIsVideoTestimonialRedirectURLActive(false);
                                                    handleUpdateBusinessSettingField("VideoTestimonialRedirectURL", videoTestimonialRedirectURL === true ? workspaceSettings?.videoTestimonialRedirectURL : videoTestimonialRedirectURL, setVideoTestimonialRedirectURL);
                                                } else {
                                                    setIsVideoTestimonialRedirectURLActive(true);
                                                }
                                            }
                                        }}
                                            alt='save-edit'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>

            <div className='mobile-sub-menu w-100'>
                {
                    loading
                    ? <div className='loading-container w-100 d-flex justify-content-center align-items-center'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                    : <div className='workspace-menu-container'>
                        <div className="form-container h-100 business-identities-settings-gap">

                            {/* Business Name */}
                            <div className="form-group">
                                <label>Business Name</label>
                                <div className='input-container'>
                                    {
                                        isBusinessNameActive
                                        ? <input  
                                            defaultValue={businessName}
                                            readOnly={!isBusinessNameActive}
                                            autoFocus={true}
                                            onChange={(e: any) => {
                                                const { value } = e.target;
                                                if (value) {
                                                    setBusinessName(value);
                                                    setIsBusinessNameError(false);
                                                } else {
                                                    setBusinessName("");
                                                    setIsBusinessNameError(true);
                                                }
                                            }}
                                            type="text" 
                                            placeholder="Business Name"
                                            className={`${isBusinessNameError ? "input-error" : ""}`}
                                        />
                                        : <span className='input-value'>{businessName}</span>
                                    }
                                    
                                    <img
                                        src={isBusinessNameActive ? save_icon : edit_icon}
                                        className={`add-edit-save-icon ${isBusinessNameError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isBusinessNameError) {
                                                if (isBusinessNameActive && businessName) {
                                                    setIsBusinessNameActive(false);
                                                    handleUpdateBusinessSettingField("BusinessName", businessName, setBusinessName)
                                                } else {
                                                    setIsBusinessNameActive(true);
                                                }
                                            }
                                        }}
                                        alt='save-edit'
                                    />
                                </div>
                            </div>
                            
                            {/* Business Logo {"( < 25KB)"} */}
                            <div className="form-group">
                                <label>Business Logo {"( < 25KB)"}</label>
                                <div className='input-container'>
                                    <div className='image-label'>
                                        {
                                            // (imageURL || workspaceSettings?.logo) && image !== true
                                            
                                            imageURL
                                            ? <img src={imageURL} 
                                                alt={"logo"} 
                                                className='rounded-circle' 
                                            /> 
                                            : image 
                                            ? <span>Logo.png</span>
                                            : <img className='rounded-circle' src={workspaceSettings?.logo} alt='logo' /> 
                                        }                                        
                                    </div> 
                                    {
                                        image
                                        ? <input
                                            // disabled={image ? false : true}
                                            id="image" type="file"
                                            accept='*'
                                            // accept='image/png, image/jpg, image/jpeg, image/svg'
                                        
                                            onChange={(e: any) => {
                                                console.log({ img: e?.target?.files[0] });
                                                setImageURL(URL.createObjectURL(e?.target?.files[0]));
                                                setImage(e?.target?.files[0]?.name);
                                                handleImageChange(e); 
                                                // if (e?.target?.files[0]?.size < 25000) {
                                                // }

                                            }}
                                            className="form-input-field d-none w-100 my-1"
                                            placeholder="Business Image"
                                        />
                                        : null
                                    }
                                    
                                    {
                                        image
                                        ? <label className='mx-2' htmlFor="image">
                                            <img
                                                src={imageURL ? save_icon : upload_icon}
                                                className={`add-edit-save-icon`}
                                                onClick={() => {
                                                    // setImage(false);
                                                    // imageURL && setImageURL('');
                                                    if (imageURL) {
                                                        handleLogoSubmit();
                                                    }
                                                }}
                                                alt='save-edit'
                                            />
                                        </label>
                                        : <img
                                            src={edit_icon}
                                            className={`add-edit-save-icon`}
                                            onClick={() => {
                                                setImage(true);
                                                setImageURL('');
                                                if (image && imageURL) {
                                                    handleLogoSubmit();
                                                }
                                            }}
                                            alt='save-edit'
                                        />
                                    }
                                </div>
                            </div>
                            
                            {/* Subdomain for customer app */}
                            <div className="form-group">
                                <label>Subdomain for customer app</label>
                                <div className='input-container'>
                                    {
                                        isSubDomainActive
                                        ? <input  
                                            defaultValue={subDomain}
                                            readOnly={!isSubDomainActive}
                                            autoFocus={true}
                                            onChange={(e: any) => {
                                                const { value } = e.target;
                                                if (value) {
                                                    setSubDomain(value);
                                                    setIsSubDomainError(false);
                                                } else {
                                                    setSubDomain("");
                                                    setIsSubDomainError(true);
                                                }
                                            }}
                                            type="text" 
                                            placeholder="Subdomain"
                                            className={`${isSubDomainError ? "input-error" : ""}`}
                                        />
                                        : <span className='input-value'>{subDomain || "biopage.clapup.io"}</span>
                                    }
                                    
                                    {/* <img
                                        src={isSubDomainActive ? save_icon : edit_icon}
                                        className={`add-edit-save-icon ${isSubDomainError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isSubDomainError) {
                                                if (isSubDomainActive && subDomain) {
                                                    setIsSubDomainActive(false);
                                                    handleUpdateBusinessSettingField("SubDomainName", subDomain, setSubDomain)
                                                } else {
                                                    setIsSubDomainActive(true);
                                                }
                                            }
                                        }}
                                        alt='save-edit'
                                    /> */}
                                </div>
                            </div>
                            
                            {/* Redirect URL for Feedback */}
                            <div className="form-group">
                                <label>Redirect URL for Feedback</label>
                                <div className='input-container'>
                                    {
                                        isFeedBackRedirectURLActive
                                            ? <input
                                                defaultValue={feedBackRedirectURL || workspaceSettings?.customerFeedBackRedirectURL || ""}
                                                readOnly={!isFeedBackRedirectURLActive}
                                                autoFocus={true}
                                                onChange={(e: any) => {
                                                    const { value } = e.target;
                                                    if (value) {
                                                        setFeedBackRedirectURL(value);
                                                        setIsFeedBackRedirectURLError(false);
                                                    } else {
                                                        setFeedBackRedirectURL("");
                                                        setIsFeedBackRedirectURLError(true);
                                                    }
                                                }}
                                                type="text"
                                                placeholder="Redirect URL for Feedback"
                                                className={`${isFeedBackRedirectURLError ? "input-error" : ""}`}
                                            />
                                            : <span className='input-value'>{feedBackRedirectURL || workspaceSettings?.customerFeedBackRedirectURL || ""}</span>
                                    }
                                    <img
                                        src={isFeedBackRedirectURLActive ? save_icon : edit_icon}
                                        className={`add-edit-save-icon ${isFeedBackRedirectURLError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isFeedBackRedirectURLError) {
                                                if (isFeedBackRedirectURLActive && feedBackRedirectURL) {
                                                    setIsFeedBackRedirectURLActive(false);
                                                    handleUpdateBusinessSettingField("FeedBackRedirectURL", feedBackRedirectURL === true ? workspaceSettings?.customerFeedBackRedirectURL : feedBackRedirectURL, setFeedBackRedirectURL);
                                                } else {
                                                    setIsFeedBackRedirectURLActive(true);
                                                }
                                            }
                                        }}
                                        alt='save-edit'
                                    />
                                </div>
                            </div>          

                            {/* Redirect URL for Video Testimonial */}
                            <div className="form-group">
                                <label>Redirect URL for Video Testimonial</label>
                                <div className='input-container'>
                                    {
                                        isVideoTestimonialRedirectURLActive
                                        ? <input
                                            defaultValue={videoTestimonialRedirectURL || workspaceSettings?.videoTestimonialRedirectURL || ""}
                                            readOnly={!isVideoTestimonialRedirectURLActive}
                                            autoFocus={true}
                                            onChange={(e: any) => {
                                                const { value } = e.target;
                                                if (value) {
                                                    setVideoTestimonialRedirectURL(value);
                                                    setIsVideoTestimonialRedirectURLError(false);
                                                } else {
                                                    setVideoTestimonialRedirectURL("");
                                                    setIsVideoTestimonialRedirectURLError(true);
                                                }
                                            }}
                                            type="text"
                                            placeholder="Redirect URL for Video Testimonial"
                                            className={`${isVideoTestimonialRedirectURLError ? "input-error" : ""}`}
                                        />
                                        : <span className='input-value'>{videoTestimonialRedirectURL || workspaceSettings?.videoTestimonialRedirectURL || ""}</span>
                                    }
                                    <img
                                        src={isVideoTestimonialRedirectURLActive ? save_icon : edit_icon}
                                        className={`add-edit-save-icon ${isVideoTestimonialRedirectURLError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isVideoTestimonialRedirectURLError) {
                                                if (isVideoTestimonialRedirectURLActive && videoTestimonialRedirectURL) {
                                                    setIsVideoTestimonialRedirectURLActive(false);
                                                    handleUpdateBusinessSettingField("VideoTestimonialRedirectURL", videoTestimonialRedirectURL === true ? workspaceSettings?.videoTestimonialRedirectURL : videoTestimonialRedirectURL, setVideoTestimonialRedirectURL);
                                                } else {
                                                    setIsVideoTestimonialRedirectURLActive(true);
                                                }
                                            }
                                        }}
                                            alt='save-edit'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            
            {/* <div className='border-wrapper'>
                <label>Business Name</label>
                <div className='form-group border-custom'>
                    <input
                        defaultValue={workspaceSettings?.businessName || ""}
                        readOnly={(businessName || businessName === '') ? false :  true}
                        onChange={(e: any) => {
                            setBusinessName(e?.target?.value);
                        }}
                        name="BusinessName" 
                        type="text" className="form-input-field w-100 my-2" 
                        placeholder="Business Name" 
                    />
                    <button 
                        onClick={() => {
                            setBusinessName(true);
                            if(businessName){
                                // handleUpdateBusinessSettingField("BusinessName", businessName, setBusinessName);
                                handleUpdateBusinessSettingField("BusinessName", businessName === true ? workspaceSettings?.businessName : businessName, setBusinessName)
                            }

                        }}
                        disabled={businessName === 'error' || businessName === ''}
                        type="button"
                    >
                        {
                            (businessName || businessName === '') ? "Save" : "Edit"
                        }
                    </button>
                    {
                        (businessName === "error" || businessName === "") && <p className='text-danger'>Your business name is required</p>
                    }
                </div>
            </div> */}
            
            {/* Business Image {"( < 25KB)"} */}
            {/* <div className='border-wrapper'>
                <label>Business Image {"( < 25KB)"}</label>
                <div className='form-group border-custom'>
                    <div className='w-100 image-label my-2'>
                        <label className='mx-2' htmlFor="image">
                            <span className='d-block rounded px-2 text-success w-100 mx-auto border p-1 position-relative' style={{cursor: 'pointer', maxHeight: '85%', top: 5, left: 10, overflow: 'hidden'}}>
                                {
                                    (imageURL || workspaceSettings?.logo) && image !== true
                                    ? <img src={imageURL || workspaceSettings?.logo} 
                                        style={{height: 30, width: 30}} 
                                        alt={image || "image"} 
                                        className='img-fluid rounded-circle' 
                                      /> 
                                    : (imageURL && workspaceSettings?.logo)? <img src={imageURL || workspaceSettings?.logo} style={{height: 40, width: 40}} alt={"image"} className='img-fluid' /> 
                                    : <span style={{lineHeight: "1.8rem"}}>Upload Image</span>
                                }
                            </span>
                        </label>
                    </div>
                    <input disabled={image ? false : true}
                        id="image" type="file"
                        accept='image/png, image/jpg, image/jpeg, image/svg'
                        onChange={(e: any) => {
                        // console.log({img: e?.target?.files[0]});
                        setImage(e?.target?.files[0]?.name);
                        handleImageChange(e); 

                    }} className="form-input-field d-none w-100 my-1" placeholder="Business Image" />
                    <button 
                        onClick={() => {
                            setImage(true);
                            !image && setImageURL(null);
                            if(image && imageURL){
                                handleLogoSubmit(imageURL);
                            }
                        }}
                        className="special-img-btn"
                        // disabled={image === ''}
                        type="button"
                    >
                        {(image) ? "Save" : "Edit"}
                    </button>
                </div>
            </div> */}
            
            {/* Redirect URL for Feedback (Leave blank for default) */}
            {/* <div className='border-wrapper'>
                <label>Redirect URL for Feedback (Leave blank for default)</label>
                <div className='form-group border-custom'>
                    <input 
                        defaultValue={workspaceSettings?.customerFeedBackRedirectURL || ""}
                        readOnly={(feedBackRedirectURL || feedBackRedirectURL === '') ? false :  true}
                        onChange={(e: any) => {
                            setFeedBackRedirectURL(e?.target?.value);
                        } }
                        name="feedBackRedirectURL" 
                        type="text" className="form-input-field w-100 my-2" 
                        placeholder="Redirect URL for Feedback"
                    />
                    <button 
                        onClick={() => {
                            setFeedBackRedirectURL(true);
                            if(feedBackRedirectURL){
                                handleUpdateBusinessSettingField("FeedBackRedirectURL", feedBackRedirectURL === true ? workspaceSettings?.customerFeedBackRedirectURL : feedBackRedirectURL, setFeedBackRedirectURL);
                            }
                        }}
                        disabled={feedBackRedirectURL === 'error' || feedBackRedirectURL === ''}
                        type="button"
                    >
                        {
                            (feedBackRedirectURL || feedBackRedirectURL === "") ? "Save" : "Edit"
                        }
                    </button>
                    {
                        (feedBackRedirectURL === "error" || feedBackRedirectURL === "") && <p className='text-danger'>Your customer feedback url is required</p>
                    }
                </div>
            </div> */}
            
            {/* Redirect URL for Video Testimonial (Leave blank for default) */}
            {/* <div className='border-wrapper'>
                <label>Redirect URL for Video Testimonial (Leave blank for default)</label>
                <div className='form-group border-custom'>
                    <input
                        defaultValue={workspaceSettings?.videoTestimonialRedirectURL || ""}
                        readOnly={(videoTestimonialRedirectURL || videoTestimonialRedirectURL === '') ? false :  true}
                        onChange={(e: any) => {
                            setVideoTestimonialRedirectURL(e?.target?.value);
                        }}
                        type="text" 
                        className="form-input-field w-100 mt-1" 
                        placeholder="URL" 
                    />
                    <button
                        onClick={() => {
                            setVideoTestimonialRedirectURL(true);
                            if(videoTestimonialRedirectURL){
                                handleUpdateBusinessSettingField("VideoTestimonialRedirectURL", videoTestimonialRedirectURL === true ? workspaceSettings?.videoTestimonialRedirectURL : videoTestimonialRedirectURL, setVideoTestimonialRedirectURL);
                            }
                        }}
                        disabled={videoTestimonialRedirectURL === 'error' || videoTestimonialRedirectURL === ''}
                        type="button"
                    >
                        {
                            (videoTestimonialRedirectURL || videoTestimonialRedirectURL === "") ? "Save" : "Edit"
                        }
                    </button>
                    {
                        (videoTestimonialRedirectURL === "error" || videoTestimonialRedirectURL === "") && <p className='text-danger'>Your video testimonial url is required</p>
                    }
                </div>
            </div> */}
        </React.Fragment>
    );
};

export default BusinessIdentities;