import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import save_icon from '../../../../assets/icons/settings/save-icon.svg';
import edit_icon from '../../../../assets/icons/settings/edit-icon.svg';
import upload_icon from '../../../../assets/icons/settings/upload-icon.svg';
import preview_icon from '../../../../assets/icons/settings/biopage/preview.svg';
import { useNavigate } from 'react-router';
import { requestForSelectingBioPageTemplate, toggleSelectedTemplate, updateBioPageSettings } from '../../biopageReducer';
// import { getWorkspaceSettings } from '../../../workspace-management/workSpaceReducer';
// import { getProfileInfo } from '../../../profile-management/profileManageReducer';
// import { getBioPageSettings } from from '../../biopageReducer';
import { Spinner } from 'react-bootstrap';
import { setSuccessMessage } from '../../../../dashboard/reducer';
// import { convertImageToBase64 } from '../../../../utils/convertingFunction';
import { uploadIconFileData } from '../../saga';
import { setErrorMessage } from '../../../user-management/userManagementReducer';
import { modifyString } from '../../../../utils/mediaService';
// import Dialog from '../../../../components/dialogs/dialog';
import CommonModalCustomWidth from '../../../../components/CommonModal/CommonModalCustomWidth';
import TemplateSelection from './BiopageTemplates/TemplateSelection';
import './GeneralSettings.scss';

const GeneralSettings: React.FC = () => {
    // Bio page Brand Name
    const [brandName, setBrandName] = useState<any>(null);
    const [isBrandNameActive, setIsBrandNameActive] = useState<any>(false);
    const [isBrandNameError, setIsBrandNameError] = useState<any>(false);

    // Bio page Logo
    const [image, setImage] = useState<any>("");
    const [imageData, setImageData] = useState<any>(null);
    const [imageURL, setImageURL] = useState<any>("");

    // Bio page SEO Title
    const [seoTitle, setSeoTitle] = useState<any>(null);
    const [isSeoTitleActive, setIsSeoTitleActive] = useState<any>(false);
    const [isSeoTitleError, setIsSeoTitleError] = useState<any>(false);

    // Bio page template name
    const [isReadyToSelectTemplate, setIsReadyToSelectTemplate] = useState<boolean>(false);
    
    // Bio page Meta Description
    const [metaDescription, setMetaDescription] = useState<any>(null);
    const [isMetaDescriptionActive, setIsMetaDescriptionActive] = useState<any>(false);
    const [isMetaDescriptionError, setIsMetaDescriptionError] = useState<any>(false);

    // Bio page Site Description
    const [siteDescription, setSiteDescription] = useState<any>(null);
    const [isSiteDescriptionActive, setIsSiteDescriptionActive] = useState<any>(false);
    const [isSiteDescriptionError, setIsSiteDescriptionError] = useState<any>(false);

    // const { workspaceSettings } = useSelector((state: any) => state?.workspaceManagementState);
    const { GeneralSettings, isLoading, is_selected_bio_page_template, bioPageTemplates } = useSelector((state: any) => state?.bioPageSettingState);
    const [loading, setLoading]: any = React.useState(false);
    const dispatch = useDispatch();
    // const {personalInfo} = useSelector((state: any) => state?.profileManageSettingState);
    // const {email}: any = personalInfo || {};
    // useEffect(() => {
    //     if (email) {
    //         setTimeout(() => {
    //             setLoading(false);
    //         }, 2000);
    //     } else {
    //         setLoading(true);
    //         dispatch(getProfileInfo());
    //     }
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 1000);
    // }, [dispatch, email]);

    // useEffect(() => {
    //     console.info("GeneralSettings ===> ", GeneralSettings);

        // if(GeneralSettings){
        //     setLoading(false);
        // } else {
        //     setLoading(true);
        // }

        // setTimeout(() => {
        //     setLoading(false);
        // }, 1500);
        // GeneralSettings?.map((item: any) => {
        //     if(item?.linkfield.includes("Enable_Disable")){
        //         setEnableDisable(item?.linkfieldvalue);
        //     }
        //     if(item?.linkfield === "Description"){
        //         setDescription(item?.linkfieldvalue);
        //     }
        // });
        
    // }, [dispatch, isLoading, GeneralSettings]);
    

    const handleUpdateBioPageSettingField = (columnName: any, columnValue: any, linktype: any, setState?: any) => {
        // console.log({columnValue});
        if(!columnValue){
            return setState("error");
        }
        const dataObject = {
            columnName,
            columnValue,
            clapupId: sessionStorage.getItem('cuid'),
            workSpaceId: sessionStorage.getItem('wuid'),
            roleId: sessionStorage.getItem('roleId'),
            linktype: "BiopageGeneralSettings"
        }
        console.log({dataObject});
        dispatch(updateBioPageSettings(dataObject));
        // callBack && callBack();
        dispatch(setSuccessMessage("Settings saved"));
    }

    // Biopage Logo Management

    const handleLogoSubmit = async (imageBase64 = '') => {
        // console.log({imageBase64, imageData});
        
        // if(imageURL){
        //     // const dataObject = {
        //     //     logo: imageBase64,
        //     //     clapupId: sessionStorage.getItem('cuid'),
        //     //     workSpaceId: sessionStorage.getItem('wuid'),
        //     // }
        //     // // dispatch(updateWorkSettings(dataObject));
        // }
        const { data, success, message, iconUrl } = await uploadIconFileData({ contentFile: imageData, contentType: "BiopageLogo" });


        // console.log("image-response ===> ", {data, success, message, iconUrl});
        if (iconUrl) {
            dispatch(setSuccessMessage("Logo Saved"));
            setImageURL(iconUrl)
            setImage(null);
        } else {
            dispatch(setErrorMessage("Server Error"));
            setImage(null);
        }
    }

    const handleImageChange = (e: any) => {
        // file local path
        // const image_path
        const file = e.target.files[0];
        const path = e.target.value;
        // console.log({file});
        
        // ======== 1st way to convert image to base64 ========
        setImageData(file);
        
        // ======== 2nd way to convert image to base64 ========
        // convertImageToBase64(file).then((base64Url: any) => {
        //     // console.log({base64Url});
        //     // setImageURL(base64Url);
        //     setLogoBaseUrl(base64Url);

        // })
        // .catch((error: any) => {
        //     console.log({error});
        // });
        // ======== 3rd way to convert image to base64 ========
        // const formData = new FormData();
        // const body = {
        //     "code": Number(new Date().getTime() + (1000*1000)),
        //     "AccessKey": "746def78-473d-4b9a-b230976d1e62-6dd3-46de",
        //     "workspaceId": sessionStorage.getItem('wuid'),
        // }
        // formData.append('file', file);
        // formData.append('body', btoa(JSON.stringify(body)));
        // console.log({ formBody: body });
        // axios((auth_url) + "/api/v1/upload-workspace-image", {
        //     method: 'POST',
        //     data: formData,
        // }).then(response => {
        //     console.log({response});
        //     // setImageURL(response?.data?.data?.url);
        // }).catch(error => {
        //     console.log({error});
        // });
        
    }

    const {userPermissions} = useSelector((state: any) => state?.login);
    const history = useNavigate();
    
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "generansettings-bioPage") : null;
        if(!permission){
            permission?.value === 0 && history('/not-found');
        }
    }, [userPermissions?.length]);
    
    // console.log({imageURL});
    const [isCopied, setIsCopied] = useState<any>("");
    const handleCopyUrl = (url: string) => { 
        setIsCopied(url);
        navigator.clipboard.writeText(url);
        setTimeout(() => {
            setIsCopied("");
        }, 2000);
    };

    const handleSubmitSelectedTemplate = (data?: any) => {
        console.log({ data });
        dispatch(requestForSelectingBioPageTemplate(data?.id));
    }
    
    React.useEffect(() => { 
        if (is_selected_bio_page_template) {
            dispatch(toggleSelectedTemplate(false));
            setIsReadyToSelectTemplate(!isReadyToSelectTemplate);
            dispatch(setSuccessMessage("Settings saved"));
            handleCancel();        
        }
    }, [is_selected_bio_page_template]);

    const handleCancel = () => {
        setIsReadyToSelectTemplate(false);
    }

    let current_default_template = bioPageTemplates?.find((item: any) => (item?.isCurrentTemplate));
    if (!current_default_template) {
        current_default_template = bioPageTemplates?.find((item: any) => (item?.isDefault));
    }
    return (
        <React.Fragment>
            <CommonModalCustomWidth 
                modal_visible={isReadyToSelectTemplate} 
                setModalVisible={setIsReadyToSelectTemplate}
                centered={true}
                // okText="Send"
                // cancelText="Cancel"
                class_name="modal_container"
                footer={null}
            >
                <TemplateSelection isReadyToSelectTemplate={isReadyToSelectTemplate} handleCancel={handleCancel} handleSubmitSelectedTemplate={handleSubmitSelectedTemplate}/>
            </CommonModalCustomWidth>
            <div className='lg-sub-menu h-100 p-0' style={{alignItems: 'flex-start'}}>
                {
                    loading
                    ? <div className='loading-container w-100 h-100 d-flex justify-content-center align-items-center'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                    : <div className='bio-page-menu-container'>
                        <div className="form-container general-settings-gap">

                            {/* Brand Name */}
                            <div className="form-group">
                                <label>Biopage Brand Name</label>
                                <div className='input-container'>
                                    {
                                        isBrandNameActive
                                        ? <input  
                                            defaultValue={brandName || GeneralSettings?.bioPageBrandName || ""}
                                            readOnly={!isBrandNameActive}
                                            autoFocus={true}
                                            onChange={(e: any) => {
                                                const { value } = e.target;
                                                if (value) {
                                                    setBrandName(value);
                                                    setIsBrandNameError(false);
                                                } else {
                                                    setBrandName("");
                                                    setIsBrandNameError(true);
                                                }
                                            }}
                                            type="text" 
                                            placeholder="Biopage Brand Name"
                                            className={`${((isBrandNameError ||!GeneralSettings?.bioPageBrandName) && isBrandNameActive) ? "input-error" : ""}`}
                                        />
                                        : <span className='input-value'>{brandName || GeneralSettings?.bioPageBrandName || ""}</span>
                                    }
                                    <img
                                        src={isBrandNameActive ? save_icon : edit_icon}
                                        alt="edit-icon"
                                        className={`add-edit-save-icon ${isBrandNameError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isBrandNameError) {
                                                if(isBrandNameActive && brandName){
                                                    // console.log("Brand Name is active");
                                                    setIsBrandNameActive(false);
                                                    handleUpdateBioPageSettingField("bioPageBrandName", brandName, GeneralSettings?.bioPageLinkType, setBrandName);
                                                    // handleUpdateBioPageSettingField("BioPageBrandName", brandName, GeneralSettings?.bioPageLinkType, setBrandName);
                                                } else {
                                                    setIsBrandNameActive(true);
                                                }
                                            }
                                        }}
                                    />
                                    
                                </div>
                            </div>
                            
                            {/* Biopage Logo {"( < 25KB)"} */}
                            <div className="form-group">
                                <label>Biopage Logo {"( < 25KB)"}</label>
                                <div className='input-container'>
                                    <div className='image-label'>
                                        {
                                            imageURL
                                            ? <img src={imageURL?.replaceAll("@", "/")} 
                                                alt={"logo"} 
                                                className='rounded-circle' 
                                            /> 
                                            : image 
                                            ? <span>Logo.png</span>
                                            : <img className='rounded-circle' src={GeneralSettings?.logoUrl?.replaceAll("@", "/")} alt='logo' /> 
                                        }                                      
                                    </div>
                                    {
                                        image
                                        ? <input 
                                            // disabled={image ? false : true}
                                            id="image" type="file"
                                            accept='image/*'
                                            // accept='image/png, image/jpg, image/jpeg, image/svg'
                                            onChange={(e: any) => {
                                                // console.log({img: e?.target?.files[0]});
                                                setImageURL(URL.createObjectURL(e?.target?.files[0]));
                                                setImage(e?.target?.files[0]?.name);
                                                handleImageChange(e); 

                                            }} 
                                            className="form-input-field d-none w-100 my-1" placeholder="Biopage Image"
                                        />
                                        : null
                                    }
                                    
                                    {
                                        image
                                        ? imageURL
                                            ?   <img
                                                    src={save_icon}
                                                    className={`add-edit-save-icon`}
                                                    onClick={() => {
                                                        handleLogoSubmit();
                                                    }}
                                                    alt='save-edit'
                                                />
                                            :   <label className='mx-2' htmlFor="image">
                                                    <img
                                                        src={upload_icon}
                                                        className={`add-edit-save-icon`}
                                                        alt='save-edit'
                                                    />
                                            </label>
                                        : <img
                                            src={edit_icon}
                                            className={`add-edit-save-icon`}
                                            onClick={() => {
                                                setImage(true);
                                                setImageURL('');
                                                // if (image && imageURL) {
                                                //     handleLogoSubmit();
                                                // }
                                            }}
                                            alt='save-edit'
                                        />
                                    }
                                </div>
                            </div>

                            {/* Select Template for Biopage */}
                            <div className="form-group">
                                <label>Biopage Template</label>
                                <div className='input-container'>
                                    <span className='input-value'>{current_default_template?.templateName || "Default Template"}</span>
                                    <img
                                        src={preview_icon}
                                        alt="preview-icon"
                                        // className={`add-edit-save-icon ${isBrandNameError ? "disabled" : ""}`}
                                        className={`add-edit-save-icon`}
                                        onClick={() => {setIsReadyToSelectTemplate(!isReadyToSelectTemplate)}}
                                    />
                                    
                                </div>
                            </div>
                            
                            {/* SEO Title */}
                            <div className="form-group">
                                <label>SEO Title</label>
                                <div className='input-container'>
                                    {
                                        isSeoTitleActive
                                        ? <input  
                                            defaultValue={seoTitle || GeneralSettings?.seoTitle || ""}
                                            readOnly={!isSeoTitleActive}
                                            autoFocus={true}
                                            onChange={(e: any) => {
                                                const { value } = e.target;
                                                if (value) {
                                                    setSeoTitle(value);
                                                    setIsSeoTitleError(false);
                                                } else {
                                                    setSeoTitle("");
                                                    setIsSeoTitleError(true);
                                                }
                                            }}
                                            type="text" 
                                            placeholder="SEO Title"
                                            className={`${((isSeoTitleError || !GeneralSettings?.seoTitle) && isSeoTitleActive) ? "input-error" : ""}`}
                                        />
                                        : <span className='input-value'>{seoTitle || GeneralSettings?.seoTitle || ""}</span>
                                    }
                                    
                                    <img
                                        src={isSeoTitleActive ? save_icon : edit_icon}
                                        className={`add-edit-save-icon ${isSeoTitleError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isSeoTitleError) {
                                                if (isSeoTitleActive && seoTitle) {
                                                    setIsSeoTitleActive(false);
                                                    handleUpdateBioPageSettingField("seoTitle", seoTitle, GeneralSettings?.bioPageLinkType, setSeoTitle)
                                                } else {
                                                    setIsSeoTitleActive(true);
                                                }
                                            }
                                        }}
                                        alt='save-edit'
                                    />
                                </div>
                            </div>
                            
                            {/* Meta Description */}
                            <div className='form-group'>
                                <label>Meta Description</label>
                                <div className='input-container'>
                                    {
                                        isMetaDescriptionActive
                                            ? <input
                                                defaultValue={metaDescription || GeneralSettings?.bioPageMetaDescription || ""}
                                                readOnly={!isMetaDescriptionActive}
                                                autoFocus={true}
                                                onChange={(e: any) => {
                                                    const { value } = e.target;
                                                    if (value) {
                                                        setMetaDescription(value);
                                                        setIsMetaDescriptionError(false);
                                                    } else {
                                                        setMetaDescription("");
                                                        setIsMetaDescriptionError(true);
                                                    }
                                                }}
                                                type="text"
                                                placeholder="Meta Description"
                                                className={`${((isMetaDescriptionError || !GeneralSettings?.bioPageMetaDescription) && isMetaDescriptionActive) ? "input-error" : ""}`}
                                            />
                                            : <span className='input-value'>{metaDescription || GeneralSettings?.bioPageMetaDescription || ""}</span>
                                    }
                                    <img
                                        src={isMetaDescriptionActive ? save_icon : edit_icon}
                                        className={`add-edit-save-icon ${isMetaDescriptionError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isMetaDescriptionError) {
                                                if (isMetaDescriptionActive && metaDescription) {
                                                    setIsMetaDescriptionActive(false);
                                                    // handleUpdateBioPageSettingField("BioPageMetaDescription", metaDescription, GeneralSettings?.bioPageLinkType, setMetaDescription)
                                                    handleUpdateBioPageSettingField("bioPageMetaDescription", metaDescription, GeneralSettings?.bioPageLinkType, setMetaDescription)
                                                } else {
                                                    setIsMetaDescriptionActive(true);
                                                }
                                            }
                                        }}
                                        alt='save-edit'
                                    />
                                </div>
                            </div>
                            
                            {/* Site Description */}
                            <div className='form-group'>
                                <label>Site Description</label>
                                <div className='input-container'>
                                    {
                                        isSiteDescriptionActive
                                            ? <input
                                                defaultValue={siteDescription || GeneralSettings?.bioPageSiteDescription || ""}
                                                readOnly={!isSiteDescriptionActive}
                                                autoFocus={true}
                                                onChange={(e: any) => {
                                                    const { value } = e.target;
                                                    if (value) {
                                                        setSiteDescription(value);
                                                        setIsSiteDescriptionError(false);
                                                    } else {
                                                        setSiteDescription("");
                                                        setIsSiteDescriptionError(true);
                                                    }
                                                }}
                                                type="text"
                                                placeholder="Site Description"
                                                className={`${((isSiteDescriptionError || !GeneralSettings?.bioPageSiteDescription) && isSiteDescriptionActive) ? "input-error" : ""}`}
                                            />
                                            : <span className='input-value'>{siteDescription || GeneralSettings?.bioPageSiteDescription || ""}</span>
                                    }
                                    <img
                                        src={isSiteDescriptionActive ? save_icon : edit_icon}
                                        className={`add-edit-save-icon ${isSiteDescriptionError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isSiteDescriptionError) {
                                                if (isSiteDescriptionActive && siteDescription) {
                                                    setIsSiteDescriptionActive(false);
                                                    handleUpdateBioPageSettingField("bioPageSiteDescription", siteDescription, GeneralSettings?.bioPageLinkType, setSiteDescription)
                                                    // handleUpdateBioPageSettingField("BioPageSiteDescription", siteDescription, GeneralSettings?.SiteDescription?.linktype, setSiteDescription)
                                                } else {
                                                    setIsSiteDescriptionActive(true);
                                                }
                                            }
                                        }}
                                        alt='save-edit'
                                    />
                                </div>
                            </div>
                            
                            {/* Bio-page Domain */}
                            <div className="form-group">
                                <label>Biopage Domain</label>
                                <div className='input-container'>
                                    <span className='input-value'>{GeneralSettings?.bioPageDomain || "Biopage Domain"}</span>
                                        
                                    <p className="copy"
                                        onClick={() => {
                                            handleCopyUrl(GeneralSettings?.bioPageDomain || "Biopage Domain");
                                        }}
                                    >
                                        {
                                            isCopied === (GeneralSettings?.bioPageDomain || "Biopage Domain")
                                                ? "copied"
                                                : "copy"
                                        }
                                    </p>
                                </div>
                            </div>
                            
                            {/* Feedback URL */}
                            <div className="form-group">
                                <label>Feedback URL</label>
                                <div className='input-container'>
                                    <span className='input-value'>{GeneralSettings?.bioPageFeedbackURL || "Feedback URL"}</span>
                                    <p className="copy"
                                        onClick={() => {
                                            handleCopyUrl(GeneralSettings?.bioPageFeedbackURL || "Feedback URL");
                                        }}
                                    >
                                        {
                                            isCopied === (GeneralSettings?.bioPageFeedbackURL || "Feedback URL")
                                                ? "copied"
                                                : "copy"
                                        }
                                    </p>
                                </div>
                            </div>
                            
                            {/* Video Testimonial URL */}
                            <div className="form-group">
                                <label>Video Testimonial URL</label>
                                <div className='input-container'>
                                    <span className='input-value'>{GeneralSettings?.bioPageVideoTestimonialURL || "Video Testimonial URL"}</span>
                                    
                                    <p className="copy"
                                        onClick={() => {
                                            handleCopyUrl(GeneralSettings?.bioPageVideoTestimonialURL || "Video Testimonial URL");
                                        }}
                                    >
                                        {
                                            isCopied === (GeneralSettings?.bioPageVideoTestimonialURL || "Video Testimonial URL")
                                                ? "copied"
                                                : "copy"
                                        }
                                    </p>
                                </div>
                            </div>
                            
                            {/* Video Submission URL */}
                            <div className="form-group">
                                <label>Video Submission URL</label>
                                <div className='input-container'>
                                    <span className='input-value'>{GeneralSettings?.bioPageVideoSubmissionURL || "Video Submission URL"}</span>
                                    
                                    <p className="copy"
                                        onClick={() => {
                                            handleCopyUrl(GeneralSettings?.bioPageVideoSubmissionURL || "Video Submission URL");
                                        }}
                                    >
                                        {
                                            isCopied === (GeneralSettings?.bioPageVideoSubmissionURL || "Video Submission URL")
                                                ? "copied"
                                                : "copy"
                                        }
                                    </p>
                                </div>
                            </div>
                            
                            {/* Image Submission URL */}
                            <div className="form-group">
                                <label>Image Submission URL</label>
                                <div className='input-container'>
                                    <span className='input-value'>{GeneralSettings?.bioPageImageSubmissionURL || "Image Submission URL"}</span>
                                    
                                    <p className="copy"
                                        onClick={() => {
                                            handleCopyUrl(GeneralSettings?.bioPageImageSubmissionURL || "Image Submission URL");
                                        }}
                                    >
                                        {
                                            isCopied === (GeneralSettings?.bioPageImageSubmissionURL || "Image Submission URL")
                                                ? "copied"
                                                : "copy"
                                        }
                                    </p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                }
            </div>
            
            <div className='mobile-sub-menu w-100'>
                {
                    loading
                    ? <div className='loading-container w-100 d-flex justify-content-center align-items-center'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                    : <div className='bio-page-menu-container'>
                        <div className="form-container general-settings-gap">
                            {/* Brand Name */}
                            <div className="form-group">
                                <label>Biopage Brand Name</label>
                                <div className='input-container'>
                                    {
                                        isBrandNameActive
                                        ? <input  
                                            defaultValue={brandName || GeneralSettings?.bioPageBrandName || ""}
                                            readOnly={!isBrandNameActive}
                                            autoFocus={true}
                                            onChange={(e: any) => {
                                                const { value } = e.target;
                                                if (value) {
                                                    setBrandName(value);
                                                    setIsBrandNameError(false);
                                                } else {
                                                    setBrandName("");
                                                    setIsBrandNameError(true);
                                                }
                                            }}
                                            type="text" 
                                            placeholder="Biopage Brand Name"
                                            className={`${((isBrandNameError ||!GeneralSettings?.bioPageBrandName) && isBrandNameActive) ? "input-error" : ""}`}
                                        />
                                        : <span className='input-value'>{brandName || GeneralSettings?.bioPageBrandName || ""}</span>
                                    }
                                    <img
                                        src={isBrandNameActive ? save_icon : edit_icon}
                                        alt="edit-icon"
                                        className={`add-edit-save-icon ${isBrandNameError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isBrandNameError) {
                                                if(isBrandNameActive && brandName){
                                                    // console.log("Brand Name is active");
                                                    setIsBrandNameActive(false);
                                                    handleUpdateBioPageSettingField("bioPageBrandName", brandName, GeneralSettings?.bioPageLinkType, setBrandName);
                                                    // handleUpdateBioPageSettingField("BioPageBrandName", brandName, GeneralSettings?.bioPageLinkType, setBrandName);
                                                } else {
                                                    setIsBrandNameActive(true);
                                                }
                                            }
                                        }}
                                    />
                                    
                                </div>
                            </div>

                            {/* Biopage Logo {"( < 25KB)"} */}
                            <div className="form-group">
                                <label>Biopage Logo {"( < 25KB)"}</label>
                                <div className='input-container'>
                                    <div className='image-label'>
                                        {
                                            imageURL
                                            ? <img src={imageURL?.replaceAll("@", "/")} 
                                                alt={"logo"} 
                                                className='rounded-circle' 
                                            /> 
                                            : image 
                                            ? <span>Logo.png</span>
                                            : <img className='rounded-circle' src={GeneralSettings?.logoUrl?.replaceAll("@", "/")} alt='logo' /> 
                                        }                                      
                                    </div>
                                    {
                                        image
                                        ? <input 
                                            // disabled={image ? false : true}
                                            id="image" type="file"
                                            accept='image/*'
                                            // accept='image/png, image/jpg, image/jpeg, image/svg'
                                            onChange={(e: any) => {
                                                // console.log({img: e?.target?.files[0]});
                                                setImageURL(URL.createObjectURL(e?.target?.files[0]));
                                                setImage(e?.target?.files[0]?.name);
                                                handleImageChange(e); 

                                            }} 
                                            className="form-input-field d-none w-100 my-1" placeholder="Biopage Image"
                                        />
                                        : null
                                    }
                                    
                                    {
                                        image
                                        ? imageURL
                                            ?   <img
                                                    src={save_icon}
                                                    className={`add-edit-save-icon`}
                                                    onClick={() => {
                                                        handleLogoSubmit();
                                                    }}
                                                    alt='save-edit'
                                                />
                                            :   <label className='mx-2' htmlFor="image">
                                                    <img
                                                        src={upload_icon}
                                                        className={`add-edit-save-icon`}
                                                        alt='save-edit'
                                                    />
                                            </label>
                                        : <img
                                            src={edit_icon}
                                            className={`add-edit-save-icon`}
                                            onClick={() => {
                                                setImage(true);
                                                setImageURL('');
                                                // if (image && imageURL) {
                                                //     handleLogoSubmit();
                                                // }
                                            }}
                                            alt='save-edit'
                                        />
                                    }
                                </div>
                            </div>

                            {/* Select Template for Biopage */}
                            <div className="form-group">
                                <label>Biopage Template</label>
                                <div className='input-container'>
                                    <span className='input-value'>{current_default_template?.templateName || "Default Template"}</span>
                                    <img
                                        src={preview_icon}
                                        alt="preview-icon"
                                        // className={`add-edit-save-icon ${isBrandNameError ? "disabled" : ""}`}
                                        className={`add-edit-save-icon`}
                                        onClick={() => {setIsReadyToSelectTemplate(!isReadyToSelectTemplate)}}
                                    />
                                    
                                </div>
                            </div>

                            {/* SEO Title */}
                            <div className="form-group">
                                <label>SEO Title</label>
                                <div className='input-container'>
                                    {
                                        isSeoTitleActive
                                        ? <input  
                                            defaultValue={seoTitle || GeneralSettings?.seoTitle || ""}
                                            readOnly={!isSeoTitleActive}
                                            autoFocus={true}
                                            onChange={(e: any) => {
                                                const { value } = e.target;
                                                if (value) {
                                                    setSeoTitle(value);
                                                    setIsSeoTitleError(false);
                                                } else {
                                                    setSeoTitle("");
                                                    setIsSeoTitleError(true);
                                                }
                                            }}
                                            type="text" 
                                            placeholder="SEO Title"
                                            className={`${((isSeoTitleError || !GeneralSettings?.seoTitle) && isSeoTitleActive) ? "input-error" : ""}`}
                                        />
                                        : <span className='input-value'>{seoTitle || GeneralSettings?.seoTitle || ""}</span>
                                    }
                                    
                                    <img
                                        src={isSeoTitleActive ? save_icon : edit_icon}
                                        className={`add-edit-save-icon ${isSeoTitleError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isSeoTitleError) {
                                                if (isSeoTitleActive && seoTitle) {
                                                    setIsSeoTitleActive(false);
                                                    handleUpdateBioPageSettingField("seoTitle", seoTitle, GeneralSettings?.bioPageLinkType, setSeoTitle)
                                                } else {
                                                    setIsSeoTitleActive(true);
                                                }
                                            }
                                        }}
                                        alt='save-edit'
                                    />
                                </div>
                            </div>

                            {/* Meta Description */}
                            <div className='form-group'>
                                <label>Meta Description</label>
                                <div className='input-container'>
                                    {
                                        isMetaDescriptionActive
                                            ? <input
                                                defaultValue={metaDescription || GeneralSettings?.bioPageMetaDescription || ""}
                                                readOnly={!isMetaDescriptionActive}
                                                autoFocus={true}
                                                onChange={(e: any) => {
                                                    const { value } = e.target;
                                                    if (value) {
                                                        setMetaDescription(value);
                                                        setIsMetaDescriptionError(false);
                                                    } else {
                                                        setMetaDescription("");
                                                        setIsMetaDescriptionError(true);
                                                    }
                                                }}
                                                type="text"
                                                placeholder="Meta Description"
                                                className={`${((isMetaDescriptionError || !GeneralSettings?.bioPageMetaDescription) && isMetaDescriptionActive) ? "input-error" : ""}`}
                                            />
                                            : <span className='input-value'>{metaDescription || GeneralSettings?.bioPageMetaDescription || ""}</span>
                                    }
                                    <img
                                        src={isMetaDescriptionActive ? save_icon : edit_icon}
                                        className={`add-edit-save-icon ${isMetaDescriptionError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isMetaDescriptionError) {
                                                if (isMetaDescriptionActive && metaDescription) {
                                                    setIsMetaDescriptionActive(false);
                                                    // handleUpdateBioPageSettingField("BioPageMetaDescription", metaDescription, GeneralSettings?.bioPageLinkType, setMetaDescription)
                                                    handleUpdateBioPageSettingField("bioPageMetaDescription", metaDescription, GeneralSettings?.bioPageLinkType, setMetaDescription)
                                                } else {
                                                    setIsMetaDescriptionActive(true);
                                                }
                                            }
                                        }}
                                        alt='save-edit'
                                    />
                                </div>
                            </div>

                            {/* Site Description */}
                            <div className='form-group'>
                                <label>Site Description</label>
                                <div className='input-container'>
                                    {
                                        isSiteDescriptionActive
                                            ? <input
                                                defaultValue={siteDescription || GeneralSettings?.bioPageSiteDescription || ""}
                                                readOnly={!isSiteDescriptionActive}
                                                autoFocus={true}
                                                onChange={(e: any) => {
                                                    const { value } = e.target;
                                                    if (value) {
                                                        setSiteDescription(value);
                                                        setIsSiteDescriptionError(false);
                                                    } else {
                                                        setSiteDescription("");
                                                        setIsSiteDescriptionError(true);
                                                    }
                                                }}
                                                type="text"
                                                placeholder="Site Description"
                                                className={`${((isSiteDescriptionError || !GeneralSettings?.bioPageSiteDescription) && isSiteDescriptionActive) ? "input-error" : ""}`}
                                            />
                                            : <span className='input-value'>{siteDescription || GeneralSettings?.bioPageSiteDescription || ""}</span>
                                    }
                                    <img
                                        src={isSiteDescriptionActive ? save_icon : edit_icon}
                                        className={`add-edit-save-icon ${isSiteDescriptionError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isSiteDescriptionError) {
                                                if (isSiteDescriptionActive && siteDescription) {
                                                    setIsSiteDescriptionActive(false);
                                                    handleUpdateBioPageSettingField("bioPageSiteDescription", siteDescription, GeneralSettings?.bioPageLinkType, setSiteDescription)
                                                    // handleUpdateBioPageSettingField("BioPageSiteDescription", siteDescription, GeneralSettings?.SiteDescription?.linktype, setSiteDescription)
                                                } else {
                                                    setIsSiteDescriptionActive(true);
                                                }
                                            }
                                        }}
                                        alt='save-edit'
                                    />
                                </div>
                            </div>
                        
                            {/* Bio-page Domain */}
                            <div className="form-group">
                                <label>Biopage Domain</label>
                                <div className='input-container'>
                                    <span className='input-value'>{GeneralSettings?.bioPageDomain || "Biopage Domain"}</span>
                                        
                                    <p className="copy"
                                        onClick={() => {
                                            handleCopyUrl(GeneralSettings?.bioPageDomain || "Biopage Domain");
                                        }}
                                    >
                                        {
                                            isCopied === (GeneralSettings?.bioPageDomain || "Biopage Domain")
                                                ? "copied"
                                                : "copy"
                                        }
                                    </p>
                                </div>
                            </div>
                            
                            {/* Feedback URL */}
                            <div className="form-group">
                                <label>Feedback URL</label>
                                <div className='input-container'>
                                    <span className='input-value'>{GeneralSettings?.bioPageFeedbackURL || "Feedback URL"}</span>
                                    <p className="copy"
                                        onClick={() => {
                                            handleCopyUrl(GeneralSettings?.bioPageFeedbackURL || "Feedback URL");
                                        }}
                                    >
                                        {
                                            isCopied === (GeneralSettings?.bioPageFeedbackURL || "Feedback URL")
                                                ? "copied"
                                                : "copy"
                                        }
                                    </p>
                                </div>
                            </div>
                            
                            {/* Video Testimonial URL */}
                            <div className="form-group">
                                <label>Video Testimonial URL</label>
                                <div className='input-container'>
                                    <span className='input-value'>{modifyString(GeneralSettings?.bioPageVideoTestimonialURL || "Video Testimonial URL", 17, 20)}</span>
                                    
                                    <p className="copy"
                                        onClick={() => {
                                            handleCopyUrl(GeneralSettings?.bioPageVideoTestimonialURL || "Video Testimonial URL");
                                        }}
                                    >
                                        {
                                            isCopied === (GeneralSettings?.bioPageVideoTestimonialURL || "Video Testimonial URL")
                                                ? "copied"
                                                : "copy"
                                        }
                                    </p>
                                </div>
                            </div>
                            
                            {/* Video Submission URL */}
                            <div className="form-group">
                                <label>Video Submission URL</label>
                                <div className='input-container'>
                                    <span className='input-value'>{GeneralSettings?.bioPageVideoSubmissionURL || "Video Submission URL"}</span>
                                    
                                    <p className="copy"
                                        onClick={() => {
                                            handleCopyUrl(GeneralSettings?.bioPageVideoSubmissionURL || "Video Submission URL");
                                        }}
                                    >
                                        {
                                            isCopied === (GeneralSettings?.bioPageVideoSubmissionURL || "Video Submission URL")
                                                ? "copied"
                                                : "copy"
                                        }
                                    </p>
                                </div>
                            </div>
                            
                            {/* Image Submission URL */}
                            <div className="form-group">
                                <label>Image Submission URL</label>
                                <div className='input-container'>
                                    <span className='input-value'>{GeneralSettings?.bioPageImageSubmissionURL || "Image Submission URL"}</span>
                                    
                                    <p className="copy"
                                        onClick={() => {
                                            handleCopyUrl(GeneralSettings?.bioPageImageSubmissionURL || "Image Submission URL");
                                        }}
                                    >
                                        {
                                            isCopied === (GeneralSettings?.bioPageImageSubmissionURL || "Image Submission URL")
                                                ? "copied"
                                                : "copy"
                                        }
                                    </p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                }
            </div>
            
        </React.Fragment>
    );
};

export default GeneralSettings;