import React, { useEffect, useState } from 'react';
import { Dropdown, Space } from 'antd';
import calender from '../../assets/icons/calendar-alt.svg';
import filter from '../../assets/icons/feather-filter.svg';
import search from '../../assets/icons/search-icon.svg';
import cross from '../../assets/icons/cross-icon.svg';
import Add_Options from '../../assets/icons/Add-Options.svg';
import nav_hamburger from '../../assets/icons/nav-hamburger.svg';

import Calendar from './Calendar';
import './search.scss';
import { DebounceInput } from 'react-debounce-input';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store';
import { openMobileDrawer } from '../../profile/reducer';
import Workspace from '../layout/header/components/workspace';
import MobileCalendar from './MobileCalendar';
import { modifyString } from '../../utils/mediaService';

interface SearchProps {
    parentCallback?: any;
    isShowFilter?: boolean;
    searchText?: any; 
    setSearchText?: any;
    setConversationId?: any;
    activeTab?: string;
    activePage?: any;
    parentKey?: any;
    is_whats_app_filter_data?: boolean;
    search_placeholder?: string;
}   

const Search: React.FC<SearchProps> = ({ parentCallback, parentKey, search_placeholder, isShowFilter, searchText, setSearchText, setConversationId, activeTab, activePage, is_whats_app_filter_data }) => {
    const path = window.location.pathname;
    const { searchText: sText } = useSelector((state: RootState) => state?.unified);
    // const { workspaceSettings } = useSelector((state: any) => state?.workspaceManagementState);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const route_path = window?.location?.pathname;
    const query_string = window?.location?.search;

    const [searchInput, setSearchInput] = useState('');
    const [readStatus, setReadStatus] = useState<any>(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [isOpenReadStatus, setIsOpenReadStatus] = useState(false);
    const [isWhatsappOpenReadStatus, setIsWhatsappOpenReadStatus] = useState(false);
    const [isOpenCalender, setIsOpenCalender] = useState(false);
    const [isOpenMobileCalender, setIsOpenMobileCalender] = useState(false);
    
    useEffect(() => {        
        console.log("====searchText===", {searchText, searchInput});
        // if(searchText){
        if(searchInput){
            parentCallback(searchInput, "searchText");
        }
    }, [searchInput]);

    useEffect(() => {
      if (!sText) {
        setSearchInput(sText);
      }
    //   console.log({query_string});
      
    }, [sText, query_string]);

    const handleSelectField = (value: any, option: any, setDropdownState?: any, dropDownState?: any) => {
        console.log("====value===", value);
        console.log("====option===", option);
        if(option === "date"){
            setSelectedDate(value);
            if (isOpenMobileCalender){
                setIsOpenMobileCalender(false);
            } else if (isOpenCalender){
                setIsOpenCalender(false);
            }
            const date_text = new Date(value[0]).getFullYear() +
              "-" +
              (new Date(value[0]).getMonth() + 1 < 10
                ? "0" + (new Date(value[0]).getMonth() + 1)
                : new Date(value[0]).getMonth() + 1) +
              "-" +
              new Date(value[0]).getDate() +
              " ~ " +
              new Date(value[1]).getFullYear() +
              "-" +
              (new Date(value[1]).getMonth() + 1 < 10
                ? "0" + (new Date(value[1]).getMonth() + 1)
                : new Date(value[1]).getMonth() + 1) +
              "-" +
              new Date(value[1]).getDate();
            setSearchText(date_text);
            dispatch({type: "REMOVE_FILTER_BY_READ_STATUS"});
            parentCallback({fromDate: new Date(value[0]).toISOString()?.replace('Z', ''), toDate: new Date(value[1]).toISOString().replace('Z', '')}, "searchDates");
        } else if (option?.includes("whatsapp")) {
            if (option?.includes("big-size")) { 
                const modified_string = modifyString((value || ""), 3, 3, "...");
                // console.log({modified_string, value});
                
                setReadStatus(value);
                setSearchText(modified_string || value);
            } else {
                setReadStatus(value); 
                setSearchText(value);
            }
            setDropdownState(!dropDownState);
            dispatch({type: "REMOVE_SEARCH_FROM_TO_DATE"});
            parentCallback(value, "readStatus");
        } else {
            setReadStatus(value); 
            setIsOpenReadStatus(!isOpenReadStatus);
            setSearchText(value);
            dispatch({type: "REMOVE_SEARCH_FROM_TO_DATE"});
            parentCallback(value, "readStatus");
        }
    }

    const filter_menu = (
        <div className='filter border bg-white shadow mt-2 p-2 rounded' style={{width: 100, marginRight: 50}}>
            <p onClick={() => handleSelectField("All", "read-status")} style={{margin: 2, cursor: 'pointer'}} className='text-start'>All</p>
            <p onClick={() => handleSelectField("Not Replied", "read-status")} style={{margin: 2, cursor: 'pointer'}} className='text-start'>Not Replied</p>
            <p onClick={() => handleSelectField("Replied", "read-status")} style={{margin: 2, cursor: 'pointer'}} className='text-start'>Replied</p>
        </div>
    );

    const whats_app_filter_data = (
        <div className='filter border bg-white shadow mt-2 p-2 rounded' style={{width: 120, marginRight: 50}}>
            <p onClick={() => handleSelectField("All", "whatsapp-read-status", setIsWhatsappOpenReadStatus, isWhatsappOpenReadStatus)} style={{margin: 2, cursor: 'pointer'}} className='text-start'>All</p>
            <p onClick={() => handleSelectField(sessionStorage.getItem('cuid') , "big-size-whatsapp-read-status", setIsWhatsappOpenReadStatus, isWhatsappOpenReadStatus)} style={{margin: 2, cursor: 'pointer'}} className='text-start'>Assign to me</p>
        </div>
    );

    const calender_menu = (
        <div className='calendar'>
            <Calendar isOpenCalender={isOpenCalender} handleSelectField={handleSelectField}/>
        </div>
    );

    const mobile_calender_menu = (
        <div className='calendar'>
            <MobileCalendar isOpenCalender={isOpenMobileCalender} handleSelectField={handleSelectField}/>
        </div>
    );
    
    
    return (
        <>
            <style type="text/css">
                {`
                    .rs-anim-fade{
                        left: 95px !important;
                        top: ${(path === "/customer-feedback" || path === "/video-testimonial") ? "135px" : "135px"} !important;
                    }

                    .rs-picker-daterange-header {
                        display: none;
                    }

                    @media (max-width: 769px){
                        .rs-anim-fade{
                            left: 65px !important;
                            top: 55px !important;
                        }
                    }
                    
                    @media (max-width: 350px){
                        .rs-anim-fade{
                            left: 35px !important;
                            top: 55px !important;
                        }
                    }
                `}
                {/* top: ${(path === "/customer-feedback" || path === "/video-testimonial") ? "115px" : "140px"} !important; */}
            </style>
            <div className="search-wrapper position-sticky top-0">
                {
                    parentKey === 'gallery'
                    ? <DebounceInput
                        // value={searchInput}
                        style={{height: 58}}
                        type="text" 
                        placeholder={search_placeholder || "Search"}
                        minLength={0}
                        debounceTimeout={1000}
                        onChange={(event: any) => parentCallback(event.target.value, "wall")} 
                     />
                    : parentKey
                    ? <DebounceInput
                        // value={searchInput}
                        type="text" placeholder="Search"
                        minLength={0}
                        debounceTimeout={1000}
                        onChange={(event: any) => parentCallback(event.target.value, "wall")} 
                     />
                    : <DebounceInput
                        // readOnly={searchText ? true : false}
                        onClick={() => {
                            if(!searchText){
                                setReadStatus(null);
                                setSelectedDate(null);
                                // dispatch({type: "REMOVE_SEARCH_TEXT"});
                                dispatch({type: "REMOVE_FILTER_BY_READ_STATUS"});
                                dispatch({type: "REMOVE_SEARCH_FROM_TO_DATE"});
                            }
                        }}
                        value={searchInput}
                        type="text" placeholder="Search"
                        minLength={0}
                        debounceTimeout={1000}
                        onChange={(event: any) => {
                            const { value } = event.target;
                            console.log({value});
                            
                            value ? setSearchInput(value) : parentCallback("", "searchText");                                
                        }} 
                    />
                }
                
                {/* <div className="lg-search-wrapper ">
                    <DebounceInput
                        onClick={() => {
                            if(searchText){
                                setSearchText("");
                                setReadStatus(null);
                                setSelectedDate(null);
                                // dispatch({type: "REMOVE_SEARCH_TEXT"});
                                dispatch({type: "REMOVE_FILTER_BY_READ_STATUS"});
                                dispatch({type: "REMOVE_SEARCH_FROM_TO_DATE"});
                            }
                        }}
                        value={searchInput}
                        type="text" placeholder="Search"
                        minLength={0}
                        debounceTimeout={1000}
                        onChange={(event: any) => setSearchInput(event.target.value)} 
                    />
                </div> */}
                {/* <div className="mobile-search-wrapper">
                    {
                        query_string?.includes("wall")
                        ? <DebounceInput
                            // value={searchInput}
                            type="text" placeholder="Search"
                            minLength={0}
                            debounceTimeout={1000}
                            onChange={(event: any) => parentCallback(event.target.value, true)} 
                        />
                        : <DebounceInput
                            // readOnly={searchText ? true : false}
                            onClick={() => {
                                if(searchText){
                                    setSearchText("");
                                    setReadStatus(null);
                                    setSelectedDate(null);
                                    // dispatch({type: "REMOVE_SEARCH_TEXT"});
                                    dispatch({type: "REMOVE_FILTER_BY_READ_STATUS"});
                                    dispatch({type: "REMOVE_SEARCH_FROM_TO_DATE"});
                                }
                            }}
                            value={searchInput}
                            type="text" placeholder="Search"
                            minLength={0}
                            debounceTimeout={1000}
                            onChange={(event: any) => setSearchInput(event.target.value)} 
                        />
                    }
                </div> */}
                {/* <input value={searchInput} onChange={(e: any) => debounce(setSearchInput(e?.target.value), 500)} readOnly={searchText ? true : false} type="search" alt="" placeholder="Search" /> */}
                {/* <i className="fa fa-search mt-1"></i> */}
                {
                    !parentKey && searchText && !searchInput && <div
                        className={`${(route_path?.includes("/whatsapp") || route_path?.includes("/messages")) && !is_whats_app_filter_data ? "bot-search-text": "search-text"} border rounded `}
                        style={{padding: '0 1%'}}
                    >
                        {/* fa cross icon */}
                        <p className='m-0 w-100'>{searchText}</p>
                        <img 
                            onClick={() => {
                                setSearchText("");
                                if(readStatus){
                                    dispatch({type: "REMOVE_FILTER_BY_READ_STATUS"});
                                    setReadStatus(null);
                                    parentCallback("", "readStatus");
                                }
                                if(selectedDate){
                                    setSelectedDate(null);
                                    dispatch({ type: "REMOVE_SEARCH_FROM_TO_DATE" });
                                    parentCallback({
                                        fromDate: "",
                                        toDate: ""
                                    }, "searchDates");
                                }
                                setConversationId(null);
                                navigate(route_path+`${activeTab && activePage ? ("?"+activeTab+"="+activePage) : ""}`);
                            }}
                            style={{height: 12, width: 12, cursor: 'pointer'}} 
                            src={cross} alt="x" 
                        />
                        {/* <i onClick={() => setSearchText("")} className=" fa fa-times"></i> */}
                    </div>
                }

                {/* mobile */}
                <img className='search-icon' src={search} alt="search" />
                {
                    parentKey === 'gallery'
                        ?
                        <img
                            className='mobile-nav-bar'
                            style={{top: 20}}
                            onClick={() => dispatch(openMobileDrawer())}
                            src={nav_hamburger} alt="mobile-nav-bar"
                        />
                        :
                        <img
                            className='mobile-nav-bar'
                            onClick={() => dispatch(openMobileDrawer())}
                            src={nav_hamburger} alt="mobile-nav-bar"
                        />
                }
                {
                    !isShowFilter && <div className={`refresh-icon`}
                        style={{right: `${(route_path?.includes("/whatsapp") || route_path?.includes("/messages")) ? "0" : "5px"}`}}
                    >
                        {
                            !parentKey && <>
                                <Space direction="vertical">
                                    <div className={`${query_string?.toLocaleLowerCase()?.includes("wall") && 'mobile-calender-filter'}`}>
                                        <div className='mobile-calendar'>
                                            <Dropdown 
                                                onOpenChange={() => {
                                                    console.log("====searchInput===", searchInput);
                                                    if(searchInput){
                                                        setSearchInput("");
                                                        setReadStatus(null);
                                                        dispatch({type: "REMOVE_FILTER_BY_READ_STATUS"});
                                                        parentCallback("", "readStatus");
                                                    }
                                                    setIsOpenMobileCalender(!isOpenMobileCalender);
                                                }}
                                                open={isOpenMobileCalender}
                                                trigger={['click']} 
                                                overlay={mobile_calender_menu} 
                                                placement="bottom"
                                            >
                                                <img style={{cursor: 'pointer', height: 14}} src={calender} alt="calender" />
                                            </Dropdown>
                                        </div>
                                        <div
                                            className='calendar-lg'
                                        >
                                            <Dropdown 
                                                onOpenChange={() => {
                                                    console.log("====searchInput===", searchInput);
                                                    if(searchInput){
                                                        setSearchInput("");
                                                        setReadStatus(null);
                                                        dispatch({type: "REMOVE_FILTER_BY_READ_STATUS"});
                                                        parentCallback("", "readStatus");
                                                    }
                                                    setIsOpenCalender(!isOpenCalender);
                                                }}
                                                open={isOpenCalender}
                                                trigger={['click']} 
                                                overlay={calender_menu} 
                                                placement="bottom"
                                            >
                                                <img style={{cursor: 'pointer', height: 14}} src={calender} alt="calender" />
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Space>
                                
                                {/* whatsapp parent-filter-status */}
                                {
                                    is_whats_app_filter_data
                                    ?
                                    <Space direction="vertical">
                                        <div className={``}>
                                            <Dropdown 
                                                onOpenChange={() => {
                                                    console.log("====searchInput===", searchInput);
                                                    if(searchInput){
                                                        setSearchInput("");
                                                        setSelectedDate(null);
                                                        dispatch({type: "REMOVE_SEARCH_FROM_TO_DATE"});
                                                        parentCallback({
                                                            fromDate: "",
                                                            toDate: ""
                                                        }, "searchDates");
                                                    }
                                                    setIsWhatsappOpenReadStatus(!isWhatsappOpenReadStatus);
                                                }}
                                                
                                                open={isWhatsappOpenReadStatus}
                                                trigger={['click']} 
                                                overlay={whats_app_filter_data} 
                                                placement="bottom"
                                            >
                                                <img style={{cursor: 'pointer', height: 14}} src={filter} alt="filter" />
                                            </Dropdown>
                                        </div>
                                    </Space>
                                    : null
                                }


                                {/* filter-status */}
                                <Space direction="vertical" style={{display: `${(route_path?.includes("/whatsapp") || route_path?.includes("/messages")) ? "none": "block"}`}}>
                                    <div className={`${query_string?.toLocaleLowerCase()?.includes("wall") && 'mobile-calender-filter'}`}>
                                        <Dropdown 
                                            onOpenChange={() => {
                                                console.log("====searchInput===", searchInput);
                                                if(searchInput){
                                                    setSearchInput("");
                                                    setSelectedDate(null);
                                                    dispatch({type: "REMOVE_SEARCH_FROM_TO_DATE"});
                                                    parentCallback({
                                                        fromDate: "",
                                                        toDate: ""
                                                    }, "searchDates");
                                                }
                                                setIsOpenReadStatus(!isOpenReadStatus);
                                            }}
                                            
                                            open={isOpenReadStatus}
                                            trigger={['click']} 
                                            overlay={filter_menu} 
                                            placement="bottom"
                                        >
                                            <img style={{cursor: 'pointer', height: 14}} src={filter} alt="filter" />
                                        </Dropdown>
                                    </div>
                                </Space>
                            </>
                        }
                        {/* <Space direction="vertical">
                            <img className='ws-logo' src={workspaceSettings?.logo} alt="search" />
                        </Space> */}
                        
                        <Space direction="vertical">
                            <div style={{height: 20}} className='d-flex align-items-center me-1'>
                                <div className='ws-logo'
                                    style={{
                                        marginTop: route_path?.includes("/gallery") ? 22 : 0,
                                        marginRight: route_path?.includes("/gallery") ? 5 : 0,
                                    }}
                                >
                                    <Workspace isFromSearch={true} marginRight={10} height={28} width={"auto"} />
                                </div>
                                {
                                    query_string?.toLocaleLowerCase()?.includes("wall")
                                    || route_path?.includes("/gallery")
                                    ? null
                                    : <img style={{ marginTop: 5 }} className='add-options' src={Add_Options} alt="Add-Options" />
                                }
                            </div>
                        </Space>
                    </div>
                }
                

            </div>
        </>
    )
}

export default Search;