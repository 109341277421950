import React, { useEffect, useState } from 'react';
import { Button, Input, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getTemplateImage } from '../../../../../../../dashboard/image_service';
import { RootState } from '../../../../../../../store';
import { toggleTemplateEdition } from '../../../../../reducer';
import { setErrorMessage } from '../../../../../../../dashboard/reducer';
import { checkVariable } from '../../../../../../../utils/convertingFunction';

const HeaderForm: React.FC<any> = () => {
    const dispatch = useDispatch();
    const { toggle_template_edition }: any = useSelector((state: RootState) => state?.promotionsState);
    const [selectedType, setSelectedType] = useState<any>("");
    const [headers, setHeaders] = useState<any[]>([]);

    useEffect(() => {
        if (toggle_template_edition?.templateHeaderType) {
            setSelectedType(toggle_template_edition?.templateHeaderType);
            setHeaders([
                {
                    id: Number(new Date()),
                    type: toggle_template_edition?.templateHeaderType,
                    value: toggle_template_edition?.templateHeader
                }
            ]);
        }
    }, [toggle_template_edition]);

    const handleAddHeader = () => {
        if (headers?.length) {
            return;
        }
        setHeaders([
            ...headers,
            {
                id: Number(new Date()),
                type: selectedType,
                value: ""
            }
        ]);

        const data = {
            ...toggle_template_edition,
            templateHeaderType: selectedType,
            templateHeader: "",
        }
        dispatch(toggleTemplateEdition(data));
    };

    const handleRemoveHeader = (id?: any) => { 
        setHeaders(headers.filter(header => header.id !== id));
        const data = {
            ...toggle_template_edition,
            templateHeaderType: "",
            templateHeader: "",
        };
        dispatch(toggleTemplateEdition(data));
    };

    const handleChange = (value: any, id: any) => {
        if (value?.length > 60) {
            // dispatch(setErrorMessage("Header text should be maximum 60 characters!"));
            return;
        }
        const regex = /\{\{[^}]+\}\}/g;
        const matches = value?.match(regex);
        // checking proper variable or not
        if (matches?.length) {
            const variable = matches[0]?.replace("{{", "").replace("}}", "");
            const is_variable = checkVariable(variable);
            console.log({ variable, is_variable });
            if (!is_variable) {
                dispatch(setErrorMessage("Invalid variable!"));
                return;
            }
        }
        if (matches?.length > 1) {
            dispatch(setErrorMessage("Only one variable is allowed."));
            return;
        }

        setHeaders(headers?.map(header => header.id === id ? { ...header, value: value } : header));
        const data = {
            ...toggle_template_edition,
            templateHeaderType: selectedType || "",
            templateHeader: value || "",
        }
        dispatch(toggleTemplateEdition(data));
    }

    return (
        <React.Fragment>
            <div className='header-form-container'>
                <div className='title-content'>
                    <div className='title'>Header</div>
                    <div className='optional'>{"(Required)"}</div>
                </div>

                <div className="selector-container">
                    <Select
                        style={{ width: 150, borderRadius: "5px" }}
                        placeholder="Select a type"
                        allowClear
                        autoFocus
                        onChange={(value) => {
                            setSelectedType(value);
                            const data = {
                                ...toggle_template_edition,
                                templateHeaderType: value,
                                templateHeader: "",
                            }
                            dispatch(toggleTemplateEdition(data));
                        }}
                    >
                        <Select.Option value="TEXT">Text</Select.Option>
                        <Select.Option value="IMAGE">Image</Select.Option>
                        <Select.Option value="VIDEO">Video</Select.Option>
                        <Select.Option value="DOCUMENT">Document</Select.Option>
                    </Select>
                    <img
                        onClick={() => {
                            if (selectedType) {
                                handleAddHeader();
                            }
                        }}
                        src={getTemplateImage("add-line")}
                        alt="add-line"
                    />
                </div>

                <div className="header-form">
                    {headers?.map((header: any, index: any) => (
                        <div key={index} className="form">
                            <label
                                className="form-item"
                                style={{ textTransform: 'capitalize' }}
                            >
                                <span>
                                    Header {header?.type?.toLowerCase()} 
                                    {
                                        header?.type?.toLowerCase() === "text"
                                            ? <span>{ " (Max 60 Characters)"}</span>
                                        :   null
                                    }
                                </span>
                            </label>
                            <div className="form-item">
                                <Input
                                    placeholder={
                                        header?.type?.toLowerCase() === "image"
                                        ? "Header Image Field"
                                        : header?.type?.toLowerCase() === "video"
                                        ? "Header Video Field"
                                        : header?.type?.toLowerCase() === "document"
                                        ? "Header Document Field"
                                        : "Enter the header text"
                                    }
                                    value={header?.value}
                                    disabled={header?.type?.toLowerCase() !== "text"}
                                    autoSave="off"
                                    autoComplete="off"
                                    onChange={(e: any) => {
                                        const inputValue = e.target.value;
                                        const formattedValue = inputValue.replace(/\s+/g, ' ');
                                        handleChange(formattedValue, header?.id);
                                    }}
                                />
                                <div className='remove-item' onClick={() => handleRemoveHeader(header?.id)}>Remove</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    )
};

export default HeaderForm;
