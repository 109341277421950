import React from 'react';
import { useDispatch } from 'react-redux';
// import { RootState } from '../../../store';
// import { useSelector } from 'react-redux';
import BotMessageCard from './Cards/BotMessageCard';
import { getImage } from '../../../dashboard/image_service';
import WhatsAppMessageCard from './Cards/WhatsAppMessageCard';

interface Props {
  inbox?: any;
  activePage?: any;
  activeTab?: any;
  wallData?: any[];
  routePath?: string;
  setWallData?: any;
  type?: string;
  isFromModalBox?: boolean;
  last?: number;
  handleSeeOtherConversation?: any;
}

const MessagesInboxCard: React.FC<Props> = ({ inbox, handleSeeOtherConversation, type, activePage, activeTab, wallData: wallUnifiedData, isFromModalBox, last }) => {
  
  // const { wallBoxId, unifiedData } = useSelector((state: RootState) => state?.unified);
  
  const route_path = window.location.pathname;
  const query_string = window.location.search;
  const dispatch = useDispatch();



  const handleSeeOtherWhatsappConversation = (inbox: any) => {
    handleSeeOtherConversation(inbox);
  };

  const handleSeeOtherBotConversation = (inbox: any) => {
    handleSeeOtherConversation(inbox);
  };

  return (
    <React.Fragment>
      {
        type === "bot-messages"
        ? <BotMessageCard last={last} isFromModalBox={isFromModalBox} query_string={query_string} getImage={getImage} handleSeeOtherBotConversation={handleSeeOtherBotConversation} inbox={inbox} type={type} activePage={activePage} activeTab={activeTab} routePath={route_path} />
        : type === "whats-app-messages"
        ? <WhatsAppMessageCard 
            last={last} isFromModalBox={isFromModalBox}
            query_string={query_string} getImage={getImage} 
            handleSeeOtherWhatsappConversation={handleSeeOtherWhatsappConversation} 
            inbox={inbox} type={type} activePage={activePage} 
            activeTab={activeTab} routePath={route_path} 
          />
        : null
      }

    </React.Fragment>
  );
}

export default MessagesInboxCard;