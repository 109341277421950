import React, { useEffect, useState } from "react";
import { Container, Spinner } from 'react-bootstrap';
import './profile.scss';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../store';
import axios from 'axios';
// import queryString from 'query-string';
import { 
    // getAccessToken, triggerTwitterLogin,
    triggerGoogleLogin, getUserPermissions, 
    setAppAccessTypeMessage, triggerGoogleSignup, 
    triggerGoogleConnection, setGoogleSignupPayload,
    // resetTwitterRequestToken,
    // triggerTwitterConnection, triggerTwitterAuthData,
    // triggerGetIntegrationConnections,
} from "../signup-login/loginReducer";
import { useNavigate } from "react-router-dom";
import SignUpModalForm from "../components/SignUpModal/SignUpModaForm";
import { api_url, auth_url } from "../utils/server";
import { setErrorMessage } from "../dashboard/reducer";
// import Cookies from 'js-cookie';
import { getWorkspaceSettings } from "../pages/workspace-management/workSpaceReducer";
// import { findWorkspaceList } from "../components/layout/header/headerReducer";

const Profile: React.FC = () => {
    const {workspaces} = useSelector((state: any) => state?.headerState);
    const [openState, setOpenState] = useState(false);
    const google_state: any = useSelector((state: RootState) => state?.login);
    const {
        // loginUserDetails,
        clapupUserId,
        googleAccessData,
        googleSignupData,
        sign_up_failed,
        // googleSignupPayload, 
        // twitter_auth_data
    }: any = google_state;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    let access_token = window.location.href?.split("access_token")[1]?.split("scope")[0].split("&")[0].split("=")[1];
    const app_connect = sessionStorage.getItem('app_connect');
    const google_connect = sessionStorage.getItem("google_connect");

    const accessType = localStorage.getItem('appAccessType');
    // const getGoogleLoginID = (code: any, client_details?: any) => {
    const getGoogleLoginID = (google_data?: any) => {
        // const googleCode = window.location.href?.split("code")[1]?.split("scope")[0].split("&")[0].split("=")[1];
        const googleCode = window.location.href?.split("code")[1]?.split("scope")[0].split("&")[0].split("=");
        const { google_token: code, client_details } = google_data;
        console.log('code ====> ', { code, client_details, googleCode });
        if (client_details?.clientId) {
            dispatch(setGoogleSignupPayload(null));
            sessionStorage.setItem('google_code', code);
            axios.post(`${auth_url}/oauthCallback?redirect=https://${window.location.host}/profile`, { code, ...client_details })
            .then((res) => {
                // console.log('res data ===99==> ', {res});
                if (res?.data?.tokens) {
                    const { access_token, refresh_token, id_token, expiry_date  } = res?.data?.tokens;
                    const app_connect = sessionStorage.getItem('app_connect');
                    // console.log("Access Type", accessType);
                    console.log("App Connect", app_connect);
                    if (google_connect === 'google-connection') {
                        localStorage.setItem('integrated_user_details', JSON.stringify(res?.data?.userdetails));
                        const {id, name, email} = res?.data?.userdetails;
                        // setIdToken(id_token);
                        const integration_obj = {
                            accessToken: access_token,
                            refreshToken: refresh_token,
                            idToken: id_token,
                            expiresIn: expiry_date,
                            workspaceId: sessionStorage.getItem('wuid'),
                            gmbUserID: id,
                            isServiceUser: true,
                            emailId: email,
                            userName: name,
                            userDisplayName: name,
                        };
    
                        console.log({ integration_obj });
    
                        axios.post(api_url + '/api/v1.2/google/UpdateGMBAccount', integration_obj, {
                            headers: {
                              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                              'Content-Type': 'application/json'
                            }
                        })
                        .then((data) => {
                            const { data: googleData, status }: any = data;
                            console.log('data ====> ', data);
                            if (status === 200) {
                                // sessionStorage.removeItem('app_connect');
                                dispatch(triggerGoogleConnection({data: googleData, status, id_token: btoa(id_token)}));
                                dispatch({type: "SET_INTEGRATION_TYPE", payload: "google"});
                                // setIntegrationType('google');
                                // navigate('/workspace?ws_settings=integrations');
                                setTimeout(() => {
                                    navigate('/workspace/integrations');
                                }, 1500);
                            }
                        });
                    } 
                    else if (accessType === 'google-login') {
                        sessionStorage.setItem("accessed_user_details", JSON.stringify(res?.data?.userdetails));
                        localStorage.setItem("accessed_user_details", JSON.stringify(res?.data?.userdetails));
                        sessionStorage.setItem('cuid', clapupUserId);
                        const inviteId = localStorage.getItem('invitation_id');
                        // sessionStorage.removeItem('app_connect');
                        console.log("Google Sign in id token =============== ", {id_token, inviteId});
                        dispatch(triggerGoogleLogin({ idToken: id_token, inviteId: inviteId || "" }));
                    } 
                    else if (accessType === 'google-signup') {
                        sessionStorage.setItem("accessed_user_details", JSON.stringify(res?.data?.userdetails));
                        localStorage.setItem("accessed_user_details", JSON.stringify(res?.data?.userdetails));
                        sessionStorage.setItem('cuid', clapupUserId);
                        console.log("Google Signup id token =============== ", id_token);
                        dispatch(triggerGoogleSignup({idToken: id_token}));
                        // setOpenState(true);
                        // navigate('/signup', { state: { id_token } });
                        // localStorage.setItem('isCreatedWorkspace', "true");
                    }
                    // else {
                    //     dispatch(setErrorMessage("You are not authorized, please try again"));
                    //     dispatch({type: "LOGOUT"});
                    //     navigate('/login');
                    // }
                }
            }).catch((err) => {
                console.log('err ====> ', err?.response?.data?.message);
                dispatch({type: "LOGOUT"});
                dispatch(setErrorMessage(err?.response?.data?.message || "Something went wrong, please try again"));
                navigate('/login');
            });
        }
        // else { 
        //     dispatch({ type: "LOGOUT" });
        //     navigate("/login", { replace: true });
        //     dispatch(setErrorMessage(accessType === 'google-signup' ? "Signup Failed": "Server Error"));
        // }
    }
    
    const toggleModal = () => {
        setOpenState(!openState);
    }
    
    useEffect(() => {
        const googleCode = window.location.href?.split("code")[1]?.split("scope")[0].split("&")[0].split("=")[1];
        const google_code = sessionStorage.getItem("google_code");
        console.log("Google Response ====> ", { google_code });
        console.log("Google Response ====> ", { google_response: window.location.href });
        if (googleCode) {
            console.log("=====================", { app_connect, googleCode });
            if ((googleCode || google_code) && google_connect === 'google-connection') {
                console.log({ googleCode });
                sessionStorage.setItem("isLoading", "true");
                const client_details = JSON.parse(sessionStorage.getItem("client_details") || "{}");
                if (client_details) {
                    getGoogleLoginID({google_token: googleCode, client_details});

                }
                sessionStorage.removeItem("client_details");
            }
            else if (googleCode && !google_code) {
                sessionStorage.setItem("isLoading", "true");
                const client_details = JSON.parse(sessionStorage.getItem("client_details") || "{}");
                const google_token = googleCode || access_token;
                console.log({ googleCode, client_details});
                if (client_details?.clientId) {
                    getGoogleLoginID({google_token, client_details});
                } else {
                    dispatch({ type: "LOGOUT" });
                    navigate("/login", { replace: true });
                    sessionStorage.removeItem("client_details");
                }
                
            }
            else if(!google_code && accessType !== 'google-signup'){
                // console.log("=======We will redirect to login page=======");
                const sessionAuth = sessionStorage.getItem("cuid") && sessionStorage.getItem("roleId") && sessionStorage.getItem("wuid");
                if(!sessionAuth){
                    navigate("/login", { replace: true });
                }
            }
        }
    }, []);
    // }, [googleCode]);

    const { userPermissions } = useSelector((state: any) => state?.login);
    
    const processData = () => { 
        let uid;
        let roleId;
        let wuid;
        let sessionAuth;
        try {
            // ==== workspace creating time => if we want to refresh ====
            if(localStorage.getItem('isCreatedWorkspace') === "true"){
                console.log({newWorkspace_userId: localStorage.getItem('isCreatedWorkspace')});
                setOpenState(true);
            }

            // ==== workspace creating time ====


            // else if (!workspaces?.length) {
            //     // dispatch(findWorkspaceList());
            //     // dispatch(getWorkspaceSettings());
            // } 
            if (workspaces?.length) {
                // console.log({workspaces});
                
                localStorage.setItem('isCreatedWorkspace', "false");
                setOpenState(false);
                const {userId, roleId: role_id, workSpaceId}: any = workspaces?.length ? workspaces?.find((item: any) => item?.userId === sessionStorage.getItem('cuid')) : {};
                uid = userId;
                roleId = role_id;
                wuid = workSpaceId;
                sessionAuth = (uid && roleId && wuid) ? true : false;
            }
                
            // console.log({googleSignupData, accessType, googleAccessData, app_connect, google_connect, sessionAuth, workspaces});
            // const inviteId = localStorage.getItem('invitation_id');
            // if (sessionAuth && !inviteId) {
            if (sessionAuth) {
                if(userPermissions?.length){
                // if(userPermissions?.length && !app_connect && !google_connect){
                    setTimeout(() => {
                        navigate('/dashboard');
                        // localStorage.removeItem('invitation_id');
                        setTimeout(() => sessionStorage.removeItem("isLoading"), 2000);
                        //   sessionStorage.removeItem('isLoading');
                    }, 1000);
                } else {
                    // dispatch(getUserPermissions({
                    //     userId: uid,
                    //     roleId: roleId,
                    //     workspaceId: wuid,
                    // }));
                    // dispatch(getWorkspaceSettings());
                }
                return;
            }

            if (googleSignupData || googleAccessData) {
                console.log('Google Access Data ====> ', { googleAccessData, accessType, app_connect });
                sessionStorage.removeItem('appAccessType');

                // =================================================================
                // googleSignupData?.success will be replaced !googleSignupData?.success
                // if (googleAccessData?.success && accessType === 'google-login' && inviteId) {
                //     sessionStorage.removeItem("isLoading");
                // } else
                if (googleAccessData?.success && accessType === 'google-login') {
                    sessionStorage.removeItem("isLoading");
                    navigate('/dashboard');
                    // window.location.reload();
                }
                else if (!googleAccessData?.success && accessType === 'google-login') {
                    sessionStorage.removeItem("isLoading");
                    dispatch(setErrorMessage(googleAccessData?.errors[0] || "Login failed"));
                    // dispatch(setErrorMessage(googleAccessData?.errors[0] || "Something went wrong. Please try again later."));
                    dispatch({ type: "LOGOUT" });
                    navigate("/login", { replace: false });
                }
                else if (process.env.NODE_ENV === "development" && accessType === 'google-signup' && !app_connect) {
                    sessionStorage.removeItem("isLoading");
                    dispatch(setAppAccessTypeMessage(`User signed up successfully by google. ${googleSignupData?.errors?.length && ("But " + googleSignupData?.errors[0])}`));
                    // sessionStorage.clear();
                    // localStorage.clear();
                    // navigate("/signup", { replace: true });
                    // setOpenState(true);
                }
                else if (googleSignupData?.success && accessType === 'google-signup' && !app_connect) {
                    sessionStorage.removeItem("isLoading");
                    dispatch(setAppAccessTypeMessage(`User signed up successfully by google. ${googleSignupData?.errors?.length && ("But " + googleSignupData?.errors[0])}`));
                    // sessionStorage.clear();
                    // localStorage.clear();
                    // navigate("/signup", { replace: true });
                    setOpenState(true);
                }
                else if (!googleSignupData?.success && accessType === 'google-signup') {
                    sessionStorage.removeItem("isLoading");
                    dispatch(setErrorMessage(googleSignupData?.errors[0] || "Server error"));
                    dispatch({ type: "LOGOUT" });
                    sessionStorage.clear();
                    localStorage.clear();
                    navigate("/signup", { replace: true });
                }
            }
            else if (!sessionAuth && sign_up_failed) { 
                dispatch({ type: "LOGOUT" });
                navigate("/login", { replace: true });
                dispatch(setErrorMessage(accessType === 'google-signup' ? "Signup Failed": "Server Error"));
            }
            
        } catch (error: any) {
            // console.log({error: googleAccessData?.errors[0] || error});
            dispatch(setErrorMessage( accessType === 'google-login' ? "Login Failed" : accessType === 'google-signup' ? "Signup Failed": "Server Error"));
            dispatch({ type: "LOGOUT" });
            navigate("/login", { replace: true });
        }
    };

    useEffect(() => {
        console.log("googleSignupData => ", { googleSignupData });
        processData();
    }, [
        dispatch,
        googleAccessData?.success,
        sign_up_failed,
        googleSignupData?.success,
        app_connect, workspaces?.length,
        userPermissions?.length
    ]);

    // window.addEventListener("beforeunload", function (event) {
    // //your code goes here on location change 
    //     console.log("============636 ===========", {event})
    // });
    return (
        <React.Fragment>
            {(openState) && <SignUpModalForm open={openState} parentCallback={toggleModal} />}
            {
                (sessionStorage.getItem('isLoading') !== 'true')
                ? <div>
                    <Container fluid>
                        {/* <h3>Profile</h3> */}
                    </Container>
                    {/* <Modal open={openState} children={pageListContent} parentCallback={toggleModal} /> */}
                    
                </div>
                : (sessionStorage.getItem('isLoading') === 'true') 
                ? <div className="d-flex justify-content-center align-items-center" style={{height: "80vh", width: '100%'}}>
                    <Spinner animation="border" variant="primary" />
                </div>
                : <div className="d-flex justify-content-center align-items-center" style={{height: "80vh", width: '100%'}}>
                    <h4>No Data Found</h4>
                </div>

            }
        </React.Fragment>
    )
}

export default Profile;