import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import {Button, Descriptions, Table} from 'antd';
import AntTable from '../../../../../components/AntTable';
import Facebook from "../../../../../assets/icons/facebook-reviews.svg";
import cross_icon from "../../../../../assets/icons/cross-svgrepo.svg";
import CommonModal from '../../../../../components/CommonModal/CommonModal';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
// import queryString from 'query-string';
// import axios from 'axios';
import { RootState } from '../../../../../store';
import { useDispatch } from 'react-redux';
import {
  // getAccessToken, resetTwitterRequestToken, triggerTwitterConnection,
  triggerDisconnectIntegrationConnection, triggerFacebookConnection,
  setFacebookConnection,
  // triggerGetIntegrationConnections, triggerTwitterLogin
} from '../../../../../signup-login/loginReducer';
// import { api_url, auth_url } from '../../../../../utils/server';
import {
  toggleShowFbPageMapModal, 
  triggerMapFacebookConnectionPages,
  // triggerFacebookConnectionPages
} from '../../../workSpaceReducer';
import Dialog from '../../../../../components/dialogs/dialog';
import { fb_app_id } from '../../../../../utils/server';
// import { generateAccessToken } from '../../../../../profile/reducer';
// // import queryString from 'query-string';
// import { Spinner } from 'react-bootstrap';
// import { getProfileInfo } from '../../../../profile-management/profileManageReducer';

// const Google = "https://images.clapup.io/clapupapp/btn_google_light_normal_ios.svg";
declare global {
  interface Window {
    FB: any; // Assuming 'FB' has any type
    fbAsyncInit: () => void;
  }
}

const FacebookIntegration: React.FC = () => {
  const dispatch = useDispatch();
  const query_string = window.location.search;
  const {
    facebook_connected_mapped_pages,
    show_fb_page_map_modal
  } = useSelector((state: any) => state?.workspaceManagementState);
  // const twitterRequestToken = useSelector((state: RootState) => state?.login?.requestTokens);
  const {
    integration_type,
    // twitter_connection_data,
    facebook_connection_data,
    integrationConnections,
    userPermissions,
  }: any = useSelector((state: RootState) => state?.login);

  // const [loading, setLoading]: any = React.useState(false);
  const { personalInfo } = useSelector((state: any) => state?.profileManageSettingState);
  const { email }: any = personalInfo || {};

  // ========== Facebook connection of window credential =======
  useEffect(() => {
    // Load the Facebook SDK script
    if (window) {
      (function(d: any, s: any, id: any) {
        let js: any, fjs: any = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
  
      // Initialize the SDK once the script is loaded
      window.fbAsyncInit = function() {
        window.FB.init({
          appId      : '254750246571176', // Replace with your Facebook App ID
          // clientId      : '254750246571176', // Replace with your Facebook App ID
          cookie     : true,  // Enable cookies to allow the server to access the session
          xfbml      : true,  // Parse social plugins on this webpage
          version    : 'v20.0' // Use this Graph API version
        });
  
        // // Initialize Business SDK
        // window.FB.BusinessSDK.init({
        //   configurationId: '3387082161438539', // Replace with your Configuration ID
        //   response_type: 'code',
        //   override_default_response_type: true,
        // });
  
        // Check login status
        window.FB.getLoginStatus(function(response: any) {
          // statusChangeCallback(response);
          console.log({login_status_response: response });
        });
      };
      
    }
  }, []);

  // ========== Facebook connection of window credential =======


  // useEffect(() => {
  //   const { access_token }: any = queryString.parse(
  //     window.location.href.split("?")[1]
  //   );
  //   // if (email) {
  //   //   // setLoading(true);
  //   //   if (!access_token) {
  //   //     setTimeout(() => {
  //   //       setLoading(false);
  //   //     }, 1500);
  //   //   }
  //   // } else {
  //   //   setLoading(true);
  //   //   dispatch(getProfileInfo());
  //   // }
  //   // setTimeout(() => {
  //   //   setLoading(false);
  //   // }, 5000);
  // }, [dispatch, email]);

  const [facebook_modal_visible, setFacebookModalVisible]: any = React.useState(false);
  const [facebook_table_data, setFacebookTableData]: any = React.useState([]);
  const [facebook_table_columns, setFacebookTableColumns]: any = React.useState([]);
  useEffect(() => {
    if (facebook_table_columns?.length && !facebook_modal_visible) {
      console.log({facebook_table_columns: facebook_table_columns?.length});
      integration_type === "facebook" && setFacebookModalVisible(true);
    }
   }, [facebook_table_columns?.length]);

  
  // const path = window.location.pathname;
  // const [connectionData, setConnection] = React.useState();

  // const redirect = auth_url;
  // const fb_access_token = sessionStorage.getItem("fb_access_token");
  React.useEffect(() => {
    try{
        // const query = query_string.replace('?ws_settings=', '');
        // console.log({ connection_type });
        // console.log({path, query});
        // const { access_token, code }: any = queryString.parse(
        const { code }: any = queryString.parse(
        window.location.href.split("?")[1]
        );
        if (code) {
          console.log({ code });
          const redirectUri = `${window.location.origin}/workspace/integrations`;
          const dataObj: any = {
            code,
            redirectUri: encodeURIComponent(redirectUri),
            workspaceId: sessionStorage.getItem("wuid") || localStorage.getItem("wuid"),
            clapupUserId: sessionStorage.getItem("cuid") || localStorage.getItem("cuid"),
          };
          console.log({ dataObj });
          dispatch(triggerFacebookConnection(dataObj));
          // dispatch(toggleShowFbPageMapModal(true));
          history("/workspace/integrations");
        }
        // if (access_token) {
        //     sessionStorage.setItem("fb_access_token", access_token);
        //     axios.get(`https://graph.facebook.com/me?fields=name,id,gender,location,picture,email,birthday&access_token=${access_token}`)
        //     .then((res) => {
        //     // console.log('response  ======71=====>', res);
        //     if (res?.data) {
        //         console.log("response_data  ======48=====>", res.data);
        //         const facebookObj: any = {
        //             facebookUserId: res?.data?.id,
        //             facebookUserName: res?.data?.name,
        //             clapupUserId: sessionStorage.getItem("cuid"),
        //             roleId: sessionStorage.getItem("roleId"),
        //             workspaceId: sessionStorage.getItem("wuid"),
        //             authAccessToken: access_token,
        //         };
        //         res?.data?.email
        //         ? (facebookObj.facebookUserEmail = res.data.email)
        //         : (facebookObj.facebookUserEmail =
        //             "mmhk.mmhkitsforyou@gmail.com");
        //         console.log({ facebookObj });
                
        //         // dispatch(getProfileInfo());
        //         dispatch(triggerFacebookConnection(facebookObj));
        //         dispatch(toggleShowFbPageMapModal(true));
        //         // get_integration_connections();
        //         dispatch({
        //             type: "SET_INTEGRATION_TYPE",
        //             payload: "facebook",
        //         });
        //         sessionStorage.setItem("fb_user_id", res?.data?.id);
        //         sessionStorage.removeItem("app_connect");
        //         sessionStorage.removeItem("isLoading");
        //         sessionStorage.removeItem("fb_access_token");
        //         // history("/workspace/integrations");
        //     }
        //     })
        //     .catch((err) => {
        //         console.log("err data  =========>", err);
        //     });
        
        // }
    } catch (err: any) {
        console.log("Error fb connection: ", err);
    }
  }, [dispatch, query_string]);
  // Old One
  const handleFacebookConnect = () => {
    sessionStorage.setItem("isLoading", "true");
    sessionStorage.setItem("app_connect", "facebook-connection");
    // console.log("Connect to Facebook");
    // setIntegrationType("facebook-connection");

    // const redirectUri = `${window.location.origin}/workspace/integration/facebook`;
    const redirectUri = `${window.location.origin}/workspace/integrations`;
    // const redirectUri = `${window.location.origin}/workspace?ws_settings=integrations`;
    console.log({ redirectUri });
    // axios.get(api_url + `/api/v1.2/facebook/FacebookAuthUrl?redirectUrl=${redirectUri}`)
    // .then((res: any) => {
    //   if(res?.data) {
    //     window.location.href = res?.data;
    //     // window.location.href = `https://www.facebook.com/v12.0/dialog/oauth?app_id=1133792337115010&redirect_uri=${redirectUri}&response_type=token,signed_request,graph_domain&sdk=joey&version=v2.3&scope=email`;
    //   }
    // })

    const appId = fb_app_id || "254750246571176";
    // const configId = "3387082161438539";
    // const fb_auth_url = `https://www.facebook.com/v12.0/dialog/oauth?app_id=${appId}&config_id=${configId}&redirect_uri=${redirectUri}&response_type=token,signed_request,graph_domain&sdk=joey&version=v2.3&scope=email`;
    // const fb_auth_url = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=email,public_profile,pages_show_list,pages_read_user_content,pages_manage_engagement`;
    // const fb_auth_url = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=email,public_profile,pages_show_list,pages_read_engagement,pages_read_user_content,pages_manage_engagement`;
    const fb_auth_url = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=email,public_profile,pages_show_list,pages_read_engagement,pages_read_user_content,pages_manage_engagement`;
    console.log({ fb_host: fb_auth_url });
    
    window.location.href = fb_auth_url

    // // authorized to token request
    // axios.get(
    //   `${api_url}/api/v1.2/clapup/getKeys?provider=Facebook`,
    //   {
    //     headers: {
    //       'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
    //       'Content-Type': 'application/json'
    //     }
    //   }
    // ).then(response => {
    //   // console.log({ response });
    //   // const appId = "1181528909962277";
    //   const fb_auth_url = `https://www.facebook.com/v12.0/dialog/oauth?app_id=${response?.data?.appId}&redirect_uri=${redirectUri}&response_type=token,signed_request,graph_domain&sdk=joey&version=v2.3&scope=email`;
    //   console.log({ fb_host: fb_auth_url });
      
    //   window.location.href = fb_auth_url;
      
    // }).catch((error: any) => {
    //   console.log('error: ', error);
    //   const { response } = error;
    //   const { status }: any = response;
    //   if ((status === 403 || status === 401)) {
    //     dispatch(generateAccessToken());
    //   }
    // });
      
    // // window.location.href = `https://www.facebook.com/v12.0/dialog/oauth?app_id=848535813124549&redirect_uri=${redirectUri}&response_type=token,signed_request,graph_domain&sdk=joey&version=v2.3&scope=email`;
    // // window.location.href = "https://m.facebook.com/v2.3/dialog/oauth?app_id=848535813124549&cbt=1670404473740&channel_url=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df138cc517dfaac%26domain%3Dlocalhost%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Flocalhost%253A4000%252Ff206c87397aaddc%26relation%3Dopener&client_id=848535813124549&display=touch&domain=localhost&e2e=%7B%7D&fallback_redirect_uri=https%3A%2F%2Flocalhost%3A4000%2Fworkspace%3Fws_settings%3Dintegrations&locale=en_US&logger_id=f1875da449b0ca8&origin=2&redirect_uri=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df2954142793db4c%26domain%3Dlocalhost%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Flocalhost%253A4000%252Ff206c87397aaddc%26relation%3Dopener%26frame%3Df18d07f88eeea7c&response_type=token%2Csigned_request%2Cgraph_domain&sdk=joey&version=v2.3";

    // // axios.get("https://graph.facebook.com/oauth/access_token?client_id=848535813124549&client_secret=27146ebb56a56dca78639bf900986b8f&grant_type=client_credentials")
    // // .then((res) => {
    // //     console.log('res data  =====54====>', res);
    // //     const {access_token}: any = res?.data;

    // //     // window.location.href = `https://graph.facebook.com/474455308116896/accounts?access_token=${access_token}`;
    // // })
    // // .catch((err) => {
    // //     console.log('err data  =========>', err);
    // // });
  };

  // // New One ==== Facebook Connection
  // Function to handle the login status
  // const statusChangeCallback = (response: any) => {
  //   console.log('Facebook login status: ', response);
  //   if (response?.status === 'connected') {
  //     // Logged into your app and Facebook
  //     console.log('User is logged in');
  //     fetchUserProfile();
  //     fetchMarketingData();
  //   } else {
  //     // Not logged into your app or not logged into Facebook
  //     console.log('User is not logged in');
  //   }
  // };

  // // Fetch user profile data
  // const fetchUserProfile = () => {
  //   window.FB.api('/me', { fields: 'name, email' }, function(response: any) {
  //     console.log('User profile: ', response);
  //   });
  // };

  // // Fetch marketing data
  // const fetchMarketingData = () => {
  //   // Use Facebook Marketing API to fetch marketing data
  //   window.FB.api('/me/adaccounts', 'GET', {}, function(response: any) {
  //     console.log('Marketing data: ', response);
  //   });
  // };

  // const handleFacebookConnect = () => {
  //   sessionStorage.setItem("isLoading", "true");
  //   sessionStorage.setItem("app_connect", "facebook-connection");
  //   // console.log("Connect to Facebook");
  //   // setIntegrationType("facebook-connection");
  //   const clientId = "254750246571176";
  //   const configId = "7897989166959591";
  //   // const redirectUri = `${window.location.origin}/workspace/integration/facebook`;
  //   const redirectUri = `${window.location.origin}/workspace/integrations`;

  //   // const redirectUri = `${window.location.origin}/workspace/integrations`;
  //   console.log({ fb_redirectUri: encodeURIComponent(redirectUri) });
  //   // axios.get(api_url + `/api/v1.2/facebook/FacebookAuthUrl?redirectUrl=${redirectUri}`)
  //   // const fb_auth_url = `https://www.facebook.com/v12.0/dialog/oauth?app_id=${"254750246571176"}&redirect_uri=${redirectUri}&response_type=token,signed_request,graph_domain&sdk=joey&version=v2.3&scope=email`;
  //   // .then((res: any) => {
  //   //   if(res?.data) {
  //   //     window.location.href = res?.data;
  //   //     // window.location.href = `https://www.facebook.com/v12.0/dialog/oauth?app_id=1133792337115010&redirect_uri=${redirectUri}&response_type=token,signed_request,graph_domain&sdk=joey&version=v2.3&scope=email`;
  //   //   }
  //   // })
  //   // authorized to token request
  //   axios.get(
  //     `${api_url}/api/v1.2/clapup/getKeys?provider=Facebook`,
  //     {
  //       headers: {
  //         'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
  //         'Content-Type': 'application/json'
  //       }
  //     }
  //   ).then(response => {
  //     // console.log({ response });
  //     window.FB.login(function (response: any) {
  //       console.log({ response });
  //       try {
  //         // const {code} = response?.authResponse;
  //         // if(code){
  //         //   const dataObj: any = {
  //         //     code,
  //         //     redirectUri: encodeURIComponent(redirectUri),
  //         //     workspaceId: sessionStorage.getItem("wuid") || localStorage.getItem("wuid"),
  //         //     clapupUserId: sessionStorage.getItem("cuid") || localStorage.getItem("cuid"),
  //         //   };
            
  //         //   dispatch(triggerFacebookConnection(dataObj));
  //         // }
  //       } catch (err: any) {
  //         console.log({ err });
  //       }
  //       // statusChangeCallback(response);
  //     },
  //       {
  //         // scope: 'public_profile,email,ads_read',
  //         scope: 'public_profile,email,pages_show_list,pages_read_engagement,pages_read_user_content',
  //         redirect_uri: redirectUri,
  //         // redirect_uri: encodeURIComponent(redirectUri),
  //         // client_id: clientId,
  //         // config_id: configId, // Replace with your Configuration ID
  //         // client_id: response?.data?.appId,
  //         auth_type: 'rerequest',
  //         // display: 'popup',
  //         response_type: 'code',
  //         // override_default_response_type: true,
  //       }
  //     );

  //     // New One
  //     // const oauthUrl = createOAuthUrl(clientId, redirectUri);
  //     // const popup = openPopup(oauthUrl, 'oauthPopup');
  //     // if (popup) {
  //     //   listenForOAuthToken(popup, redirectUri, (token) => {
  //     //     if (token) {
  //     //       console.log('OAuth Token: => ', token);
  //     //       // Handle the token, e.g., save it or use it to fetch user data
  //     //     } else {
  //     //       console.error('OAuth flow failed or was cancelled.');
  //     //     }
  //     //   });
  //     // }

  //     // Old One
  //     // const fb_auth_url = `https://www.facebook.com/v12.0/dialog/oauth?app_id=${response?.data?.appId}&redirect_uri=${redirectUri}&response_type=token,signed_request,graph_domain&sdk=joey&version=v2.3&scope=email`;
  //     // const fb_auth_url = `https://www.facebook.com/v12.0/dialog/oauth?app_id=${"app_id"}&redirect_uri=${redirectUri}&response_type=token,signed_request,graph_domain&sdk=joey&version=v2.3&scope=email`;

  //     // console.log({ fb_host: fb_auth_url });
      
  //     // window.location.href = fb_auth_url
      
  //   }).catch((error: any) => {
  //     console.log('error: ', error);
  //     const { response } = error;
  //     const { status }: any = response;
  //     if ((status === 403 || status === 401)) {
  //       dispatch(generateAccessToken());
  //     }
  //   });
      
  //   // window.location.href = `https://www.facebook.com/v12.0/dialog/oauth?app_id=848535813124549&redirect_uri=${redirectUri}&response_type=token,signed_request,graph_domain&sdk=joey&version=v2.3&scope=email`;
  //   // window.location.href = "https://m.facebook.com/v2.3/dialog/oauth?app_id=848535813124549&cbt=1670404473740&channel_url=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df138cc517dfaac%26domain%3Dlocalhost%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Flocalhost%253A4000%252Ff206c87397aaddc%26relation%3Dopener&client_id=848535813124549&display=touch&domain=localhost&e2e=%7B%7D&fallback_redirect_uri=https%3A%2F%2Flocalhost%3A4000%2Fworkspace%3Fws_settings%3Dintegrations&locale=en_US&logger_id=f1875da449b0ca8&origin=2&redirect_uri=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df2954142793db4c%26domain%3Dlocalhost%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Flocalhost%253A4000%252Ff206c87397aaddc%26relation%3Dopener%26frame%3Df18d07f88eeea7c&response_type=token%2Csigned_request%2Cgraph_domain&sdk=joey&version=v2.3";

  //   // axios.get("https://graph.facebook.com/oauth/access_token?client_id=848535813124549&client_secret=27146ebb56a56dca78639bf900986b8f&grant_type=client_credentials")
  //   // .then((res) => {
  //   //     console.log('res data  =====54====>', res);
  //   //     const {access_token}: any = res?.data;

  //   //     // window.location.href = `https://graph.facebook.com/474455308116896/accounts?access_token=${access_token}`;
  //   // })
  //   // .catch((err) => {
  //   //     console.log('err data  =========>', err);
  //   // });
  // };

  // useEffect(() => {
  //     if (!integrationConnections?.length || !show_fb_page_map_modal) {
  //       sessionStorage.removeItem("isLoading");
  //       // get_integration_connections();
  //     }

  //     // console.log('twitter_connection_data ===== ===== ==> ', {twitter_connection_data});
  //     // if(!twitter_connection_data) {
  //     //   sessionStorage.setItem('isLoading', 'false');
  //     //   sessionStorage.removeItem('app_connect');
  //     //   get_integration_connections();
  //     // }

  // }, [
  //   dispatch,
  //   integration_type, 
  //   // show_fb_page_map_modal,
  //   integrationConnections?.length
  // ]);

  // const get_integration_connections = () => {
  //   const workspaceId = sessionStorage.getItem('wuid');
  //   dispatch(triggerGetIntegrationConnections(workspaceId));
  // }

  const handleDisconnect = (Provider?: any, ProviderUserId?: String) => {
    console.log('ConnectWarning ==>', {Provider, ProviderUserId});
    dispatch(triggerDisconnectIntegrationConnection({ Provider, ProviderUserId, WorkspaceId: sessionStorage.getItem('wuid') }));
    setTimeout(() => {
      // dispatch(getFacebookConnectionPages());
      setIsDialogShow(false);
      // dispatch(getTwitterConnectionData());
    }, 1000);
    // get_integration_connections();
      
  }


  // const user = JSON.parse(localStorage.getItem("integrated_user_details") || "{}");

  // useEffect(() => {
  //   if (integration_type === "twitter") {
  //     console.log("twitterRequestToken ====> ", twitterRequestToken);
  //   } 
  // }, [integration_type]);

  // facebook connection

  const [selectedFacebookPageIds, setSelectedFacebookPageIds] = useState<any[]>([]);

  const handleFacebookSelectedPages = (selectedRowKeys: any) => {
    console.log("selectedRowKeys ====> ", selectedRowKeys);
    setSelectedFacebookPageIds(selectedRowKeys);
  };

  const handleFacebookPagesContinue = () => {
    console.log("selectedFacebookPageIdsContinue ====> ", selectedFacebookPageIds);
    const pageIds = selectedFacebookPageIds?.join(",");
    const workspaceId = sessionStorage.getItem("wuid");
    const facebookPageMapWithWorkspaceObject = {
      pageIds,
      workspaceId,
      facebookAccountId: sessionStorage.getItem("fb_user_id")
    };
    console.log({ facebookPageMapWithWorkspaceObject });
    dispatch(triggerMapFacebookConnectionPages(facebookPageMapWithWorkspaceObject));
    // dispatch(toggleShowFbPageMapModal(false));
  };

  const [facebookAccountInfo, setFacebookAccountInfo] = useState<any>(null);
  const [facebookConnectedMappedPages, setFacebookConnectedMappedPages] = useState<any>([]);
  const [facebookConnectedPageColumns, setFacebookConnectedPageColumns] = useState<any>([]);

  // recent connected data
  useEffect(() => {
    if (facebook_connection_data?.facebookUserId) {
      // integration_type === "facebook" && setFacebookModalVisible(true);
      const { pageDetails } = facebook_connection_data?.integrationDetails;
      dispatch({ type: "SET_INTEGRATION_TYPE", payload: "" });
      if (!pageDetails?.length) {
        return;
      }
      console.log({facebook_connection_data, integration_type});
      setFacebookTableColumns([
        {
          title: "#",
          dataIndex: "idx",
          key: "idx",
        },
        {
          title: "Pages",
          dataIndex: "pageName",
          key: "pageName",
          align: "start",
        },
        
      ]);

      const table_data = pageDetails?.map((item: any, idx: number) => {
        // facebook_connection_data?.map((item: any, idx: number) => {
          return {
            ...item,
            id: item?.pageid,
            idx: idx + 1,
          };
      }) || [];
      const selectedRowKeys = table_data?.map((item: any) => (item?.isActive) && item?.id);

      selectedRowKeys?.length && setSelectedFacebookPageIds(selectedRowKeys);
      console.log("facebook_connection_data ====> " );
      
      console.log({ table_data,  facebook_connection_data});

      setFacebookTableData(table_data);
      // setFacebookModalVisible(true);
      // setFacebookModalVisible(true);
      dispatch(toggleShowFbPageMapModal(true));
      // get_integration_connections();
    } else {
      setFacebookTableData([]);
    }

  }, [dispatch, facebook_connection_data?.facebookUserId]);
  
  // old connected data
  useEffect(() => {
    // if (facebook_connection_page_data === "success") {
    //   // dispatch(getFacebookConnectionPages());
    //   get_integration_connections();
    // }
    if (facebook_connected_mapped_pages?.length) {
      console.log("facebook_connected_pages == 347 ====> ",facebook_connected_mapped_pages);
      setFacebookConnectedPageColumns([
        {
          title: "#",
          dataIndex: "id",
          key: "id",
          align: 'left'
        },
        {
          title: "Pages",
          dataIndex: "pageName",
          key: "pageName",
          align: "right",

        },
      ]);
      
      const table_data = facebook_connected_mapped_pages?.map(
        (item: any, idx: number) => {
          return {
            ...item,
            id: idx + 1,
          };
        }
      );
      
      setFacebookConnectedMappedPages(table_data);
    }
    // else {
    //   dispatch(getFacebookConnectionPages());
    // }

  }, [facebook_connected_mapped_pages?.length]);
  // continue button for google location services

  const history = useNavigate();

  useEffect(() => {
    // console.log({userPermissions});
    if (userPermissions?.length) {
      const permission = userPermissions?.find(
        (item: any) => item?.name === "integration"
      );
      if (permission) {
        permission?.value === 0 && history("/not-found");
      }
    }
  }, [history, userPermissions?.length]);
  const [twitterAccountInfo, setTwitterAccountInfo] = useState<any>(null);

  // console.log({ selectedFacebookPageIds, facebookAccountInfo });

  const [isDialogShow, setIsDialogShow] = useState<any>(null);
  const handleDialogCallback = (flag?: any) => {
    console.log({ flag });
    if (!flag) {
      setIsDialogShow(null);
    } else {
      if (isDialogShow === "Facebook" && integrationConnections?.length > 1) {
        handleDisconnect("Facebook", integrationConnections[2].UserId);
      }
      // else {
      //   handleDisconnect("Twitter", integrationConnections[1].UserId);
      // }
      setTimeout(() => {
        setIsDialogShow(null);
      }, 1500);
    }
  };

  useEffect(() => {
    const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "integration") : null;
    // const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "generalSettings-Account") : null;
    if(permission){
        permission?.value === 0 && history('/not-found');
    }
  }, [userPermissions?.length]);

  // console.log({show_fb_page_map_modal, facebook_table_data, integrationConnections});
  return (
    <React.Fragment>
      {/* Showing ==== Modal ======= Start */}
      {
        isDialogShow ?
          <Dialog
            open={isDialogShow}
            parentCallback={(flag?: any) => handleDialogCallback(flag)}
            classes='dialog-container-content'
            title='Disconnect?'
            warning_dialog_content={
              isDialogShow === "Google"
              ? "If you continue, all your data related to Google Business Profile will be deleted from our system"
              : isDialogShow === "Facebook" ? "If you continue, all your data related to Facebook Business Page will be deleted from our system"
              : null
            }
            footer={true}
            right_button_text='Yes, Continue'
            left_button_text='No, Cancel'
            // hover={true}
          />
        : null
        
      }

      {
        facebookAccountInfo?.UserId
        ? <CommonModal
            padding={"10px 5px"}
            title={
              <div className="d-flex flex-row justify-content-between align-items-center py-2">
                <p
                  style={{ fontSize: 20, fontWeight: 700 }}
                  className="text-capitaliz ps-3"
                >
                  Facebook Account Info.
                </p>
                <button
                  onClick={() => setFacebookAccountInfo(false)}
                  className={
                    "m-2 rounded rounded-circle btn btn-outline-light px-2 py-0"
                  }
                  style={{ color: "#FAFBFC", backgroundColor: "#ABB9CC" }}
                >
                  <img
                    className="mb-1 p-0"
                    height={10}
                    src={cross_icon}
                    alt=""
                  />
                </button>
              </div>
            }
            // closeIcon={true}
            modal_visible={facebookAccountInfo?.UserId ? true : false}
            centered={true}
            setModalVisible={setFacebookAccountInfo}
            footer={null}
          >
            <div style={{ padding: "0 24px" }}>
              <Descriptions
              // title="User Info"
              >
                {facebookAccountInfo?.UserName ? (
                  <Descriptions.Item
                    label="User Name"
                    children={facebookAccountInfo?.UserName}
                  />
                ) : null}

                {facebookAccountInfo?.UserId ? (
                  <Descriptions.Item
                    label="User Id"
                    children={facebookAccountInfo?.UserId}
                  />
                ) : null}

                {facebookAccountInfo?.DisplayName ? (
                  <Descriptions.Item
                    label="Display Name"
                    children={facebookAccountInfo?.DisplayName}
                  />
                ) : null}

                {facebookAccountInfo?.Provider ? (
                  <Descriptions.Item
                    label="Provider"
                    children={facebookAccountInfo?.Provider}
                  />
                ) : null}

                {facebookAccountInfo?.EmailId ? (
                  <Descriptions.Item
                    label="Email Id"
                    children={facebookAccountInfo?.EmailId}
                  />
                ) : null}
              </Descriptions>

              <Table
                // loading={facebookConnectedPageColumns?.length ? false : true}
                columns={facebookConnectedPageColumns}
                dataSource={facebookConnectedMappedPages}
                rowKey="id"
                pagination={false}
              />
            </div>
          </CommonModal>
        : null
      }

      {twitterAccountInfo ? (
        <CommonModal
          padding={"10px 5px"}
          title={
            <div className="d-flex flex-row justify-content-between align-items-center py-2">
              <p
                style={{ fontSize: 20, fontWeight: 700 }}
                className="text-capitaliz ps-3"
              >
                Twitter Account Info.
              </p>
              <button
                onClick={() => setTwitterAccountInfo(false)}
                className={
                  "m-2 rounded rounded-circle btn btn-outline-light px-2 py-0"
                }
                style={{ color: "#FAFBFC", backgroundColor: "#ABB9CC" }}
              >
                {/* font awesome icon fa-xmark in react */}
                {/* <FontAwesomeIcon icon={faTimes} /> */}
                <img
                  className="mb-1 p-0"
                  height={10}
                  src={cross_icon}
                  alt=""
                />
              </button>
            </div>
          }
          // closeIcon={true}
          modal_visible={twitterAccountInfo?.UserId ? true : false}
          centered={true}
          setModalVisible={setTwitterAccountInfo}
          footer={null}
        >
          <div style={{ padding: "0 24px" }}>
            <Descriptions
            // title="User Info"
            >
              {twitterAccountInfo?.UserName ? (
                <Descriptions.Item
                  label="User Name"
                  children={twitterAccountInfo?.UserName}
                />
              ) : null}

              {twitterAccountInfo?.UserId ? (
                <Descriptions.Item
                  label="User Id"
                  children={twitterAccountInfo?.UserId}
                />
              ) : null}

              {twitterAccountInfo?.DisplayName ? (
                <Descriptions.Item
                  label="Display Name"
                  children={twitterAccountInfo?.DisplayName}
                />
              ) : null}

              {twitterAccountInfo?.Provider ? (
                <Descriptions.Item
                  label="Provider"
                  children={twitterAccountInfo?.Provider}
                />
              ) : null}

              {twitterAccountInfo?.EmailId ? (
                <Descriptions.Item
                  label="Email Id"
                  children={twitterAccountInfo?.EmailId}
                />
              ) : null}
            </Descriptions>
          </div>
        </CommonModal>
      ) : null}
      {/* Showing ==== Modal ======= End */}
      
      {/* {
        loading
        ? <div className='loading-container w-100 d-flex justify-content-center align-items-center'>
            <Spinner animation="border" variant="primary" />
          </div>
          :
      } */}

      <div className='integration-container'>
        {/* Facebook */}
        <div className="card-container">
          <div className='logo-container'>
            <img
              className="logo-icon"
              src={Facebook}
              alt="facebook"
            />
          </div>
          <div className='card-content'>
            <h4 className='card-title'>Facebook Business Profile</h4>
            <div className='description-container'>
              <div className='card-description'>
                <p >A simple way to connect to your Facebook pages and manage recommendations, comments, and mentions</p>
              </div>
              <div className='button-container'>
                <button
                  type="button"
                  className="card-button"
                  onClick={() => {
                    // facebookConnectedMappedPages?.length
                    // integrationConnections?.length &&
                    // integrationConnections[2].UserId &&
                    // facebook_connected_mapped_pages?.length
                    // ? handleDisconnect(
                    //     "Facebook",
                    //     integrationConnections[2].UserId
                    //   )
                    // : handleFacebookConnect();
                    // if (facebook_connected_mapped_pages?.length) {
                    if (integrationConnections?.length > 1 && integrationConnections[2]?.UserId) {
                      setIsDialogShow("Facebook")
                    } else {
                      handleFacebookConnect();
                    }
                  }}
                >
                  {
                    integrationConnections?.length > 1 &&
                    // integrationConnections[2].UserId &&
                    // facebookConnectedMappedPages?.length
                    integrationConnections[2]?.UserId
                    // && facebook_connected_mapped_pages?.length
                    ? "Disconnect"
                    : "Connect"
                  }
                </button>
              </div>
            </div>
            {
              integrationConnections?.length > 1 && integrationConnections[2]?.UserId
              ? <div className='card-footer'>
                    <button
                      type='button' className='card-footer-button'
                      onClick={() => setFacebookAccountInfo(integrationConnections[2])}
                    >
                      Configure Pages</button>
                    {/* <p>{integrationConnections[2]?.DisplayName || integrationConnections[2]?.UserName || ""}</p> */}
                    <p>{integrationConnections[2]?.DisplayName || integrationConnections[2]?.UserName || ""}</p>
                </div>
              : null
            }
            
          </div>
        </div>
        
      </div>

      {
        facebook_table_data?.length ? (
        <CommonModal
          title={
            <h4 style={{ padding: "0 24px" }} className="header-title pt-3">
              {facebook_table_data?.length
                ? "Select Pages"
                : "No Pages found"}
            </h4>
          }
          // modal_visible={facebook_modal_visible}
          modal_visible={show_fb_page_map_modal}
          centered={true}
          setModalVisible={setFacebookModalVisible}
          okText="Continue"
          cancelText="Cancel"
          footer={[
            <div style={{ padding: "20px 24px" }} key="1">
              <Button
                className="common-btn"
                onClick={() => {
                  dispatch(toggleShowFbPageMapModal(false));
                  dispatch(setFacebookConnection(null));
                  // setFacebookModalVisible(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className="common-btn"
                onClick={() => handleFacebookPagesContinue()}
                // disabled={selectedFacebookPageIds?.length ? false : true}
              >
                Continue
              </Button>
            </div>,
          ]}
        >
          <div style={{ padding: "0 10px" }}>
            <AntTable
              handleSelectedRows={handleFacebookSelectedPages}
              table_columns={facebook_table_columns}
              table_data={facebook_table_data}
              selectedRowKeys={selectedFacebookPageIds}
            />
          </div>
        </CommonModal>
        ) : null
      }
      {/* {facebook_modal_visible && facebook_table_data?.length ? (
      ) : null} */}
      
    </React.Fragment>
  );
};

// const createOAuthUrl = (clientId: string, redirectUri: string): string => {
//   const params = new URLSearchParams({
//     client_id: clientId,
//     // display will be window
//     display: 'popup',
//     // display: 'page',
//     response_type: 'token',
//     redirect_uri: redirectUri
//   });

//   return `https://www.facebook.com/v20.0/dialog/oauth?${params.toString()}`;
// };

// const openPopup = (url: string, windowName: string, width = 500, height = 600): Window | null => {
//   const left = window.screenX + (window.outerWidth - width) / 2;
//   const top = window.screenY + (window.outerHeight - height) / 2;

//   return window.open(url, windowName, `width=${width},height=${height},top=${top},left=${left}`);
// };

// const listenForOAuthToken = (popup: Window | null, redirectUri: string, callback: (token: string | null) => void): void => {
//   const interval = setInterval(() => {
//     try {
//       if (!popup || popup.closed) {
//         clearInterval(interval);
//         callback(null);
//         return;
//       }

//       if (popup.location.href.startsWith(redirectUri)) {
//         const hash = new URL(popup.location.href).hash;
//         const token = new URLSearchParams(hash.slice(1)).get('access_token');

//         clearInterval(interval);
//         popup.close();
//         callback(token);
//       }
//     } catch (error) {
//       // Cross-origin error, ignore
//     }
//   }, 500);
// };
export default FacebookIntegration;
