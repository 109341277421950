import React from 'react';
import './../Conversations.scss';
import emptyBox from '../../../../assets/img/Empty.svg';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GoogleConversations from '../Conversations/GoogleConversation';
import FacebookConversations from '../Conversations/FacebookConversation';
import TwitterConversations from '../Conversations/TwitterConversation';
import FeedbackConversations from '../Conversations/FeedbackConversation';
import VideoConversations from '../Conversations/VideoConversation';
import { RootState } from '../../../../store';
import { toggleReplyingOn, toggleIsInReplyingMode, replyFacebookPost, triggerConversationDetails, setConversationDetailsPayload } from '../../../../dashboard/reducer';
import { api_url } from '../../../../utils/server';
import Dialog from '../../../dialogs/dialog';
// import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { getImage } from '../../../../dashboard/image_service';
import connection from '../../../../utils/signalRconnectionConversation';
interface Props {
    conversationId: string;
    // handleReplaceConversation: any;
    activePage: string;
    activeTab: string; 
    routePath: string;
}

const Conversations: React.FC<Props> = ({ conversationId,  activePage, activeTab, routePath }) => {
  // const {currentWorkspace: curWorkspace} = useSelector((state: any) => state?.headerState);
  const user_info = JSON.parse(
    sessionStorage.getItem("accessed_user_details") || "{}"
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { workspaceSettings } = useSelector((state: any) => state?.workspaceManagementState);
  const query_string = window.location.search;
  const { unifiedData: data, isReplyingOn, isInReplyingMode } = useSelector((state: any) => state?.unified);
  const {integrationConnections}: any = useSelector((state: RootState) => state?.login);
  const tag = query_string?.includes("reviews") ? "reviews" : query_string?.includes("mentions") ? "mentions" : query_string?.includes("comments") ? "comments" : query_string?.includes("qa") ? "qa" : "all";
  const {
    InboxAll_InboxAll, InboxAll_InboxAllnextDate,
    InboxReviews_InboxReviews, InboxReviews_InboxReviewsNextDate,
    InboxComments_InboxComments, InboxComments_InboxCommentsNextDate,
    InboxMentions_InboxMentions, InboxMentions_InboxMentionsNextDate,
    InboxQandA_InboxQandA, InboxQandA_InboxQandANextDate

  }: any = data || {};
  
  const unifiedData: any[] = tag === "all"
      ? InboxAll_InboxAll
      : tag === "reviews"
        ? InboxReviews_InboxReviews
      : tag === "comments"
        ? InboxComments_InboxComments
      : tag === "mentions"
        ? InboxMentions_InboxMentions
      : tag === "qa"
        ? InboxQandA_InboxQandA
      : [];
  
  // console.log({ unified_data: data });
  
  
  const timelineRef:any = React.useRef();
  const textRef:any = React.useRef();
  const [conversation, setConversation] = useState<any>(null);
  const [isReply, setIsReply] = useState<boolean>(false);
  const [replyText, setReplyText] = useState<any>(null);
  const [isRemovingReplyText, setIsRemovingReplyText] = useState<boolean>(false);
  const [sender, setSender] = useState<any>(null);
  
  // ===== SignalR start ====
  // Join a workspace (replace with actual workspace ID)
  const joinWorkspace = async (workspaceId: any) => {
      try {
          await connection.invoke("JoinWorkspace", workspaceId);
          console.log(`SignalR_Conversation: Joined workspace: ${workspaceId}`);
      } catch (error) {
          console.error("SignalR_Conversation: Error joining workspace:", error);
      }
  };

  // Receiving route
  useEffect(() => {
    console.log('SignalR_Conversation => ', {state: connection?.state, conversation});

    if (connection?.state === 'Connected' && conversation) {
      // console.log("SignalR_Conversation: => ", {state: connection?.state, email: user_info?.email, name: user_info?.name, reviewId: conversation?.reviewId, provider: conversation?.provider });


      if (sessionStorage.getItem('wuid') || localStorage.getItem('wuid')) {
          joinWorkspace(sessionStorage.getItem('wuid') || localStorage.getItem('wuid'));
      }

      const handleLockWhenTyping = (details: any) => {
        const sender_details = JSON.parse(details);
        const { isFromPrevious, name: sender_name, reviewId, provider, email, replyText } = sender_details || {};
        // console.log("SignalR_Conversation: received data:", {isFromPrevious, email_sender_details: sender_details, isInReplyingMode});
        
        if (isFromPrevious) {
          setSender(null);
          dispatch(toggleIsInReplyingMode(false));
        } else if (email !== user_info?.email && reviewId === conversation?.reviewId && provider === conversation?.provider) {
          console.log(`SignalR_Conversation User with ID ${sender_name} is typing`);
          if(replyText){
            setSender(sender_name);
            dispatch(toggleIsInReplyingMode(true));
          } else {
            setSender(null);
            dispatch(toggleIsInReplyingMode(false));
          }

          setTimeout(() => {
            setSender(null);
            dispatch(toggleIsInReplyingMode(false));
          }, 10000); // 10 mins = 600 sec
        }
      }

      // receives routes
      connection.on('LockWhenTyping', handleLockWhenTyping);

      // Clean up SignalR connection on component unmount
      return () => {
        connection.off('LockWhenTyping', handleLockWhenTyping);
        // leave workspace Id
        if (sessionStorage.getItem('wuid') || localStorage.getItem('wuid')) {
          connection.invoke("LeaveWorkspace", (sessionStorage.getItem('wuid') || localStorage.getItem('wuid'))).catch(err => console.error('Failed to leave workspace: ', err));
        }
      }

    }
  }, [connection?.state, conversation]);

  useEffect(() => {
    if (conversation && connection?.state === "Connected" && replyText !== null) {
      const sender_info = {
        isFromPrevious: true,
        replyText, email: user_info?.email,
        name: user_info?.name, reviewId:
        conversation?.reviewId, 
        provider: conversation?.provider
      };
      console.log("SignalR_Conversation invoked by data: => ", {state: connection?.state, replyText, email: user_info?.email, name: user_info?.name, reviewId: conversation?.reviewId, provider: conversation?.provider });
      if (replyText?.trim()) { 
        sessionStorage.setItem("prev_sender_info", JSON.stringify({ replyText: '', email: user_info?.email, name: user_info?.name, reviewId: conversation?.reviewId, provider: conversation?.provider }));
        sender_info.isFromPrevious = false;
      }

      // invoking routes
      const reviewExample = conversation?.reviewId;
      const providerExample = conversation?.provider;
      const workspaceId = sessionStorage.getItem('wuid') || localStorage.getItem('wuid');
      // connection.invoke('LockWhileTyping', true, JSON.stringify(sender_info), reviewExample, providerExample, workspaceId)
      connection.invoke('LockWhileTyping', true, JSON.stringify(sender_info), reviewExample, providerExample, workspaceId)
      .catch((error: any) => {
        console.error('SignalR_Conversation invoked: Failed to send typing status:', error);
      });

      // if (replyText?.length) {
      //   console.log("SignalR: => ", { replyText, email: user_info?.email, name: user_info?.name, reviewId: conversation?.reviewId, provider: conversation?.provider });
      //   connection.invoke('LockWhileTyping', true, sender_info, conversation?.reviewId, conversation?.provider)
      //   .catch((error: any) => {
      //     console.error('Failed to send typing status:', error);
      //   });
      // } 
      // else {
      //   console.log({email_replyText: replyText});
        
      //   connection.invoke('LockWhileTyping', false,  sender_info,  conversation?.reviewId, conversation?.provider)
      //   .catch((error: any) => {
      //     console.error('SignalR Failed to send typing status:', error);
      //   });
      // }  
    }
  }, [replyText]);

  // ===== SignalR End ====
  
  useEffect(() => {
    dispatch(toggleIsInReplyingMode(false));
    if(isRemovingReplyText){
      const prev_sender_info = JSON.parse(sessionStorage.getItem("prev_sender_info") || "{}");
      console.log({sign_prev: prev_sender_info});
      if (prev_sender_info?.reviewId && connection) {
        sessionStorage.removeItem("prev_sender_info");
        setReplyText("");   
      }
    }
  }, [isRemovingReplyText]);


  // after refreshing conversation details will be come by conversation_id
  useEffect(() => { 
    // timelineRef.current?.scrollIntoView({ behavior: "smooth" });
    // timelineRef.current?.scrollTo(0, timelineRef.current.scrollHeight);
    if (conversationId && unifiedData?.length) {
      const conversation = unifiedData?.find((conversation: any) => conversation?.reviewId === conversationId);
      console.log("Hello => ", {conversation, workspaceSettings});
      if(conversation){
        // textRef.current.value = conversation?.person_2?.message || "";
        setSender(null);
        setIsReply(false);
        // setReplyText("");   
        setConversation(conversation);
        const dataObj: any = {
          clapupUserId: sessionStorage.getItem('cuid'),
          roleId: sessionStorage.getItem('roleId'),
          workspaceId: sessionStorage.getItem('wuid'),
        }
        // console.log({dataObj});
          
        // if (conversation?.provider?.includes('google')) {
        //   // console.log({ conversation, workspaceSettings });
        //   const tag = conversation?.tag;
        //   // dataObj.locationId = conversation?.locationId;
        //   // dataObj.reviewerDisplayName = conversation?.reviewerDisplayName;
        //   // console.log({googleDataObj: dataObj, tag, conversation});
        //   if (tag?.includes('googleQandA')) {
        //     // dispatch(triggerConversationDetails(null, `${api_url}/api/v1.2/google/GetGoogleAnswer?questionId=${conversation?.reviewId}`, 'GET'));
        //   } else {
  
        //   }
        // } else 
        if (conversation?.provider?.includes('facebook')) {
          // dataObj.reviewerName = conversation?.reviewedbyUserName;
          const tag = conversation?.tag;
          if (tag === 'comments') {
            dataObj.pageId = conversation?.companyId;
            dataObj.postId = conversation?.postId || conversation?.PostId;
            dataObj.commentId = conversation?.reviewId;
            dataObj.facebookUserId = integrationConnections[2]?.UserId;
            console.log({ dataObj, conversation, integrationConnections });
            dispatch(setConversationDetailsPayload(dataObj, api_url + "/api/v1.2/facebook/GetPostDetailsByCommentId"));
            // if (dataObj.commentId && dataObj.postId) {
            // }
          } else if (tag === 'mentions') {
            dataObj.pageId = conversation?.companyId;
            dataObj.mentionId = conversation?.reviewId;
            console.log({dataObj, conversation });
            dispatch(setConversationDetailsPayload(dataObj, api_url + "/api/v1.2/facebook/GetMentionReplies"));
          } else if (tag === 'reviews') {
            dataObj.pageId = conversation?.reviewPageId || conversation?.companyId;
            dataObj.reviewId = conversation?.reviewId;
            console.log({ dataObj, conversation });
            dispatch(setConversationDetailsPayload(dataObj, api_url + "/api/v1.2/facebook/GetReviewReplies"));
            // dispatch(triggerFacebookReviewsUnifiedDetails(dataObj));
          }
          
        }
        else {
          dispatch(setConversationDetailsPayload(null, ""));
        }
        // else if (conversation?.provider?.includes('twitter')) {
        //     // will be added later
        //     console.log({dataObj});
        //     dispatch(triggerTwitterReviewsUnifiedDetails(dataObj));
        // // } else if(conversation?.provider === 'customerFeedback') {
        // } else if(conversation?.provider?.includes('customer')) {
        //     console.log({dataObj});
        //     dispatch(triggerCustomerFeedbackReviewsUnifiedDetails(dataObj));
        // } else if(conversation?.provider?.includes('video')) {
        //     console.log({dataObj});
        //     dispatch(triggerVideoTestimonialReviewsUnifiedDetails(dataObj));
        // }
      }
    }
  }, [conversationId]);

  // replying data
  const handleFBReply = (reply = replyText, currentData?: any, flag = "facebook") => {
    if (flag === "facebook") {
      const { commentId, replyId, pageId } = currentData;
      const { reviewId, reviewPageId, companyId, tag } = conversation;
      const replyPayload = {
        commentId: commentId || replyId || reviewId,
        comment: reply,
        pageId: pageId || reviewPageId || companyId,
        pageModule: tag
      };
      console.log({ replyPayload, reply, currentData, conversation });
      if (reply) {
        dispatch(replyFacebookPost(replyPayload));
        // dispatch(setSuccessMessage("Reply Updated Successfully!!!"));
        setReplyText("");
        textRef.current.value = "";
        setIsReply(false);
        dispatch(toggleReplyingOn(false));
        dispatch(toggleIsInReplyingMode(false));
      }
    } else {
      console.log({reply, currentData, conversation});
      if (reply) {
        // dispatch(setSuccessMessage("Reply Updated Successfully!!!"));
        setReplyText("");
        textRef.current.value = "";
        setIsReply(false);
        dispatch(toggleReplyingOn(false));
      }
    }
  }

  // others provider replying
  const handleReply = (reply = replyText, currentData?: any, flag = "facebook") => {
    if (reply) {
      console.log({ reply });
      setReplyText("");
      textRef.current.value = "";
      setIsReply(false);
      dispatch(toggleReplyingOn(false));
    } 
  }

  const [isGoForDetails, setIsGoForDetails] = useState<boolean>(false);
  const handleDetailChange = () => {
    sessionStorage.setItem('details', (!isGoForDetails) + '');
    
    setIsGoForDetails(!isGoForDetails);
  };

  useEffect(() => {
    const details = sessionStorage.getItem('details');
    // console.log({details});
    if(details === 'true'){
      setIsGoForDetails(true);
    } else {
      setIsGoForDetails(false);
    }
    
  }, [query_string]);
  const [movingPage, setMovingPage] = useState<any>(null);

  const handleReplaceConversation = (flag: string, inbox?: any) => {
    sessionStorage.setItem("details", isGoForDetails + "");
    
    const idx = unifiedData.findIndex(item => Number(new Date(item?.createdOn)) === Number(new Date(inbox?.createdOn)));
    console.log({flag, inbox, idx, unifiedData, key: Number(new Date(inbox?.createdOn))});
    if (flag === 'next') {
      const nextConversation = unifiedData[(idx + 1) >= (unifiedData?.length) ? (idx) : (idx+1)];
      // if (nextConversation && nextConversation?.reviewId !== conversationId) {
        // console.log({slNo_next_conv: nextConversation});
      if (nextConversation) {
        
        // if (nextConversation) {
        if(isReplyingOn){
          setMovingPage(`${routePath}?${activeTab}=${activePage}&cov=${nextConversation?.reviewId}`);
        } else {
          navigate(`${routePath}?${activeTab}=${activePage}&cov=${nextConversation?.reviewId}`);
        }
      }
    } else {
      const prevConversation = unifiedData[(idx - 1) < 1 ? 0 : (idx-1)];
      // console.log({prevConversation, SlNo: prevConversation?.SlNo, Idx: (idx) < 1 ? 1 : (idx - 1)});
      
      // if (prevConversation && prevConversation?.reviewId !== conversationId) {
      if (prevConversation) {
        if (isReplyingOn) {
          setMovingPage(`${routePath}?${activeTab}=${activePage}&cov=${prevConversation?.reviewId}`);
        } else { 
          navigate(`${routePath}?${activeTab}=${activePage}&cov=${prevConversation?.reviewId}`);
        }
      }
    }
  }

  const handlePrevPath = () => {
    sessionStorage.setItem("details", !isGoForDetails + "");
    if (isReplyingOn) {
      setMovingPage(`${routePath}?${activeTab}=${activePage}`);
    } else { 
      navigate(`${routePath}?${activeTab}=${activePage}`);
    }
  }

  const handlePageChange = (flag: string) => {
    if (!flag) {
      setMovingPage(null);
    } else {
      navigate(movingPage);
      setMovingPage(null);
    }
  }



  const {provider} = conversation || {};
  // console.log({person_1, person_2, properties, details});
  // console.log({fb_conv: conversation});
  
  return (
    <React.Fragment>
      {
        movingPage ?
          <Dialog
            open={movingPage}
            parentCallback={(flag?: any) => handlePageChange(flag)}
            classes='dialog-container-content'
            title='Continue?'
            warning_dialog_content={"If you confirm, all your edits will be lost. Locked message will be unlocked and editor will be accessible to others."}
            footer={true}
            left_button_text='No, Cancel'
            right_button_text='Yes, Continue'
            // hover={true}
          />
        : null
        
      }
      {conversation ? (
        provider?.includes("google")
        ? <GoogleConversations sender={sender} replyText={replyText} conversation={conversation} handlePrevPath={handlePrevPath} handleReplaceConversation={handleReplaceConversation} workspaceSettings={workspaceSettings} getImage={getImage} isReply={isReply} setIsReply={setIsReply} setReplyText={setReplyText} textRef={textRef} handleReply={handleReply} conversationId={conversationId} isGoForDetails={isGoForDetails} handleDetailChange={handleDetailChange} />
        : provider?.includes("facebook")
        ? <FacebookConversations sender={sender} replyText={replyText} conversation={conversation} handlePrevPath={handlePrevPath} handleReplaceConversation={handleReplaceConversation} workspaceSettings={workspaceSettings} getImage={getImage} isReply={isReply} setIsReply={setIsReply} setReplyText={setReplyText} textRef={textRef} handleReply={handleFBReply} conversationId={conversationId} isGoForDetails={isGoForDetails} handleDetailChange={handleDetailChange} />
        : provider?.includes("twitter")
        ? <TwitterConversations sender={sender} replyText={replyText} conversation={conversation} handlePrevPath={handlePrevPath} handleReplaceConversation={handleReplaceConversation} workspaceSettings={workspaceSettings} getImage={getImage} isReply={isReply} setIsReply={setIsReply} setReplyText={setReplyText} textRef={textRef} handleReply={handleReply} conversationId={conversationId} isGoForDetails={isGoForDetails} handleDetailChange={handleDetailChange} />
        : provider?.includes("customer")
        ? <FeedbackConversations sender={sender} replyText={replyText} conversation={conversation} handlePrevPath={handlePrevPath} handleReplaceConversation={handleReplaceConversation} workspaceSettings={workspaceSettings} getImage={getImage} isReply={isReply} setIsReply={setIsReply} setReplyText={setReplyText} textRef={textRef} handleReply={handleReply} conversationId={conversationId} isGoForDetails={isGoForDetails} handleDetailChange={handleDetailChange} />
        : provider?.includes("video")
        ? <VideoConversations sender={sender} replyText={replyText} conversation={conversation} handlePrevPath={handlePrevPath} handleReplaceConversation={handleReplaceConversation} workspaceSettings={workspaceSettings} getImage={getImage} isReply={isReply} setIsReply={setIsReply} setReplyText={setReplyText} textRef={textRef} handleReply={handleReply} conversationId={conversationId} isGoForDetails={isGoForDetails} handleDetailChange={handleDetailChange} />
        : null
        
      ) : (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <img src={emptyBox} alt="" />
          <h4>Your Wall is Empty.</h4>
          <span>Select an item from inbox to display!</span>
        </div>
      )}
    </React.Fragment>
  );
}

export default Conversations;
