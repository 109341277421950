import { takeEvery, put, call, all, delay } from 'redux-saga/effects';
import axios from "axios";
import Constants from '../../constants';
import { getBotDetailsSettings, setBotDetailsSettings } from './botReducer';
import { generateAccessToken } from '../../profile/reducer';
import { api_url } from '../../utils/server';
import { setSuccessMessage } from '../../dashboard/reducer';
import { setErrorMessage } from '../user-management/userManagementReducer';

// Create Content
export const uploadBotFileData = async(data: any, insertObject?: any) => {
    try {
        const {
            contentFile,
            // isForBioPage,
            contentType
        } = data;
        if (!contentFile) {
            return { success: true, message: "File not found" };
        }
        
        const common_payload: any = {
            clapupUserId: sessionStorage.getItem('cuid'),
            workspaceId: sessionStorage.getItem('wuid'),
            roleId: sessionStorage.getItem('roleId'),
        };

        const url = api_url + "/api/v1.2/gallery/CreateContent";
        const { name: fileName, size, type }: any = contentFile;
        const sizeInBytes = size;
        // Convert bytes to kilobytes (KB)
        const sizeInKB = sizeInBytes / 1024;
        // Convert bytes to megabytes (MB)
        const sizeInMB = sizeInKB / 1024;
        let fileSize = "";
        // if (sizeInMB > 15) {
        //   dispatch(setShowingUploadingProgress(false));
        //   dispatch(setIsContentUploadedError(true));
        //   dispatch(setErrorMessage(`Error. Maximum file size is 15 MB`));
        //   return;
        // }
        if (sizeInMB > 1) {
          fileSize = sizeInMB.toFixed(2) + " MB";
        } else {
          fileSize = sizeInKB.toFixed(2) + " KB";
        }
        let content_payload: any = {
            contentType: contentType || "AIChatBotFiles",
            folderPath: contentType || "AIChatBotFiles",
            subCategory: contentType || "AIChatBotFiles",
            contentText: "",
            isFolder: "0",
            contentFileName: fileName.replaceAll(" ", ""),
            fileSize,
            isAvailableinGallery: false,
            isCreationCompleted: true,
            ...common_payload,
        };

        if (type?.includes("video")) {
            content_payload["contentType"] = "AIChatBotFiles";
            content_payload["folderPath"] = 'AIChatBotFiles';
            content_payload["subCategory"] = "AIChatBotFiles";
        }

        const {
            data: content_response,
            status: content_status
        }: any = await axios.post( url, content_payload, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                'Content-Type': 'application/json'
            }
        });

        if (content_status !== 200) {
            return { success: false, message: "Create content error" };
        }

        const {
            id: contentId, folderPath,
            contentTypeId, workspaceId,
            url: aws_pre_assigned_url,
            contentFileName, uniqueNo
        } = content_response;
        const lastDotIndex: any = contentFileName?.lastIndexOf('.');
        // const new_image_file_name = `${folderPath}/` + contentFileName.slice(0, lastDotIndex) + `_${uniqueNo}.` + contentFileName.slice(lastDotIndex + 1);
        let mimetype = contentFileName?.slice(lastDotIndex + 1);
        if (mimetype) {
            if (mimetype?.toLowerCase() === "pdf") {
                mimetype = "application/pdf";
            } else if (mimetype?.toLowerCase() === "jpg") {
                mimetype = "image/jpeg";
            } else if (mimetype?.toLowerCase() === "jpeg") {
                mimetype = "image/jpeg";
            } else if (mimetype?.toLowerCase() === "png") {
                mimetype = "image/png";
            } else if (mimetype?.toLowerCase() === "svg") {
                mimetype = "image/svg+xml"
            } else if (mimetype?.toLowerCase() === "mp4") {
              mimetype = "video/mp4";
            } else if (mimetype?.toLowerCase() === "mkv") {
                mimetype = "video/x-matroska";
            } else if (mimetype?.toLowerCase() === "webm") {
                mimetype = "video/webm";
            } else if (mimetype?.toLowerCase() === "avi") {
              mimetype = "video/x-msvideo";
            } else if (mimetype?.toLowerCase() === "mov") {
              mimetype = "video/quicktime"
            } else if (mimetype?.toLowerCase() === "wmv") {
              mimetype = "video/x-ms-wmv"
            };
        }
        
        const { status: aws_status }: any = await axios.put(aws_pre_assigned_url, contentFile, {
            headers: {
                'Content-Type': `${mimetype}`, // Set the Content-Type based on the file type
            },
        });

        if (aws_status !== 200) {
            return { success: false, message: "AWS error" };
        }
        
        const cloudFlareR2Path = `${folderPath}@${contentFileName.slice(0, lastDotIndex) + `_${uniqueNo}.` + contentFileName.slice(lastDotIndex + 1)}`;
        const cloud_flare_url = api_url + "/api/v1.2/gallery/UpdateCloudFlareR2UploadStatusAfterContentCreation";
        const { data: cloud_data, status: cloud_status }: any = await axios.post(
            cloud_flare_url,
            { workspaceId, contentId, cloudFlareR2Path },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        // const status = 200;
        if (cloud_status !== 200) {
            return { success: false, message: "Cloudflare status error" };
        }
        
        // const stage_url = api_url + "/api/v1.2/gallery/UpdateContentStages";
        // const { data: publish_stage_response, status: stage_status }: any = await axios.post(
        //     stage_url,
        //     {
        //         ...common_payload,
        //         workspaceId,
        //         contentId,
        //         stageName: "publish",
        //         stageValue: "1"
        //     },
        //     {
        //         headers: {
        //             'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
        //             'Content-Type': 'application/json'
        //         }
        //     }
        // );
        // if (stage_status !== 200) {
        //     return { success: false, message: "Update stage error" };
        // }
        

        // const { data: bio_page_stage_response, status: bio_page_stage_status }: any = await axios.post(
        //     stage_url,
        //     {
        //         ...common_payload,
        //         workspaceId,
        //         contentId,
        //         stageName: "biopage",
        //         stageValue: "1"
        //     },
        //     {
        //         headers: {
        //             'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
        //             'Content-Type': 'application/json'
        //         }
        //     }
        // );


        // const ai_configure_payload = {
        const insert_ai_configure_payload = {
            ...insertObject,
            workspaceId: common_payload?.workspaceId,
            clapupUserId: common_payload?.clapupUserId,
            contentId: contentId,
            // chatBotType: "biopage",
            // chatBotId: data?.aiChatBotId
        };

        // insert api...

        const insert_ai_config_url = api_url + "/api/v1.2/AIChatBot/InsertAIChatBotSettings";
        const { data: insert_ai_response, status: insert_ai_status }: any = await axios.post(
            insert_ai_config_url,
            insert_ai_configure_payload,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        // console.log({open_ai_response, open_ai_status});
        
        if (insert_ai_status !== 200) {
            return {
                success: false,
                message: "insert ai setting error"
            };
        }
        
        const currentContent: any = {
            ...content_response,
            presignedUrl: cloud_data?.presignedUrl,
            contentId
        };

        return {
            success: true,
            data: currentContent,
            message: "Published in Biopage"
        };
        

    } catch (error) {
        console.log('error', error);
        return { success: false, message: "Server error" };
    }
}

// Update Content
export const updateBotFileData = async(data: any) => {
    try {
        const { contentFile, contentType, contentId, uniqueNo, aiChatBotId } = data;

        if (!contentFile) {
            return { success: true, message: "File not found" };
        }
        const common_payload: any = {
            clapupUserId: sessionStorage.getItem('cuid'),
            workspaceId: sessionStorage.getItem('wuid'),
            roleId: sessionStorage.getItem('roleId'),
        };
        const url = api_url + "/api/v1.2/gallery/UploadNewVersion";
        const { name: fileName, size, type } = contentFile;
        const sizeInBytes = size;
        // Convert bytes to kilobytes (KB)
        const sizeInKB = sizeInBytes / 1024;
        // Convert bytes to megabytes (MB)
        const sizeInMB = sizeInKB / 1024;
        let fileSize = "";
        // if (sizeInMB > 15) {
        //   dispatch(setShowingUploadingProgress(false));
        //   dispatch(setIsContentUploadedError(true));
        //   dispatch(setErrorMessage(`Error. Maximum file size is 15 MB`));
        //   return;
        // }
        if (sizeInMB > 1) {
          fileSize = sizeInMB.toFixed(2) + " MB";
        } else {
          fileSize = sizeInKB.toFixed(2) + " KB";
        }
        const content_payload: any = {
            ...common_payload,
            contentId,
            contentFileName: fileName.replaceAll(" ", ""),
            contentSize: fileSize
        };
        const {
            data: content_response,
            status: content_status
        }: any = await axios.post( url, content_payload, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                'Content-Type': 'application/json'
            }
        });

        if (content_status !== 200) {
            return { success: false, message: "Create content error" };
        }

        const { url: aws_pre_assigned_url } = content_response;
        const contentFileName = fileName.replaceAll(" ", "");

        const lastDotIndex: any = contentFileName?.lastIndexOf('.');
        let mimetype = contentFileName?.slice(lastDotIndex + 1);
        if (mimetype) {
            if (mimetype?.toLowerCase() === "pdf") {
                mimetype = "application/pdf";
            } else if (mimetype?.toLowerCase() === "jpg") {
                mimetype = "image/jpeg";
            }
            else if (mimetype?.toLowerCase() === "jpeg") {
                mimetype = "image/jpeg";
            }
            else if (mimetype?.toLowerCase() === "png") {
                mimetype = "image/png";
            }
            else if (mimetype?.toLowerCase() === "svg") {
                mimetype = "image/svg+xml"
            }
            else if (mimetype?.toLowerCase() === "mp4") {
              mimetype = "video/mp4";
            }
            else if (mimetype?.toLowerCase() === "mkv") {
                mimetype = "video/x-matroska";
            }
            else if (mimetype?.toLowerCase() === "webm") {
                mimetype = "video/webm";
            }
            else if (mimetype?.toLowerCase() === "avi") {
              mimetype = "video/x-msvideo";
            }
            else if (mimetype?.toLowerCase() === "mov") {
              mimetype = "video/quicktime"
            }
            else if (mimetype?.toLowerCase() === "wmv") {
              mimetype = "video/x-ms-wmv"
            };
        }
        
        const { status: aws_status }: any = await axios.put(aws_pre_assigned_url, contentFile, {
            headers: {
                'Content-Type': `${mimetype}`, // Set the Content-Type based on the file type
            },
        })

        if (aws_status !== 200) {
            return { success: false, message: "AWS error" };
        }
        const folder_path = contentType || "ImageAssets";

        const cloudFlareR2Path = `${folder_path}@${contentFileName.slice(0, lastDotIndex) + `_${uniqueNo}.` + contentFileName.slice(lastDotIndex + 1)}`;
        const cloud_flare_url = api_url + "/api/v1.2/gallery/UpdateContentAfterNewVersionUpload";
        const { data: cloud_data, status: cloud_status }: any = await axios.post(
            cloud_flare_url,
            { workspaceId: content_payload?.workspaceId , contentId, cloudFlareR2Path },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        // const status = 200;
        if (cloud_status !== 200) {
            return { success: false, message: "Cloudflare status error" };
        }

        // const update_stage_payload = {
        //     ...common_payload,
        //     contentId,
        //     stageName: "publish",
        //     stageValue: "1"
        // };

        // const stage_url = api_url + "/api/v1.2/gallery/UpdateContentStages";
        // const { data: publish_stage_response, status: stage_status }: any = await axios.post(
        //     stage_url,
        //     update_stage_payload,
        //     {
        //         headers: {
        //             'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
        //             'Content-Type': 'application/json'
        //         }
        //     }
        // );

        // if (stage_status !== 200) {
        //     return { success: false, message: "Update stage error" };
        // }

        // const { data: bio_page_stage_response, status: bio_page_stage_status }: any = await axios.post(
        //     stage_url,
        //     {
        //         ...common_payload,
        //         contentId,
        //         stageName: "biopage",
        //         stageValue: "1"
        //     },
        //     {
        //         headers: {
        //             'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
        //             'Content-Type': 'application/json'
        //         }
        //     }
        // );

        // if (bio_page_stage_status !== 200) {
        //     return { success: false, message: "Update bio-page stage error" };
        // }

        const ai_configure_payload = {
            workspaceId: common_payload?.workspaceId,
            clapupUserId: common_payload?.clapupUserId,
            contentId: contentId,
            chatBotType: "biopage",
            chatBotId: aiChatBotId
        };

        const open_ai_config_url = api_url + "/api/v1.2/AIChatBot/ConfigureAIChatBot";
        const { data: open_ai_response, status: open_ai_status }: any = await axios.post(
            open_ai_config_url,
            ai_configure_payload,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        // console.log({open_ai_response, open_ai_status});
        
        if (open_ai_status !== 200) {
            return { success: false, message: "open ai error" };
        }
        

        const currentContent: any = {
            ...content_response,
            presignedUrl: cloud_data?.presignedUrl,
        };

        return {
            success: true,
            data: currentContent
        };

    } catch (error) {
        console.log('error', error);
        return { success: false, message: "Server error" };
    }
}

// Worker Saga:
function* getBotSettingsData(action: any = {}): Generator<any> {
    try {
        const { workspaceId } = action.payload;
        const url = api_url + `/api/v1.2/AIChatBot/GetAIChatBotSettings?workspaceId=${workspaceId}`;
        const { data, status }: any = yield call(axios.get, url, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {
            yield put(setBotDetailsSettings(data?.chatBotDetails || []));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* updateBioPageSettingsData(action: any): Generator<any> {
    console.log({BotManagerPayload: action?.payload});
    
    try {
        const url = api_url + "/api/v1.2/AIChatBot/UpdateAIChatBotSettings";
        const {status}: any = yield call(axios.post, url, action?.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {
            yield put((getBotDetailsSettings()));
            delay(1000);
            yield put(setSuccessMessage("Settings Saved"));
            // yield put(setUpdatedBioPageSettingsData());
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}




// Watcher Saga:
function* botSaga() {
    
    yield all([
        takeEvery(Constants.GET_BOT_DETAILS_SETTINGS, getBotSettingsData),
        takeEvery(Constants.UPDATE_BOT_DETAILS_SETTINGS, updateBioPageSettingsData),
        
    ]);
}

export default botSaga;