import React, { Fragment, useEffect, useState } from "react";
import './review.scss';
import google from '../../assets/img/google-review.png';
import star from '../../assets/img/star-review.png';
import videos from '../../assets/icons/video-chat-line-1.2.svg';
import feedbacks from '../../assets/icons/feedback-line-1.2.svg';
import facebook from '../../assets/icons/facebook-reviews.svg';
import Ratings from "../ratings/ratings";
import Carousel from 'react-bootstrap/Carousel';
import { useSelector } from "react-redux";
interface Props {
    title?: any;
    data?: any;
    img?: any;
}
const Reviews: React.FC<Props> = ({ title, img, data }) => {
    // const { workspaces } = useSelector((state: any) => state?.headerState);
    const {workspaceSettings} = useSelector((state: any) => state?.workspaceManagementState);
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex: any, e: any) => {
        setIndex(selectedIndex);
    };

    // useEffect(() => {
    //     // console.log(img , " ====> ", data);
    // }, [data]);
    const getImage = () => {
        switch (img) {
            case "google":
                return google
            case "star":
                return star
            case "facebook":
                return facebook
            case "customer feedback":
                return videos
            case "video testimonial":
                return feedbacks
            default:
                break;
        }
    }
    // console.log('data', data)
    return (
        <Fragment>
            <div className="review-wrap">

                {
                    img === 'google' &&
                    <div className="review-ratings">
                        {
                            !data?.length
                            ? <div style={{height: '100%'}}>
                                <div className="w-100 title d-flex flex-row justify-content-between">
                                    <h3>Google review</h3>
                                    <img className="" style={{width: 19, height: 19}} alt="Google" src={getImage()} />
                                </div>
                                <div className="no-data">
                                    <p>No data</p>
                                </div>
                            </div>
                            : data?.length === 1
                            ? <div className="carousel-container">
                                {
                                    data.map((e: any, i: any) => {
                                        return (
                                            <div key={i}>
                                                <div className="review-title mb-1">
                                                    <div className="title">
                                                        <h3>{title}</h3>
                                                        <h4>{e.locationName || "Location Name"}</h4>
                                                        {/* <h4>{subTitle}</h4> */}
                                                    </div>
                                                    <div className="description p-0 m-0 d-flex align-items-start justify-content-between">
                                                        <div className="review-replied">
                                                            <span className="d-block count">{e?.ReviewsReplied}</span>
                                                            <small className="d-block reviews">{
                                                                e?.ReviewsReplied > 1 ? 'Reviews ' : 'Review '
                                                            } replied</small>
                                                        </div>
                                                        <img className="mt-0" style={{width: 19, height: 19}} alt="Google" src={getImage()} />
                                                    </div>
                                                </div>
                                                <div className="review-item-wrap">
                                                    <div className="ratings-wrap">
                                                        <div className="rating">
                                                            <div className="star-rating">
                                                                <span className="star-value">{e.AverageRating}</span>
                                                                <Ratings value={e.AverageRating} />
                                                            </div>
                                                        </div>
                                                        <span className="reviews mt-2">
                                                            {e?.TotalReviews || 0} 
                                                            {
                                                                e?.TotalReviews > 1 ? ' Reviews' : ' Review'
                                                            }
                                                        </span>
                                                    </div>
                                                    <div className="count-wrap">
                                                        <span className="count">{e?.AddedToWallCount}</span>
                                                        <span className="count-title">
                                                            {
                                                                e?.AddedToWallCount > 1 ? 'Reviews ' : 'Review '
                                                            } 
                                                            added to wall
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            : <Carousel className="carousel-container" interval={null} activeIndex={index} controls={true} onSelect={handleSelect}>
                                {
                                    data?.map((e: any, i: any) => {
                                        return (
                                            <Carousel.Item key={i}>
                                                <div className="review-title mb-1">
                                                    <div className="title">
                                                        <h3>{title}</h3>
                                                        <h4>{e.locationName || "Location Name"}</h4>
                                                        {/* <h4>{subTitle}</h4> */}
                                                    </div>
                                                    <div className="description p-0 m-0 d-flex align-items-start justify-content-between">
                                                        <div className="review-replied">
                                                            <span className="d-block count">{e?.ReviewsReplied}</span>
                                                            <small className="d-block reviews">{
                                                                e?.ReviewsReplied > 1 ? 'Reviews ' : 'Review '
                                                            } replied</small>
                                                        </div>
                                                        <img className="mt-0" style={{width: 19, height: 19}} alt="Google" src={getImage()} />
                                                    </div>
                                                </div>
                                                <div className="review-item-wrap">
                                                    <div className="ratings-wrap">
                                                        <div className="rating">
                                                            <div className="star-rating">
                                                                <span className="star-value">{e.AverageRating}</span>
                                                                <Ratings value={e.AverageRating} />
                                                            </div>
                                                        </div>
                                                        <span className="reviews mt-2">
                                                            {e?.TotalReviews || 0} 
                                                            {
                                                                e?.TotalReviews > 1 ? ' Reviews' : ' Review'
                                                            }
                                                        </span>
                                                    </div>
                                                    <div className="count-wrap">
                                                        <span className="count">{e?.AddedToWallCount}</span>
                                                        <span className="count-title">
                                                            {
                                                                e?.AddedToWallCount > 1 ? 'Reviews ' : 'Review '
                                                            } 
                                                            added to wall
                                                        </span>
                                                    </div>
                                                </div>
                                            </Carousel.Item>

                                        )
                                    })
                                }
                            </Carousel>
                        }
                    </div>
                }
                {
                    img === 'facebook' &&
                    <div className="review-ratings">
                        {
                            !data?.length
                            ? <div style={{height: '100%'}}>
                                <div className="w-100 title d-flex flex-row justify-content-between">
                                    <h3>Facebook review</h3>
                                    <img className="" style={{width: 19, height: 19}} alt="Google" src={getImage()} />
                                </div>
                                <div className="no-data">
                                    <p>No data</p>
                                </div>
                            </div>
                            : data?.length === 1
                            ? <div className="carousel-container">
                                {
                                    data?.map((e: any, i: any) => {
                                        return (
                                            <div key={i}>
                                                <div className="review-title mb-1">
                                                    <div className="title">
                                                        <h3>{title}</h3>
                                                        <h4>{e?.PageName || "Page Name"}</h4>
                                                    </div>
                                                    <div className="description p-0 m-0 d-flex align-items-start justify-content-between">
                                                        <div className="review-replied">
                                                            <span className="d-block count">{e?.reviewsReplied}</span>
                                                            <small className="d-block reviews">
                                                                {
                                                                    e?.reviewsReplied > 1 ? 'Reviews ' : 'Review '
                                                                } 
                                                                replied
                                                            </small>
                                                        </div>
                                                        <img className="mt-0" style={{width: 19, height: 19}} alt="Google" src={getImage()} />
                                                    </div>
                                                </div>
                                                <div className="review-item-wrap">
                                                    <div className="ratings-wrap">
                                                        <div className="rating">
                                                            <div className="star-rating">
                                                                <span className="star-value">{e?.Rating}</span>
                                                                <Ratings value={e?.Rating} />
                                                            </div>
                                                        </div>
                                                        <span className="reviews mt-2">
                                                            {e?.TotalReviews || 0} 
                                                            {
                                                                e?.TotalReviews > 1 ? ' Reviews' : ' Review'
                                                            }
                                                        </span>
                                                        {/* <span className="reviews mt-2">{e.TOTALREVIEWS}</span> */}
                                                    </div>
                                                    <div className="count-wrap">
                                                        <span className="count d-block">{e.WallCount}</span>
                                                        <span className="d-block count-title">
                                                            {
                                                                e?.WallCount > 1 ? 'Reviews ' : 'Review '
                                                            } 
                                                            added to wall
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            : 
                            <Carousel 
                                className="carousel-container"
                                interval={null}
                                activeIndex={index}
                                controls={true} 
                                onSelect={handleSelect}
                            >
                                {
                                    data?.map((e: any, i: any) => {
                                        return (
                                            <Carousel.Item key={i}>
                                                <div className="review-title mb-1">
                                                    <div className="title">
                                                        <h3>{title}</h3>
                                                        <h4>{e?.PageName || "Page Name"}</h4>
                                                    </div>
                                                    <div className="description p-0 m-0 d-flex align-items-start justify-content-between">
                                                        <div className="review-replied">
                                                            <span className="d-block count">{e?.reviewsReplied}</span>
                                                            <small className="d-block reviews">
                                                                {
                                                                    e?.reviewsReplied > 1 ? 'Reviews ' : 'Review '
                                                                } 
                                                                replied
                                                            </small>
                                                        </div>
                                                        <img className="mt-0" style={{width: 19, height: 19}} alt="Google" src={getImage()} />
                                                    </div>
                                                </div>
                                                <div className="review-item-wrap">
                                                    <div className="ratings-wrap">
                                                        <div className="rating">
                                                            <div className="star-rating">
                                                                <span className="star-value">{e?.Rating}</span>
                                                                <Ratings value={e?.Rating} />
                                                            </div>
                                                        </div>
                                                        <span className="reviews mt-2">
                                                            {e?.TotalReviews || 0} 
                                                            {
                                                                e?.TotalReviews > 1 ? ' Reviews' : ' Review'
                                                            }
                                                        </span>
                                                        {/* <span className="reviews mt-2">{e.TOTALREVIEWS}</span> */}
                                                    </div>
                                                    <div className="count-wrap">
                                                        <span className="count d-block">{e.WallCount}</span>
                                                        <span className="d-block count-title">
                                                            {
                                                                e?.WallCount > 1 ? 'Reviews ' : 'Review '
                                                            } 
                                                            added to wall
                                                        </span>
                                                    </div>
                                                </div>
                                            </Carousel.Item>
                                        )
                                    })
                                }
                            </Carousel>
                        }
                        
                    </div>
                }
                {
                    img === 'CustomerFeedback' &&
                    <div className="review-ratings">
                        <Carousel indicators={false} nextIcon="" prevIcon="" interval={null} activeIndex={index} controls={true} onSelect={handleSelect}>
                            {
                                data !== undefined && data.length ? data.map((e: any, i: any) => {
                                    return (
                                        <Carousel.Item key={i}>
                                            <div className="review-title mb-1">
                                                <div className="title">
                                                    <h3>{title}</h3>
                                                    <h4>{e?.locationName || workspaceSettings?.workspaceName || "WS Name"}</h4>
                                                </div>
                                                <div className="description p-0 m-0 d-flex align-items-start justify-content-between">
                                                    <div className="review-replied">
                                                        <span className="d-block count">{e?.CustomerFeedbackRequestCount}</span>
                                                        <small className="d-block reviews">
                                                            Feedback 
                                                            {
                                                                e?.CustomerFeedbackRequestCount > 1 ? ' requests' : ' request'
                                                            }
                                                        </small>
                                                    </div>
                                                    <img className="mt-0" style={{width: 19, height: 19}} alt="Google" src={feedbacks} />
                                                </div>
                                            </div>
                                            <div className="review-item-wrap">
                                                <div className="ratings-wrap">
                                                    <div className="rating">
                                                        <div className="star-rating">
                                                            <span className="star-value">{Math.ceil(e?.AverageRating)}</span>
                                                            <Ratings value={e?.AverageRating < 1 ? 1 : Math.ceil(e?.AverageRating)} />
                                                        </div>
                                                    </div>
                                                    <span className="reviews mt-2">
                                                        {e?.CustomerFeedbackCount}
                                                        {
                                                            e?.CustomerFeedbackCount > 1 ? ' Feedbacks' : ' Feedback'
                                                        }
                                                    </span>
                                                </div>
                                                <div className="count-wrap">
                                                    <span className="count">{e.CustomerFeedbackWallCount}</span>
                                                    <span className="count-title">
                                                        {
                                                            e?.CustomerFeedbackWallCount > 1 ? 'Feedbacks ' : 'Feedback '
                                                        } 
                                                        added to wall
                                                    </span>
                                                </div>
                                            </div>
                                        </Carousel.Item>
                                    )
                                }) : null
                            }
                        </Carousel>
                    </div>
                }
                {
                    img === 'VideoTestimonials' &&
                    <div className="review-ratings">
                        <Carousel interval={null} nextIcon="" prevIcon="" activeIndex={index} controls={true} onSelect={handleSelect}>
                            {
                                data !== undefined && data?.length ? data.map((e: any, i: any) => {
                                    return (
                                        <Carousel.Item key={i}>
                                            <div className="review-title mb-1">
                                                <div className="title">
                                                    <h3>{title}</h3>
                                                    <h4>{e?.locationName || workspaceSettings?.workspaceName || "WS Name"}</h4>
                                                </div>
                                                <div className="description p-0 m-0 d-flex align-items-start justify-content-between">
                                                    <div className="review-replied">
                                                        <span className="d-block count">{e?.VideoTestimonialRequestCount || 0}</span>
                                                        <small className="d-block reviews">
                                                            Feedback 
                                                            {
                                                                e?.VideoTestimonialRequestCount > 1 ? ' requests ' : ' request '
                                                            }
                                                        </small>
                                                    </div>
                                                    <img className="mt-0" style={{width: 19, height: 19}} alt="videos" src={videos} />
                                                </div>
                                            </div>
                                            <div className="review-item-wrap">
                                                <div className="ratings-wrap">
                                                    <div className="rating">
                                                        <div className="star-rating">
                                                            <span className="star-value">{Math.ceil(e?.AverageRating)}</span>
                                                            <Ratings value={e?.AverageRating < 1 ? 1 : Math.ceil(e?.AverageRating)} />
                                                        </div>
                                                    </div>
                                                    <span className="reviews mt-2">
                                                        {e?.VideoTestimonialCount}
                                                        {
                                                            e?.VideoTestimonialCount > 1 ? ' Videos ' : ' Video '
                                                        }
                                                    </span>
                                                </div>
                                                <div className="count-wrap">
                                                    <span className="count">{e?.VideoTestimonialWallCount}</span>
                                                    <span className="count-title">
                                                        {
                                                            e?.VideoTestimonialWallCount > 1 ? 'Videos ' : 'Video '
                                                        } 
                                                        added to wall
                                                    </span>
                                                </div>
                                            </div>
                                        </Carousel.Item>

                                    )
                                }) : null
                            }
                        </Carousel>
                    </div>
                }

            </div>
        </Fragment>
    )
}

export default Reviews;