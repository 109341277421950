import Constants from "../../constants";

const initialState = {
    notifications: [],
    personalInfo: {},
    loading: false,
    error: null
}

// get workspaces
export const getNotificationSettings = (data: any = null) => {

    return {
        type: Constants?.GET_NOTIFICATION_SETTINGS,
        payload: data
    };
}

// notification list

export const setNotificationSettingList = (data = {}) => {

    return {
        type: Constants?.GET_NOTIFICATION_SETTING_LIST,
        payload: data
    };
}

export const updateNotification = (data: any = null) => {
    return {
        type: Constants?.UPDATE_NOTIFICATION_PREFERENCE,
        payload: data
    };
}

// profile info

export const getProfileInfo = (data: any = null) => {
    return {
        type: Constants?.GET_PROFILE_INFO,
        payload: data
    };
}

export const updateProfileInfoSetting = (data: any = null) => {
    return {
        type: Constants?.UPDATE_PROFILE_INFO_SETTING,
        payload: data
    };
}

export const updateProfileImage = (data: any = null) => {
    return {
        type: Constants?.UPLOAD_PROFILE_IMAGE,
        payload: data
    };
}

export const setProfileInfo = (data: any = null) => {
    return {
        type: Constants?.SET_PROFILE_INFO,
        payload: data
    };
}


const profileManageSettingReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case Constants.GET_NOTIFICATION_SETTING_LIST:
            return {
                ...state,
                notifications: action.payload
            };

        case Constants.SET_PROFILE_INFO:
            return {
                ...state,
                personalInfo: action?.payload
            };

        default:
            return state;
    }
}

export default profileManageSettingReducer;